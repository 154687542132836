import React, { createContext, useContext, useState, useCallback, useEffect } from 'react';
import { updatePublicProfileFields, getPublicProfileByUsername, listenToPublicProfile } from '../firebase/PublicProfile';
import { fetchPostsByUser } from '../firebase/Posts';
import { getUserRoutines } from '../firebase/Routines';
import { getFollowingByUserId } from '../firebase/Following';
import { getPublicProfileByUserId } from '../firebase/PublicProfile';

// Import other necessary functions for exercises, meals, etc.

const ProfileContext = createContext();

export const useProfile = () => useContext(ProfileContext);

export const ProfileProvider = ({ children }) => {
    // Basic profile data
    const [profileData, setProfileData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [currentUsername, setCurrentUsername] = useState(null);
    
    // Content states
    const [profilePosts, setProfilePosts] = useState([]);
    const [profileRoutines, setProfileRoutines] = useState([]);
    const [profileExercises, setProfileExercises] = useState([]);
    const [profileMeals, setProfileMeals] = useState([]);
    
    // Loading states for different content types
    const [postsLoading, setPostsLoading] = useState(false);
    const [routinesLoading, setRoutinesLoading] = useState(false);
    const [exercisesLoading, setExercisesLoading] = useState(false);
    const [mealsLoading, setMealsLoading] = useState(false);

    // Get profile by username
    const getProfileByUsername = useCallback(async (username) => {
        // If we already have this username loaded, don't fetch again
        if (currentUsername === username && profileData) {
            return profileData;
        }
        
        setLoading(true);
        setCurrentUsername(username);
        try {
            const profile = await getPublicProfileByUsername(username);
            setProfileData(profile);
            return profile;
        } catch (error) {
            console.error('Error fetching profile:', error);
            return null;
        } finally {
            setLoading(false);
        }
    }, [currentUsername, profileData]);

    // Update profile data
    const updateProfileData = useCallback(async (userId, updates) => {
        try {
            await updatePublicProfileFields(userId, updates);
            if (profileData?.id === userId) {
                setProfileData(prev => ({ ...prev, ...updates }));
            }
        } catch (error) {
            console.error('Error updating profile:', error);
            throw error;
        }
    }, [profileData]);

    // Load posts for the profile
    const loadProfilePosts = useCallback(async (userId) => {
        if (!userId) return null;
        
        // If we already have posts for this user and they're loading, don't start another fetch
        if (postsLoading) {
            console.log(`[ProfileContext] Already loading posts for user: ${userId}`);
            return null;
        }
        
        setPostsLoading(true);
        console.log(`[ProfileContext] Loading posts for user: ${userId}`);
        
        // Return the unsubscribe function
        return fetchPostsByUser(userId, (postsData) => {
            console.log(`[ProfileContext] Received ${postsData.length} posts for user ${userId}`);
            setProfilePosts(postsData);
            setPostsLoading(false);
        });
    }, [postsLoading]);

    // Load routines for the profile
    const loadProfileRoutines = useCallback(async (userId) => {
        if (!userId) return;
        
        setRoutinesLoading(true);
        try {
            const routines = await getUserRoutines(userId);
            console.log(`[ProfileContext] Loaded ${routines.length} routines for user ${userId}`);
            setProfileRoutines(routines);
        } catch (error) {
            console.error('[ProfileContext] Error loading routines:', error);
        } finally {
            setRoutinesLoading(false);
        }
    }, []);

    // Load exercises for the profile - implement similar to routines
    const loadProfileExercises = useCallback(async (userId) => {
        if (!userId) return;
        
        setExercisesLoading(true);
        try {
            // Replace with your actual function to fetch user exercises
            // const exercises = await getUserExercises(userId);
            const exercises = []; // Placeholder
            console.log(`[ProfileContext] Loaded ${exercises.length} exercises for user ${userId}`);
            setProfileExercises(exercises);
        } catch (error) {
            console.error('[ProfileContext] Error loading exercises:', error);
        } finally {
            setExercisesLoading(false);
        }
    }, []);

    // Load meals for the profile - implement similar to routines
    const loadProfileMeals = useCallback(async (userId) => {
        if (!userId) return;
        
        setMealsLoading(true);
        try {
            // Replace with your actual function to fetch user meals
            // const meals = await getUserMeals(userId);
            const meals = []; // Placeholder
            console.log(`[ProfileContext] Loaded ${meals.length} meals for user ${userId}`);
            setProfileMeals(meals);
        } catch (error) {
            console.error('[ProfileContext] Error loading meals:', error);
        } finally {
            setMealsLoading(false);
        }
    }, []);

    // Clear all profile data when changing profiles
    const clearProfileData = useCallback(() => {
        setProfilePosts([]);
        setProfileRoutines([]);
        setProfileExercises([]);
        setProfileMeals([]);
    }, []);

    // Effect to clear data when username changes
    useEffect(() => {
        clearProfileData();
    }, [currentUsername, clearProfileData]);

    // Add this function to listen for profile updates
    const listenToProfileUpdates = useCallback((userId) => {
        if (!userId) return null;
        
        return listenToPublicProfile(userId, (updatedProfile, error) => {
            if (error) {
                console.error("Error in profile listener:", error);
                return;
            }
            
            if (updatedProfile) {
                setProfileData(updatedProfile);
            }
        });
    }, []);

    // Add this function to the ProfileContext
    const getFollowingProfiles = useCallback(async (userId) => {
        if (!userId) return [];
        
        try {
            // This should match how you're getting following from your FollowingContext
            const followingList = await getFollowingByUserId(userId);
            
            // Now fetch each user's profile information
            // You may need to adjust this based on your actual data structure
            const profiles = await Promise.all(
                followingList.map(async (followingItem) => {
                    // Assuming followingItem has the id of the user being followed
                    const followedUserId = followingItem.followedUserId || followingItem.id;
                    // Fetch the profile for this user
                    // You may need to import this function from firebase/PublicProfile
                    const profile = await getPublicProfileByUserId(followedUserId);
                    return profile || { id: followedUserId };
                })
            );
            
            return profiles.filter(profile => profile !== null);
        } catch (error) {
            console.error('Error fetching following profiles:', error);
            return [];
        }
    }, []);

    const value = {
        // Basic profile data
        profileData,
        loading,
        getProfileByUsername,
        updateProfileData,
        currentUsername,
        
        // Posts
        profilePosts,
        postsLoading,
        loadProfilePosts,
        
        // Routines
        profileRoutines,
        routinesLoading,
        loadProfileRoutines,
        
        // Exercises
        profileExercises,
        exercisesLoading,
        loadProfileExercises,
        
        // Meals
        profileMeals,
        mealsLoading,
        loadProfileMeals,
        
        // Utilities
        clearProfileData,
        listenToProfileUpdates,
        
        // Add the new function
        getFollowingProfiles,
    };

    return (
        <ProfileContext.Provider value={value}>
            {children}
        </ProfileContext.Provider>
    );
}; 