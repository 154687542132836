import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Card, CardContent } from "../components/ui/card";
import { useScan } from '../contexts/ScanContext';
import LoadingGradient from '../components/ui/LoadingGradient';
import { ResponsiveRadar } from '@nivo/radar';
import { ResponsiveLine } from '@nivo/line';
import { colorSchemes } from '@nivo/colors';
import { useHeader } from '../contexts/HeaderContext';
import FeedbackModal from '../components/FeedbackModal';

const AnimatedDial = ({ score }) => {
	const normalizedScore = score / 10; // Convert score to a 0-1 range
	const endAngle = -180 + (180 * normalizedScore); // Calculate end angle based on score

	// Generate the arc path
	const generateArcPath = (start, end) => {
		const startAngle = start * (Math.PI / 180);
		const endAngle = end * (Math.PI / 180);
		const rx = 90;
		const ry = 90;
		const xAxisRotation = 0;
		const largeArcFlag = end - start <= 180 ? "0" : "1";
		const sweepFlag = 1;
		const x1 = 100 + rx * Math.cos(startAngle);
		const y1 = 110 + ry * Math.sin(startAngle);
		const x2 = 100 + rx * Math.cos(endAngle);
		const y2 = 110 + ry * Math.sin(endAngle);

		return `M ${x1} ${y1} A ${rx} ${ry} ${xAxisRotation} ${largeArcFlag} ${sweepFlag} ${x2} ${y2}`;
	};

	return (
		<svg className="w-full" viewBox="0 0 200 120">
			{/* Background Arc */}
			<path
				d={generateArcPath(-180, 0)}
				fill="none"
				stroke="#333"
				strokeWidth="4"
				strokeDasharray="4 4"
			/>
			{/* Score Arc */}
			<motion.path
				d={generateArcPath(-180, endAngle)}
				fill="none"
				stroke="white"
				strokeWidth="4"
				initial={{ pathLength: 0 }}
				animate={{ pathLength: 1 }}
				transition={{ duration: 1, ease: "easeOut" }}
			/>
			{/* Tick marks */}
			{[...Array(81)].map((_, i) => {
				const angle = -180 + i * 4.5;
				if (angle <= endAngle) {
					const x1 = 100 + 85 * Math.cos(angle * Math.PI / 180);
					const y1 = 110 + 85 * Math.sin(angle * Math.PI / 180);
					const x2 = 100 + (i % 2 === 0 ? 75 : 80) * Math.cos(angle * Math.PI / 180);
					const y2 = 110 + (i % 2 === 0 ? 75 : 80) * Math.sin(angle * Math.PI / 180);
					return (
						<motion.line
							key={i}
							x1={x1}
							y1={y1}
							x2={x2}
							y2={y2}
							stroke="white"
							strokeWidth={i % 2 === 0 ? "2" : "1"}
							initial={{ opacity: 0 }}
							animate={{ opacity: 1 }}
							transition={{ duration: 0.05, delay: i * 0.01, ease: "easeOut" }}
						/>
					);
				}
				return null;
			})}
			{/* Score */}
			<motion.text
				x="100"
				y="90"
				textAnchor="middle"
				fill="white"
				fontSize="48"
				fontWeight="bold"
				initial={{ opacity: 0 }}
				animate={{ opacity: 1 }}
				transition={{ duration: 0.5, delay: 0.8, ease: "easeOut" }}
			>
				<motion.tspan
					initial={{ y: 20 }}
					animate={{ y: 0 }}
					transition={{ duration: 0.5, delay: 0.8, ease: "easeOut" }}
				>
					{score}
				</motion.tspan>
			</motion.text>
			{/* Overall text */}
			<motion.text
				x="100"
				y="115"
				textAnchor="middle"
				fill="white"
				fontSize="16"
				initial={{ opacity: 0 }}
				animate={{ opacity: 1 }}
				transition={{ duration: 0.5, delay: 1.3, ease: "easeOut" }}
			>
				OVERALL
			</motion.text>
		</svg>
	);
};

const ScoreBar = ({ name, score, onSelect, isSelected }) => (
	<div
		className={`flex items-center mb-4 cursor-pointer transition-colors duration-200 ${isSelected ? 'bg-gray-100' : 'hover:bg-gray-50'}`}
		onClick={() => onSelect(name)}
	>
		<div className="flex items-center w-full p-2">
			<div className="flex flex-col w-20 sm:w-24 mr-2 flex-shrink-0">
				<div className={`text-white text-center py-1 px-2 ${isSelected ? 'bg-black' : 'bg-gray-800'}`}>
					{score.toFixed(1)}
				</div>
				<div className="bg-gray-200 text-gray-800 text-center py-1 px-2 text-xs">
					{name}
				</div>
			</div>
			<div className="flex-grow mx-2">
				<div className="bg-gray-200 h-6 overflow-hidden">
					<div
						className={`h-full transition-all duration-300 ease-out ${isSelected ? 'bg-black' : 'bg-gray-800'}`}
						style={{ width: `${score * 10}%` }}
					/>
				</div>
			</div>
			<div className={`w-20 sm:w-24 md:w-28 text-right flex-shrink-0 flex items-center justify-end ${isSelected ? 'text-black font-semibold' : 'text-gray-500'}`}>
				<span className="text-[10px] sm:text-xs md:text-sm mr-1">SEE DETAILS</span>
				<svg
					className="w-3 h-3 sm:w-4 sm:h-4"
					viewBox="0 0 24 24"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M9 18L15 12L9 6"
						stroke="currentColor"
						strokeWidth="2"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</svg>
			</div>
		</div>
	</div>
);

const NoScanWarning = () => (
	<div className="flex flex-col items-center justify-center h-full text-gray-500 font-['Roboto_Condensed']">
		<svg className="w-16 h-16 mb-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
			<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
		</svg>
		<p className="text-xl font-bold mb-2">No Scans Available</p>
		<p className="text-center">Take a new scan to see your results!</p>
	</div>
);

const Legend = ({ data, colors }) => {
	return (
		<div className="flex flex-wrap justify-center items-start bg-white p-2 rounded-md shadow-md mb-4 font-['Roboto_Condensed']">
			{data.map((item, index) => (
				<div key={item} className="mx-2 my-1">
					<span
						className="text-sm font-semibold"
						style={{ color: colors[index % colors.length] }}
					>
						{item}
					</span>
				</div>
			))}
		</div>
	);
};

const ScanChart = ({ data }) => {
	if (!data || data.length < 2) {
		return <div>Not enough data for comparison</div>;
	}

	const formatDateTime = (timestamp) => {
		const date = new Date(timestamp);
		return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
	};

	const lastTwoScans = data.slice(-2);
	const chartData = Object.keys(lastTwoScans[0].scores.Evaluation).map(key => ({
		key,
		[formatDateTime(lastTwoScans[0].timestamp)]: lastTwoScans[0].scores.Evaluation[key].Score,
		[formatDateTime(lastTwoScans[1].timestamp)]: lastTwoScans[1].scores.Evaluation[key].Score,
	}));

	const legendData = [formatDateTime(lastTwoScans[1].timestamp), formatDateTime(lastTwoScans[0].timestamp)];
	const colors = ['#ff6b6b', '#4ecdc4'];

	return (
		<div className="w-full h-full flex flex-col items-center justify-center p-4">
			<Legend data={legendData} colors={colors} />
			<div style={{ width: '100%', height: 'calc(100% - 50px)' }}>
				<ResponsiveRadar
					data={chartData}
					keys={legendData}
					indexBy="key"
					maxValue={10}
					margin={{ top: 20, right: 80, bottom: 40, left: 80 }}
					curve="linearClosed"
					borderWidth={2}
					borderColor={{ from: 'color' }}
					gridLabelOffset={15}
					dotSize={8}
					dotColor={{ theme: 'background' }}
					dotBorderWidth={2}
					colors={colors}
					blendMode="multiply"
					motionConfig="wobbly"
					gridLevels={3}
					gridLabels={(value) => (value % 5 === 0 ? value : '')}
					theme={{
						fontSize: 11,
						textColor: '#333333',
					}}
				/>
			</div>
		</div>
	);
};

const ScansOverTime = ({ data }) => {
	if (!data || data.length === 0) {
		return <div>No scan data available</div>;
	}

	const formatDateTime = (timestamp) => {
		const date = new Date(timestamp);
		return `${date.toLocaleDateString('en-US', { month: 'short', day: 'numeric' })} ${date.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' })}`;
	};

	// Sort the data chronologically
	const sortedData = [...data].sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));

	const chartData = Object.keys(sortedData[0].scores.Evaluation).map(key => ({
		id: key,
		data: sortedData.map(scan => ({
			x: formatDateTime(scan.timestamp),
			y: scan.scores.Evaluation[key].Score
		}))
	}));

	const colors = colorSchemes.category10;

	return (
		<div className="w-full h-full flex flex-col items-center justify-center p-4">
			<Legend data={chartData.map(item => item.id)} colors={colors} />
			<div style={{ width: '100%', height: 'calc(100% - 50px)' }}>
				<ResponsiveLine
					data={chartData}
					margin={{ top: 20, right: 10, bottom: 50, left: 60 }}
					xScale={{ type: 'point' }}
					yScale={{ type: 'linear', min: 0, max: 10, stacked: false, reverse: false }}
					yFormat=" >-.2f"
					axisTop={null}
					axisRight={null}
					axisBottom={{
						tickSize: 5,
						tickPadding: 5,
						tickRotation: 0,
						legend: 'Date',
						legendOffset: 40,
						legendPosition: 'middle'
					}}
					axisLeft={{
						tickSize: 5,
						tickPadding: 5,
						tickRotation: 0,
						legend: 'Score',
						legendOffset: -40,
						legendPosition: 'middle'
					}}
					pointSize={10}
					pointColor={{ theme: 'background' }}
					pointBorderWidth={2}
					pointBorderColor={{ from: 'serieColor' }}
					pointLabelYOffset={-12}
					useMesh={true}
					legends={[]}
					colors={colors}
					theme={{
						fontSize: 20,
						textColor: '#333333',
					}}
				/>
			</div>
		</div>
	);
};

const Home = () => {
	const { scanHistory, loading } = useScan();
	const [selectedCategory, setSelectedCategory] = useState(null);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isMobile, setIsMobile] = useState(false);
	const [activeTab, setActiveTab] = useState('scores');
	const { updateHeader } = useHeader();
	const [categoryData, setCategoryData] = useState(null);

	useEffect(() => {
		if (scanHistory.length > 0) {
			const categories = Object.keys(scanHistory[0].scores.Evaluation);
			if (categories.length > 0) {
				setSelectedCategory(categories[0]);
			}
		}

		const checkIfMobile = () => {
			setIsMobile(window.innerWidth < 768);
		};

		checkIfMobile();
		window.addEventListener('resize', checkIfMobile);

		return () => window.removeEventListener('resize', checkIfMobile);
	}, [scanHistory]);

	useEffect(() => {
		updateHeader('HOME');
		
		return () => updateHeader('');
	}, [updateHeader]);

	// Update category data whenever selectedCategory changes
	useEffect(() => {
		if (scanHistory.length > 0 && selectedCategory) {
			setCategoryData({
				name: selectedCategory,
				feedback: scanHistory[0].scores.Evaluation[selectedCategory]?.Feedback || '',
				tips: scanHistory[0].scores.Evaluation[selectedCategory]?.Tips || []
			});
		}
	}, [selectedCategory, scanHistory]);

	const renderContent = () => {
		if (loading) {
			return <LoadingGradient className="h-full w-full rounded" />;
		}

		if (scanHistory.length === 0) {
			return (
				<div className="flex flex-col md:flex-row gap-6 h-full">
					<div className="flex flex-col gap-4 w-full md:w-1/2 h-full">
						<Card className="bg-black flex-1">
							<CardContent className="p-6">
								<NoScanWarning />
							</CardContent>
						</Card>
						<Card className="bg-white flex-1">
							<CardContent className="p-6 flex items-center justify-center">
								<p className="text-gray-500 text-center">Complete a scan to see your scores</p>
							</CardContent>
						</Card>
					</div>
					<div className="flex w-full md:w-1/2 h-full">
						<Card className="flex-1">
							<CardContent className="p-6 flex items-center justify-center">
								<p className="text-gray-500 text-center">Scan details will appear here</p>
							</CardContent>
						</Card>
					</div>
				</div>
			);
		}

		if (isMobile) {
			switch (activeTab) {
				case 'scores':
					return renderScoresTab();
				case 'chart':
					return (
						<Card className="h-full">
							<CardContent className="h-full flex flex-col">
								<h2 className="text-xl font-bold uppercase mb-2">Scan Chart</h2>
								<div className="flex-grow">
									<ScanChart data={scanHistory} />
								</div>
							</CardContent>
						</Card>
					);
				case 'graph':
					return (
						<Card className="h-full">
							<CardContent className="h-full flex flex-col">
								<h2 className="text-xl font-bold uppercase mb-2">Scans Over Time</h2>
								<div className="flex-grow">
									<ScansOverTime data={scanHistory} />
								</div>
							</CardContent>
						</Card>
					);
				default:
					return null;
			}
		} else {
			return renderDesktopLayout();
		}
	};

	const renderScoresTab = () => {
		const mostRecentScan = scanHistory[0];
		const overallScore = mostRecentScan.scores["Overall Score"];
		const categories = Object.entries(mostRecentScan.scores.Evaluation).map(([name, data]) => ({
			name,
			score: data.Score,
			feedback: data.Feedback,
			tips: data.Tips
		}));

		return (
			<div className="flex flex-col gap-4 w-full h-full">
				<Card className="bg-black flex-1">
					<CardContent className="p-6">
						<AnimatedDial score={parseFloat(overallScore.toFixed(1))} />
					</CardContent>
				</Card>
				<Card className="bg-white flex-1">
					<CardContent className="p-0">
						{categories.map((category) => (
							<ScoreBar
								key={category.name}
								name={category.name}
								score={category.score}
								onSelect={handleCategorySelect}
								isSelected={category.name === selectedCategory}
							/>
						))}
					</CardContent>
				</Card>
			</div>
		);
	};

	const renderDesktopLayout = () => {
		const mostRecentScan = scanHistory[0];
		const overallScore = mostRecentScan.scores["Overall Score"];
		const categories = Object.entries(mostRecentScan.scores.Evaluation).map(([name, data]) => ({
			name,
			score: data.Score,
			feedback: data.Feedback,
			tips: data.Tips
		}));

		return (
			<div className="flex flex-col gap-6 h-full">
				<div className="flex flex-row gap-6 h-1/2">
					<Card className="bg-black w-1/2">
						<CardContent className="p-6">
							<AnimatedDial score={parseFloat(overallScore.toFixed(1))} />
						</CardContent>
					</Card>
					<Card className="w-1/2">
						<CardContent className="h-full flex flex-col">
							<h2 className="text-xl font-bold uppercase mb-2">Scan Chart</h2>
							<div className="flex-grow">
								<ScanChart data={scanHistory} />
							</div>
						</CardContent>
					</Card>
				</div>
				<div className="flex flex-row gap-6 h-1/2">
					<Card className="bg-white w-1/2">
						<CardContent className="p-0">
							{categories.map((category) => (
								<ScoreBar
									key={category.name}
									name={category.name}
									score={category.score}
									onSelect={handleCategorySelect}
									isSelected={category.name === selectedCategory}
								/>
							))}
						</CardContent>
					</Card>
					<Card className="w-1/2">
						<CardContent className="h-full flex flex-col">
							<h2 className="text-xl font-bold uppercase mb-2">Scans Over Time</h2>
							<div className="flex-grow">
								<ScansOverTime data={scanHistory} />
							</div>
						</CardContent>
					</Card>
				</div>
			</div>
		);
	};

	const handleCategorySelect = (name) => {
		setSelectedCategory(name);
		setIsModalOpen(true);
	};

	const TabButton = ({ name, label }) => (
		<motion.button
			whileHover={{ scale: 1.05 }}
			whileTap={{ scale: 0.95 }}
			className={`flex-1 px-4 py-2 font-semibold 
				${activeTab === name
					? 'bg-white text-black border-2 border-black'
					: 'bg-black text-white'
				}`}
			onClick={() => setActiveTab(name)}
		>
			{label}
		</motion.button>
	);

	return (
		<div className="flex flex-col h-full">
			{isMobile && scanHistory.length > 0 && (
				<div className="flex bg-black mb-4 overflow-hidden w-[90%] mx-auto">
					<TabButton name="scores" label="SCORES" />
					<TabButton name="chart" label="CHART" />
					<TabButton name="graph" label="GRAPH" />
				</div>
			)}
			<div className="w-[90%] mx-auto h-full">
				{renderContent()}
			</div>

			<FeedbackModal
				category={categoryData}
				isOpen={isModalOpen}
				onClose={() => setIsModalOpen(false)}
			/>
		</div>
	);
};

export default Home;
