// Function to extract UTM parameters from URL
export function getUtmParams() {
    const urlParams = new URLSearchParams(window.location.search);
    const utmParams = {};
    
    // Extract all utm parameters
    const utmSource = urlParams.get('utm_source');
    const utmMedium = urlParams.get('utm_medium');
    const utmCampaign = urlParams.get('utm_campaign');
    const utmContent = urlParams.get('utm_content');
    const utmTerm = urlParams.get('utm_term');
    
    // Only add parameters that exist
    if (utmSource) utmParams.utm_source = utmSource;
    if (utmMedium) utmParams.utm_medium = utmMedium;
    if (utmCampaign) utmParams.utm_campaign = utmCampaign;
    if (utmContent) utmParams.utm_content = utmContent;
    if (utmTerm) utmParams.utm_term = utmTerm;
    
    return utmParams;
}

// Function to retrieve stored UTM parameters
export function getStoredUtmParams() {
    const storedParams = sessionStorage.getItem('utm_params');
    return storedParams ? JSON.parse(storedParams) : {};
}

// Function to store UTM parameters for later use
export function storeUtmParams() {
    const params = getUtmParams();
    if (Object.keys(params).length > 0) {
        sessionStorage.setItem('utm_params', JSON.stringify(params));
    }
    return params;
}