import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { usePost } from '../../contexts/PostContext';
import { useAuth } from '../../contexts/AuthContext';
import { useExercise } from '../../contexts/ExerciseContext';
import { useCreate } from '../../contexts/CreateContext';
import WarningModal from '../../components/ui/WarningModal';
import FixedBottomButton from '../../components/ui/FixedBottomButton';
import { trackPostCreation } from '../../analytics/conversations';
import ReactDOM from 'react-dom';
import { checkTextForInappropriateContent } from '../../firebase/NSFWCheck';

// Post tag categories
const POST_TAG_GROUPS = {
  'Performance-Based': [
    'Personal Record (PR)',
    'Progress Update',
    'Challenge Completed',
    'Goal Achieved'
  ],
  'Workout & Training': [
    'Workout Routine',
    'Training Tip',
    'Warmup & Mobility',
    'Conditioning'
  ],
  'Bodybuilding & Aesthetics': [
    'Physique Update',
    'Bulking Phase',
    'Cutting Phase',
    'Competition Prep'
  ],
  'Nutrition & Meal Plans': [
    'Meal Plan',
    'High Protein Meal',
    'Supplement Stack',
    'Macros & Dieting'
  ],
  'Miscellaneous & Fun': [
    'Gym Fail',
    'Lifting Memes',
    'Gym Culture',
    'Rankings & Debates'
  ]
};

// Post Tags Modal Component
const PostTagsModal = ({ isOpen, onClose, selectedTags, onTagsChange }) => {
  const [expandedTagGroups, setExpandedTagGroups] = useState({});

  if (!isOpen) return null;

  const toggleTagGroup = (groupName) => {
    setExpandedTagGroups(prev => ({
      ...prev,
      [groupName]: !prev[groupName]
    }));
  };

  const handleTagToggle = (tag) => {
    if (selectedTags.includes(tag)) {
      onTagsChange(selectedTags.filter(t => t !== tag));
    } else {
      onTagsChange([...selectedTags, tag]);
    }
  };

  return ReactDOM.createPortal(
    <div className="fixed inset-0 z-50 flex items-center justify-center backdrop-blur-sm bg-black bg-opacity-75 md:pl-16 lg:pl-64">
      <div className="bg-white text-black w-11/12 max-w-3xl mx-auto overflow-hidden relative border border-black max-h-[90vh] flex flex-col">
        {/* Close button - absolute positioned */}
        <button 
          onClick={onClose}
          className="absolute top-4 right-4 text-black"
          aria-label="Close"
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
            <line x1="18" y1="6" x2="6" y2="18"></line>
            <line x1="6" y1="6" x2="18" y2="18"></line>
          </svg>
        </button>
        
        {/* Centered icon and title */}
        <div className="p-6 pt-12">
          <div className="flex flex-col items-center text-center mb-6">
            <div className="border border-black p-2 mb-4">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <path d="M20.59 13.41l-7.17 7.17a2 2 0 0 1-2.83 0L2 12V2h10l8.59 8.59a2 2 0 0 1 0 2.82z"></path>
                <line x1="7" y1="7" x2="7.01" y2="7"></line>
              </svg>
            </div>
            <h3 className="text-xl font-bold uppercase">Post Tags</h3>
          </div>
          
          <p className="text-sm text-gray-600 mb-4">
            Select tags that best describe your post. These help others find your content based on their interests.
          </p>
        </div>

        {/* Scrollable content */}
        <div className="overflow-y-auto flex-grow px-6 pb-4">
          <div className="space-y-4">
            {Object.entries(POST_TAG_GROUPS).map(([groupName, tags]) => (
              <div key={groupName} className="border border-gray-200 rounded">
                <button
                  type="button"
                  onClick={() => toggleTagGroup(groupName)}
                  className="w-full p-3 text-left font-medium flex justify-between items-center bg-gray-100"
                >
                  {groupName}
                  <span>{expandedTagGroups[groupName] ? '−' : '+'}</span>
                </button>
                {expandedTagGroups[groupName] && (
                  <div className="p-3 flex flex-wrap gap-2">
                    {tags.map(tag => (
                      <button
                        key={tag}
                        type="button"
                        onClick={() => handleTagToggle(tag)}
                        className={`py-2 px-3 text-sm transition-colors ${
                          selectedTags.includes(tag) 
                            ? 'bg-black text-white' 
                            : 'bg-gray-100 text-black border border-gray-200'
                        }`}
                      >
                        {tag}
                      </button>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>

        {/* Selected tags summary and buttons */}
        <div className="p-6 border-t border-gray-200">
          <div className="mb-4">
            <p className="font-medium mb-2">Selected Tags ({selectedTags.length}):</p>
            <div className="flex flex-wrap gap-2">
              {selectedTags.length > 0 ? (
                selectedTags.map(tag => (
                  <div key={tag} className="bg-black text-white px-3 py-1 text-sm flex items-center gap-1">
                    {tag}
                    <button 
                      type="button" 
                      onClick={(e) => {
                        e.stopPropagation();
                        handleTagToggle(tag);
                      }}
                      className="ml-1 text-white hover:text-gray-300"
                    >
                      ×
                    </button>
                  </div>
                ))
              ) : (
                <p className="text-sm text-gray-500">No tags selected</p>
              )}
            </div>
          </div>
          
          <button
            onClick={onClose}
            className="w-full bg-black hover:bg-gray-800 text-white font-bold py-2 px-4"
          >
            Done
          </button>
        </div>
      </div>
    </div>,
    document.body
  );
};

const CreatePost = () => {
  const navigate = useNavigate();
  const { addPost } = usePost();
  const { currentUser } = useAuth();
  const { exercises } = useExercise();
  const { createData, clearCreateData } = useCreate();
  
  // Add debugging logs
//   useEffect(() => {
//     console.log("CreateData in CreatePost:", createData);
//   }, [createData]);
  
  // Determine if we're creating from a specific content source
  const hasRootContent = Boolean(
    (createData.fromExercise && createData.exerciseId) || 
    (createData.fromMeal && createData.mealId) || 
    (createData.fromPost && createData.postId)
  );
  
  // Initialize form data with values from context
  const [formData, setFormData] = useState({
    caption: '',
    contentType: createData.contentType || 'post',
    contentId: '',
    userId: currentUser.uid,
    tags: []
  });
  
  const [mediaFile, setMediaFile] = useState(null);
  const [mediaPreview, setMediaPreview] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('Warning');
  const [isTagsModalOpen, setIsTagsModalOpen] = useState(false);

  // Update form data when relevant context values change
  useEffect(() => {
    if (createData.fromExercise && createData.exerciseId) {
      setFormData(prev => ({
        ...prev,
        contentType: 'exercise',
        contentId: createData.exerciseId,
      }));
    }
  }, [createData.exerciseId, createData.fromExercise]);

  useEffect(() => {
    if (createData.fromMeal && createData.mealId) {
      setFormData(prev => ({
        ...prev,
        contentType: 'meal',
        contentId: createData.mealId,
      }));
    }
  }, [createData.mealId, createData.fromMeal]);

  useEffect(() => {
    if (createData.fromPost && createData.postId) {
      setFormData(prev => ({
        ...prev,
        contentType: 'post',
        contentId: createData.postId,
      }));
    }
  }, [createData.postId, createData.fromPost]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleTagsChange = (newTags) => {
    setFormData(prev => ({
      ...prev,
      tags: newTags
    }));
  };

  const handleFileChange = (e) => {
    try {
      const file = e.target.files[0];
      console.log("File input event:", e);
      console.log("Files array:", e.target.files);
      
      if (!file) {
        console.log("No file selected");
        return;
      }
      
      console.log("File selected:", file);
      
      if (!file.type.startsWith('image/') && !file.type.startsWith('video/')) {
        setError('Please upload an image or video file');
        setModalTitle('Invalid File Type');
        setShowErrorModal(true);
        return;
      }
      
      const previewUrl = URL.createObjectURL(file);
      console.log("Preview URL created:", previewUrl);
      
      setMediaFile(file);
      setMediaPreview(previewUrl);
      
      console.log("Media preview state set to:", previewUrl);
    } catch (error) {
      console.error("Error in handleFileChange:", error);
      setError('Error uploading file. Please try again.');
      setModalTitle('File Upload Error');
      setShowErrorModal(true);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    
    if (!formData.caption || !mediaFile) {
      let errorMessage = '';
      if (!formData.caption) errorMessage += 'Please add a caption';
      if (!mediaFile) errorMessage += errorMessage ? ' and upload media' : 'Please upload media';
      
      setError(errorMessage);
      setModalTitle('Missing Information');
      setShowErrorModal(true);
      return;
    }

    setLoading(true);
    try {
      // Check caption for inappropriate content
      try {
        await checkTextForInappropriateContent(formData.caption);
      } catch (contentError) {
        setError(contentError.message);
        setModalTitle('Content Policy Violation');
        setShowErrorModal(true);
        setLoading(false);
        return;
      }
      
      // Create a post object with the simplified fields
      const postObject = {
        caption: formData.caption,
        contentType: formData.contentType,
        contentId: formData.contentId,
        tags: formData.tags,
      };
      
      console.log("Submitting post with data:", postObject);
      
      const postId = await addPost(postObject, mediaFile);
      
      // Track post creation
      trackPostCreation(currentUser.uid, postId, formData.contentType);
      
      // Clear the context data only after successful submission
      clearCreateData();
      
      navigate('/discover');
    } catch (error) {
      console.error("Error creating post:", error);
      
      // Set appropriate title based on error message
      if (error.message && error.message.includes('NSFW')) {
        setModalTitle('Content Policy Violation');
      } else {
        setModalTitle('Error Creating Post');
      }
      
      setError(error.message || 'Failed to create post');
      setShowErrorModal(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // Only cleanup media preview, don't clear context data here
    return () => {
      if (mediaPreview) URL.revokeObjectURL(mediaPreview);
      // Don't call clearCreateData() here
    };
  }, [mediaPreview]);

  // Get the title and type of the root content
  const getRootContentInfo = () => {
    if (createData.fromExercise && createData.exerciseId) {
      // Find the exercise title from the exercises array if not provided in context
      let title = createData.exerciseTitle;
      if (!title && exercises) {
        const exercise = exercises.find(ex => ex.id === createData.exerciseId);
        title = exercise?.title;
      }
      
      return {
        type: 'EXERCISE',
        title: title || 'Exercise',
        description: 'Creating post for this exercise'
      };
    } else if (createData.fromMeal && createData.mealId) {
      return {
        type: 'MEAL',
        title: createData.mealTitle || 'Meal',
        description: 'Creating post for this meal'
      };
    } else if (createData.fromPost && createData.postId) {
      return {
        type: 'POST',
        title: createData.postTitle || 'Post',
        description: 'Referencing this post'
      };
    }
    return null;
  };

  const rootContent = getRootContentInfo();
  
  return (
    <div className="flex-1">
      <form onSubmit={handleSubmit}>
        {/* Root Content Reference - Only show if there is root content */}
        {hasRootContent && rootContent && (
          <div className="mb-6">
            <div className="bg-black text-white p-4">
              <span>{rootContent.type}</span>
            </div>
            <div className="p-4 border border-gray-300 bg-gray-100">
              <p className="font-bold">{rootContent.title}</p>
              <p className="text-sm text-gray-500">{rootContent.description}</p>
            </div>
          </div>
        )}

        {/* Caption Section */}
        <div className="mb-6">
          <div className="bg-black text-white p-4">
            <span>CAPTION</span>
          </div>
          <textarea
            name="caption"
            value={formData.caption}
            onChange={handleInputChange}
            placeholder="Write a caption for your post..."
            className="w-full p-4 border border-gray-300"
            rows="4"
            
          />
        </div>

        {/* Media Section */}
        <div className="mb-6 bg-white">
          <div className="bg-black text-white p-4">
            <span>MEDIA</span>
          </div>
          <label 
            htmlFor="media-upload-post"
            className="block border-2 border-dashed border-gray-300 p-8 text-center cursor-pointer"
          >
            <div className="flex flex-col items-center justify-center">
              {!mediaPreview ? (
                <>
                  <svg className="w-12 h-12 mb-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12"/>
                  </svg>
                  <span>UPLOAD IMAGES</span>
                </>
              ) : (
                <div className="w-full">
                  <div className="mb-2 text-sm text-gray-500">Preview:</div>
                  {mediaFile?.type.startsWith('video/') ? (
                    <video 
                      src={mediaPreview} 
                      className="max-h-64 mx-auto border border-gray-300 rounded" 
                      controls
                    />
                  ) : (
                    <img 
                      src={mediaPreview} 
                      alt="Preview" 
                      className="max-h-64 mx-auto border border-gray-300 rounded shadow-sm"
                    />
                  )}
                  <div className="mt-2 text-sm text-blue-500">
                    Click to change media
                  </div>
                </div>
              )}
            </div>
            <input
              type="file"
              onChange={handleFileChange}
              className="hidden"
              accept="image/*,video/*"
              id="media-upload-post"
            />
          </label>
        </div>

        {/* Tags Section */}
        <div className="mb-6">
          <div className="bg-black text-white p-4">
            <span>TAGS</span>
            <span className="text-gray-400 text-sm ml-2">(Optional)</span>
          </div>
          <div className="p-4 border border-gray-300 bg-white">
            <div className="flex flex-col">
              <button
                type="button"
                onClick={() => setIsTagsModalOpen(true)}
                className="bg-gray-100 hover:bg-gray-200 text-black py-2 px-4 mb-4"
              >
                Select Post Tags
              </button>
              
              {formData.tags.length > 0 && (
                <div>
                  <p className="font-medium mb-2">Selected Tags ({formData.tags.length}):</p>
                  <div className="flex flex-wrap gap-2">
                    {formData.tags.map(tag => (
                      <div key={tag} className="bg-black text-white px-3 py-1 text-sm">
                        {tag}
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Submit Button - Now using the FixedBottomButton component */}
        <FixedBottomButton
          text="Create Post"
          loadingText="Creating..."
          isLoading={loading}
          disabled={loading}
          type="submit"
        />
      </form>
      
      {/* Add the warning modal */}
      <WarningModal
        isOpen={showErrorModal}
        onClose={() => setShowErrorModal(false)}
        title={modalTitle}
        message={error}
        confirmText="OK"
      />

      {/* Post Tags Modal */}
      <PostTagsModal
        isOpen={isTagsModalOpen}
        onClose={() => setIsTagsModalOpen(false)}
        selectedTags={formData.tags}
        onTagsChange={handleTagsChange}
      />
    </div>
  );
};

export default CreatePost; 