import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { auth } from "../../firebase/Init";
import { useAuth } from "../../contexts/AuthContext";
import UserAgreementModal from "../../components/ui/UserAgreementModal";
import { releaseUsername, confirmUsername } from "../../firebase/Username";
import { updateUserData } from "../../firebase/User";
import { useOnboarding } from "../../contexts/OnboardingContext";
import { trackOnboardingComplete } from "../../analytics/conversations";

const FinalizeStep = ({ prevStep, handleChange, formData }) => {
  const [errors, setErrors] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [hasReadAgreement, setHasReadAgreement] = useState(false);
  const navigate = useNavigate();
  const { refreshOnboardingStatus } = useAuth();
  const { dispatch } = useOnboarding();
  const {
    birthday,
    heightFeet,
    heightInches,
    weight,
    fitnessGoal,
    fitnessGoalTags,
    trainingStyle,
    trainingStyleTags,
    agreementRead,
    isOver18,
    username
  } = formData;

  // Determine if sections were skipped
  const skippedFitnessGoals = !fitnessGoalTags || fitnessGoalTags.length === 0;
  const skippedTrainingPreferences = !trainingStyleTags || trainingStyleTags.length === 0;

  const validateForm = () => {
    let isValid = true;
    let newErrors = {};

    if (!hasReadAgreement) {
      newErrors.agreementRead =
        "You must read through the entire agreement before proceeding.";
      isValid = false;
    }

    if (!agreementRead) {
      newErrors.agreementRead =
        "You must certify that you have read and agree to the terms.";
      isValid = false;
    }

    if (!isOver18) {
      newErrors.isOver18 = "You must certify that you are over 18 years old.";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }

    const user = auth.currentUser;
    if (!user) {
      navigate("/");
      return;
    }

    try {
      console.log("Attempting to confirm username:", username);
      const usernameConfirmed = await confirmUsername(username, user.uid);
      console.log("Username confirmed:", usernameConfirmed);

      if (!usernameConfirmed) {
        setErrors({ submit: "Username is no longer available. Please start over." });
        return;
      }
      
      const userData = {
        username,
        age: new Date().getFullYear() - birthday.getFullYear(),
        height: `${heightFeet}'${heightInches}"`,
        weight,
        fitnessGoal: skippedFitnessGoals ? {} : {
          title: fitnessGoal.title,
          tags: fitnessGoalTags,
          description: fitnessGoal.description,
          division: fitnessGoal.division,
        },
        trainingStyle: skippedTrainingPreferences ? {} : {
          title: trainingStyle.title,
          tags: trainingStyleTags,
          description: trainingStyle.description,
          division: trainingStyle.division,
        },
        agreementRead,
        isOver18,
        onboardingComplete: true,
        bio: "",
        profile_picture_url: user.photoURL || "",
        workoutStreak: 0,
        mealsRecorded: 0,
        followerCount: 0,
        followingCount: 0,
        skippedFitnessGoals,
        skippedTrainingPreferences
      };

      console.log("Updating user data:", userData);
      await updateUserData(user.uid, userData);

      // Track onboarding completion
      trackOnboardingComplete(user.uid, [
        'basic_info', 
        !skippedFitnessGoals && 'fitness_goals', 
        !skippedTrainingPreferences && 'training_style', 
        'username'
      ].filter(Boolean));

      dispatch({ type: "SET_ONBOARDING_COMPLETE" });
      refreshOnboardingStatus();
      navigate("/home");
    } catch (error) {
      console.error("Error saving onboarding data: ", error);

      if (error.message.includes("confirming username")) {
        setErrors({ submit: "Failed to confirm username. Please try again." });
      } else if (error.message.includes("updating user data")) {
        setErrors({ submit: "Failed to update user data. Please try again." });
      } else {
        setErrors({ submit: "An unexpected error occurred. Please try again." });
      }

      await releaseUsername(username);
    }
  };

  const handleAgreement = () => {
    setHasReadAgreement(true);
    handleChange("agreementRead")(true);
    setIsModalOpen(false);
  };

  return (
    <div className="w-full h-screen flex justify-center items-center p-2">
      <div className="bg-white w-full max-w-3xl text-gray-600 shadow-xl border flex flex-col h-[600px] min-h-[600px] max-h-[600px] relative">
        {/* Fixed Header */}
        <div className="bg-white border-b px-4 py-4 sticky top-0">
          <div className="text-center">
            <h2 className="text-gray-800 text-xl font-semibold sm:text-2xl uppercase">
              Review Your Profile
            </h2>
            <p className="text-gray-600 mt-2">
              Confirm your information before completing registration
            </p>
          </div>
        </div>

        <form onSubmit={handleSubmit} className="flex flex-col flex-1 overflow-hidden">
          {/* Scrollable Content */}
          <div className="flex-1 overflow-auto px-4">
            <div className="py-4 space-y-4">
              {/* Basic Info Summary */}
              <div>
                <h3 className="font-light text-white bg-black py-2 px-4 sticky top-0  uppercase">
                  Basic Information
                </h3>
                <div className="bg-gray-50 p-4 rounded-lg">
                  <ul className="space-y-2">
                    <li>
                      <span className="font-medium">Age:</span>{" "}
                      {new Date().getFullYear() - birthday.getFullYear()} years
                    </li>
                    <li>
                      <span className="font-medium">Height:</span> {heightFeet}'{heightInches}"
                    </li>
                    <li>
                      <span className="font-medium">Weight:</span> {weight} lbs
                    </li>
                  </ul>
                </div>
              </div>

              {/* Physique Goals Summary */}
              <div>
                <h3 className="font-light text-white bg-black py-2 px-4 sticky top-0  uppercase">
                  Physique Goals
                </h3>
                <div className="bg-gray-50 p-4 rounded-lg">
                  {skippedFitnessGoals ? (
                    <div className="flex items-center mb-3">
                      <span className="text-black-500 font-medium underline">Incomplete</span>
                      <span className="text-xs text-gray-500 ml-2">(Section skipped)</span>
                    </div>
                  ) : (
                    <>
                      <div className="mb-3">
                        <span className="font-medium">Suggested Match:</span>{" "}
                        <span className="text-black">{fitnessGoal.title}</span>
                        <p className="text-sm text-gray-600 mt-1">{fitnessGoal?.description}</p>
                      </div>
                      <div className="flex flex-wrap gap-1 mt-2">
                        {fitnessGoalTags.map((tag) => (
                          <span key={tag} className="text-xs bg-gray-200 px-2 py-1 rounded">
                            {tag}
                          </span>
                        ))}
                      </div>
                    </>
                  )}
                </div>
              </div>

              {/* Training Preferences Summary */}
              <div>
                <h3 className="font-light text-white bg-black py-2 px-4 sticky top-0  uppercase">
                  Training Style
                </h3>
                <div className="bg-gray-50 p-4 rounded-lg">
                  {skippedTrainingPreferences ? (
                    <div className="flex items-center mb-3">
                      <span className="text-black-500 font-medium underline">Incomplete</span>
                      <span className="text-xs text-gray-500 ml-2">(Section skipped)</span>
                    </div>
                  ) : (
                    <>
                      <div className="mb-3">
                        <span className="font-medium">Category Match:</span>{" "}
                        <span className="text-black">{trainingStyle.title}</span>
                        <p className="text-sm text-gray-600 mt-1">{trainingStyle.description}</p>
                      </div>
                      <div className="flex flex-wrap gap-1 mt-2">
                        {trainingStyleTags.map((tag) => (
                          <span key={tag} className="text-xs bg-gray-200 px-2 py-1 rounded">
                            {tag}
                          </span>
                        ))}
                      </div>
                    </>
                  )}
                </div>
              </div>

              {/* Agreement Section */}
              <div>
                <h3 className="font-light text-white bg-black py-2 px-4 sticky top-0  uppercase">
                  Terms & Agreements
                </h3>
                <div className="bg-gray-50 p-4 rounded-lg">
                  <div className="mb-3">
                    <label className="flex items-center cursor-pointer">
                      <div className="relative flex items-center">
                        <input
                          type="checkbox"
                          checked={agreementRead}
                          onChange={(e) => handleChange("agreementRead")(e.target.checked)}
                          disabled={!hasReadAgreement}
                          className="sr-only"
                        />
                        <div className={`w-5 h-5 border-2 flex justify-center items-center mr-2 ${agreementRead ? 'bg-black border-black' : 'border-gray-500'} ${!hasReadAgreement ? 'opacity-50' : ''}`}>
                          {agreementRead && (
                            <svg className="w-3 h-3 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={3} d="M5 13l4 4L19 7" />
                            </svg>
                          )}
                        </div>
                      </div>
                      <span className="text-sm">
                        I have read and agree to the{" "}
                        <button
                          type="button"
                          onClick={() => setIsModalOpen(true)}
                          className="text-blue-600 hover:text-blue-800 underline"
                        >
                          terms and conditions
                        </button>
                      </span>
                    </label>
                    {errors.agreementRead && (
                      <p className="text-red-500 text-xs mt-1">{errors.agreementRead}</p>
                    )}
                  </div>

                  <div>
                    <label className="flex items-center cursor-pointer">
                      <div className="relative flex items-center">
                        <input
                          type="checkbox"
                          checked={isOver18}
                          onChange={(e) => handleChange("isOver18")(e.target.checked)}
                          className="sr-only"
                        />
                        <div className={`w-5 h-5 border-2 flex justify-center items-center mr-2 ${isOver18 ? 'bg-black border-black' : 'border-gray-500'}`}>
                          {isOver18 && (
                            <svg className="w-3 h-3 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={3} d="M5 13l4 4L19 7" />
                            </svg>
                          )}
                        </div>
                      </div>
                      <span className="text-sm">I certify that I am over 18 years old</span>
                    </label>
                    {errors.isOver18 && (
                      <p className="text-red-500 text-xs mt-1">{errors.isOver18}</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Fixed Footer */}
          <div className="bg-white border-t px-4 py-4 sticky bottom-0 ">
            {errors.submit && (
              <p className="text-red-500 text-sm mb-3">{errors.submit}</p>
            )}
            
            <div className="flex justify-between items-center">
              <button
                type="button"
                onClick={prevStep}
                className="px-6 py-2 bg-gray-100 text-gray-700 hover:bg-gray-200 transition duration-300 rounded"
              >
                Back
              </button>
              <button
                type="submit"
                className="px-6 py-2 bg-black text-white hover:bg-gray-700 transition duration-300 rounded"
              >
                Confirm and Complete
              </button>
            </div>
          </div>
        </form>
      </div>

      <UserAgreementModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onRead={handleAgreement}
      />
    </div>
  );
};

export default FinalizeStep;
