// New file to define shared constants
export const PUBLIC_PROFILE_FIELDS = {
    username: true,
    profile_picture_url: true,
    bio: true,
    fitnessGoals: true,
    achievements: true,
    trainingStyle: true,
    workouts: true
};

// Fields that are always public, even for private profiles
export const MINIMAL_PROFILE_FIELDS = {
    username: true,
    profile_picture_url: true
};

export const PROFILE_FIELD_MAPPING = {
    photoURL: 'profile_picture_url',
    // Add other field mappings if names differ between users and profiles
};

// Add to existing constants
export const PRIVATE_PROFILE_FIELDS = {
    username: true,
    profile_picture_url: true
};

// Define which fields are hidden in private profiles
export const PRIVACY_SENSITIVE_FIELDS = {
    bio: true,
    fitnessGoals: true,
    achievements: true,
    trainingStyle: true,
    workouts: true
}; 