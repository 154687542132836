import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';

const FeedbackModal = ({ category, isOpen, onClose }) => {
	if (!isOpen || !category) return null;

	return (
		<AnimatePresence>
			{isOpen && (
				<motion.div
					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					exit={{ opacity: 0 }}
					className="fixed inset-0 z-50 flex items-center justify-center backdrop-blur-sm bg-black bg-opacity-75 md:pl-16 lg:pl-64"
					onClick={onClose}
				>
					<motion.div
						initial={{ scale: 0.9, opacity: 0 }}
						animate={{ scale: 1, opacity: 1 }}
						exit={{ scale: 0.9, opacity: 0 }}
						transition={{ type: "spring", damping: 30, stiffness: 300 }}
						className="bg-white text-black w-11/12 max-w-2xl mx-auto overflow-hidden relative border border-black"
						onClick={(e) => e.stopPropagation()}
					>
						{/* Close button - absolute positioned */}
						<button 
							onClick={onClose}
							className="absolute top-4 right-4 text-black"
							aria-label="Close"
						>
							<svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
								<line x1="18" y1="6" x2="6" y2="18"></line>
								<line x1="6" y1="6" x2="18" y2="18"></line>
							</svg>
						</button>
						
						{/* Category title */}
						<div className="p-6 pt-12">
							<div className="flex flex-col items-center text-center mb-6">
								<h3 className="text-2xl md:text-3xl font-bold uppercase">{category.name}</h3>
							</div>
							
							{/* Feedback section */}
							<div className="mb-6">
								<h4 className="text-lg font-light font-condensed mb-2 bg-gray-200 px-3 py-2 uppercase">FEEDBACK</h4>
								<p className="text-base md:text-lg border border-gray-300 p-4 rounded">{category.feedback}</p>
							</div>
							
							{/* Tips section */}
							<div className="mb-4">
								<h4 className="text-lg font-light font-condensed mb-3 uppercase">TIPS</h4>
								<ul className="list-none space-y-5 relative">
									<div className="absolute left-1.5 top-0 bottom-0 w-px bg-gray-300"></div>
									{category.tips && category.tips.map((tip, index) => (
										<li key={index} className="pl-7 relative text-base md:text-lg">
											<div className="absolute left-0 top-2 w-3 h-3 bg-black rounded-full"></div>
											{tip}
										</li>
									))}
								</ul>
							</div>
						</div>
					</motion.div>
				</motion.div>
			)}
		</AnimatePresence>
	);
};

export default FeedbackModal; 