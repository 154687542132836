import React, { useState } from 'react';
import { isUsernameAvailable, validateUsername, reserveUsername } from '../../firebase/Username';
import { FaUser, FaCheck, FaTimes } from 'react-icons/fa';
import { useAuth } from '../../contexts/AuthContext';
import { useOnboarding } from '../../contexts/OnboardingContext';
import { checkTextForInappropriateContent } from '../../firebase/NSFWCheck';
import WarningModal from '../../components/ui/WarningModal';

const UsernameStep = ({ nextStep }) => {
    const { state, dispatch, quitOnboarding } = useOnboarding();
    const { username } = state.formData;
    const [error, setError] = useState('');
    const [isChecking, setIsChecking] = useState(false);
    const [isAvailable, setIsAvailable] = useState(null);
    const [debounceTimeout, setDebounceTimeout] = useState(null);
    const { currentUser } = useAuth();
    const [showQuitModal, setShowQuitModal] = useState(false);

    const checkUsername = async (value) => {
        setIsChecking(true);
        setIsAvailable(null);
        
        const validationError = validateUsername(value);
        if (validationError) {
            setError(validationError);
            setIsChecking(false);
            setIsAvailable(false);
            return;
        }

        // Check for inappropriate content in username
        try {
            await checkTextForInappropriateContent(value);
        } catch (contentError) {
            setError('Username contains inappropriate content that violates our community guidelines');
            setIsChecking(false);
            setIsAvailable(false);
            return;
        }

        try {
            const available = await isUsernameAvailable(value);
            setIsAvailable(available);
            setError(available ? '' : 'Username is already taken');
        } catch (err) {
            setError('Error checking username availability');
            setIsAvailable(false);
        }
        setIsChecking(false);
    };

    const handleUsernameChange = (e) => {
        const value = e.target.value.toLowerCase();
        dispatch({ type: 'SET_FORM_DATA', payload: { field: 'username', value } });
        
        if (debounceTimeout) clearTimeout(debounceTimeout);
        
        setDebounceTimeout(
            setTimeout(() => {
                if (value) checkUsername(value);
            }, 500)
        );
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!isAvailable || isChecking) return;

        try {
            // Double-check for inappropriate content before final submission
            try {
                await checkTextForInappropriateContent(username);
            } catch (contentError) {
                setError('Username contains inappropriate content that violates our community guidelines');
                setIsAvailable(false);
                return;
            }
            
            const reserved = await reserveUsername(username, currentUser.uid, true);
            if (reserved) {
                dispatch({ type: 'START_ONBOARDING' });
                nextStep();
            } else {
                setError('Username is no longer available');
            }
        } catch (error) {
            console.error('Error reserving username:', error);
            setError('Error reserving username. Please try again.');
        }
    };

    const handleQuitClick = () => {
        setShowQuitModal(true);
    };

    const handleConfirmQuit = () => {
        setShowQuitModal(false);
        setTimeout(() => {
            quitOnboarding();
        }, 100);
    };

    return (
        <div className="w-full h-screen flex justify-center items-center p-2">
            <div className="bg-white w-full max-w-3xl text-gray-600 shadow-xl border flex flex-col h-[600px] min-h-[600px] max-h-[600px]">
                <div className="flex-1 flex flex-col justify-center items-center px-8 py-6">
                    <div className="w-full max-w-md">
                        <div className="text-center mb-6">
                            <h2 className="text-gray-800 text-xl font-semibold sm:text-2xl uppercase">Choose your username</h2>
                            <p className="text-sm text-gray-500 mt-2">This will be your unique identifier</p>
                        </div>

                        <form onSubmit={handleSubmit} className="space-y-4">
                            <div>
                                <label className="text-sm text-gray-600 font-bold">Username</label>
                                <div className="relative mt-2">
                                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                        <FaUser className="text-gray-400" />
                                    </div>
                                    <input
                                        type="text"
                                        value={username}
                                        onChange={handleUsernameChange}
                                        className="w-full pl-10 pr-10 py-2 text-gray-500 bg-white border border-gray-300 focus:outline-none focus:border-black focus:ring-2 focus:ring-black focus:ring-opacity-50"
                                        placeholder="Enter username"
                                    />
                                    {username && (
                                        <div className="absolute inset-y-0 right-0 pr-3 flex items-center">
                                            {isChecking ? (
                                                <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-gray-900" />
                                            ) : isAvailable ? (
                                                <FaCheck className="text-green-500" />
                                            ) : (
                                                <FaTimes className="text-red-500" />
                                            )}
                                        </div>
                                    )}
                                </div>
                                {error && <p className="text-red-500 text-xs mt-1">{error}</p>}
                            </div>

                            <div className="flex space-x-4 mt-8">
                                <button
                                    type="button"
                                    onClick={handleQuitClick}
                                    className="flex-1 px-4 py-2 text-red-500 font-medium border border-red-500 hover:bg-red-50 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50 transition duration-300"
                                >
                                    Quit
                                </button>
                                <button
                                    type="submit"
                                    disabled={!isAvailable || isChecking}
                                    className="flex-1 px-4 py-2 text-white font-medium bg-black hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-black focus:ring-opacity-50 transition duration-300 disabled:bg-gray-400 disabled:cursor-not-allowed"
                                >
                                    Next
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <WarningModal
                isOpen={showQuitModal}
                onClose={() => setShowQuitModal(false)}
                title="Quit Onboarding"
                accountForSidebar={false}
                message={
                    <div className="flex flex-col items-center">
                        <p className="mb-6">Are you sure you want to quit? You will be logged out and all progress will be lost.</p>
                        <div className="flex space-x-4 w-full">
                            <button
                                onClick={() => setShowQuitModal(false)}
                                className="flex-1 px-4 py-2 border border-black hover:bg-gray-100"
                            >
                                Cancel
                            </button>
                            <button
                                onClick={handleConfirmQuit}
                                className="flex-1 px-4 py-2 bg-red-500 text-white hover:bg-red-600"
                            >
                                Yes, Quit
                            </button>
                        </div>
                    </div>
                }
            />
        </div>
    );
};

export default UsernameStep; 