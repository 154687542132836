// Constants for validation rules
export const VALIDATION_RULES = {
    username: {
        minLength: 3,
        maxLength: 30,
        pattern: /^[a-zA-Z0-9_]+$/, // Only alphanumeric and underscore
        message: 'Username must be 3-30 characters long and contain only letters, numbers, and underscores'
    },
    bio: {
        maxLength: 500,
        message: 'Bio cannot exceed 500 characters'
    },
    photoURL: {
        pattern: /^https?:\/\/.+/i,
        message: 'Photo URL must be a valid URL'
    },
    fitnessGoals: {
        maxItems: 5,
        message: 'Cannot have more than 5 fitness goals'
    },
    achievements: {
        maxItems: 50,
        message: 'Cannot have more than 50 achievements'
    }
};

// Validation functions
export const validateUsername = (username) => {
    const rule = VALIDATION_RULES.username;
    if (!username || typeof username !== 'string') {
        return 'Username is required';
    }
    if (username.length < rule.minLength || username.length > rule.maxLength) {
        return rule.message;
    }
    if (!rule.pattern.test(username)) {
        return rule.message;
    }
    return null;
};

export const validateBio = (bio) => {
    if (!bio) return null; // Bio is optional
    const rule = VALIDATION_RULES.bio;
    if (bio.length > rule.maxLength) {
        return rule.message;
    }
    return null;
};

export const validatePhotoURL = (url) => {
    if (!url) return null; // Photo URL is optional
    const rule = VALIDATION_RULES.photoURL;
    if (!rule.pattern.test(url)) {
        return rule.message;
    }
    return null;
};

export const validateArray = (array, field) => {
    if (!array) return null;
    const rule = VALIDATION_RULES[field];
    if (!Array.isArray(array)) {
        return `${field} must be an array`;
    }
    if (array.length > rule.maxItems) {
        return rule.message;
    }
    return null;
};

// Main validation function
export const validateUserData = (data) => {
    const errors = {};

    // Required fields
    if (data.username) {
        const usernameError = validateUsername(data.username);
        if (usernameError) errors.username = usernameError;
    }

    // Optional fields
    if (data.bio !== undefined) {
        const bioError = validateBio(data.bio);
        if (bioError) errors.bio = bioError;
    }

    if (data.photoURL !== undefined) {
        const photoError = validatePhotoURL(data.photoURL);
        if (photoError) errors.photoURL = photoError;
    }

    if (data.fitnessGoals !== undefined) {
        const goalsError = validateArray(data.fitnessGoals, 'fitnessGoals');
        if (goalsError) errors.fitnessGoals = goalsError;
    }

    if (data.achievements !== undefined) {
        const achievementsError = validateArray(data.achievements, 'achievements');
        if (achievementsError) errors.achievements = achievementsError;
    }

    return Object.keys(errors).length > 0 ? errors : null;
}; 