import React, { createContext, useContext, useState } from 'react';

const CreateContext = createContext();

export const useCreate = () => useContext(CreateContext);

export const CreateProvider = ({ children }) => {
  const [createData, setCreateData] = useState({
    activeTab: 0,
    exerciseId: '',
    exerciseTitle: '',
    mealId: '',
    mealTitle: '',
    postId: '',
    postTitle: '',
    contentType: '', // 'exercise', 'meal', or 'post'
    fromExercise: false,
    fromMeal: false,
    fromPost: false,
  });

  const setCreateTab = (tabIndex) => {
    setCreateData(prev => ({
      ...prev,
      activeTab: tabIndex
    }));
  };

  const setExerciseData = (data) => {
    setCreateData(prev => ({
      ...prev,
      exerciseId: data.exerciseId || '',
      exerciseTitle: data.exerciseTitle || '',
      contentType: 'exercise',
      fromExercise: data.fromExercise || false,
      // Reset other content types
      mealId: '',
      mealTitle: '',
      postId: '',
      postTitle: '',
      fromMeal: false,
      fromPost: false,
    }));
  };

  const setMealData = (data) => {
    setCreateData(prev => ({
      ...prev,
      mealId: data.mealId || '',
      mealTitle: data.mealTitle || '',
      contentType: 'meal',
      fromMeal: data.fromMeal || false,
      // Reset other content types
      exerciseId: '',
      exerciseTitle: '',
      postId: '',
      postTitle: '',
      fromExercise: false,
      fromPost: false,
    }));
  };

  const setPostData = (data) => {
    setCreateData(prev => ({
      ...prev,
      postId: data.postId || '',
      postTitle: data.postTitle || '',
      contentType: 'post',
      fromPost: data.fromPost || false,
      // Reset other content types
      exerciseId: '',
      exerciseTitle: '',
      mealId: '',
      mealTitle: '',
      fromExercise: false,
      fromMeal: false,
    }));
  };

  const clearCreateData = () => {
    setCreateData({
      activeTab: 0,
      exerciseId: '',
      exerciseTitle: '',
      mealId: '',
      mealTitle: '',
      postId: '',
      postTitle: '',
      contentType: '',
      fromExercise: false,
      fromMeal: false,
      fromPost: false,
    });
  };

  const value = {
    createData,
    setCreateTab,
    setExerciseData,
    setMealData,
    setPostData,
    clearCreateData
  };

  return (
    <CreateContext.Provider value={value}>
      {children}
    </CreateContext.Provider>
  );
}; 