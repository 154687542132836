import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useScan } from '../contexts/ScanContext';
import { motion } from 'framer-motion';
import { Card, CardContent } from "../components/ui/card";
import LoadingGradient from '../components/ui/LoadingGradient';
import { useHeader } from '../contexts/HeaderContext';
import FeedbackModal from '../components/FeedbackModal';

const AnimatedDial = ({ score }) => {
	const normalizedScore = score / 10; // Convert score to a 0-1 range
	const endAngle = -180 + (180 * normalizedScore); // Calculate end angle based on score

	// Generate the arc path
	const generateArcPath = (start, end) => {
		const startAngle = start * (Math.PI / 180);
		const endAngle = end * (Math.PI / 180);
		const rx = 90;
		const ry = 90;
		const xAxisRotation = 0;
		const largeArcFlag = end - start <= 180 ? "0" : "1";
		const sweepFlag = 1;
		const x1 = 100 + rx * Math.cos(startAngle);
		const y1 = 110 + ry * Math.sin(startAngle);
		const x2 = 100 + rx * Math.cos(endAngle);
		const y2 = 110 + ry * Math.sin(endAngle);

		return `M ${x1} ${y1} A ${rx} ${ry} ${xAxisRotation} ${largeArcFlag} ${sweepFlag} ${x2} ${y2}`;
	};

	return (
		<svg className="w-full" viewBox="0 0 200 120">
			{/* Background Arc */}
			<path
				d={generateArcPath(-180, 0)}
				fill="none"
				stroke="#333"
				strokeWidth="4"
				strokeDasharray="4 4"
			/>
			{/* Score Arc */}
			<motion.path
				d={generateArcPath(-180, endAngle)}
				fill="none"
				stroke="white"
				strokeWidth="4"
				initial={{ pathLength: 0 }}
				animate={{ pathLength: 1 }}
				transition={{ duration: 1, ease: "easeOut" }}
			/>
			{/* Tick marks */}
			{[...Array(81)].map((_, i) => {
				const angle = -180 + i * 4.5;
				if (angle <= endAngle) {
					const x1 = 100 + 85 * Math.cos(angle * Math.PI / 180);
					const y1 = 110 + 85 * Math.sin(angle * Math.PI / 180);
					const x2 = 100 + (i % 2 === 0 ? 75 : 80) * Math.cos(angle * Math.PI / 180);
					const y2 = 110 + (i % 2 === 0 ? 75 : 80) * Math.sin(angle * Math.PI / 180);
					return (
						<motion.line
							key={i}
							x1={x1}
							y1={y1}
							x2={x2}
							y2={y2}
							stroke="white"
							strokeWidth={i % 2 === 0 ? "2" : "1"}
							initial={{ opacity: 0 }}
							animate={{ opacity: 1 }}
							transition={{ duration: 0.05, delay: i * 0.01, ease: "easeOut" }}
						/>
					);
				}
				return null;
			})}
			{/* Score */}
			<motion.text
				x="100"
				y="90"
				textAnchor="middle"
				fill="white"
				fontSize="48"
				fontWeight="bold"
				initial={{ opacity: 0 }}
				animate={{ opacity: 1 }}
				transition={{ duration: 0.5, delay: 0.8, ease: "easeOut" }}
			>
				<motion.tspan
					initial={{ y: 20 }}
					animate={{ y: 0 }}
					transition={{ duration: 0.5, delay: 0.8, ease: "easeOut" }}
				>
					{score}
				</motion.tspan>
			</motion.text>
			{/* Overall text */}
			<motion.text
				x="100"
				y="115"
				textAnchor="middle"
				fill="white"
				fontSize="16"
				initial={{ opacity: 0 }}
				animate={{ opacity: 1 }}
				transition={{ duration: 0.5, delay: 1.3, ease: "easeOut" }}
			>
				OVERALL
			</motion.text>
		</svg>
	);
};

const ScoreBar = ({ name, score, onSelect, isSelected }) => (
	<div
		className={`flex items-center mb-4 cursor-pointer transition-colors duration-200 ${isSelected ? 'bg-gray-100' : 'hover:bg-gray-50'}`}
		onClick={() => onSelect(name)}
	>
		<div className="flex items-center w-full p-2">
			<div className="flex flex-col w-20 sm:w-24 mr-2 flex-shrink-0">
				<div className={`text-white text-center py-1 px-2 ${isSelected ? 'bg-black' : 'bg-gray-800'}`}>
					{score.toFixed(1)}
				</div>
				<div className="bg-gray-200 text-gray-800 text-center py-1 px-2 text-xs">
					{name}
				</div>
			</div>
			<div className="flex-grow mx-2">
				<div className="bg-gray-200 h-6 overflow-hidden">
					<div
						className={`h-full transition-all duration-300 ease-out ${isSelected ? 'bg-black' : 'bg-gray-800'}`}
						style={{ width: `${score * 10}%` }}
					/>
				</div>
			</div>
			<div className={`w-20 sm:w-24 md:w-28 text-right flex-shrink-0 flex items-center justify-end ${isSelected ? 'text-black font-semibold' : 'text-gray-500'}`}>
				<span className="text-[10px] sm:text-xs md:text-sm mr-1">SEE DETAILS</span>
				<svg
					className="w-3 h-3 sm:w-4 sm:h-4"
					viewBox="0 0 24 24"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M9 18L15 12L9 6"
						stroke="currentColor"
						strokeWidth="2"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</svg>
			</div>
		</div>
	</div>
);

const ScanDetail = () => {
	const { scanId } = useParams();
	const { scanHistory, loading } = useScan();
	const [selectedCategory, setSelectedCategory] = useState(null);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isMobile, setIsMobile] = useState(false);
	const [categoryData, setCategoryData] = useState(null);
	const navigate = useNavigate();
	const { updateHeader } = useHeader();

	// Find the current scan by its ID
	const currentScan = scanHistory.find(scan => scan.id === scanId);

	useEffect(() => {
		if (currentScan && Object.keys(currentScan.scores.Evaluation).length > 0) {
			setSelectedCategory(Object.keys(currentScan.scores.Evaluation)[0]);
		}

		const checkIfMobile = () => {
			setIsMobile(window.innerWidth < 768);
		};

		checkIfMobile();
		window.addEventListener('resize', checkIfMobile);

		return () => window.removeEventListener('resize', checkIfMobile);
	}, [currentScan]);

	// Update category data whenever selectedCategory changes
	useEffect(() => {
		if (currentScan && selectedCategory) {
			setCategoryData({
				name: selectedCategory,
				feedback: currentScan.scores.Evaluation[selectedCategory]?.Feedback || '',
				tips: currentScan.scores.Evaluation[selectedCategory]?.Tips || []
			});
		}
	}, [selectedCategory, currentScan]);

	// Set header with scan details
	useEffect(() => {
		const leading = (
			<button 
				onClick={() => navigate(-1)}
				className="flex items-center"
			>
				<svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
					<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
				</svg>
			</button>
		);

		const trailing = currentScan ? (
			<div className="text-sm">
				{formatDate(currentScan.timestamp)}
			</div>
		) : null;

		updateHeader('SCAN DETAILS', trailing, leading);

		return () => updateHeader('');
	}, [currentScan, navigate, updateHeader]);

	const handleCategorySelect = (name) => {
		setSelectedCategory(name);
		setIsModalOpen(true);
	};

	const formatDate = (timestamp) => {
		if (!timestamp) return "";
		const date = new Date(timestamp);
		return date.toLocaleDateString('en-US', { 
			year: 'numeric', 
			month: 'long', 
			day: 'numeric',
			hour: '2-digit',
			minute: '2-digit'
		});
	};

	const renderScoresContent = () => {
		if (!currentScan) return null;
		
		const overallScore = currentScan.scores["Overall Score"];
		const categories = Object.entries(currentScan.scores.Evaluation).map(([name, data]) => ({
			name,
			score: data.Score,
			feedback: data.Feedback,
			tips: data.Tips
		}));

		return (
			<div className="flex flex-col gap-4 w-full h-full">
				<Card className="bg-black">
					<CardContent className="p-6 flex justify-center">
						<div className="max-w-md w-full">
							<AnimatedDial score={parseFloat(overallScore.toFixed(1))} />
						</div>
					</CardContent>
				</Card>
				<Card className="bg-white flex-1">
					<CardContent className="p-0">
						{categories.map((category) => (
							<ScoreBar
								key={category.name}
								name={category.name}
								score={category.score}
								onSelect={handleCategorySelect}
								isSelected={category.name === selectedCategory}
							/>
						))}
					</CardContent>
				</Card>
			</div>
		);
	};

	const renderContent = () => {
		if (loading) {
			return <LoadingGradient className="h-full w-full rounded" />;
		}

		if (!currentScan) {
			return (
				<div className="flex items-center justify-center h-full">
					<div className="text-center">
						<h2 className="text-xl font-bold mb-4">Scan Not Found</h2>
						<p className="mb-4">The scan you're looking for doesn't exist or has been deleted.</p>
						<button 
							onClick={() => navigate(-1)} 
							className="px-4 py-2 bg-black text-white rounded"
						>
							Go Back
						</button>
					</div>
				</div>
			);
		}

		if (isMobile) {
			return renderScoresContent();
		} else {
			return renderDesktopLayout();
		}
	};

	const renderDesktopLayout = () => {
		if (!currentScan) return null;
		
		const overallScore = currentScan.scores["Overall Score"];
		const categories = Object.entries(currentScan.scores.Evaluation).map(([name, data]) => ({
			name,
			score: data.Score,
			feedback: data.Feedback,
			tips: data.Tips
		}));

		return (
			<div className="flex flex-col gap-6 h-full">
				<Card className="bg-black">
					<CardContent className="p-6 flex justify-center">
						<div className="max-w-md w-full">
							<AnimatedDial score={parseFloat(overallScore.toFixed(1))} />
						</div>
					</CardContent>
				</Card>
				<Card className="bg-white w-full flex-1">
					<CardContent className="p-0 overflow-y-auto h-full">
						{categories.map((category) => (
							<ScoreBar
								key={category.name}
								name={category.name}
								score={category.score}
								onSelect={handleCategorySelect}
								isSelected={category.name === selectedCategory}
							/>
						))}
					</CardContent>
				</Card>
			</div>
		);
	};

	return (
		<div className="flex flex-col h-screen">
			<main className="flex-1 flex flex-col py-8">
				<div className="container mx-auto w-[90%] h-full">
					{renderContent()}
				</div>
			</main>

			<FeedbackModal
				category={categoryData}
				isOpen={isModalOpen}
				onClose={() => setIsModalOpen(false)}
			/>
		</div>
	);
};

export default ScanDetail; 