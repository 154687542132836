import {
  collection,
  doc,
  getDoc,
  deleteDoc,
  onSnapshot,
  getDocs,
  addDoc,
  writeBatch,
  query,
  orderBy,
  where,
  deleteField,
  increment
} from "firebase/firestore";
import {
  ref as storageRef,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import { firestore, storage } from "./Init";
import { getPublicProfileByUserId as getUserProfile } from "./PublicProfile";
import { checkImageForNSFW } from "./NSFWCheck";

// Create new post with media upload
export const createPost = async (userId, postData, mediaFile) => {
  try {
    const timestamp = Date.now();
    const newPostRef = doc(collection(firestore, "posts"));
    const newPostId = newPostRef.id;

    // Get user profile to store username
    const userProfile = await getUserProfile(userId);
    if (!userProfile) throw new Error("User profile not found");

    // Upload media file if provided
    let mediaUrl = null;
    let mediaType = null;
    let mediaPath = null;
    if (mediaFile) {
      // Check for NSFW content before uploading
      if (mediaFile.type.startsWith("image/")) {
        await checkImageForNSFW(mediaFile);
      }

      // If passed NSFW check or is not an image, proceed with upload
      const fileRef = storageRef(storage, `posts/${newPostId}/${mediaFile.name}`);
      await uploadBytes(fileRef, mediaFile);
      mediaUrl = await getDownloadURL(fileRef);
      mediaType = mediaFile.type.startsWith("video/") ? "video" : "image";
      mediaPath = `posts/${newPostId}/${mediaFile.name}`;
    }

    const postDocData = {
      userId,
      username: userProfile.username,
      caption: postData.caption,
      mediaUrl,
      mediaType,
      mediaPath,
      contentType: postData.contentType || 'post',
      contentId: postData.contentId || null,
      tags: postData.tags || [],
      createdAt: timestamp,
      likes: {},
      // Comments will be stored as a subcollection
    };

    // Use a batch to write the post document and update the related content document if needed
    const batch = writeBatch(firestore);
    batch.set(newPostRef, postDocData);

    // If this post is related to another content item, update that item
    if (postData.contentId && postData.contentType) {
      // For backward compatibility, also set exerciseId if contentType is 'exercise'
      if (postData.contentType === 'exercise') {
        batch.update(newPostRef, { exerciseId: postData.contentId });

        const exerciseDocRef = doc(firestore, "exercises", postData.contentId);
        batch.update(exerciseDocRef, {
          [`posts.${newPostId}`]: true,
          postCount: increment(1)
        });
      }

      // Add similar handling for other content types as needed
    }

    await batch.commit();

    return newPostId;
  } catch (error) {
    console.error("Error creating post:", error);
    throw error;
  }
};

// Delete post and its media
export const deletePost = async (postId) => {
  try {
    const postDocRef = doc(firestore, "posts", postId);
    const postSnap = await getDoc(postDocRef);
    if (!postSnap.exists()) return;
    const post = postSnap.data();

    if (post?.mediaPath) {
      const fileRef = storageRef(storage, post.mediaPath);
      await deleteObject(fileRef);
    }

    // Use a batch to delete the post and update the exercise document if applicable
    const batch = writeBatch(firestore);
    batch.delete(postDocRef);
    if (post.exerciseId) {
      const exerciseDocRef = doc(firestore, "exercises", post.exerciseId);
      batch.update(exerciseDocRef, {
        [`posts.${postId}`]: deleteField(),
        postCount: increment(-1)
      });
    }
    await batch.commit();
  } catch (error) {
    console.error("Error deleting post:", error);
    throw error;
  }
};

// Toggle like on a post
export const toggleLike = async (postId, userId) => {
  try {
    const postDocRef = doc(firestore, "posts", postId);
    const postSnap = await getDoc(postDocRef);
    if (!postSnap.exists()) throw new Error("Post not found");
    const postData = postSnap.data();
    const batch = writeBatch(firestore);
    if (postData.likes && postData.likes[userId]) {
      batch.update(postDocRef, { [`likes.${userId}`]: deleteField() });
    } else {
      batch.update(postDocRef, { [`likes.${userId}`]: true });
    }
    await batch.commit();
  } catch (error) {
    console.error("Error toggling like:", error);
    throw error;
  }
};

// Add comment to post (stored in a subcollection)
export const addComment = async (postId, userId, text) => {
  try {
    const userProfile = await getUserProfile(userId);
    if (!userProfile) throw new Error("User profile not found");
    const timestamp = Date.now();
    const commentsCollectionRef = collection(firestore, "posts", postId, "comments");
    const commentData = {
      userId,
      username: userProfile.username,
      text,
      createdAt: timestamp
    };
    const newCommentDoc = await addDoc(commentsCollectionRef, commentData);
    return newCommentDoc.id;
  } catch (error) {
    console.error("Error adding comment:", error);
    throw error;
  }
};

// Delete comment from post
export const deleteComment = async (postId, commentId) => {
  try {
    const commentDocRef = doc(firestore, "posts", postId, "comments", commentId);
    await deleteDoc(commentDocRef);
  } catch (error) {
    console.error("Error deleting comment:", error);
    throw error;
  }
};

// Helper function to fetch comment count for a post
const getCommentCount = async (postId) => {
  const commentsCollectionRef = collection(firestore, "posts", postId, "comments");
  const commentsSnapshot = await getDocs(commentsCollectionRef);
  return commentsSnapshot.size;
};

// Fetch posts for a specific exercise in real time
export const fetchPostsByExercise = (exerciseId, callback) => {
  const postsCollectionRef = collection(firestore, "posts");
  const q = query(postsCollectionRef, where("exerciseId", "==", exerciseId));
  const unsubscribe = onSnapshot(q, async (snapshot) => {
    const postsArray = await Promise.all(
      snapshot.docs.map(async (docSnap) => {
        const post = docSnap.data();
        const exerciseDocRef = doc(firestore, "exercises", post.exerciseId);
        const exerciseSnap = await getDoc(exerciseDocRef);
        if (exerciseSnap.exists()) {
          const exercise = exerciseSnap.data();
          const authorProfile = await getUserProfile(exercise.userId);
          const commentCount = await getCommentCount(docSnap.id);
          return {
            id: docSnap.id,
            ...post,
            exerciseTitle: exercise.title,
            exerciseAuthor: authorProfile?.username,
            likeCount: post.likes ? Object.keys(post.likes).length : 0,
            commentCount,
          };
        }
        return null;
      })
    );
    callback(postsArray.filter(Boolean).sort((a, b) => b.createdAt - a.createdAt));
  });
  return unsubscribe;
};

// Fetch posts by user in real time
export const fetchPostsByUser = (userId, callback) => {
  const postsCollectionRef = collection(firestore, "posts");
  const q = query(postsCollectionRef, where("userId", "==", userId));
  const unsubscribe = onSnapshot(q, async (snapshot) => {
    const postsArray = await Promise.all(
      snapshot.docs.map(async (docSnap) => {
        const post = docSnap.data();
        if (post.exerciseId) {
          const exerciseDocRef = doc(firestore, "exercises", post.exerciseId);
          const exerciseSnap = await getDoc(exerciseDocRef);
          if (exerciseSnap.exists()) {
            const exercise = exerciseSnap.data();
            const authorProfile = await getUserProfile(exercise.userId);
            const commentCount = await getCommentCount(docSnap.id);
            return {
              id: docSnap.id,
              ...post,
              exerciseTitle: exercise.title,
              exerciseAuthor: authorProfile?.username,
              likeCount: post.likes ? Object.keys(post.likes).length : 0,
              commentCount,
            };
          }
        }
        const commentCount = await getCommentCount(docSnap.id);
        return {
          id: docSnap.id,
          ...post,
          likeCount: post.likes ? Object.keys(post.likes).length : 0,
          commentCount,
        };
      })
    );
    callback(postsArray.filter(Boolean).sort((a, b) => b.createdAt - a.createdAt));
  });
  return unsubscribe;
};

// Fetch all posts in real time, including exercise details when available
export const fetchAllPosts = (callback) => {
  const postsCollectionRef = collection(firestore, "posts");
  const q = query(postsCollectionRef, orderBy("createdAt", "desc"));
  const unsubscribe = onSnapshot(q, async (snapshot) => {
    const postsArray = await Promise.all(
      snapshot.docs.map(async (docSnap) => {
        const post = docSnap.data();
        if (post.exerciseId) {
          const exerciseDocRef = doc(firestore, "exercises", post.exerciseId);
          const exerciseSnap = await getDoc(exerciseDocRef);
          if (exerciseSnap.exists()) {
            const exercise = exerciseSnap.data();
            const authorProfile = await getUserProfile(exercise.userId);
            const commentCount = await getCommentCount(docSnap.id);
            return {
              id: docSnap.id,
              ...post,
              exerciseTitle: exercise.title,
              exerciseAuthor: authorProfile?.username,
              likeCount: post.likes ? Object.keys(post.likes).length : 0,
              commentCount,
            };
          }
        }
        const commentCount = await getCommentCount(docSnap.id);
        return {
          id: docSnap.id,
          ...post,
          likeCount: post.likes ? Object.keys(post.likes).length : 0,
          commentCount,
        };
      })
    );
    callback(postsArray.filter(Boolean));
  });
  return unsubscribe;
};
