import { trackEvent } from "./events";
import { getUtmParams } from "./utm";


// Track lead generation
export function trackLead(source) {
  trackEvent('generate_lead', {
    lead_source: source
  });
}

// Track user site visits with UTM parameters
export function trackSiteVisit(source = 'direct', referrer = null) {
  // Get UTM parameters from the URL
  const utmParams = getUtmParams();
  
  // Create the event data with basic visit info
  const eventData = {
    visit_source: source,
    referrer: referrer || document.referrer || 'none',
    timestamp: new Date().toISOString()
  };
  
  // Add UTM parameters if they exist
  if (Object.keys(utmParams).length > 0) {
    Object.assign(eventData, utmParams);
  }
  
  // Track the event with all parameters
  trackEvent('site_visit', eventData);
}

// Track account creation
export function trackAccountCreation(method = 'email', userId = null) {
  trackEvent('account_creation', {
    creation_method: method,
    user_id: userId,
    timestamp: new Date().toISOString()
  });
}

// Track onboarding completion
export function trackOnboardingComplete(userId = null, steps_completed = []) {
  trackEvent('onboarding_complete', {
    user_id: userId,
    steps_completed,
    timestamp: new Date().toISOString()
  });
}

// Track post creation
export function trackPostCreation(userId = null, postId = null, contentType = 'post') {
  trackEvent('post_creation', {
    user_id: userId,
    post_id: postId,
    content_type: contentType,
    timestamp: new Date().toISOString()
  });
}

// Track exercise creation
export function trackExerciseCreation(userId = null, exerciseId = null, muscleGroups = []) {
  trackEvent('exercise_creation', {
    user_id: userId,
    exercise_id: exerciseId,
    muscle_groups: muscleGroups,
    timestamp: new Date().toISOString()
  });
}

// Track body scan creation
export function trackScanCreation(userId = null, scanId = null) {
  trackEvent('scan_creation', {
    user_id: userId,
    scan_id: scanId,
    timestamp: new Date().toISOString()
  });
}