import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';
import { doc, getDoc } from 'firebase/firestore';
import { firestore } from '../firebase/Init';
import { useAuth } from '../contexts/AuthContext';
import { getPublicProfileByUserId } from '../firebase/PublicProfile';
import { hasUserLiked, toggleLike } from '../firebase/interactions/Likes';
import { addComment, getComments, getCommentCount, deleteComment } from '../firebase/interactions/Comments';
import { useHeader } from '../contexts/HeaderContext';

const PostDetail = () => {
    const { postId } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const { currentUser } = useAuth();
    const { updateHeader } = useHeader();
    
    const [post, setPost] = useState(null);
    const [loading, setLoading] = useState(true);
    const [imageLoaded, setImageLoaded] = useState(false);
    const [comment, setComment] = useState('');
    const [comments, setComments] = useState([]);
    const [isLiked, setIsLiked] = useState(false);
    const [likeCount, setLikeCount] = useState(0);
    const [commentCount, setCommentCount] = useState(0);
    const [submittingComment, setSubmittingComment] = useState(false);
    const [showComments, setShowComments] = useState(location.state?.openComments || true);
    const [deletingCommentId, setDeletingCommentId] = useState(null);

    // Set up header with back button
    useEffect(() => {
        const backButton = (
            <button 
                onClick={() => navigate(-1)}
                className="text-gray-600 hover:text-gray-900"
            >
                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 19l-7-7m0 0l7-7m-7 7h18" />
                </svg>
            </button>
        );
        
        updateHeader('POST', null, backButton);
        
        return () => updateHeader('');
    }, [navigate, updateHeader]);

    useEffect(() => {
        const fetchPostData = async () => {
            try {
                // Fetch post data from Firebase
                const postDocRef = doc(firestore, "posts", postId);
                const postSnap = await getDoc(postDocRef);
                
                if (!postSnap.exists()) {
                    setLoading(false);
                    return;
                }
                
                const postData = postSnap.data();
                
                // Check if current user has liked the post
                let userHasLiked = false;
                if (currentUser) {
                    userHasLiked = await hasUserLiked(postId, currentUser.uid);
                }
                
                // Get like and comment counts
                const likeCountValue = postData.likeCount || 0;
                const commentCountValue = postData.commentCount || 0;
                
                // Fetch exercise details if this post references an exercise
                let exerciseDetails = {};
                if (postData.exerciseId) {
                    const exerciseDocRef = doc(firestore, "exercises", postData.exerciseId);
                    const exerciseSnap = await getDoc(exerciseDocRef);
                    
                    if (exerciseSnap.exists()) {
                        const exerciseData = exerciseSnap.data();
                        
                        // Fetch exercise author profile
                        const authorProfile = await getPublicProfileByUserId(exerciseData.userId);
                        
                        exerciseDetails = {
                            exerciseTitle: exerciseData.title,
                            exerciseAuthor: authorProfile?.username || 'unknown'
                        };
                    }
                }
                
                // Fetch comments for this post if showing comments
                if (showComments) {
                    const commentsData = await getComments(postId);
                    setComments(commentsData);
                }
                
                // Set state with all the data
                setPost({
                    id: postId,
                    ...postData,
                    ...exerciseDetails
                });
                setLikeCount(likeCountValue);
                setCommentCount(commentCountValue);
                setIsLiked(userHasLiked);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching post:', error);
                setLoading(false);
            }
        };

        fetchPostData();
    }, [postId, currentUser, showComments]);

    // Refresh comment count when needed
    const refreshCommentCount = async () => {
        try {
            const count = await getCommentCount(postId);
            setCommentCount(count);
        } catch (error) {
            console.error('Error refreshing comment count:', error);
        }
    };

    const handleLike = async () => {
        if (!currentUser) {
            // Redirect to login or show login prompt
            navigate('/login');
            return;
        }
        
        try {
            // Use the toggleLike function which handles both liking and unliking
            const newLikeState = await toggleLike(postId, currentUser.uid);
            
            // Update local state based on the new like state
            setIsLiked(newLikeState);
            setLikeCount(prev => newLikeState ? prev + 1 : prev - 1);
        } catch (error) {
            console.error('Error toggling like:', error);
        }
    };

    const handleComment = async (e) => {
        e.preventDefault();
        if (!comment.trim() || !currentUser) return;

        setSubmittingComment(true);
        try {
            // Get the current user's username
            const username = currentUser.displayName || 'Anonymous';
            
            // Add comment to Firebase
            const commentId = await addComment(postId, currentUser.uid, username, comment.trim());
            
            // Create a new comment object for local state
            const newComment = {
                id: commentId,
                userId: currentUser.uid,
                username: username,
                comment: comment.trim(),
                createdAt: Date.now()
            };
            
            // Update local state
            setComments([newComment, ...comments]);
            setComment('');
            
            // Refresh the comment count from Firestore
            await refreshCommentCount();
        } catch (error) {
            console.error('Error adding comment:', error);
        } finally {
            setSubmittingComment(false);
        }
    };

    const handleDeleteComment = async (commentId) => {
        if (!currentUser) return;
        
        setDeletingCommentId(commentId);
        try {
            await deleteComment(postId, commentId);
            
            // Update local state
            setComments(comments.filter(c => c.id !== commentId));
            
            // Refresh the comment count from Firestore
            await refreshCommentCount();
        } catch (error) {
            console.error('Error deleting comment:', error);
        } finally {
            setDeletingCommentId(null);
        }
    };

    if (loading) {
        return (
            <div className="flex justify-center items-center h-screen">
                <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
            </div>
        );
    }

    if (!post) {
        return (
            <div className="flex flex-col items-center justify-center h-screen">
                <h2 className="text-xl font-bold mb-2">Post not found</h2>
                <button 
                    className="text-blue-500 hover:underline"
                    onClick={() => navigate('/')}
                >
                    Return to home
                </button>
            </div>
        );
    }

    return (
        <div className="w-[90%] mx-auto bg-white shadow-sm">
            {/* Post Content */}
            <div className="border-b border-gray-200">
                {/* Header */}
                <div className="flex p-4">
                    <div 
                        className="w-12 h-12 rounded-full bg-gray-200 mr-3 shrink-0 cursor-pointer"
                        onClick={() => navigate(`/profile/${post.userId}`)}
                    />
                    <div className="flex flex-col justify-center">
                        <span 
                            className="font-semibold text-base cursor-pointer hover:underline"
                            onClick={() => navigate(`/profile/${post.userId}`)}
                        >
                            @{post.username}
                        </span>
                        <p className="text-base mt-1">{post.caption}</p>
                    </div>
                    <button className="text-gray-400 hover:text-gray-600 ml-auto">
                        <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 24 24">
                            <path d="M12 14a2 2 0 1 0 0-4 2 2 0 0 0 0 4z" />
                            <path d="M19 14a2 2 0 1 0 0-4 2 2 0 0 0 0 4z" />
                            <path d="M5 14a2 2 0 1 0 0-4 2 2 0 0 0 0 4z" />
                        </svg>
                    </button>
                </div>

                {/* Media */}
                {post.mediaUrl && (
                    <div className="relative aspect-[4/3] bg-gray-200">
                        {post.mediaType === 'video' ? (
                            <video
                                src={post.mediaUrl}
                                className="absolute inset-0 w-full h-full object-cover"
                                controls
                            />
                        ) : (
                            <>
                                {/* Loading placeholder */}
                                <div className={`absolute inset-0 bg-gray-200 transition-opacity duration-300 ${imageLoaded ? 'opacity-0' : 'opacity-100'}`} />
                                
                                {/* Image */}
                                <img
                                    src={post.mediaUrl}
                                    alt="Post"
                                    className={`absolute inset-0 w-full h-full object-cover transition-opacity duration-300 ${imageLoaded ? 'opacity-100' : 'opacity-0'}`}
                                    onLoad={() => setImageLoaded(true)}
                                />
                            </>
                        )}
                        {/* Exercise Reference Overlay */}
                        {post.exerciseId && (
                            <div 
                                className="absolute bottom-0 left-0 right-0 p-3 bg-black/50 backdrop-blur-sm cursor-pointer"
                                onClick={() => navigate(`/exercises/${post.exerciseId}`)}
                            >
                                <div className="flex items-center text-white">
                                    <span className="font-medium text-base">
                                        {post.exerciseTitle}
                                    </span>
                                    <span className="mx-2 opacity-60">•</span>
                                    <span className="text-base opacity-80">
                                        @{post.exerciseAuthor}
                                    </span>
                                </div>
                            </div>
                        )}
                    </div>
                )}

                {/* Actions */}
                <div className="p-4">
                    <div className="flex items-center">
                        <motion.button 
                            whileTap={{ scale: 0.9 }}
                            className={`transition-colors ${isLiked ? 'text-red-500' : 'hover:text-red-500'}`}
                            onClick={handleLike}
                        >
                            <svg className="w-7 h-7" fill={isLiked ? "currentColor" : "none"} stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" />
                            </svg>
                        </motion.button>
                        <span className="text-base font-semibold ml-1 mr-4">{likeCount}</span>
                        <button 
                            className="hover:text-blue-500 transition-colors"
                            onClick={() => setShowComments(!showComments)}
                        >
                            <svg className="w-7 h-7" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z" />
                            </svg>
                        </button>
                        <span className="text-base ml-1">{commentCount}</span>
                        <button className="ml-auto hover:text-gray-600">
                            <svg className="w-7 h-7" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8.684 13.342C8.886 12.938 9 12.482 9 12c0-.482-.114-.938-.316-1.342m0 2.684a3 3 0 110-2.684m0 2.684l6.632 3.316m-6.632-6l6.632-3.316m0 0a3 3 0 105.367-2.684 3 3 0 00-5.367 2.684zm0 9.316a3 3 0 105.368 2.684 3 3 0 00-5.368-2.684z" />
                            </svg>
                        </button>
                    </div>
                    <div className="mt-2 text-sm text-gray-500">
                        {new Date(post.createdAt).toLocaleString()}
                    </div>
                </div>
            </div>

            {/* Comment Form */}
            {showComments && (
                <>
                    <div className="p-4 border-b border-gray-200">
                        <form onSubmit={handleComment} className="flex">
                            <input
                                type="text"
                                placeholder={currentUser ? "Add a comment..." : "Sign in to comment"}
                                className="flex-grow p-2 text-base border border-gray-300 rounded-l-md focus:outline-none focus:ring-1 focus:ring-blue-500"
                                value={comment}
                                onChange={(e) => setComment(e.target.value)}
                                disabled={!currentUser || submittingComment}
                            />
                            <button 
                                type="submit"
                                className="bg-blue-500 text-white px-4 py-2 text-base rounded-r-md hover:bg-blue-600 transition-colors disabled:bg-blue-300"
                                disabled={!comment.trim() || !currentUser || submittingComment}
                            >
                                {submittingComment ? 'Posting...' : 'Post'}
                            </button>
                        </form>
                    </div>

                    {/* Comments */}
                    <div className="divide-y divide-gray-200">
                        {comments.length === 0 ? (
                            <div className="p-6 text-center text-gray-500 text-base">
                                No comments yet. Be the first to comment!
                            </div>
                        ) : (
                            comments.map(comment => (
                                <div key={comment.id} className="p-4">
                                    <div className="flex">
                                        <div 
                                            className="w-8 h-8 rounded-full bg-gray-200 mr-3 shrink-0 cursor-pointer"
                                            onClick={() => navigate(`/profile/${comment.userId}`)}
                                        />
                                        <div className="flex-grow">
                                            <div className="flex items-center">
                                                <span 
                                                    className="font-semibold text-base cursor-pointer hover:underline"
                                                    onClick={() => navigate(`/profile/${comment.userId}`)}
                                                >
                                                    @{comment.username}
                                                </span>
                                                <span className="text-sm text-gray-500 ml-2">
                                                    {new Date(comment.createdAt).toLocaleString()}
                                                </span>
                                                
                                                {/* Delete button - only show for the comment author */}
                                                {currentUser && currentUser.uid === comment.userId && (
                                                    <button 
                                                        className="ml-auto text-gray-400 hover:text-red-500"
                                                        onClick={() => handleDeleteComment(comment.id)}
                                                        disabled={deletingCommentId === comment.id}
                                                    >
                                                        {deletingCommentId === comment.id ? (
                                                            <span className="text-xs">Deleting...</span>
                                                        ) : (
                                                            <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                                            </svg>
                                                        )}
                                                    </button>
                                                )}
                                            </div>
                                            <p className="mt-1 text-base">{comment.comment}</p>
                                        </div>
                                    </div>
                                </div>
                            ))
                        )}
                    </div>
                </>
            )}
        </div>
    );
};

export default PostDetail; 