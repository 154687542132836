import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

const WelcomePopup = () => {
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        // Show popup after a short delay when component mounts
        const timer = setTimeout(() => {
            setIsOpen(true);
        }, 500);

        return () => clearTimeout(timer);
    }, []);

    const closePopup = () => {
        setIsOpen(false);
    };

    return (
        <AnimatePresence>
            {isOpen && (
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    className="fixed inset-0 bg-black bg-opacity-50 z-50"
                    onClick={closePopup}
                >
                    <motion.div
                        initial={{ y: "100%" }}
                        animate={{ y: 0 }}
                        exit={{ y: "100%" }}
                        transition={{ type: "spring", damping: 30, stiffness: 300 }}
                        className="absolute bottom-0 left-0 right-0 bg-white rounded-t-lg p-6 max-h-[90vh] overflow-y-auto"
                        onClick={(e) => e.stopPropagation()}
                    >
                        <div className="w-12 h-1 bg-gray-300 rounded mx-auto mb-4" />
                        <h2 className="text-2xl font-black mb-4 flex items-center">
                            Welcome to MOGGED
                        </h2>
                        <div className="space-y-4">
                            <p className="font-light text-xl">
                                MOGGED is a new fitness community built for lifters to connect, track progress, and share their journey. We're still in the early stages, actively developing features that will bring lifters together—whether it's through rankings, progress updates, or discovering new workouts and meal plans.
                            </p>
                            <p className="font-light text-xl">
                                As we build, we want your input! Join us in shaping MOGGED into the ultimate space for lifters to motivate each other, share insights, and grow together.
                            </p>
                            <button 
                                onClick={closePopup}
                                className="w-full mt-4 px-4 py-2 text-white font-bold bg-black hover:bg-gray-700 hover:shadow-xl transition duration-300"
                            >
                                Got it
                            </button>
                        </div>
                    </motion.div>
                </motion.div>
            )}
        </AnimatePresence>
    );
};

export default WelcomePopup; 