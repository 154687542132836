import React, { useRef, useEffect, useState, useCallback, forwardRef, useImperativeHandle } from 'react';
import * as tf from '@tensorflow/tfjs';
import * as poseDetection from '@tensorflow-models/pose-detection';
import { isDoubleBicepPose, isBodyInFrame, drawSkeleton } from '../../utils/poseUtils';

// Define pose types and their requirements
export const POSE_TYPES = {
    FRONT: {
        id: 'FRONT',
        name: 'Front Double Bicep',
        description: 'Stand facing the camera with arms raised, flexing biceps',
        checkPose: (pose) => {
            // Use existing isDoubleBicepPose logic
            return isDoubleBicepPose(pose);
        }
    },
    SIDE: {
        id: 'SIDE',
        name: 'Side Chest',
        description: 'Stand sideways, chest expanded, near arm raised',
        checkPose: (pose) => {
            // Add side pose detection logic here
            return true; // Placeholder
        }
    },
    BACK: {
        id: 'BACK',
        name: 'Back Double Bicep',
        description: 'Face away from camera, raise and flex both arms',
        checkPose: (pose) => {
            // Add back pose detection logic here
            return true; // Placeholder
        }
    }
};

const PoseCamera = forwardRef(({ onCapture, poseType, onInitialized }, ref) => {
    const videoRef = useRef(null);
    const canvasRef = useRef(null);
    const containerRef = useRef(null);
    const [detector, setDetector] = useState(null);
    const [isStreaming, setIsStreaming] = useState(false);
    const [isInitializing, setIsInitializing] = useState(true);
    const localStreamRef = useRef(null);
    const requestAnimationRef = useRef(null);
    const FRAMES_TO_HOLD = 30;
    const poseBufferRef = useRef([]);
    const currentPoseType = POSE_TYPES[poseType];
    const SMOOTHING_WINDOW = 10; // Number of frames to average
    const poseHistoryRef = useRef([]); // Store recent poses

    const captureImage = useCallback(async () => {
        if (!canvasRef.current) return;

        try {
            canvasRef.current.toBlob((blob) => {
                const file = new File([blob], 'pose-capture.jpg', { type: 'image/jpeg' });
                onCapture(file);
            }, 'image/jpeg');
        } catch (error) {
            console.error('Error capturing image:', error);
        }
    }, [onCapture]);

    const smoothPose = (newPose) => {
        if (!newPose) return null;

        // Add new pose to history
        poseHistoryRef.current.push(newPose);

        // Keep only the most recent frames
        if (poseHistoryRef.current.length > SMOOTHING_WINDOW) {
            poseHistoryRef.current.shift();
        }

        // Not enough frames yet for smoothing
        if (poseHistoryRef.current.length < 2) return newPose;

        // Create smoothed pose
        const smoothed = {
            ...newPose,
            keypoints: newPose.keypoints.map((keypoint, index) => {
                // Calculate average position for this keypoint
                const avgX = poseHistoryRef.current.reduce((sum, pose) =>
                    sum + pose.keypoints[index].x, 0) / poseHistoryRef.current.length;
                const avgY = poseHistoryRef.current.reduce((sum, pose) =>
                    sum + pose.keypoints[index].y, 0) / poseHistoryRef.current.length;

                return {
                    ...keypoint,
                    x: avgX,
                    y: avgY
                };
            })
        };

        return smoothed;
    };

    const stopCamera = useCallback(() => {
        if (requestAnimationRef.current) {
            cancelAnimationFrame(requestAnimationRef.current);
            requestAnimationRef.current = null; // Clear the reference
        }

        if (localStreamRef.current) {
            localStreamRef.current.getTracks().forEach(track => track.stop());
            localStreamRef.current = null;
        }

        if (videoRef.current) {
            videoRef.current.pause();
            videoRef.current.srcObject = null;
        }

        setIsStreaming(false);
    }, []);

    // Add stopCamera to ref for parent component access
    useImperativeHandle(ref, () => ({
        stopCamera
    }), [stopCamera]);

    const detectPose = useCallback(async () => {
        if (!detector || !isStreaming || !canvasRef.current) return; // Simple check here is sufficient

        try {
            const poses = await detector.estimatePoses(videoRef.current);
            const ctx = canvasRef.current.getContext('2d');

            ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
            ctx.drawImage(videoRef.current, 0, 0, canvasRef.current.width, canvasRef.current.height);

            if (poses.length > 0) {
                // Apply smoothing to the pose
                const smoothedCurrentPose = smoothPose(poses[0]);

                // Use smoothed pose for all subsequent operations
                const frameCheck = isBodyInFrame(smoothedCurrentPose, canvasRef);
                const rawPoseCheck = currentPoseType.checkPose(smoothedCurrentPose);

                const isInPosition = rawPoseCheck && frameCheck.inFrame;

                if (updatePoseBuffer(isInPosition)) {
                    await captureImage();
                    stopCamera(); // Stop camera after capturing image
                    return;
                }

                // Draw frame guide
                const margin = 0.1;
                ctx.strokeStyle = frameCheck.inFrame ? '#00ff00' : '#ff0000';
                ctx.lineWidth = 2;
                ctx.setLineDash([5, 5]);
                ctx.strokeRect(
                    canvasRef.current.width * margin,
                    canvasRef.current.height * margin,
                    canvasRef.current.width * (1 - 2 * margin),
                    canvasRef.current.height * (1 - 2 * margin)
                );
                ctx.setLineDash([]);

                // Draw skeleton with color based on pose detection
                const skeletonColor = isInPosition ? '#00ff00' : '#ff0000';
                ctx.strokeStyle = skeletonColor;
                ctx.lineWidth = 2;
                drawSkeleton(ctx, smoothedCurrentPose);

                // Draw keypoints using smoothed pose
                smoothedCurrentPose.keypoints.forEach(keypoint => {
                    if (keypoint.score > 0.3) {
                        ctx.beginPath();
                        ctx.arc(keypoint.x, keypoint.y, 4, 0, 2 * Math.PI);
                        ctx.fillStyle = skeletonColor;
                        ctx.fill();
                    }
                });

                // Update pose instruction text
                if (frameCheck.inFrame) {
                    ctx.font = '24px Arial';
                    ctx.fillStyle = 'white';
                    ctx.strokeStyle = 'black';
                    ctx.lineWidth = 3;
                    ctx.textAlign = 'center';
                    ctx.strokeText(currentPoseType.description, canvasRef.current.width / 2, 70);
                    ctx.fillText(currentPoseType.description, canvasRef.current.width / 2, 70);
                }
            }
        } catch (error) {
            console.error('Error in pose detection:', error);
        }
    }, [detector, currentPoseType, captureImage, stopCamera, isStreaming]);

    const updatePoseBuffer = (isInPose) => {
        poseBufferRef.current.push(isInPose);
        if (poseBufferRef.current.length > FRAMES_TO_HOLD) {
            poseBufferRef.current.shift();
        }

        // Check if we have enough consecutive true values
        if (poseBufferRef.current.length === FRAMES_TO_HOLD &&
            poseBufferRef.current.every(Boolean)) {
            return true;
        }
        return false;
    };

    const startCamera = useCallback(async () => {
        if (!videoRef.current) {
            console.log('Video element not yet available, waiting...');
            return;
        }

        console.log("Cross-Origin Isolation Enabled:", window.crossOriginIsolated);
        try {
            if (!window.isSecureContext) {
                console.error('Application not running in secure context');
            }

            const stream = await navigator.mediaDevices.getUserMedia({
                video: true,
                audio: false
            });

            videoRef.current.srcObject = stream;
            localStreamRef.current = stream;

            await new Promise((resolve) => {
                videoRef.current.onloadedmetadata = () => {
                    console.log('Video metadata loaded');
                    resolve();
                };
            });

            if (canvasRef.current && containerRef.current) {
                const { videoWidth, videoHeight } = videoRef.current;
                canvasRef.current.width = videoWidth;
                canvasRef.current.height = videoHeight;
                containerRef.current.style.aspectRatio = `${videoWidth}/${videoHeight}`;
            }

            setIsStreaming(true);
            setIsInitializing(false);
            onInitialized?.();

        } catch (error) {
            console.error('Error accessing camera:', error);
            setIsInitializing(false);
        }
    }, [onInitialized]);

    useEffect(() => {
        console.log("useEffect for initialization called");
        let mounted = true;

        const initializeAll = async () => {
            try {
                console.log("initializeAll called");
                while (mounted && !videoRef.current) {
                    await new Promise(resolve => setTimeout(resolve, 100));
                }

                if (!mounted) return;

                await tf.ready();
                const model = poseDetection.SupportedModels.MoveNet;
                const detectorConfig = {
                    modelType: poseDetection.movenet.modelType.SINGLEPOSE_LIGHTNING
                };
                const detector = await poseDetection.createDetector(model, detectorConfig);
                console.log("Pose detector created");

                if (!mounted) return;
                setDetector(detector);
                console.log("Detector set");
                await startCamera();
            } catch (error) {
                console.error("Error in initialization:", error);
                setIsInitializing(false);
            }
        };

        initializeAll();

        return () => {
            mounted = false;
            stopCamera();
            setIsInitializing(false);
        };
    }, [startCamera, stopCamera]);

    // Run pose detection loop
    useEffect(() => {
        let animationFrameId;

        const runDetection = async () => {
            if (isStreaming && detector) {
                await detectPose();
                animationFrameId = requestAnimationFrame(runDetection);
            }
        };

        if (isStreaming && detector) {
            runDetection();
        }

        return () => {
            if (animationFrameId) {
                cancelAnimationFrame(animationFrameId);
            }
        };
    }, [isStreaming, detector, detectPose]);

    return (
        <div className="relative flex flex-col h-full">
            {isInitializing ? (
                <div className="flex flex-col items-center justify-center h-64">
                    <div className="w-16 h-16 border-4 border-blue-500 border-t-transparent animate-spin mb-4" />
                    <p className="text-gray-600">Starting camera...</p>
                </div>
            ) : (
                <>
                    <video
                        ref={videoRef}
                        className="absolute w-full h-full object-contain bg-black"
                        autoPlay
                        playsInline
                    />
                    <div
                        ref={containerRef}
                        className="relative flex-1 flex items-center justify-center bg-black"
                    >
                        <canvas
                            ref={canvasRef}
                            className="absolute w-full h-full object-contain"
                        />
                    </div>

                    <div className="absolute top-2 right-2 bg-black bg-opacity-50 p-2 text-white">
                        <p>Current Pose:</p>
                        <p>{POSE_TYPES[poseType].name}</p>
                    </div>
                </>
            )}
        </div>
    );
});

export default PoseCamera; 