import React, { createContext, useContext, useState, useCallback } from 'react';

const HeaderContext = createContext();

export const useHeader = () => useContext(HeaderContext);

export const HeaderProvider = ({ children }) => {
  const [headerTitle, setHeaderTitle] = useState('');
  const [headerTrailing, setHeaderTrailing] = useState(null);
  const [headerLeading, setHeaderLeading] = useState(null);

  const updateHeader = useCallback((title, trailing = null, leading = null) => {
    setHeaderTitle(title);
    setHeaderTrailing(trailing);
    setHeaderLeading(leading);
  }, []);

  return (
    <HeaderContext.Provider value={{ 
      headerTitle, 
      headerTrailing, 
      headerLeading, 
      updateHeader,
      setHeaderTitle,
      setHeaderTrailing,
      setHeaderLeading
    }}>
      {children}
    </HeaderContext.Provider>
  );
}; 