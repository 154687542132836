import { firestore } from "./Init";
import {
  doc,
  getDoc,
  setDoc,
  deleteDoc,
  collection,
  query,
  getDocs,
  onSnapshot,
  serverTimestamp,
  orderBy,
  limit,
  updateDoc,
  increment
} from "firebase/firestore";

/**
 * Add a following relationship by creating documents in subcollections
 * @param {string} currentUserId - The ID of the user who is following
 * @param {string} targetUserId - The ID of the user being followed
 * @returns {Promise<boolean>} - Whether the operation was successful
 */
export const addFollowing = async (currentUserId, targetUserId) => {
  console.log(`[Following] Adding following relationship: ${currentUserId} -> ${targetUserId}`);
  
  if (!currentUserId || !targetUserId) {
    console.warn("[Following] Missing user IDs in addFollowing");
    return false;
  }
  
  if (currentUserId === targetUserId) {
    console.warn("[Following] User attempted to follow themselves");
    return false; // Can't follow yourself
  }
  
  try {
    // First check if the relationship already exists to avoid duplicate increments
    const alreadyFollowing = await isFollowing(currentUserId, targetUserId);
    if (alreadyFollowing) {
      console.log(`[Following] Relationship already exists: ${currentUserId} -> ${targetUserId}`);
      return true; // Already following, no need to create documents or increment counts
    }
    
    // Create a document in the current user's following collection
    const followingRef = doc(
      firestore,
      `users/${currentUserId}/publicProfile/info/following/${targetUserId}`
    );
    
    // Create a document in the target user's followers collection
    const followerRef = doc(
      firestore,
      `users/${targetUserId}/publicProfile/info/followers/${currentUserId}`
    );
    
    // Get references to the public profile documents
    const currentUserProfileRef = doc(
      firestore,
      `users/${currentUserId}/publicProfile/info`
    );
    
    const targetUserProfileRef = doc(
      firestore,
      `users/${targetUserId}/publicProfile/info`
    );
    
    console.log(`[Following] Creating documents at paths:
      - ${followingRef.path}
      - ${followerRef.path}`);
    
    // Add timestamp to both documents
    const timestamp = serverTimestamp();
    const followData = {
      userId: targetUserId,
      createdAt: timestamp
    };
    
    const followerData = {
      userId: currentUserId,
      createdAt: timestamp
    };
    
    // Create both documents
    await setDoc(followingRef, followData);
    await setDoc(followerRef, followerData);
    
    // Update the counts
    try {
      await updateDoc(currentUserProfileRef, {
        followingCount: increment(1)
      });
    } catch (error) {
      // If the document doesn't exist, create it with initial count
      if (error.code === 'not-found') {
        await setDoc(currentUserProfileRef, {
          followingCount: 1
        }, { merge: true });
      } else {
        throw error;
      }
    }
    
    try {
      await updateDoc(targetUserProfileRef, {
        followerCount: increment(1)
      });
    } catch (error) {
      // If the document doesn't exist, create it with initial count
      if (error.code === 'not-found') {
        await setDoc(targetUserProfileRef, {
          followerCount: 1
        }, { merge: true });
      } else {
        throw error;
      }
    }
    
    console.log(`[Following] Successfully added following relationship: ${currentUserId} -> ${targetUserId}`);
    return true;
  } catch (error) {
    console.error("[Following] Error adding following:", error);
    throw error;
  }
};

/**
 * Remove a following relationship by deleting documents from subcollections
 * @param {string} currentUserId - The ID of the user who is unfollowing
 * @param {string} targetUserId - The ID of the user being unfollowed
 * @returns {Promise<boolean>} - Whether the operation was successful
 */
export const removeFollowing = async (currentUserId, targetUserId) => {
  console.log(`[Following] Removing following relationship: ${currentUserId} -> ${targetUserId}`);
  
  if (!currentUserId || !targetUserId) {
    const missingId = !currentUserId ? 'currentUserId' : 'targetUserId';
    console.warn(`[Following] Missing ${missingId} in removeFollowing`);
    return false;
  }
  
  try {
    // First check if the following relationship exists
    const isAlreadyFollowing = await isFollowing(currentUserId, targetUserId);
    if (!isAlreadyFollowing) {
      console.log(`[Following] Relationship doesn't exist: ${currentUserId} -> ${targetUserId}`);
      return true; // Already not following
    }
    
    // Get references to both documents
    const followingRef = doc(
      firestore,
      `users/${currentUserId}/publicProfile/info/following/${targetUserId}`
    );
    
    const followerRef = doc(
      firestore,
      `users/${targetUserId}/publicProfile/info/followers/${currentUserId}`
    );
    
    // Get references to the public profile documents
    const currentUserProfileRef = doc(
      firestore,
      `users/${currentUserId}/publicProfile/info`
    );
    
    const targetUserProfileRef = doc(
      firestore,
      `users/${targetUserId}/publicProfile/info`
    );
    
    console.log(`[Following] Deleting documents at paths:
      - ${followingRef.path}
      - ${followerRef.path}`);
    
    // Delete both documents
    await deleteDoc(followingRef);
    await deleteDoc(followerRef);
    
    // Update the counts
    await updateDoc(currentUserProfileRef, {
      followingCount: increment(-1)
    });
    
    await updateDoc(targetUserProfileRef, {
      followerCount: increment(-1)
    });
    
    console.log(`[Following] Successfully removed following relationship: ${currentUserId} -> ${targetUserId}`);
    return true;
  } catch (error) {
    console.error("[Following] Error removing following:", error);
    throw error;
  }
};

/**
 * Toggle following status for a user
 * @param {string} currentUserId - The ID of the current user
 * @param {string} targetUserId - The ID of the target user
 * @returns {Promise<boolean>} - The new following state (true if following, false if unfollowed)
 */
export const toggleFollowing = async (currentUserId, targetUserId) => {
  console.log(`[Following] Toggling following status: ${currentUserId} -> ${targetUserId}`);
  
  try {
    const following = await isFollowing(currentUserId, targetUserId);
    console.log(`[Following] Current status: ${following ? 'Following' : 'Not following'}`);
    
    if (following) {
      await removeFollowing(currentUserId, targetUserId);
      console.log(`[Following] Toggled to: Not following`);
      return false;
    } else {
      await addFollowing(currentUserId, targetUserId);
      console.log(`[Following] Toggled to: Following`);
      return true;
    }
  } catch (error) {
    console.error("[Following] Error toggling following status:", error);
    throw error;
  }
};

/**
 * Check if currentUserId is following targetUserId
 * @param {string} currentUserId - The ID of the current user
 * @param {string} targetUserId - The ID of the target user
 * @returns {Promise<boolean>} - Whether the current user is following the target user
 */
export const isFollowing = async (currentUserId, targetUserId) => {
  console.log("isFollowing called with:", currentUserId, targetUserId);
  if (!currentUserId || !targetUserId) {
    console.warn("[Following] Missing user IDs in isFollowing");
    return false;
  }
  
  try {
    const followingRef = doc(
      firestore,
      `users/${currentUserId}/publicProfile/info/following/${targetUserId}`
    );
    
    const docSnap = await getDoc(followingRef);
    const result = docSnap.exists();
    console.log(`[Following] Checking if ${currentUserId} follows ${targetUserId}: ${result}`);
    return result;
  } catch (error) {
    console.error("[Following] Error checking following status:", error);
    return false;
  }
};

/**
 * Retrieve a list of users that the given user is following
 * @param {string} userId - The ID of the user
 * @param {number} [maxResults=100] - Maximum number of results to return
 * @returns {Promise<Array>} - Array of user objects the user is following
 */
export const getFollowingByUserId = async (userId, maxResults = 100) => {
  console.log(`[Following] Getting following list for user: ${userId}`);
  
  if (!userId) {
    console.warn("[Following] Missing userId in getFollowing");
    return [];
  }
  
  try {
    const followingRef = collection(
      firestore,
      `users/${userId}/publicProfile/info/following`
    );
    
    const q = query(
      followingRef,
      orderBy("createdAt", "desc"),
      limit(maxResults)
    );
    
    const querySnapshot = await getDocs(q);
    const following = querySnapshot.docs.map(doc => ({
      userId: doc.id,
      ...doc.data()
    }));
    
    console.log(`[Following] Found ${following.length} users that ${userId} is following`);
    return following;
  } catch (error) {
    console.error("[Following] Error getting following list:", error);
    throw error;
  }
};

/**
 * Get the count of users that the given user is following
 * @param {string} userId - The ID of the user
 * @returns {Promise<number>} - The number of users being followed
 */
export const getFollowingCount = async (userId) => {
  if (!userId) return 0;
  try {
    const following = await getFollowingByUserId(userId);
    return following.length;
  } catch (error) {
    console.error("Error getting following count:", error);
    return 0;
  }
};

/**
 * Listen in real time to the following status for a given target user
 * @param {string} currentUserId - The ID of the current user
 * @param {string} targetUserId - The ID of the target user
 * @param {Function} callback - Callback function with isFollowing status
 * @returns {Function} - Unsubscribe function
 */
export const listenToFollowingStatus = (currentUserId, targetUserId, callback) => {
  if (!currentUserId || !targetUserId) return () => {};
  
  const followingRef = doc(
    firestore,
    `users/${currentUserId}/publicProfile/info/following/${targetUserId}`
  );
  
  const unsubscribe = onSnapshot(followingRef, (docSnap) => {
    callback(docSnap.exists());
  }, (error) => {
    console.error("Error listening to following status:", error);
    callback(false);
  });
  
  return unsubscribe;
};

/**
 * Listen in real time to changes in the list of users that the given user is following
 * @param {string} userId - The ID of the user
 * @param {Function} callback - Callback function with array of following user objects
 * @param {number} [maxResults=100] - Maximum number of results to return
 * @returns {Function} - Unsubscribe function
 */
export const listenToFollowing = (userId, callback, maxResults = 100) => {
  if (!userId) return () => {};
  
  const followingRef = collection(
    firestore,
    `users/${userId}/publicProfile/info/following`
  );
  
  const q = query(
    followingRef,
    orderBy("createdAt", "desc"),
    limit(maxResults)
  );
  
  const unsubscribe = onSnapshot(q, (querySnapshot) => {
    const following = querySnapshot.docs.map(doc => ({
      userId: doc.id,
      ...doc.data()
    }));
    callback(following);
  }, (error) => {
    console.error("Error listening to following list:", error);
    callback([]);
  });
  
  return unsubscribe;
};
