import { logEvent } from 'firebase/analytics';
import { analytics } from '../firebase/Init';
import { getUtmParams, getStoredUtmParams } from './utm';

// Get analytics instance

// Track page view with UTM parameters
export function trackPageView() {
    const utmParams = getUtmParams();
    
    // Log page_view event with UTM parameters
    logEvent(analytics, 'page_view', {
      page_title: document.title,
      page_location: window.location.href,
      page_path: window.location.pathname,
      ...utmParams
    });
    
    // If this is a landing from a QR code (utm_source=qrcode)
    if (utmParams.utm_source === 'qrcode') {
      logEvent(analytics, 'qr_code_scan', {
        ...utmParams
      });
    }
    
    // Store UTM parameters as user properties for attribution
    if (Object.keys(utmParams).length > 0) {
      // Store in sessionStorage for attribution across sessions
      sessionStorage.setItem('utm_params', JSON.stringify(utmParams));
    }
}

// Generic event tracking function with UTM attribution
export function trackEvent(eventName, eventParams = {}) {
    const utmParams = getStoredUtmParams();
    
    logEvent(analytics, eventName, {
      ...eventParams,
      ...utmParams
    });
}