import { firestore } from "./Init";
import {
  doc,
  getDoc,
  updateDoc,
  setDoc,
  deleteDoc,
  getDocs,
  collectionGroup,
  onSnapshot
} from "firebase/firestore";
import {
  PUBLIC_PROFILE_FIELDS,
  PROFILE_FIELD_MAPPING
} from "./constants";
import { validateUserData } from "./validation";

// Retrieve a user's public profile by userId
export const getPublicProfileByUserId = async (userId) => {
  if (!userId) return null;
  try {
    const profileRef = doc(firestore, "users", userId, "publicProfile", "info");
    const profileSnapshot = await getDoc(profileRef);
    if (!profileSnapshot.exists()) {
      return null;
    }
    return {
      uid: userId,
      ...profileSnapshot.data()
    };
  } catch (error) {
    console.error("Error fetching public profile by userId:", error);
    throw error;
  }
};

// Retrieve a user's public profile by username
export const getPublicProfileByUsername = async (username) => {
  if (!username) return null;
  const sanitizedUsername = username.toLowerCase().trim();
  try {
    const usernameRef = doc(firestore, "usernames", sanitizedUsername);
    const usernameSnapshot = await getDoc(usernameRef);
    if (!usernameSnapshot.exists()) {
      return null;
    }
    const uid = usernameSnapshot.data().uid;
    const profileRef = doc(firestore, "users", uid, "publicProfile", "info");
    const profileSnapshot = await getDoc(profileRef);
    if (!profileSnapshot.exists()) {
      return null;
    }
    return {
      uid,
      ...profileSnapshot.data()
    };
  } catch (error) {
    console.error("Error fetching public profile by username:", error);
    throw error;
  }
};

// Update public profile fields (only allowed public fields)
export const updatePublicProfileFields = async (uid, updates) => {
  if (!uid) return;
  const validationErrors = validateUserData(updates);
  if (validationErrors) {
    throw new Error(JSON.stringify(validationErrors));
  }
  const sanitizedUpdates = Object.keys(updates)
    .filter((key) => PUBLIC_PROFILE_FIELDS[key])
    .reduce((obj, key) => {
      const profileKey = PROFILE_FIELD_MAPPING[key] || key;
      obj[profileKey] = updates[key];
      return obj;
    }, {});
  try {
    const profileRef = doc(firestore, "users", uid, "publicProfile", "info");
    await updateDoc(profileRef, sanitizedUpdates);
  } catch (error) {
    console.error("Error updating public profile fields:", error);
    throw error;
  }
};

// Set public profile data based on privacy setting (minimal if private)
export const setPublicProfileData = async (uid, userData, isPrivate) => {
  if (!uid) return;
  try {
    const publicProfileRef = doc(firestore, "users", uid, "publicProfile", "info");
    if (isPrivate) {
      const minimalProfile = {
        username: userData.username,
        profileImage: userData.photoURL || ""
      };
      await setDoc(publicProfileRef, minimalProfile, { merge: true });
    } else {
      console.log("Setting public profile data for user:", uid);
      await setDoc(publicProfileRef, userData, { merge: true });
    }
  } catch (error) {
    console.error("Error setting public profile data:", error);
    throw error;
  }
};

// Sync the public profile with the main user document
export const syncPublicProfileWithUserDoc = async (uid) => {
  try {
    const userRef = doc(firestore, "users", uid);
    const userSnapshot = await getDoc(userRef);
    if (!userSnapshot.exists()) {
      throw new Error("User not found");
    }
    const userData = userSnapshot.data();
    await setPublicProfileData(uid, userData, userData.isPrivate);
    return true;
  } catch (error) {
    console.error("Error syncing public profile with user doc:", error);
    throw error;
  }
};

// Delete the public profile
export const deletePublicProfile = async (uid) => {
  if (!uid) return;
  try {
    const publicProfileRef = doc(firestore, "users", uid, "publicProfile", "info");
    await deleteDoc(publicProfileRef);
  } catch (error) {
    console.error("Error deleting public profile:", error);
    throw error;
  }
};

// Retrieve all public profiles using a collection group query
export const listPublicProfiles = async () => {
  try {
    const profilesQuerySnapshot = await getDocs(collectionGroup(firestore, "publicProfile"));
    const profiles = [];
    profilesQuerySnapshot.forEach((docSnapshot) => {
      // Retrieve the uid from the parent user document
      const userId = docSnapshot.ref.parent.parent.id;
      profiles.push({ uid: userId, ...docSnapshot.data() });
    });
    return profiles;
  } catch (error) {
    console.error("Error listing public profiles:", error);
    throw error;
  }
};

// Listen for updates to a user's public profile
export const listenToPublicProfile = (userId, callback) => {
  if (!userId) return null;
  
  try {
    const profileRef = doc(firestore, "users", userId, "publicProfile", "info");
    return onSnapshot(profileRef, (snapshot) => {
      if (snapshot.exists()) {
        const profileData = {
          uid: userId,
          ...snapshot.data()
        };
        callback(profileData);
      } else {
        callback(null);
      }
    }, (error) => {
      console.error("Error listening to public profile:", error);
      callback(null, error);
    });
  } catch (error) {
    console.error("Error setting up profile listener:", error);
    return null;
  }
};
