import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, useParams, Navigate } from "react-router-dom";
import { useUser } from "../contexts/UserContext";
import { useFollowing } from "../contexts/FollowingContext";
import { useFollowers } from "../contexts/FollowersContext";
import { useScan } from "../contexts/ScanContext";
import { addFollowing, removeFollowing } from "../firebase/Following";
import {
  FaDumbbell,
  FaUtensils,
  FaTrophy,
  FaClock,
  FaChevronDown,
  FaCheck,
  FaTimes,
} from "react-icons/fa";
import { motion, AnimatePresence } from "framer-motion";
import PostPreview from "../components/PostPreview";
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
import ScoreCard from "../components/ui/ScoreCard";
import RoutinePreview from "../components/routine/RoutinePreview";
import { useProfile } from "../contexts/ProfileContext";
import { useHeader } from '../contexts/HeaderContext';
import ReactDOM from 'react-dom';
import { isUsernameAvailable } from "../firebase/Username";

const formatDate = (dateString) => {
  if (!dateString) return "N/A";
  const date = new Date(dateString);
  return new Intl.DateTimeFormat("en-US", {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
  }).format(date);
};

const PostsTab = ({ userId }) => {
  const { profilePosts, postsLoading, loadProfilePosts } = useProfile();
  const [listener, setListener] = useState(null);

  useEffect(() => {
    // Only set up a listener if we don't already have one for this userId
    if (userId && !listener) {
      console.log(`PostsTab: Setting up posts listener for user ${userId}`);
      const unsubscribe = loadProfilePosts(userId);
      setListener(unsubscribe);
    }
    
    // Clean up the listener when the component unmounts or userId changes
    return () => {
      if (typeof listener === 'function') {
        console.log(`PostsTab: Cleaning up posts listener for user ${userId}`);
        listener();
        setListener(null);
      }
    };
  }, [userId, loadProfilePosts, listener]);

  if (postsLoading) {
    return (
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        {[1, 2, 3].map((i) => (
          <div key={i} className="aspect-square bg-gray-200 rounded-lg animate-pulse" />
        ))}
      </div>
    );
  }

  return (
    <div className="flex flex-col w-full space-y-4">
      {profilePosts.length === 0 ? (
        <div className="text-center text-xl text-gray-500 py-4">
          No posts yet.
        </div>
      ) : (
        profilePosts.map((post) => (
          <PostPreview key={post.id} post={post} />
        ))
      )}
    </div>
  );
};

const RoutinesTab = ({ userId }) => {
  const { profileRoutines, routinesLoading, loadProfileRoutines } = useProfile();

  useEffect(() => {
    if (userId) {
      loadProfileRoutines(userId);
    }
  }, [userId, loadProfileRoutines]);

  if (routinesLoading) {
    return (
      <div className="flex flex-col gap-4 w-full">
        {[1, 2, 3, 4].map((i) => (
          <div key={i} className="h-32 bg-gray-200 rounded-lg animate-pulse" />
        ))}
      </div>
    );
  }

  if (profileRoutines.length === 0) {
    return (
      <div className="text-center text-xl text-gray-500 py-4">
        No routines yet.
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-4 w-full">
      {profileRoutines.map((routine) => (
        <RoutinePreview key={routine.id} routine={routine} />
      ))}
    </div>
  );
};

const TabUnderline = () => (
  <motion.div
    className="absolute bottom-0 left-0 right-0 h-0.5 bg-black w-full"
    layoutId="tabUnderline"
  />
);

const StatsAccordion = ({ isOpen, onToggle, profileData }) => {
  return (
    <div className="mb-2 border border-gray-200 overflow-hidden">
      <div
        className="bg-black text-white px-4 py-1 flex justify-between items-center cursor-pointer sm:cursor-default"
        onClick={onToggle}
      >
        <h2 className="text-xl font-bold">STATS</h2>
        <div className="sm:hidden">
          <motion.div
            animate={{ rotate: isOpen ? 180 : 0 }}
            transition={{ duration: 0.3 }}
          >
            <FaChevronDown size={16} />
          </motion.div>
        </div>
      </div>
      <div className="hidden sm:block">
        <div className="w-full p-4">
          <div className="flex flex-col divide-y divide-gray-200">
            <div className="flex pb-3">
              <div className="flex items-center gap-2 sm:gap-4 flex-1">
                <div className="w-8 h-8 sm:w-12 sm:h-12 flex items-center justify-center bg-gray-100 rounded-full">
                  <FaDumbbell className="w-4 h-4 sm:w-6 sm:h-6 text-gray-700" />
                </div>
                <div>
                  <p className="text-gray-600 text-[10px] xs:text-xs sm:text-sm md:text-base">
                    Workout Streak
                  </p>
                  <p className="text-lg sm:text-xl md:text-2xl font-bold">COMING SOON</p>
                </div>
              </div>
              <div className="flex items-center gap-2 sm:gap-4 flex-1 pl-3 sm:pl-6">
                <div className="w-8 h-8 sm:w-12 sm:h-12 flex items-center justify-center bg-gray-100 rounded-full">
                  <FaUtensils className="w-4 h-4 sm:w-6 sm:h-6 text-gray-700" />
                </div>
                <div>
                  <p className="text-gray-600 text-[10px] xs:text-xs sm:text-sm md:text-base">
                    Meals Recorded
                  </p>
                  <p className="text-lg sm:text-xl md:text-2xl font-bold">COMING SOON</p>
                </div>
              </div>
            </div>
            <div className="flex pt-3">
              <div className="flex items-center gap-2 sm:gap-4 flex-1">
                <div className="w-8 h-8 sm:w-12 sm:h-12 flex items-center justify-center bg-gray-100 rounded-full">
                  <FaTrophy className="w-4 h-4 sm:w-6 sm:h-6 text-gray-700" />
                </div>
                <div>
                  <p className="text-gray-600 text-[10px] xs:text-xs sm:text-sm md:text-base">
                    RANK
                  </p>
                  <p className="text-lg sm:text-xl md:text-2xl font-bold">COMING SOON</p>
                </div>
              </div>
              <div className="flex items-center gap-2 sm:gap-4 flex-1 pl-3 sm:pl-6">
                <div className="w-8 h-8 sm:w-12 sm:h-12 flex items-center justify-center bg-gray-100 rounded-full">
                  <FaClock className="w-4 h-4 sm:w-6 sm:h-6 text-gray-700" />
                </div>
                <div className="flex flex-col">
                  <span className="text-sm text-gray-500">Member since</span>
                  <span>{formatDate(profileData?.createdAt)}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AnimatePresence initial={false}>
        {isOpen && (
          <motion.div
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: "auto", opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            transition={{ duration: 0.3, ease: "easeInOut" }}
            className="overflow-hidden sm:hidden"
          >
            <div className="w-full p-4">
              <div className="flex flex-col divide-y divide-gray-200">
                <div className="flex pb-3">
                  <div className="flex items-center gap-2 sm:gap-4 flex-1">
                    <div className="w-8 h-8 sm:w-12 sm:h-12 flex items-center justify-center bg-gray-100 rounded-full">
                      <FaDumbbell className="w-4 h-4 sm:w-6 sm:h-6 text-gray-700" />
                    </div>
                    <div>
                      <p className="text-gray-600 text-[10px] xs:text-xs sm:text-sm md:text-base">
                        Workout Streak
                      </p>
                      <p className="text-lg sm:text-xl md:text-2xl font-bold">100</p>
                    </div>
                  </div>
                  <div className="flex items-center gap-2 sm:gap-4 flex-1 pl-3 sm:pl-6">
                    <div className="w-8 h-8 sm:w-12 sm:h-12 flex items-center justify-center bg-gray-100 rounded-full">
                      <FaUtensils className="w-4 h-4 sm:w-6 sm:h-6 text-gray-700" />
                    </div>
                    <div>
                      <p className="text-gray-600 text-[10px] xs:text-xs sm:text-sm md:text-base">
                        Meals Recorded
                      </p>
                      <p className="text-lg sm:text-xl md:text-2xl font-bold">50</p>
                    </div>
                  </div>
                </div>
                <div className="flex pt-3">
                  <div className="flex items-center gap-2 sm:gap-4 flex-1">
                    <div className="w-8 h-8 sm:w-12 sm:h-12 flex items-center justify-center bg-gray-100 rounded-full">
                      <FaTrophy className="w-4 h-4 sm:w-6 sm:h-6 text-gray-700" />
                    </div>
                    <div>
                      <p className="text-gray-600 text-[10px] xs:text-xs sm:text-sm md:text-base">
                        World Rank
                      </p>
                      <p className="text-lg sm:text-xl md:text-2xl font-bold">25th</p>
                    </div>
                  </div>
                  <div className="flex items-center gap-2 sm:gap-4 flex-1 pl-3 sm:pl-6">
                    <div className="w-8 h-8 sm:w-12 sm:h-12 flex items-center justify-center bg-gray-100 rounded-full">
                      <FaClock className="w-4 h-4 sm:w-6 sm:h-6 text-gray-700" />
                    </div>
                    <div className="flex flex-col">
                      <span className="text-sm text-gray-500">Member since</span>
                      <span>{formatDate(profileData?.createdAt)}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

// Edit Profile Modal Component
const EditProfileModal = ({ isOpen, onClose, userData }) => {
  const [formData, setFormData] = useState({
    username: userData?.username || '',
    photoURL: userData?.photoURL || '',
  });
  const [photoPreview, setPhotoPreview] = useState(userData?.photoURL || '');
  const [usernameError, setUsernameError] = useState('');
  const [isChecking, setIsChecking] = useState(false);
  const [isAvailable, setIsAvailable] = useState(null);
  const [debounceTimeout, setDebounceTimeout] = useState(null);

  useEffect(() => {
    if (userData) {
      setFormData({
        username: userData.username || '',
        photoURL: userData.photoURL || '',
      });
      setPhotoPreview(userData.photoURL || '');
      // Reset username validation state when modal opens with current username
      setIsAvailable(true);
      setUsernameError('');
    }
  }, [userData]);

  const validateUsername = (username) => {
    if (username.length < 3) {
      return 'Username must be at least 3 characters';
    }
    if (username.length > 20) {
      return 'Username must be less than 20 characters';
    }
    if (!/^[a-zA-Z0-9_]+$/.test(username)) {
      return 'Username can only contain letters, numbers, and underscores';
    }
    return '';
  };

  const checkUsername = async (value) => {
    // If username hasn't changed, it's valid
    if (value === userData?.username) {
      setIsAvailable(true);
      setUsernameError('');
      return;
    }

    setIsChecking(true);
    setIsAvailable(null);
    
    const validationError = validateUsername(value);
    if (validationError) {
      setUsernameError(validationError);
      setIsChecking(false);
      setIsAvailable(false);
      return;
    }

    try {
      const available = await isUsernameAvailable(value);
      setIsAvailable(available);
      setUsernameError(available ? '' : 'Username is already taken');
    } catch (err) {
      setUsernameError('Error checking username availability');
      setIsAvailable(false);
    }
    setIsChecking(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    
    if (name === 'username') {
      const lowerCaseValue = value.toLowerCase();
      setFormData(prev => ({
        ...prev,
        [name]: lowerCaseValue
      }));
      
      if (debounceTimeout) clearTimeout(debounceTimeout);
      
      setDebounceTimeout(
        setTimeout(() => {
          if (lowerCaseValue) checkUsername(lowerCaseValue);
        }, 500)
      );
    } else {
      setFormData(prev => ({
        ...prev,
        [name]: value
      }));
    }
  };

  const handleFileChange = (e) => {
    try {
      const file = e.target.files[0];
      
      if (!file) {
        return;
      }
      
      if (!file.type.startsWith('image/')) {
        alert('Please upload an image file');
        return;
      }
      
      const previewUrl = URL.createObjectURL(file);
      
      setFormData(prev => ({
        ...prev,
        photoFile: file
      }));
      setPhotoPreview(previewUrl);
    } catch (error) {
      console.error("Error in handleFileChange:", error);
      alert('Error uploading file. Please try again.');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!isAvailable || isChecking) {
      return;
    }
    
    // Here you would implement the profile update logic
    // Now the file is available in formData.photoFile if needed for upload
    console.log('Submitting profile update with:', formData);
    
    // For now, just close the modal
    onClose();
  };

  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <div className="fixed inset-0 z-50 flex items-center justify-center backdrop-blur-sm bg-black bg-opacity-75 md:pl-16 lg:pl-64">
      <div className="bg-white text-black w-11/12 max-w-3xl mx-auto overflow-hidden relative border border-black max-h-[90vh] flex flex-col">
        {/* Close button - absolute positioned */}
        <button 
          onClick={onClose}
          className="absolute top-4 right-4 text-black"
          aria-label="Close"
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
            <line x1="18" y1="6" x2="6" y2="18"></line>
            <line x1="6" y1="6" x2="18" y2="18"></line>
          </svg>
        </button>
        
        {/* Centered icon and title */}
        <div className="p-6 pt-12">
          <div className="flex flex-col items-center text-center mb-6">
            <div className="border border-black p-2 mb-4">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                <circle cx="12" cy="7" r="4"></circle>
              </svg>
            </div>
            <h3 className="text-xl font-bold uppercase">Edit Profile</h3>
          </div>
        </div>

        {/* Scrollable content */}
        <div className="overflow-y-auto flex-grow px-6 pb-4">
          <form onSubmit={handleSubmit}>
            {/* Profile Picture */}
            <div className="mb-6">
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Profile Picture
              </label>
              <div className="flex items-center space-x-4">
                <div className="w-20 h-20 rounded-full overflow-hidden bg-gray-200">
                  {photoPreview && (
                    <img 
                      src={photoPreview} 
                      alt="Profile Preview" 
                      className="w-full h-full object-cover"
                    />
                  )}
                </div>
                <label className="cursor-pointer bg-gray-100 hover:bg-gray-200 px-4 py-2 rounded">
                  Change Photo
                  <input
                    type="file"
                    onChange={handleFileChange}
                    className="hidden"
                    accept="image/*"
                  />
                </label>
              </div>
            </div>

            {/* Username */}
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Username
              </label>
              <div className="relative">
                <input
                  type="text"
                  name="username"
                  value={formData.username}
                  onChange={handleInputChange}
                  className="w-full p-2 pr-10 border border-gray-300 rounded"
                />
                {formData.username && (
                  <div className="absolute inset-y-0 right-0 pr-3 flex items-center">
                    {isChecking ? (
                      <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-gray-900" />
                    ) : isAvailable ? (
                      <FaCheck className="text-green-500" />
                    ) : (
                      <FaTimes className="text-red-500" />
                    )}
                  </div>
                )}
              </div>
              {usernameError && <p className="text-red-500 text-xs mt-1">{usernameError}</p>}
            </div>
          </form>
        </div>

        {/* Buttons */}
        <div className="p-6 border-t border-gray-200">
          <button
            onClick={handleSubmit}
            disabled={!isAvailable || isChecking}
            className="w-full bg-black hover:bg-gray-800 text-white font-bold py-2 px-4 disabled:bg-gray-400 disabled:cursor-not-allowed"
          >
            Save Changes
          </button>
        </div>
      </div>
    </div>,
    document.body
  );
};

// Followers/Following Modal Component
const FollowListModal = ({ isOpen, onClose, title, users = [], isLoading, currentUserId, onFollowToggle, count = 0 }) => {
  const navigate = useNavigate();
  
  if (!isOpen) return null;

  const handleProfileClick = (username) => {
    if (!username) return;
    
    // Close the modal first
    onClose();
    
    // Then navigate to the profile page
    navigate(`/profile/${username}`);
  };

  // Calculate the number of placeholder items to show based on the count prop
  // Use count when available, otherwise default to 4
  // Always limit to a maximum of 10 placeholders
  const placeholderCount = count > 0 ? Math.min(count, 10) : 4;

  return ReactDOM.createPortal(
    <div className="fixed inset-0 z-50 flex items-center justify-center backdrop-blur-sm bg-black bg-opacity-75 md:pl-16 lg:pl-64">
      <div className="bg-white text-black w-11/12 max-w-md mx-auto overflow-hidden relative border border-black max-h-[90vh] flex flex-col">
        {/* Close button - absolute positioned */}
        <button 
          onClick={onClose}
          className="absolute top-4 right-4 text-black"
          aria-label="Close"
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
            <line x1="18" y1="6" x2="6" y2="18"></line>
            <line x1="6" y1="6" x2="18" y2="18"></line>
          </svg>
        </button>
        
        {/* Centered icon and title */}
        <div className="p-6 pt-12">
          <div className="flex flex-col items-center text-center mb-6">
            <div className="border border-black p-2 mb-4">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                <circle cx="9" cy="7" r="4"></circle>
                <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
              </svg>
            </div>
            <h3 className="text-xl font-bold uppercase">{title}</h3>
          </div>
        </div>

        {/* Scrollable content */}
        <div className="overflow-y-auto flex-grow px-6 pb-4">
          {isLoading ? (
            <div className="space-y-4">
              {Array.from({ length: placeholderCount }).map((_, i) => (
                <div key={i} className="flex items-center p-2 h-[58px] bg-gray-200 rounded-lg animate-pulse">
                  <div className="w-10 h-10 rounded-full bg-gray-300 mr-4 animate-pulse"></div>
                  <div className="flex-1">
                    <div className="h-4 bg-gray-300 rounded w-24 mb-2 animate-pulse"></div>
                    <div className="h-3 bg-gray-300 rounded w-16 animate-pulse"></div>
                  </div>
                </div>
              ))}
            </div>
          ) : users.length === 0 ? (
            <div className="py-4 text-center">No {title.toLowerCase()} yet</div>
          ) : (
            <div className="space-y-4">
              {users.map(user => (
                <div key={user.id || user.userId} className="flex items-center p-2 hover:bg-gray-100">
                  <div 
                    className="flex items-center flex-grow cursor-pointer" 
                    onClick={() => handleProfileClick(user.username)}
                  >
                    <div className="w-10 h-10 rounded-full overflow-hidden bg-gray-200 mr-4">
                      <img 
                        src={user.photoURL || "/images/default-profile.png"} 
                        alt={user.username || "User"} 
                        className="w-full h-full object-cover"
                      />
                    </div>
                    <div>
                      <div className="font-bold">@{user.username || "User"}</div>
                      {user.displayName && <div className="text-sm text-gray-600">{user.displayName}</div>}
                    </div>
                  </div>
                  
                  {/* Follow/Unfollow button (only show for other users) */}
                  {currentUserId && user.id !== currentUserId && onFollowToggle && (
                    <button
                      onClick={() => onFollowToggle(user.id)}
                      className={`px-3 py-1 text-xs border ${
                        user.isFollowing 
                          ? 'border-gray-300 text-gray-600' 
                          : 'border-black text-black'
                      } rounded-md hover:bg-gray-100`}
                    >
                      {user.isFollowing ? 'Unfollow' : 'Follow'}
                    </button>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>,
    document.body
  );
};

const Account = () => {
  const { username } = useParams();
  const { userData, getCurrentUserId } = useUser();
  const { getFollowingForUser, listenToUserFollowing, isFollowing, getFollowingProfiles: fetchFollowingProfiles } = useFollowing();
  const { getFollowersForUser, listenToUserFollowers, getFollowerProfiles } = useFollowers();
  const { scanHistory, loading: scanLoading, deleteScan } = useScan();
  const { 
    profileData, 
    loading: profileLoading, 
    getProfileByUsername,
    listenToProfileUpdates
  } = useProfile();
  const [isFollowingUser, setIsFollowingUser] = useState(false);
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("POSTS");
  const [selectedScans, setSelectedScans] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [statsCollapsed, setStatsCollapsed] = useState(() => window.innerWidth < 640);
  const [profileListener, setProfileListener] = useState(null);
  const { updateHeader } = useHeader();
  const [isEditProfileModalOpen, setIsEditProfileModalOpen] = useState(false);
  const [isFollowersModalOpen, setIsFollowersModalOpen] = useState(false);
  const [isFollowingModalOpen, setIsFollowingModalOpen] = useState(false);
  const [followersData, setFollowersData] = useState([]);
  const [followingData, setFollowingData] = useState([]);
  const [isLoadingFollowerData, setIsLoadingFollowerData] = useState(false);
  const [isLoadingFollowingData, setIsLoadingFollowingData] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setStatsCollapsed(window.innerWidth < 640);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Load profile data
  useEffect(() => {
    if (username && (!profileData || profileData.username !== username)) {
      // First, clean up any existing listener
      if (typeof profileListener === 'function') {
        profileListener();
        setProfileListener(null);
      }
      
      // Then fetch the initial profile data
      getProfileByUsername(username).then(profile => {
        if (profile?.uid) {
          // Set up a listener for future updates
          const unsubscribe = listenToProfileUpdates(profile.uid);
          setProfileListener(unsubscribe);
        }
      });
    }
    
    // Clean up on unmount
    return () => {
      if (typeof profileListener === 'function') {
        profileListener();
      }
    };
  }, [username, profileData, getProfileByUsername, listenToProfileUpdates, profileListener]);

  useEffect(() => {
    const checkFollowingStatus = async () => {
      // The document ID is the user ID in Firestore
      const currentUserId = getCurrentUserId();
      const targetUserId = profileData?.id || profileData?.uid;
      
      if (currentUserId && targetUserId && currentUserId !== targetUserId) {
        console.log(`[Account] Checking if ${currentUserId} follows ${targetUserId}`);
        try {
          const followStatus = await isFollowing(targetUserId);

          console.log(`[Account] Follow status: ${followStatus}`);
          setIsFollowingUser(followStatus);
        } catch (error) {
          console.error("[Account] Error checking following status:", error);
        }
      }
    };
    
    checkFollowingStatus();
  }, [userData, profileData, isFollowing, getCurrentUserId]);

  useEffect(() => {
    if (!profileData?.uid) return;
    const unsubscribeFollowers = listenToUserFollowers(profileData.uid);
    const unsubscribeFollowing = listenToUserFollowing(profileData.uid);
    return () => {
      unsubscribeFollowers && unsubscribeFollowers();
      unsubscribeFollowing && unsubscribeFollowing();
    };
  }, [profileData?.uid, listenToUserFollowers, listenToUserFollowing]);

  const getUserId = (user) => {
    return user?.uid || user?.id;
  };

  const isOwnProfile = userData?.username === username;

  const handleShare = async () => {
    try {
      await navigator.share({
        title: `${profileData?.displayName}'s Profile`,
        text: "Check out my fitness profile on MOGGED",
        url: window.location.href,
      });
    } catch (error) {
      navigator.clipboard
        .writeText(window.location.href)
        .then(() => alert("Profile link copied to clipboard!"))
        .catch((err) => console.error("Error copying to clipboard:", err));
    }
  };

  const handleFollowClick = async () => {
    const currentUserId = getCurrentUserId();
    
    if (!currentUserId) {
      console.log("[Account] Cannot follow: User not logged in or ID missing");
      navigate("/login");
      return;
    }
    
    const targetUserId = getUserId(profileData);
    if (!targetUserId) {
      console.error("[Account] Cannot follow: Target profile ID is missing");
      return;
    }
    
    console.log(`[Account] Follow button clicked. Current user: ${currentUserId}, Target user: ${targetUserId}`);
    
    try {
      if (isFollowingUser) {
        await removeFollowing(currentUserId, targetUserId);
        setIsFollowingUser(false);
      } else {
        await addFollowing(currentUserId, targetUserId);
        setIsFollowingUser(true);
      }
    } catch (error) {
      console.error("[Account] Error updating follow status:", error);
      alert("Failed to update follow status. Please try again.");
    }
  };

  const followers = getFollowersForUser(profileData?.uid);
  const following = getFollowingForUser(profileData?.uid);
  
  // Use the counts from the profile data if available, otherwise fall back to array length
  const followersCount = profileData?.followerCount || followers.length;
  const followingCount = profileData?.followingCount || following.length;

  const handleEdit = () => {
    setIsEditing(!isEditing);
    setSelectedScans([]);
  };

  const handleSelectScan = (scanId) => {
    setSelectedScans((prev) =>
      prev.includes(scanId)
        ? prev.filter((id) => id !== scanId)
        : [...prev, scanId]
    );
  };

  const handleDelete = async () => {
    if (
      window.confirm(
        `Are you sure you want to delete ${selectedScans.length} scan(s)?`
      )
    ) {
      for (let scanId of selectedScans) {
        await deleteScan(scanId);
      }
      setSelectedScans([]);
      setIsEditing(false);
    }
  };

  useEffect(() => {
    if (!isOwnProfile && activeTab === "SCANS") {
      setActiveTab("POSTS");
    }
  }, [isOwnProfile, activeTab]);

  const toggleStats = () => {
    setStatsCollapsed(!statsCollapsed);
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case "POSTS":
        return <PostsTab userId={profileData?.uid} />;
      case "SCANS":
        return isOwnProfile ? (
          <div className="flex flex-col gap-4">
            <div className="flex justify-between items-center">
              {scanHistory.length > 0 && (
                <button
                  onClick={handleEdit}
                  className={`group flex items-center justify-center border rounded-md px-3 py-1 transition-colors duration-200 ${
                    isEditing
                      ? "text-gray-600 hover:bg-red-500 border-gray-300 hover:border-red-500"
                      : "text-gray-600 hover:bg-green-500 border-gray-300 hover:border-green-500"
                  }`}
                >
                  <AiOutlineEdit
                    size={20}
                    className="mr-2 text-gray-600 transition-colors duration-200 group-hover:text-white"
                  />
                  <span className="transition-colors duration-200 group-hover:text-white">
                    {isEditing ? "Cancel" : "Edit"}
                  </span>
                </button>
              )}
            </div>
            {scanLoading ? (
              <div>Loading scans...</div>
            ) : scanHistory.length === 0 ? (
              <div className="text-center text-xl text-gray-500 py-4">
                No scans yet.
              </div>
            ) : (
              <div className="grid grid-cols-1 gap-4">
                {scanHistory.map((scan) => (
                  <div key={scan.id} className="relative">
                    {isEditing && (
                      <input
                        type="checkbox"
                        checked={selectedScans.includes(scan.id)}
                        onChange={() => handleSelectScan(scan.id)}
                        className="absolute top-2 left-2 z-10 w-5 h-5"
                      />
                    )}
                    <ScoreCard
                      imageUrl={scan.imageUrls.FRONT}
                      timestamp={scan.timestamp}
                      scores={scan.scores}
                      scanId={scan.id}
                    />
                  </div>
                ))}
              </div>
            )}
            {isEditing && selectedScans.length > 0 && (
              <div className="fixed bottom-4 right-4">
                <button
                  onClick={handleDelete}
                  className="flex items-center justify-center bg-red-500 text-white px-4 py-2 rounded-lg hover:bg-red-600 shadow-lg"
                >
                  <AiOutlineDelete size={20} className="mr-2" />
                  DELETE SELECTED ({selectedScans.length})
                </button>
              </div>
            )}
          </div>
        ) : (
          <Navigate to={`/profile/${username}`} replace />
        );
      case "ROUTINES":
        return <RoutinesTab userId={profileData?.uid} />;
      default:
        return <PostsTab userId={profileData?.uid} />;
    }
  };

  const slideVariants = {
    enter: {
      x: "100%",
      opacity: 0,
    },
    center: {
      x: 0,
      opacity: 1,
    },
    exit: {
      x: "-100%",
      opacity: 0,
    },
  };

  useEffect(() => {
    if (profileData) {
      const memberSinceTrailing = (
        <div className="flex flex-col">
          <span className="text-sm text-gray-500">Member since</span>
          <span>{formatDate(profileData?.createdAt)}</span>
        </div>
      );
      
      updateHeader('ACCOUNT', memberSinceTrailing);
    } else {
      updateHeader('ACCOUNT');
    }
    
    return () => updateHeader('');
  }, [profileData, updateHeader]);

  // Function to load detailed followers data with follow status
  const loadFollowersData = useCallback(async () => {
    if (!profileData?.uid) {
      console.error("[Account] Cannot load followers data: Profile ID is missing");
      return;
    }
    
    console.log("[Account] Starting to load followers data");
    setIsLoadingFollowerData(true);
    try {
      console.log(`[Account] Loading followers data for ${profileData.uid}`);
      // Get follower profiles
      const profiles = await getFollowerProfiles(profileData.uid);
      console.log(`[Account] Received ${profiles.length} follower profiles`);
      
      // Check if the current user is logged in
      const currentUserId = getCurrentUserId();
      console.log(`[Account] Current user ID: ${currentUserId}`);
      
      if (currentUserId) {
        // Check if the current user follows each of these followers
        console.log(`[Account] Checking if current user follows each follower`);
        const profilesWithFollowingStatus = await Promise.all(
          profiles.map(async (profile) => {
            // Skip checking for the current user's own profile
            if (profile.id === currentUserId) {
              return { ...profile, isFollowing: false }; // Can't follow yourself
            }
            
            const following = await isFollowing(profile.id);
            console.log(`[Account] Current user follows ${profile.username || profile.id}: ${following}`);
            return { ...profile, isFollowing: following };
          })
        );
        console.log(`[Account] Setting followers data with following status`);
        setFollowersData(profilesWithFollowingStatus);
      } else {
        console.log(`[Account] Not logged in, cannot determine following status`);
        setFollowersData(profiles);
      }
    } catch (error) {
      console.error("Error loading followers data:", error);
      setFollowersData([]);
    } finally {
      setIsLoadingFollowerData(false);
    }
  }, [profileData?.uid, getFollowerProfiles, getCurrentUserId, isFollowing]);

  // Function to load detailed following data
  const loadFollowingData = useCallback(async () => {
    if (!profileData?.uid) return;
    
    setIsLoadingFollowingData(true);
    try {
      console.log(`[Account] Loading following data for ${profileData.uid}`);
      // Use getFollowingProfiles from FollowingContext instead
      const profiles = await fetchFollowingProfiles(profileData.uid);
      console.log(`[Account] Received ${profiles.length} following profiles`);
      
      // Get the current user ID to check if the current user follows these profiles
      const currentUserId = getCurrentUserId();
      console.log(`[Account] Current user ID: ${currentUserId}`);
      
      if (currentUserId && currentUserId !== profileData.uid) {
        // Check if the current user follows each of these profiles
        console.log(`[Account] Checking current user's following status for each profile`);
        const profilesWithFollowingStatus = await Promise.all(
          profiles.map(async (profile) => {
            const following = await isFollowing(profile.id);
            console.log(`[Account] Current user follows ${profile.username || profile.id}: ${following}`);
            return { ...profile, isFollowing: following };
          })
        );
        console.log(`[Account] Setting following data with following status`);
        setFollowingData(profilesWithFollowingStatus);
      } else if (currentUserId === profileData.uid) {
        // If viewing own profile, the user follows all these profiles by definition
        console.log(`[Account] Own profile, all profiles are followed`);
        const profilesWithFollowingStatus = profiles.map(profile => ({
          ...profile,
          isFollowing: true
        }));
        setFollowingData(profilesWithFollowingStatus);
      } else {
        // Not logged in, can't determine following status
        console.log(`[Account] Not logged in, cannot determine following status`);
        setFollowingData(profiles);
      }
    } catch (error) {
      console.error("Error loading following data:", error);
      setFollowingData([]);
    } finally {
      setIsLoadingFollowingData(false);
    }
  }, [profileData?.uid, fetchFollowingProfiles, getCurrentUserId, isFollowing]);

  // Handle follow/unfollow from the modal
  const handleFollowToggle = async (targetUserId) => {
    const currentUserId = getCurrentUserId();
    if (!currentUserId) {
      navigate("/login");
      return;
    }
    
    try {
      // Check current following status
      const following = await isFollowing(targetUserId);
      
      if (following) {
        await removeFollowing(currentUserId, targetUserId);
      } else {
        await addFollowing(currentUserId, targetUserId);
      }
      
      // Refresh the data based on which modal is open
      if (isFollowersModalOpen) {
        loadFollowersData();
      }
      if (isFollowingModalOpen) {
        loadFollowingData();
      }
    } catch (error) {
      console.error("Error toggling follow status:", error);
    }
  };

  // Add these useEffect hooks to load data when modals open
  useEffect(() => {
    if (isFollowersModalOpen) {
      loadFollowersData();
    }
  }, [isFollowersModalOpen, loadFollowersData]);

  useEffect(() => {
    if (isFollowingModalOpen) {
      loadFollowingData();
    }
  }, [isFollowingModalOpen, loadFollowingData]);

  if (!username) {
    return (
      <div className="flex-1 bg-white text-black p-8">
        <div className="max-w-4xl mx-auto text-center">
          <h1 className="text-4xl font-bold mb-4">Error 404</h1>
          <p className="text-gray-600 mb-8">
            No username provided. Please check the URL.
          </p>
          <button
            onClick={() => navigate(-1)}
            className="px-6 py-2 bg-black text-white rounded-md hover:bg-gray-800 transition"
          >
            Go Back
          </button>
        </div>
      </div>
    );
  }

  if (profileLoading) {
    return <div className="flex-1 bg-white text-black p-8">Loading...</div>;
  }

  if (!profileData) {
    return <div className="flex-1 bg-white text-black p-8">Profile not found</div>;
  }

  return (
    <div className="flex flex-col h-[100dvh] overflow-hidden">
      <div className="flex-shrink-0 bg-white">
        <div className="w-[90%] mx-auto py-4">
          <div className="mb-2">
            <div className="flex flex-col sm:flex-row items-center sm:items-center gap-4">
              <div className="w-16 h-16 sm:w-20 sm:h-20 lg:w-24 lg:h-24 rounded-full overflow-hidden">
                <img
                  src={profileData.photoURL || "/images/default-profile.png"}
                  alt="Profile"
                  className="w-full h-full object-cover"
                />
              </div>
              <div className="flex-1 w-full">
                <div className="flex flex-col sm:flex-row sm:items-start sm:justify-between mb-2">
                  <div className="mb-2 sm:mb-0 text-center sm:text-left flex flex-col justify-center ">
                    <h1 className="text-xl sm:text-2xl lg:text-4xl font-bold">
                      @{profileData.username || "User"}
                    </h1>
                    <div className="w-full border-t border-gray-300 mt-2"></div>
                    <p className="text-gray-600 text-xl mt-1">{profileData.trainingStyle?.title || " TRAINING STYLE NOT SET"}</p>
                    <div className="w-full border-b border-gray-300 mb-2"></div>
                  </div>
                  <div className="flex flex-col items-center sm:items-end gap-2 w-full sm:w-auto justify-center">
                    <div className="flex gap-2 w-full sm:w-auto">
                      {isOwnProfile ? (
                        <>
                          <button
                            onClick={() => setIsEditProfileModalOpen(true)}
                            className="flex-1 sm:flex-none px-4 py-1.5 bg-black text-white border-2 border-black rounded-md hover:bg-gray-800 transition whitespace-nowrap"
                          >
                            <span className="lg:text-lg text-xs">EDIT PROFILE</span>
                          </button>
                          <button
                            onClick={handleShare}
                            className="flex-1 sm:flex-none px-4 py-1.5 bg-white text-black border-2 border-black rounded-md hover:bg-gray-200 transition whitespace-nowrap"
                          >
                            <span className="lg:text-lg text-xs">SHARE PROFILE</span>
                          </button>
                        </>
                      ) : (
                        <button
                          onClick={handleFollowClick}
                          className={`w-full px-4 py-1.5 ${
                            isFollowingUser
                              ? "bg-gray-500 hover:bg-gray-600"
                              : "bg-black hover:bg-gray-800"
                          } text-white border-2 border-black rounded-md transition whitespace-nowrap`}
                        >
                          <span className="lg:text-lg text-xs">
                            {isFollowingUser ? "UNFOLLOW" : "FOLLOW"}
                          </span>
                        </button>
                      )}
                    </div>
                    <div className="flex justify-between w-full gap-4">
                      <div 
                        className="flex items-center gap-1 cursor-pointer hover:text-blue-600"
                        onClick={() => {
                          setIsFollowersModalOpen(true);
                          loadFollowersData();
                        }}
                      >
                        <span className="font-bold text-lg">{followersCount}</span>
                        <span className="text-gray-600 hover:text-blue-600">Followers</span>
                      </div>
                      <div 
                        className="flex items-center gap-1 cursor-pointer hover:text-blue-600"
                        onClick={() => {
                          setIsFollowingModalOpen(true);
                          loadFollowingData();
                        }}
                      >
                        <span className="font-bold text-lg">{followingCount}</span>
                        <span className="text-gray-600 hover:text-blue-600">Following</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <StatsAccordion
            isOpen={!statsCollapsed}
            onToggle={toggleStats}
            profileData={profileData}
          />
        </div>
      </div>
      <div className="border-b border-gray-300 flex-shrink-0 bg-white">
        <div className="w-[90%] mx-auto">
          <div className="flex w-full">
            {["POSTS", ...(isOwnProfile ? ["SCANS"] : []), "ROUTINES"].map(
              (tab) => (
                <button
                  key={tab}
                  onClick={() => setActiveTab(tab)}
                  className={`px-4 py-2 font-bold transition-colors duration-200 relative flex-1 text-center ${
                    activeTab === tab ? "text-black" : "text-gray-600 hover:text-black"
                  }`}
                >
                  <span>{tab}</span>
                  {activeTab === tab && <TabUnderline />}
                </button>
              )
            )}
          </div>
        </div>
      </div>
      <div className="flex-1 overflow-y-auto">
        <div className="w-[90%] mx-auto py-4">
          <div className="relative pb-24">
            <AnimatePresence mode="wait">
              <motion.div
                key={activeTab}
                variants={slideVariants}
                initial="enter"
                animate="center"
                exit="exit"
                transition={{
                  x: { type: "spring", stiffness: 300, damping: 30 },
                  opacity: { duration: 0.2 },
                }}
                className="w-full"
              >
                {renderTabContent()}
              </motion.div>
            </AnimatePresence>
          </div>
        </div>
      </div>
      
      {/* Followers Modal */}
      <FollowListModal
        isOpen={isFollowersModalOpen}
        onClose={() => setIsFollowersModalOpen(false)}
        title="Followers"
        users={followersData}
        isLoading={isLoadingFollowerData}
        currentUserId={getCurrentUserId()}
        onFollowToggle={handleFollowToggle}
        count={followersCount}
      />
      
      {/* Following Modal */}
      <FollowListModal
        isOpen={isFollowingModalOpen}
        onClose={() => setIsFollowingModalOpen(false)}
        title="Following"
        users={followingData}
        isLoading={isLoadingFollowingData}
        currentUserId={getCurrentUserId()}
        onFollowToggle={handleFollowToggle}
        count={followingCount}
      />
      
      {/* Edit Profile Modal */}
      <EditProfileModal
        isOpen={isEditProfileModalOpen}
        onClose={() => setIsEditProfileModalOpen(false)}
        userData={userData}
      />
    </div>
  );
};

export default Account;
