import React, { useEffect, useState, useRef } from 'react';
import { Typography } from '@mui/material';
import { useHeader } from '../contexts/HeaderContext';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { getRoutine } from '../firebase/Routines';
import { getExerciseById } from '../firebase/Exercises';
import LoadingProfile from '../components/LoadingProfile';

const RoutinePage = () => {
  const { updateHeader } = useHeader();
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const routineId = params.routineId || params.id; // Try both possible parameter names
  const [routine, setRoutine] = useState(null);
  const [exerciseDetails, setExerciseDetails] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedWeek, setSelectedWeek] = useState(1);
  const scrollRef = useRef(null);

  // Debug params
  useEffect(() => {
    console.log('[RoutineDetail] URL path:', location.pathname);
    console.log('[RoutineDetail] URL params:', params);
    console.log('[RoutineDetail] Extracted routineId:', routineId);
  }, [location.pathname, params, routineId]);

  // Fetch routine data
  useEffect(() => {
    async function fetchRoutine() {
      console.log(`[RoutineDetail] Fetching routine: ${routineId}`);
      try {
        setLoading(true);
        const routineData = await getRoutine(routineId);
        if (!routineData) {
          setError('Routine not found');
        } else {
          console.log('[RoutineDetail] Routine data loaded:', routineData);
          setRoutine(routineData);
          
          // Collect all exercise IDs from the routine
          const exerciseIds = new Set();
          
          // Check schedule days for exercises
          if (routineData.schedule && routineData.schedule.days) {
            // Handle the nested structure: schedule.days.default.Monday.exercises
            Object.keys(routineData.schedule.days).forEach(weekKey => {
              const week = routineData.schedule.days[weekKey];
              
              // Check if week is an object with day properties
              if (week && typeof week === 'object') {
                Object.keys(week).forEach(dayKey => {
                  const day = week[dayKey];
                  
                  // Check if day has exercises array
                  if (day && day.exercises && Array.isArray(day.exercises)) {
                    day.exercises.forEach(exercise => {
                      if (exercise.exerciseId) {
                        exerciseIds.add(exercise.exerciseId);
                      }
                    });
                  }
                });
              }
            });
          }
          
          // Fetch details for each exercise
          const exerciseDetailsMap = {};
          
          try {
            // Use Promise.all with a safer approach
            if (exerciseIds.size > 0) {
              const promises = Array.from(exerciseIds).map(async (exerciseId) => {
                try {
                  const exerciseData = await getExerciseById(exerciseId);
                  if (exerciseData) {
                    return { id: exerciseId, data: exerciseData };
                  }
                  return null;
                } catch (err) {
                  console.error(`Error fetching exercise ${exerciseId}:`, err);
                  return null;
                }
              });
              
              const results = await Promise.all(promises);
              
              // Process results safely
              results.forEach(result => {
                if (result && result.id && result.data) {
                  exerciseDetailsMap[result.id] = result.data;
                }
              });
            }
          } catch (err) {
            console.error('Error fetching exercise details:', err);
          }
          
          setExerciseDetails(exerciseDetailsMap);
        }
      } catch (err) {
        console.error('Error fetching routine:', err);
        setError('Failed to load routine');
      } finally {
        setLoading(false);
      }
    }

    if (routineId) {
      fetchRoutine();
    } else {
      console.error('[RoutineDetail] No routineId provided in URL:', location.pathname);
      setError('No routine ID provided');
      setLoading(false);
    }
  }, [routineId, location.pathname]);

  // Set up header
  useEffect(() => {
    const backButton = (
      <button 
        onClick={() => navigate(-1)}
        className="text-gray-600 hover:text-gray-900"
      >
        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 19l-7-7m0 0l7-7m-7 7h18" />
        </svg>
      </button>
    );
    
    const trailing = routine && (
      <Typography variant="body1">
        CREATED {new Date(routine.createdAt).toLocaleDateString()}
      </Typography>
    );
    
    updateHeader('ROUTINE', trailing, backButton);
    
    return () => updateHeader('');
  }, [navigate, updateHeader, routine]);

  // Helper function to get day name
  // const getDayName = (dayNumber) => {
  //   const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
  //   return days[dayNumber - 1];
  // };

  // Helper function to get exercises for a specific day
  // const getDayExercises = (weekNum, dayNum) => {
  //   if (!routine || !routine.schedule || !routine.schedule.days) {
  //     return null;
  //   }

  //   // Get the day name from number
  //   const dayName = getDayName(dayNum);
    
  //   // If not using unique weeks, always use default week
  //   const weekKey = routine.schedule.uniqueWeeks ? `week${weekNum}` : 'default';
    
  //   // Check if this day has a workout in the specified week
  //   const week = routine.schedule.days[weekKey];
  //   if (!week) return null;
    
  //   return week[dayName] || null;
  // };

  // Helper function to render exercise details
  const renderExerciseDetails = (exercise) => {
    if (!exercise) return null;
    
    // Check if we have the exercise details from the database
    const details = exercise.exerciseId ? exerciseDetails[exercise.exerciseId] : null;
    
    // Use exercise data from the day's schedule or fallback to section exercise
    const sets = exercise.sets || (details?.sets) || 3;
    const reps = exercise.reps || (details?.reps) || 10;
    const weight = exercise.weight !== undefined ? exercise.weight : (details?.weight || 0);
    const title = exercise.title || exercise.name || (details?.title) || 'Exercise';
    
    // Use muscleGroups from the exercise itself first, then from details
    const muscleGroups = exercise.muscleGroups || (details?.muscleGroups) || [];
    
    return (
      <div className="flex-1">
        <h5 className="font-medium">{title}</h5>
        <div className="flex items-center text-sm text-gray-600 mt-1">
          <span className="mr-3">{sets} sets</span>
          <span className="mr-3">{reps} reps</span>
          {weight > 0 && <span>{weight} kg</span>}
        </div>
        {muscleGroups && muscleGroups.length > 0 && (
          <p className="text-xs text-gray-500 mt-1">
            Targets: {Array.isArray(muscleGroups) ? muscleGroups.join(', ') : muscleGroups}
          </p>
        )}
      </div>
    );
  };

  // Safe navigation function
  const handleExerciseClick = (exerciseId) => {
    if (exerciseId) {
      navigate(`/exercises/${exerciseId}`);
    }
  };

  if (loading) {
    return <div className="flex-1 flex h-full items-center justify-center"><LoadingProfile /></div>;
  }

  if (error) {
    return (
      <div className="flex-1 flex items-center justify-center">
        <div className="text-center">
          <h2 className="text-xl font-bold mb-2">Error</h2>
          <p>{error}</p>
          <button 
            onClick={() => navigate(-1)}
            className="mt-4 bg-black text-white py-2 px-4"
          >
            Go Back
          </button>
        </div>
      </div>
    );
  }

  if (!routine) {
    return null;
  }

  // Calculate total weeks
  const totalWeeks = routine.duration || (routine.schedule && routine.schedule.weeks) || 1;

  return (
    <div className="flex flex-col h-[100dvh] overflow-hidden bg-white">
      {/* Title Section - Fixed at top */}
      <div className="sticky top-0 z-20 bg-black text-white py-2">
        <div className="w-[90%] mx-auto">
          <h1 className="text-2xl md:text-2xl font-bold uppercase tracking-wider">
            {routine.title}
          </h1>
        </div>
      </div>

      {/* Description Section - Fixed below title */}
      <div className="bg-black text-white border-b border-gray-800">
        <div className="w-[90%] mx-auto py-2">
          <p className="text-gray-400 uppercase tracking-wider">
            {routine.description}
          </p>
        </div>
      </div>

      {/* Scrollable Content */}
      <div className="flex-1 overflow-y-auto pb-24" ref={scrollRef}>
        <div className="w-[90%] mx-auto py-6">
          {/* Tags Section */}
          {routine.tags && routine.tags.length > 0 && (
            <div className="mb-6">
              <div className="bg-black text-white p-4">
                <span className="font-medium">TRAINING TAGS</span>
              </div>
              <div className="p-4 border border-gray-300">
                <div className="flex flex-wrap gap-2">
                  {routine.tags.map(tag => (
                    <div key={tag} className="bg-black text-white px-3 py-1 -full text-sm">
                      {tag}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}

          {/* Schedule Section */}
          {routine.schedule && (
            <div className="mb-6">
              <div className="bg-black text-white p-4">
                <span className="font-medium">SCHEDULE</span>
              </div>
              <div className="p-4 border border-gray-300">
                <p><strong>Duration:</strong> {routine.duration || routine.schedule.weeks} weeks</p>
                <p><strong>Days per week:</strong> {Object.keys(routine.schedule.days?.default || {}).length}</p>
                {routine.schedule.uniqueWeeks && <p><strong>Unique schedule per week:</strong> Yes</p>}
              </div>
            </div>
          )}

          {/* Week Selector */}
          {totalWeeks > 1 && (
            <div className="mb-6">
              <div className="bg-black text-white p-4">
                <span className="font-medium">WEEK</span>
              </div>
              <div className="p-4 border border-gray-300">
                <div className="flex flex-wrap gap-2">
                  {Array.from({ length: totalWeeks }, (_, i) => i + 1).map(week => (
                    <button
                      key={week}
                      onClick={() => setSelectedWeek(week)}
                      className={`px-4 py-2  ${
                        selectedWeek === week
                          ? 'bg-black text-white'
                          : 'bg-gray-200 text-black'
                      }`}
                    >
                      Week {week}
                    </button>
                  ))}
                </div>
              </div>
            </div>
          )}

          {/* Weekly Schedule */}
          <div className="mb-6">
            <div className="bg-black text-white p-4">
              <span className="font-medium">WEEKLY SCHEDULE</span>
            </div>
            <div className="border border-gray-300">
              <div className="p-4 space-y-4">
                {['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map((dayName, index) => {
                  // Get the day data from the schedule
                  const weekKey = routine.schedule.uniqueWeeks ? `week${selectedWeek}` : 'default';
                  const dayData = routine.schedule?.days?.[weekKey]?.[dayName];
                  
                  return (
                    <div key={dayName} className="border border-gray-200  overflow-hidden">
                      <div className={`p-3 ${dayData ? 'border-2 border-black' : ''} bg-gray-100`}>
                        <h3 className="font-medium">{dayName}</h3>
                      </div>
                      
                      <div className="p-3">
                        {dayData ? (
                          <div>
                           
                            
                            {/* Day's exercises */}
                            {dayData.exercises && Array.isArray(dayData.exercises) && dayData.exercises.length > 0 ? (
                              <div className="space-y-3">
                                {dayData.exercises.map((exercise, exIndex) => (
                                  <div 
                                    key={exIndex} 
                                    className="flex items-center justify-between border-b border-gray-100 pb-2 last:border-b-0 cursor-pointer"
                                    onClick={() => handleExerciseClick(exercise?.exerciseId)}
                                  >
                                    {renderExerciseDetails(exercise)}
                                    <div className="w-12 h-12 bg-gray-200 flex items-center justify-center overflow-hidden ">
                                      {exercise?.exerciseId && exerciseDetails[exercise.exerciseId]?.mediaUrl ? (
                                        <img 
                                          src={exerciseDetails[exercise.exerciseId].mediaUrl} 
                                          alt={exercise.title || 'Exercise'} 
                                          className="w-full h-full object-cover"
                                          onError={(e) => {
                                            e.target.onerror = null;
                                            e.target.src = 'https://via.placeholder.com/48?text=Ex';
                                          }}
                                        />
                                      ) : (
                                        <span className="text-xs text-gray-500">Image</span>
                                      )}
                                    </div>
                                  </div>
                                ))}
                              </div>
                            ) : (
                              <p className="text-gray-500">No exercises specified</p>
                            )}
                          </div>
                        ) : (
                          <p className="text-gray-500">Rest Day</p>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

        </div>
      </div>

     
    </div>
  );
};

export default RoutinePage; 