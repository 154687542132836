// NewScan.jsx - using react-multi-carousel with custom index indicators
import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useDropzone } from 'react-dropzone';
import { useNavigate } from 'react-router-dom';
//AiOutlineCamera
import {  AiOutlineClose, AiOutlineUpload, AiOutlineCheck } from 'react-icons/ai';
import { FaSpinner } from 'react-icons/fa';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { useScan } from '../../contexts/ScanContext';
//import PoseCamera, 
import { POSE_TYPES } from '../../components/ui/PoseCamera';
import GuidelinesModal from '../../components/ui/GuidelinesModal';
import WarningModal from '../../components/ui/WarningModal';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import FixedBottomButton from '../../components/ui/FixedBottomButton';
import { trackScanCreation } from '../../analytics/conversations';
import { useAuth } from '../../contexts/AuthContext';

const PosePreview = ({ pose, file, onRemove, isSelected, isMobile, onUpload, onShowGuidelines }) => {
  return (
    <div className="relative group h-full">
      <div className="flex flex-col h-full">
        <div className="relative bg-gray-100 flex-1">
          {file ? (
            <>
              <img
                src={URL.createObjectURL(file)}
                alt={pose.name}
                className="h-full w-full object-cover absolute inset-0"
              />
              <div className="absolute inset-0 bg-black bg-opacity-40 flex items-center justify-center z-10">
                <h3 className="text-white text-lg font-bold uppercase tracking-wider">
                  {pose.name}
                </h3>
              </div>
            </>
          ) : (
            <div className="flex h-full items-center justify-center">
              <div className="text-center p-4">
                <div className="text-gray-400 mb-2 underline">{pose.name} (no photo captured)</div>
                <p className="text-sm text-gray-500 mb-3">{pose.description}</p>
                {isMobile && (
                  <button 
                    onClick={(e) => {
                      e.stopPropagation();
                      onShowGuidelines();
                    }}
                    className="text-sm text-blue-600 underline hover:text-blue-800 relative z-20"
                  >
                    View pose guidelines
                  </button>
                )}
              </div>
            </div>
          )}
          {file && (
            <button
              onClick={(e) => {
                e.stopPropagation();
                onRemove();
              }}
              className="absolute top-2 right-2 bg-black text-white p-1 opacity-0 group-hover:opacity-100 transition-opacity z-20"
            >
              <AiOutlineClose size={16} />
            </button>
          )}
          <div className="absolute bottom-0 inset-x-0 z-20">
            <button 
              onClick={(e) => {
                e.stopPropagation();
                onUpload();
              }}
              className="w-full bg-black text-white p-2 flex items-center justify-center"
            >
              <AiOutlineUpload className="mr-2" size={16} />
              {file ? 'Replace Photo' : 'Upload Photo'}
            </button>
          </div>
          {isSelected && !file && !isMobile && (
            <div className="absolute inset-0 border-2 border-black z-0" />
          )}
        </div>
      </div>
    </div>
  );
};

const NewScan = () => {
  const [selectedPose, setSelectedPose] = useState(null);
  const [files, setFiles] = useState({});
  const [isUploading, setIsUploading] = useState(false);
  //const [showCamera, setShowCamera] = useState(false);
  const [showGuidelines, setShowGuidelines] = useState(false);
  const [warningModal, setWarningModal] = useState({
    isOpen: false,
    title: '',
    message: ''
  });
  const navigate = useNavigate();
  const { scoreBodyScan } = useScan();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1024);
  const [currentSlide, setCurrentSlide] = useState(0);
  // const [isCameraInitializing, setIsCameraInitializing] = useState(false);
  //const poseCameraRef = useRef(null);
  const carouselRef = useRef(null);
  const { currentUser } = useAuth();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1024);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const firstPose = Object.values(POSE_TYPES)[0];
    setSelectedPose(firstPose.id);
    setCurrentSlide(0);
  }, []);

  // Dropzone
  const onDrop = useCallback(
    (acceptedFiles) => {
      if (selectedPose) {
        setFiles((prev) => ({
          ...prev,
          [selectedPose]: acceptedFiles[0],
        }));
      }
    },
    [selectedPose]
  );

  // Comment out getRootProps since it's not being used
  const { /* getRootProps, */ getInputProps } = useDropzone({
    onDrop,
    accept: { 'image/*': [] },
    multiple: false,
    disabled: isUploading || !selectedPose,
  });

  // Remove file
  const removeFile = (poseType) => {
    setFiles((prev) => {
      const newFiles = { ...prev };
      delete newFiles[poseType];
      return newFiles;
    });
  };

  // Upload images & analyze
  const handleUpload = async () => {
    if (Object.keys(files).length === 0) return;
    setIsUploading(true);
    try {
      const fileArray = Object.values(files);
      const { checkImageForNSFW } = await import('../../firebase/NSFWCheck');
      try {
        for (const file of fileArray) {
          await checkImageForNSFW(file);
        }
      } catch (nsfw_error) {
        setWarningModal({
          isOpen: true,
          title: 'Inappropriate Content',
          message: nsfw_error.message || 'Inappropriate content detected. Please use different images.'
        });
        setIsUploading(false);
        return;
      }
      
      const storage = getStorage();
      const uploadedUrls = {};
      for (const [poseType, file] of Object.entries(files)) {
        const storageRef = ref(storage, `scans/${poseType}_${file.name}`);
        await uploadBytes(storageRef, file);
        uploadedUrls[poseType] = await getDownloadURL(storageRef);
      }
      const scanId = await scoreBodyScan(uploadedUrls);
      
      // Track scan creation
      trackScanCreation(currentUser.uid, scanId);
      
      navigate('/history');
    } catch (error) {
      console.error('Error uploading and scoring images:', error);
      setWarningModal({
        isOpen: true,
        title: 'Upload Failed',
        message: error.message || 'Failed to upload images. Please try again.'
      });
    } finally {
      setIsUploading(false);
    }
  };

  // Handle camera capture.
  // const handleCapture = (file, poseTypeId, newIndex) => {
  //   setTimeout(() => {
  //     setCurrentSlide(newIndex);
  //   }, 0);
  //   setFiles((prev) => ({
  //     ...prev,
  //     [poseTypeId]: file,
  //   }));
  //   setShowCamera(false);
  //   setSelectedPose(poseTypeId);
  // };

  // const handleShowCamera = useCallback(async () => {
  //   setIsCameraInitializing(true);
  //   setShowCamera(true);
  // }, []);

  // const handleHideCamera = useCallback(async () => {
  //   if (poseCameraRef.current?.stopCamera) {
  //     await poseCameraRef.current.stopCamera();
  //   }
  //   setShowCamera(false);
  //   setIsCameraInitializing(false);
  // }, []);

  const handleShowGuidelines = () => {
    setShowGuidelines(true);
  };

  // Custom handler to change carousel slide via indicator dots.
  const handleCarouselChange = (index) => {
    setCurrentSlide(index);
    setSelectedPose(Object.values(POSE_TYPES)[index].id);
    if (carouselRef.current) {
      carouselRef.current.goToSlide(index);
    }
  };

  // Render custom indicator dots.
  const renderIndicatorDots = () => {
    const totalPoses = Object.values(POSE_TYPES).length;
    return (
      <div className="flex justify-center mt-4 gap-2">
        {Array.from({ length: totalPoses }).map((_, index) => {
          const poseId = Object.values(POSE_TYPES)[index].id;
          const hasImage = Boolean(files[poseId]);
          return (
            <button
              key={index}
              onClick={() => handleCarouselChange(index)}
              className={`w-3 h-3 rounded-full transition-colors border-2 ${
                currentSlide === index 
                  ? hasImage 
                    ? 'bg-black border-black' 
                    : 'bg-white border-black'
                  : hasImage 
                    ? 'bg-gray-400 border-gray-400' 
                    : 'bg-white border-gray-400'
              }`}
              aria-label={`Go to slide ${index + 1}${hasImage ? ' (completed)' : ''}`}
            />
          );
        })}
      </div>
    );
  };

  // Mobile carousel using react-multi-carousel with custom indicators.
  const renderMobileCarousel = () => {
    const responsive = {
      mobile: {
        breakpoint: { max: 1024, min: 0 },
        items: 1,
      },
    };

    return (
      <>
        <Carousel
          ref={carouselRef}
          responsive={responsive}
          swipeable={true}
          draggable={true}
          showDots={false} // disable built-in dots
          arrows={false}
          containerClass="carousel-container"
          itemClass="px-2"
          afterChange={(previousSlide, { currentSlide }) => {
            setCurrentSlide(currentSlide);
            setSelectedPose(Object.values(POSE_TYPES)[currentSlide].id);
          }}
        >
          {Object.values(POSE_TYPES).map((pose) => (
            <div key={pose.id} className="h-[400px] sm:h-[500px] md:h-[600px]">
              <PosePreview
                pose={pose}
                file={files[pose.id]}
                onRemove={() => removeFile(pose.id)}
                isSelected={selectedPose === pose.id}
                isMobile={isMobile}
                onUpload={() => {
                  setSelectedPose(pose.id);
                  document.getElementById('file-upload-input').click();
                }}
                onShowGuidelines={handleShowGuidelines}
              />
            </div>
          ))}
        </Carousel>
        {renderIndicatorDots()}
      </>
    );
  };

  // Desktop grid view remains unchanged.
  const renderDesktopGrid = () => {
    return (
      <div>
        <div className="grid grid-cols-3 gap-6 h-[400px] sm:h-[500px] md:h-[600px] p-4">
          {Object.values(POSE_TYPES).map((pose) => (
            <div
              key={pose.id}
              onClick={() => setSelectedPose(pose.id)}
              className={`cursor-pointer h-full ${selectedPose === pose.id ? 'outline outline-2 outline-black-500' : ''}`}
            >
              <PosePreview
                pose={pose}
                file={files[pose.id]}
                onRemove={() => removeFile(pose.id)}
                isSelected={selectedPose === pose.id}
                isMobile={isMobile}
                onUpload={() => {
                  setSelectedPose(pose.id);
                  document.getElementById('file-upload-input').click();
                }}
                onShowGuidelines={handleShowGuidelines}
              />
            </div>
          ))}
        </div>
        <div className="mt-8">
          <div className="bg-black text-white p-4 flex items-center">
            <span className="font-bold">GUIDELINES</span>
          </div>
          <div className="p-4 border border-gray-300 bg-white">
            <div className="grid grid-cols-2 gap-6">
              <div>
                <ul className="space-y-3">
                  <li className="flex items-center">
                    <AiOutlineCheck className="text-green-500 mr-3 flex-shrink-0" size={24} />
                    <span className="text-base">Fitted clothing</span>
                  </li>
                  <li className="flex items-center">
                    <AiOutlineCheck className="text-green-500 mr-3 flex-shrink-0" size={24} />
                    <span className="text-base">Good lighting</span>
                  </li>
                  <li className="flex items-center">
                    <AiOutlineCheck className="text-green-500 mr-3 flex-shrink-0" size={24} />
                    <span className="text-base">Neutral background</span>
                  </li>
                </ul>
              </div>
              <div>
                <ul className="space-y-3">
                  <li className="flex items-center">
                    <AiOutlineClose className="text-red-500 mr-3 flex-shrink-0" size={24} />
                    <span className="text-base">Loose clothing</span>
                  </li>
                  <li className="flex items-center">
                    <AiOutlineClose className="text-red-500 mr-3 flex-shrink-0" size={24} />
                    <span className="text-base">Blurry photos</span>
                  </li>
                  <li className="flex items-center">
                    <AiOutlineClose className="text-red-500 mr-3 flex-shrink-0" size={24} />
                    <span className="text-base">Photo filters</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="w-full">
      <div className="p-4">
        {isUploading ? (
          <div className="flex flex-col items-center justify-center py-10 sm:py-20">
            <FaSpinner className="animate-spin text-4xl mb-4 text-black" />
            <p className="text-lg">Uploading and analyzing...</p>
          </div>
        ) 
        // : showCamera ? (
        //   <div>
        //     <div className="bg-black text-white p-4 flex justify-between items-center">
        //       <h2 className="text-xl font-bold">{POSE_TYPES[selectedPose].name}</h2>
        //       <button onClick={handleHideCamera} className="text-white hover:text-gray-200">
        //         <AiOutlineClose size={24} />
        //       </button>
        //     </div>
        //     <div className="h-[400px] sm:h-[500px] md:h-[600px]">
        //       <PoseCamera
        //         ref={poseCameraRef}
        //         onCapture={async (file) => {
        //           await handleHideCamera();
        //           handleCapture(file, selectedPose, currentSlide);
        //         }}
        //         poseType={selectedPose}
        //         onInitialized={() => setIsCameraInitializing(false)}
        //       />
        //     </div>
        //   </div>
        // ) 
        : (
          <div>
            {isMobile ? renderMobileCarousel() : renderDesktopGrid()}
          </div>
        )}
      </div>
      <input
        id="file-upload-input"
        type="file"
        {...getInputProps()}
        className="hidden"
      />
      {/* !showCamera  */}
      {!isUploading && Object.keys(files).length > 0 && (
        <FixedBottomButton
          text={
            Object.keys(files).length === 3 
              ? 'Upload and Analyze' 
              : `CAPTURE ${3 - Object.keys(files).length} ${3 - Object.keys(files).length === 3 ? '' : 'MORE'} ${3 - Object.keys(files).length === 1 ? 'POSE' : 'POSES'}`
          }
          onClick={handleUpload}
          disabled={Object.keys(files).length < 3}
        />
      )}
      {showGuidelines && <GuidelinesModal onClose={() => setShowGuidelines(false)} isOpen={true} />}
      <WarningModal 
        isOpen={warningModal.isOpen}
        onClose={() => setWarningModal({ ...warningModal, isOpen: false })}
        title={warningModal.title}
        message={warningModal.message}
      />
    </div>
  );
};

export default NewScan;
