import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const ExercisePreview = ({ exercise, onClick }) => {
    const navigate = useNavigate();
    const [imageLoaded, setImageLoaded] = useState(false);

    const handleUserClick = (e) => {
        e.stopPropagation();
        navigate(`/profile/${exercise.createdBy?.username}`);
    };

    const navigateToExerciseDetail = (e) => {
        // Don't navigate if clicking on username or options button
        if (e.target.closest('.username-link') || 
            e.target.closest('.options-button')) {
            return;
        }
        
        if (onClick) {
            onClick();
        } else {
            navigate(`/exercises/${exercise.id || exercise.objectID}`);
        }
    };

    // Default targeted muscles if none provided
    const targetedMuscles = exercise.muscleGroups || ['Chest', 'Shoulders', 'Triceps'];

    return (
        <div 
            className="bg-white shadow-md overflow-hidden cursor-pointer hover:shadow-lg transition-shadow mb-4 w-full max-w-2xl mx-auto"
            onClick={navigateToExerciseDetail}
        >
            {/* Media */}
            <div className="relative aspect-[4/3]">
                {/* Loading placeholder */}
                <div className={`absolute inset-0 bg-gray-200 transition-opacity duration-300 ${imageLoaded ? 'opacity-0' : 'opacity-100'}`} />
                
                {/* Image */}
                <img
                    src={exercise.mediaUrl || '/images/placeholder-exercise.png'}
                    alt={exercise.title}
                    className={`w-full h-full object-cover transition-opacity duration-300 ${imageLoaded ? 'opacity-100' : 'opacity-0'}`}
                    onLoad={() => setImageLoaded(true)}
                    onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = '/images/default-profile.png';
                    }}
                />
            </div>

            {/* User Info - Inline with profile pic */}
            <div className="flex bg-black items-center p-3 border-b border-gray-200">
                <div 
                    className="w-8 h-8 rounded-full bg-gray-200 mr-2 shrink-0 cursor-pointer username-link"
                    onClick={handleUserClick}
                    style={{
                        backgroundImage: `url(${exercise.createdBy?.photoURL || '/images/default-profile.png'})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center'
                    }}
                />
                <span 
                    className="font-semibold text-sm text-white cursor-pointer hover:underline username-link"
                    onClick={handleUserClick}
                >
                    @{exercise.createdBy?.username || 'unknown'}
                </span>
                <button className="text-white ml-auto options-button">
                    <span className="material-icons text-xl">more_horiz</span>
                </button>
            </div>

            {/* Exercise Info */}
            <div className="p-3">
                {/* Title with exercise icon */}
                <div className="flex items-center mb-2">
                    <h2 className="text-lg font-bold flex-grow uppercase">{exercise.title}</h2>
                    <span className="text-blue-500">
                        <span className="material-icons text-xl">fitness_center</span>
                    </span>
                </div>

                {/* Targeted Muscles Tags */}
                <div className="flex flex-wrap gap-2 mb-3">
                    {targetedMuscles.map((muscle, index) => (
                        <span 
                            key={index} 
                            className="bg-blue-100 text-blue-800 text-xs font-medium px-2.5 py-0.5 flex items-center"
                        >
                            {muscle}
                        </span>
                    ))}
                </div>

                {/* Description */}
                <p className="text-sm text-gray-700 mb-3">{exercise.description || 'A quick and effective workout for your upper body.'}</p>

                {/* Post Count Only */}
                <div className="flex items-center border-t pt-2">
                    <div className="flex items-center">
                        <span className="material-icons text-gray-800 text-xl">chat_bubble_outline</span>
                        <span className="text-sm ml-1">{exercise.postCount || 0} {exercise.postCount === 1 ? 'post' : 'posts'}</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ExercisePreview; 