import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { useAuth } from './AuthContext';
import { useUser } from './UserContext';
import { fetchScanHistory, deleteScan as deleteScanFromDb, scoreBodyScan as scoreBodyScanDb } from '../firebase/Scans';

const ScanContext = createContext();

export const useScan = () => useContext(ScanContext);

export const ScanProvider = ({ children }) => {
  const [scanHistory, setScanHistory] = useState([]);
  const [loading, setLoading] = useState(true);
  const { currentUser } = useAuth();
  const { userData } = useUser();

  // Fetch scan history
  const loadScanHistory = useCallback(() => {
    if (!currentUser) {
      setScanHistory([]);
      setLoading(false);
      return null;
    }

    return fetchScanHistory(currentUser.uid, (scans) => {
      setScanHistory(scans);
      setLoading(false);
    });
  }, [currentUser]);

  // Initialize data when user changes
  useEffect(() => {
    setLoading(true);
    const unsubscribe = loadScanHistory();
    return () => {
      if (unsubscribe) unsubscribe();
    };
  }, [loadScanHistory]);

  // Delete scan
  const deleteScan = useCallback(async (scanId) => {
    if (!currentUser) return;
    await deleteScanFromDb(currentUser.uid, scanId);
  }, [currentUser]);

  // Score body scan
  const scoreBodyScan = useCallback(async (imageUrl) => {
    const threadId = userData.assistants.scanningAssistant.threadId;
    console.log(userData);
    if (!currentUser) {
      throw new Error("User must be logged in to score body scans");
    }

    if (!threadId) {
      throw new Error("AI Assistant not initialized. Please try again in a few moments.");
    }

    if (!imageUrl) {
      throw new Error("No image URL provided");
    }

    const previousScan = scanHistory[0]; // Get most recent scan
    console.log("Previous scan:", previousScan);
    console.log("Thread ID:", threadId);
    console.log("ImageURL:", imageUrl);
    return await scoreBodyScanDb(
      currentUser.uid,
      imageUrl,
      threadId,
      previousScan || null
    );
  }, [currentUser, userData, scanHistory]);

  const value = {
    scanHistory,
    loading,
    deleteScan,
    scoreBodyScan,
  };

  return <ScanContext.Provider value={value}>{children}</ScanContext.Provider>;
};
