import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import { UserProvider, useUser } from './contexts/UserContext';
import { ScanProvider } from './contexts/ScanContext';
import { WorkoutProvider } from './contexts/WorkoutContext';
import { OnboardingProvider } from './contexts/OnboardingContext';
import Layout from './components/Layout';
import Home from './pages/Home';
import Account from './pages/Account';
import Onboarding from './pages/Onboarding';
import BodyScan from './pages/BodyScan';
import Login from './pages/auth/Login';
import Register from './pages/auth/Register';
import LoadingProfile from './components/LoadingProfile';
import Workout from './pages/Workout';
import EditAccount from './pages/EditAccount';
import Discover from './pages/Discover';
import { ProfileProvider } from './contexts/ProfileContext';
import { DiscoverProvider } from './contexts/DiscoverContext';
import { FollowingProvider } from './contexts/FollowingContext';
import { FollowersProvider } from './contexts/FollowersContext';
import Exercise from './pages/Exercise';
import { ExerciseProvider } from './contexts/ExerciseContext';
import { PostProvider } from './contexts/PostContext';
import Create from './pages/Create';
import RoutinePage from './pages/RoutineDetail';
import PostDetail from './pages/PostDetail';
import ScanDetail from './pages/ScanDetail';
import { CreateProvider } from './contexts/CreateContext';
import { trackSiteVisit } from './analytics/conversations';
import { storeUtmParams } from './analytics/utm';
import { HeaderProvider } from './contexts/HeaderContext';

// New component to handle protected routes and loading states
const ProtectedApp = () => {
  const { userLoggedIn, isOnboardingComplete, onboardingLoading } = useAuth();
  const { loading: userLoading, userData } = useUser();

  // Show loading state while user data is being fetched
  if (userLoading || onboardingLoading) {
    return (
      <div className="flex items-center justify-center min-h-[100dvh] w-full fixed inset-0">
        <LoadingProfile size="xl" />
      </div>
    );
  }

  // If user is not logged in, redirect to login
  if (!userLoggedIn) {
    return <Navigate to="/login" />;
  }

  // If user hasn't completed onboarding, redirect to onboarding
  if (!isOnboardingComplete) {
    return <Navigate to="/onboarding" />;
  }

  // Check if user data is ready for display based on skipped preferences
  const preferencesSkipped = 
    userData?.skippedFitnessGoals === true || 
    userData?.skippedTrainingPreferences === true;
    
  // If both preferences were skipped, we don't need assistants
  // Otherwise, wait for assistants to be initialized
  if (!preferencesSkipped && !userData?.assistants) {
    return (
      <div className="flex items-center justify-center min-h-[100dvh] w-full fixed inset-0">
        <div className="text-center">
          <LoadingProfile size="xl" className="mx-auto mb-4" />
          <p className="text-gray-600">Initializing AI assistants...</p>
        </div>
      </div>
    );
  }

  return (
    <FollowersProvider>
    <FollowingProvider>

    <DiscoverProvider>
    <ExerciseProvider>
    <PostProvider>
    <CreateProvider>
    <ScanProvider>
      <WorkoutProvider>
        <Layout>
          <Routes>
            <Route path="home" element={<Home />} />
            <Route path="profile/:username?" element={<Account />} />
            <Route path="edit-profile" element={<EditAccount />} />
            <Route path="scan" element={<BodyScan />} />
            <Route path="scan/:scanId" element={<ScanDetail />} />
            <Route path="workout" element={<Workout />} />
            <Route path="discover" element={<Discover />} />
            <Route path="create" element={<Create />} />
            <Route path="exercises/:exerciseId" element={<Exercise />} />
            <Route path="routine/:id" element={<RoutinePage />} />
            <Route path="posts/:postId" element={<PostDetail />} />
            <Route path="*" element={<Navigate to="/home" />} />
          </Routes>
        </Layout>
      </WorkoutProvider>
    </ScanProvider>
    </CreateProvider>
    </PostProvider>
    </ExerciseProvider>

    </DiscoverProvider>
    </FollowingProvider>
    </FollowersProvider>
  );
};

function App() {
  useEffect(() => {
    // Store UTM parameters from URL
    const utmParams = storeUtmParams();
    
    // Track site visit with UTM source if available
    const source = utmParams.utm_source || 'direct';
    trackSiteVisit(source);
    
    // Fix for iOS Safari viewport height
    const setVhProperty = () => {
      // Set the value of --vh to be 1% of the viewport height
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };
    
    // Set the property initially
    setVhProperty();
    
    // Update on resize and orientation change
    window.addEventListener('resize', setVhProperty);
    window.addEventListener('orientationchange', setVhProperty);
    
    return () => {
      window.removeEventListener('resize', setVhProperty);
      window.removeEventListener('orientationchange', setVhProperty);
    };
  }, []);

  return (
    <HeaderProvider>
      <AuthProvider>
        <UserProvider>
          <FollowingProvider>
            <FollowersProvider>
              <ProfileProvider>
                <DiscoverProvider>
                  <Router>
                    <Routes>
                      {/* Public routes */}
                      <Route path="/login" element={<Login />} />
                      <Route path="/register" element={<Register />} />
                      <Route path="/onboarding" element={<OnboardingProvider>
                        <Onboarding />
                      </OnboardingProvider>} />
                      {/* Protected routes */}
                      <Route path="/*" element={<ProtectedApp />} />
                    </Routes>
                  </Router>
                </DiscoverProvider>
              </ProfileProvider>
            </FollowersProvider>
          </FollowingProvider>
        </UserProvider>
      </AuthProvider>
    </HeaderProvider>
  );
}

export default App;
