import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useExercise } from '../../contexts/ExerciseContext';
import { useAuth } from '../../contexts/AuthContext';
import MuscleGroupSelectorSection from '../../components/ui/MuscleGroupSelectorSection';
import WarningModal from '../../components/ui/WarningModal';
import FixedBottomButton from '../../components/ui/FixedBottomButton';
import { trackExerciseCreation } from '../../analytics/conversations';
import { checkTextForInappropriateContent } from '../../firebase/NSFWCheck';

const CreateExercise = () => {
    const navigate = useNavigate();
    const { addExercise } = useExercise();
    const { currentUser } = useAuth();

    const [formData, setFormData] = useState({
        title: '',
        description: '',
        muscleGroups: ['BICEPS'], // Default selection based on mockup
        userId: currentUser.uid,
    });
    const [mediaFile, setMediaFile] = useState(null);
    const [mediaPreview, setMediaPreview] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [modalTitle, setModalTitle] = useState('Warning');

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleMuscleGroupsChange = useCallback((muscleGroups) => {
        // Only update if the selection has actually changed
        setFormData(prev => {
            // Check if the arrays are the same
            if (JSON.stringify(prev.muscleGroups) === JSON.stringify(muscleGroups)) {
                return prev; // Return the same state object if no change
            }
            return {
                ...prev,
                muscleGroups,
            };
        });
    }, []);

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            if (!file.type.startsWith('image/') && !file.type.startsWith('video/')) {
                setError('Please upload an image or video file');
                setModalTitle('Invalid File Type');
                setShowErrorModal(true);
                return;
            }
            setMediaFile(file);
            setMediaPreview(URL.createObjectURL(file));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        
        if (!formData.title || !formData.description || !mediaFile) {
            let errorMessage = 'Please fill in all required fields:';
            if (!formData.title) errorMessage += ' title,';
            if (!formData.description) errorMessage += ' description,';
            if (!mediaFile) errorMessage += ' media';
            
            // Clean up the message
            errorMessage = errorMessage.replace(/,$/g, '');
            errorMessage = errorMessage.replace(/,([^,]*)$/, ' and$1');
            
            setError(errorMessage);
            setModalTitle('Missing Information');
            setShowErrorModal(true);
            return;
        }

        setLoading(true);
        try {
            // Check title and description for inappropriate content
            try {
                await checkTextForInappropriateContent(formData.title);
                await checkTextForInappropriateContent(formData.description);
            } catch (contentError) {
                setError(contentError.message);
                setModalTitle('Content Policy Violation');
                setShowErrorModal(true);
                setLoading(false);
                return;
            }

            const exerciseData = {
                title: formData.title,
                description: formData.description,
                muscleGroups: formData.muscleGroups,
                userId: formData.userId,
            };

            const exerciseId = await addExercise(exerciseData, mediaFile);
            
            // Track exercise creation
            trackExerciseCreation(
                currentUser.uid, 
                exerciseId, 
                formData.muscleGroups
            );
            
            navigate('/exercises');
        } catch (err) {
            // Set appropriate title based on error message
            if (err.message && err.message.includes('NSFW')) {
                setModalTitle('Content Policy Violation');
            } else {
                setModalTitle('Error Creating Exercise');
            }
            
            setError(err.message || 'Failed to create exercise');
            setShowErrorModal(true);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        return () => {
            if (mediaPreview) URL.revokeObjectURL(mediaPreview);
        };
    }, [mediaPreview]);

    return (
        <div className="w-full">
            <form onSubmit={handleSubmit}>
                {/* Title Section */}
                <div className="mb-6">
                    <div className="bg-black text-white p-4">
                        <span>TITLE</span>
                    </div>
                    <input
                        type="text"
                        name="title"
                        value={formData.title}
                        onChange={handleInputChange}
                        placeholder="E.g. Bench Press"
                        className="w-full p-4 border border-gray-300"
                    />
                </div>

                {/* Description Section */}
                <div className="mb-6">
                    <div className="bg-black text-white p-4">
                        <span>DESCRIPTION</span>
                    </div>
                    <textarea
                        name="description"
                        value={formData.description}
                        onChange={handleInputChange}
                        placeholder="Describe the Exercise and Proper Form..."
                        className="w-full p-4 border border-gray-300"
                        rows="4"
                    />
                </div>

                {/* Media Section */}
                <div className="mb-6 bg-white">
                    <div className="bg-black text-white p-4">
                        <span>MEDIA</span>
                    </div>
                    <label 
                        htmlFor="media-upload" 
                        className="block border-2 border-dashed border-gray-300 p-8 text-center cursor-pointer"
                    >
                        <div className="flex flex-col items-center justify-center">
                            {!mediaPreview ? (
                                <>
                                    <svg className="w-12 h-12 mb-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12"/>
                                    </svg>
                                    <span>UPLOAD IMAGES</span>
                                </>
                            ) : (
                                <div className="w-full">
                                    {mediaFile?.type.startsWith('video/') ? (
                                        <video 
                                            src={mediaPreview} 
                                            className="max-h-48 mx-auto" 
                                            controls
                                        />
                                    ) : (
                                        <img 
                                            src={mediaPreview} 
                                            alt="Preview" 
                                            className="max-h-48 mx-auto"
                                        />
                                    )}
                                </div>
                            )}
                        </div>
                        <input
                            type="file"
                            onChange={handleFileChange}
                            className="hidden"
                            accept="image/*,video/*"
                            id="media-upload"
                        />
                    </label>
                </div>

                {/* Muscle Groups Section */}
                <MuscleGroupSelectorSection 
                    selectedMuscles={formData.muscleGroups}
                    onMuscleGroupsChange={handleMuscleGroupsChange}
                />

                {/* Submit Button - Now using the FixedBottomButton component */}
                <FixedBottomButton
                    text="Create Exercise"
                    loadingText="Creating..."
                    isLoading={loading}
                    disabled={loading}
                    type="submit"
                />
            </form>
            
            {/* Add the warning modal */}
            <WarningModal
                isOpen={showErrorModal}
                onClose={() => setShowErrorModal(false)}
                title={modalTitle}
                message={error}
                confirmText="OK"
            />
        </div>
    );
};

export default CreateExercise; 