import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { fetchExercises } from '../../firebase/Exercises';

const ExerciseSelector = ({ onSelect, onClose }) => {
  const [exercises, setExercises] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('all');
  const [categories, setCategories] = useState(['all']);

  useEffect(() => {
    const loadExercises = async () => {
      setLoading(true);
      try {
        const exerciseData = await fetchExercises();
        if (Array.isArray(exerciseData)) {
          setExercises(exerciseData);
          
          // Extract unique categories from exercises
          const uniqueCategories = new Set(
            exerciseData
              .filter(ex => ex.muscleGroups && ex.muscleGroups.length > 0)
              .flatMap(ex => ex.muscleGroups)
          );
          setCategories(['all', ...Array.from(uniqueCategories).sort()]);
        } else {
          console.error('Exercise data is not an array:', exerciseData);
          setExercises([]);
          setError('Failed to load exercises properly');
        }
      } catch (error) {
        console.error('Error loading exercises:', error);
        setExercises([]);
        setError('Failed to load exercises');
      } finally {
        setLoading(false);
      }
    };
    loadExercises();
  }, []);

  const handleExerciseSelect = (exercise, e) => {
    // Prevent event propagation
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    onSelect(exercise);
  };

  const handleCategorySelect = (category, e) => {
    // Prevent event propagation
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    setSelectedCategory(category);
  };

  const handleClose = (e) => {
    // Prevent event propagation
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    onClose();
  };

  const filteredExercises = exercises.filter(exercise => {
    const matchesSearch = exercise.title?.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesCategory = selectedCategory === 'all' || 
      (exercise.muscleGroups && exercise.muscleGroups.includes(selectedCategory));
    return matchesSearch && matchesCategory;
  });

  // Create modal content
  const modalContent = (
    <div 
      className="fixed inset-0 z-50 flex items-center justify-center backdrop-blur-sm bg-black bg-opacity-75 md:pl-16 lg:pl-64"
      onClick={handleClose}
    >
      <div 
        className="bg-white text-black w-11/12 max-w-2xl mx-auto overflow-hidden relative border border-black max-h-[90vh] flex flex-col"
        onClick={(e) => e.stopPropagation()}
      >
        {/* Close button - absolute positioned */}
        <button 
          onClick={handleClose}
          className="absolute top-4 right-4 text-black"
          aria-label="Close"
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
            <line x1="18" y1="6" x2="6" y2="18"></line>
            <line x1="6" y1="6" x2="18" y2="18"></line>
          </svg>
        </button>
        
        {/* Centered icon and title */}
        <div className="p-6 pt-12">
          <div className="flex flex-col items-center text-center mb-6">
            <div className="border border-black p-2 mb-4">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <path d="M18 8h1a4 4 0 0 1 0 8h-1"></path>
                <path d="M2 8h16v9a4 4 0 0 1-4 4H6a4 4 0 0 1-4-4V8z"></path>
                <line x1="6" y1="1" x2="6" y2="4"></line>
                <line x1="10" y1="1" x2="10" y2="4"></line>
                <line x1="14" y1="1" x2="14" y2="4"></line>
              </svg>
            </div>
            <h3 className="text-xl font-bold uppercase">Select Exercise</h3>
          </div>
          
          {/* Search and filter */}
          <div className="mb-4">
            <input
              type="text"
              placeholder="Search exercises..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full p-2 border border-gray-300 mb-2"
            />
            <div className="flex flex-wrap gap-2">
              {categories.map(category => (
                <button
                  key={category}
                  onClick={(e) => handleCategorySelect(category, e)}
                  className={`py-1 px-3  text-sm ${
                    selectedCategory === category 
                      ? 'bg-black text-white' 
                      : 'bg-gray-100 text-black border border-gray-200'
                  }`}
                >
                  {category.charAt(0).toUpperCase() + category.slice(1)}
                </button>
              ))}
            </div>
          </div>
        </div>

        {/* Scrollable content */}
        <div className="overflow-y-auto flex-grow px-6 pb-4">
          {loading ? (
            <div className="flex justify-center items-center h-32">
              <div className="animate-spin -full h-8 w-8 border-b-2 border-black"></div>
            </div>
          ) : error ? (
            <div className="text-center text-red-500 py-4">{error}</div>
          ) : filteredExercises.length === 0 ? (
            <div className="text-center text-gray-500 py-4">
              {searchTerm || selectedCategory !== 'all' 
                ? 'No exercises match your search criteria' 
                : 'No exercises available'}
            </div>
          ) : (
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
              {filteredExercises.map(exercise => (
                <button
                  key={exercise.id || Math.random()}
                  onClick={(e) => handleExerciseSelect(exercise, e)}
                  className="p-3  text-left flex justify-between items-center bg-gray-100 hover:bg-gray-200 border border-gray-200"
                >
                  <div>
                    <div className="font-medium">{exercise.title || 'Unnamed Exercise'}</div>
                    <div className="text-xs opacity-80">
                      {exercise.muscleGroups?.join(', ') || 'No muscle groups'}
                    </div>
                  </div>
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                    <path d="M5 12h14"></path>
                    <path d="M12 5v14"></path>
                  </svg>
                </button>
              ))}
            </div>
          )}
        </div>

        {/* Footer with done button */}
        <div className="p-6 border-t border-gray-200">
          <button
            onClick={handleClose}
            className="w-full bg-black hover:bg-gray-800 text-white font-bold py-2 px-4"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );

  // Use portal to render modal
  return ReactDOM.createPortal(
    modalContent,
    document.body
  );
};

export default ExerciseSelector; 