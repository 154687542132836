import React, { useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import ExerciseSelector from './ExerciseSelector';

const DaySchedule = ({ exercises, onUpdate }) => {
  const [showExerciseSelector, setShowExerciseSelector] = useState(false);

  const handleExerciseSelect = (exercise) => {
    const newExercise = {
      exerciseId: exercise.id,
      title: exercise.title,
      muscleGroups: exercise.muscleGroups,
      sets: 3,
      reps: 10,
      weight: 0
    };
    onUpdate([...exercises, newExercise]);
    setShowExerciseSelector(false);
  };

  const handleExerciseUpdate = (index, field, value) => {
    const updatedExercises = exercises.map((ex, i) => 
      i === index ? { ...ex, [field]: Number(value) } : ex
    );
    onUpdate(updatedExercises);
  };

  const handleRemoveExercise = (index) => {
    const updatedExercises = exercises.filter((_, i) => i !== index);
    onUpdate(updatedExercises);
  };

  const handleAddExerciseClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setShowExerciseSelector(true);
  };

  return (
    <div className="w-full">
      {exercises.length > 0 ? (
        <div className="space-y-3 mb-4">
          {exercises.map((exercise, index) => (
            <div key={index} className="flex flex-wrap items-center gap-2 p-3 bg-gray-50 border border-gray-200 ">
              <div className="flex-grow min-w-[200px]">
                <div className="font-medium">{exercise.title}</div>
                <div className="text-xs text-gray-600">
                  {exercise.muscleGroups?.join(', ') || 'No muscle groups'}
                </div>
              </div>
              
              <div className="flex flex-wrap gap-2">
                <div className="w-20">
                  <label className="block text-xs text-gray-600">Sets</label>
                  <input
                    type="number"
                    value={exercise.sets}
                    onChange={(e) => handleExerciseUpdate(index, 'sets', e.target.value)}
                    className="w-full p-1 border border-gray-300  text-sm"
                    min="1"
                  />
                </div>
                
                <div className="w-20">
                  <label className="block text-xs text-gray-600">Reps</label>
                  <input
                    type="number"
                    value={exercise.reps}
                    onChange={(e) => handleExerciseUpdate(index, 'reps', e.target.value)}
                    className="w-full p-1 border border-gray-300  text-sm"
                    min="1"
                  />
                </div>
                
                <div className="w-24">
                  <label className="block text-xs text-gray-600">Weight</label>
                  <input
                    type="number"
                    value={exercise.weight}
                    onChange={(e) => handleExerciseUpdate(index, 'weight', e.target.value)}
                    className="w-full p-1 border border-gray-300  text-sm"
                    min="0"
                  />
                </div>
                
                <button 
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    handleRemoveExercise(index);
                  }}
                  className="ml-1 p-1 text-red-500 hover:text-red-700 self-end"
                  aria-label="Remove exercise"
                >
                  <DeleteIcon />
                </button>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="text-center py-4 text-gray-500 italic">
          No exercises added yet
        </div>
      )}

      <button 
        onClick={handleAddExerciseClick}
        className="bg-black text-white py-2 px-4 hover:bg-gray-800"
      >
        Add Exercise
      </button>

      {showExerciseSelector && (
        <ExerciseSelector
          onSelect={handleExerciseSelect}
          onClose={() => setShowExerciseSelector(false)}
        />
      )}
    </div>
  );
};

export default DaySchedule; 