import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { useUser } from '../contexts/UserContext';
import { Card, CardContent } from "../components/ui/card";

const EditAccount = () => {
  const navigate = useNavigate();
  const { deleteAccount, isEmailUser } = useAuth();
  const { userData, updateUserData } = useUser();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [isDeleting, setIsDeleting] = useState(false);
  const [password, setPassword] = useState('');
  const [formData, setFormData] = useState({
    username: userData?.username || '',
    height: userData?.height || '',
    weight: userData?.weight || '',
    fitnessGoal: userData?.fitnessGoal || '',
    trainingStyle: userData?.trainingStyle || ''
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    try {
      await updateUserData(formData);
      navigate(`/profile/${formData.username}`);
    } catch (error) {
      setError('Failed to update profile. Please try again.');
      console.error('Error updating profile:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteAccount = async (e) => {
    e.preventDefault();
    if (window.confirm("Are you sure you want to delete your account? This action cannot be undone.")) {
      setIsDeleting(true);
      setError('');
      try {
        await deleteAccount(isEmailUser ? password : null);
        navigate('/login');
      } catch (error) {
        setError('Failed to delete account. Please try again.');
      } finally {
        setIsDeleting(false);
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  return (
    <div className="flex-1 bg-black text-white p-8">
      <div className="max-w-2xl mx-auto">
        <div className="flex items-center justify-between mb-8">
          <h1 className="text-3xl font-bold">Edit Profile</h1>
          <button
            onClick={() => navigate(-1)}
            className="px-4 py-2 text-sm text-gray-400 hover:text-white"
          >
            Cancel
          </button>
        </div>

        <Card className="bg-black border border-gray-800 mb-8">
          <CardContent className="p-6">
            <form onSubmit={handleSubmit} className="space-y-6">
              <div>
                <label className="block text-sm font-medium text-gray-400 mb-2">
                  Username
                </label>
                <input
                  type="text"
                  name="username"
                  value={formData.username}
                  onChange={handleChange}
                  className="w-full p-2 bg-gray-900 border border-gray-700 rounded text-white"
                  required
                />
              </div>

              <div className="grid grid-cols-2 gap-4">
                <div>
                  <label className="block text-sm font-medium text-gray-400 mb-2">
                    Height
                  </label>
                  <input
                    type="text"
                    name="height"
                    value={formData.height}
                    onChange={handleChange}
                    className="w-full p-2 bg-gray-900 border border-gray-700 rounded text-white"
                    placeholder="5'11''"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-400 mb-2">
                    Weight
                  </label>
                  <input
                    type="text"
                    name="weight"
                    value={formData.weight}
                    onChange={handleChange}
                    className="w-full p-2 bg-gray-900 border border-gray-700 rounded text-white"
                    placeholder="180"
                  />
                </div>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-400 mb-2">
                  Fitness Goal
                </label>
                <select
                  name="fitnessGoal"
                  value={formData.fitnessGoal}
                  onChange={handleChange}
                  className="w-full p-2 bg-gray-900 border border-gray-700 rounded text-white"
                >
                  <option value="">Select a goal</option>
                  <option value="BUILD_MUSCLE">Build Muscle</option>
                  <option value="LOSE_FAT">Lose Fat</option>
                  <option value="MAINTAIN">Maintain</option>
                </select>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-400 mb-2">
                  Training Style
                </label>
                <select
                  name="trainingStyle"
                  value={formData.trainingStyle}
                  onChange={handleChange}
                  className="w-full p-2 bg-gray-900 border border-gray-700 rounded text-white"
                >
                  <option value="">Select a style</option>
                  <option value="BODYBUILDING">Bodybuilding</option>
                  <option value="POWERLIFTING">Powerlifting</option>
                  <option value="CROSSFIT">CrossFit</option>
                </select>
              </div>

              {error && (
                <p className="text-red-500 text-sm">{error}</p>
              )}

              <div className="flex justify-end">
                <button
                  type="submit"
                  disabled={loading}
                  className="px-6 py-2 bg-white text-black rounded-md hover:bg-gray-200 transition disabled:bg-gray-500"
                >
                  {loading ? 'Saving...' : 'Save Changes'}
                </button>
              </div>
            </form>
          </CardContent>
        </Card>

        {/* Danger Zone */}
        <Card className="bg-black border border-red-800">
          <CardContent className="p-6">
            <h2 className="text-xl font-bold text-red-500 mb-4">Danger Zone</h2>
            <form onSubmit={handleDeleteAccount}>
              {isEmailUser && (
                <input
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Enter your password to confirm"
                  className="w-full p-2 mb-4 bg-gray-900 border border-gray-700 rounded text-white"
                  required
                />
              )}
              {error && <p className="text-red-500 mb-2">{error}</p>}
              <button
                type="submit"
                disabled={isDeleting}
                className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 disabled:bg-red-300"
              >
                {isDeleting ? 'Deleting...' : 'Delete Account'}
              </button>
            </form>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default EditAccount; 