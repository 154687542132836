import React, { useState, useEffect } from 'react';
import WeekSchedule from '../../components/routine/WeekSchedule';
import { useAuth } from '../../contexts/AuthContext';
import { addRoutine } from '../../firebase/Routines';
import { useNavigate } from 'react-router-dom';
import HotelIcon from '@mui/icons-material/Hotel';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import FixedBottomButton from '../../components/ui/FixedBottomButton';
import { TRAINING_TAG_GROUPS } from '../../models/training-preferences';
import ReactDOM from 'react-dom';
import WarningModal from '../../components/ui/WarningModal';
import { checkTextForInappropriateContent } from '../../firebase/NSFWCheck';

// Training Tags Modal Component
const TrainingTagsModal = ({ isOpen, onClose, selectedTags, onTagsChange }) => {
  const [expandedTagGroups, setExpandedTagGroups] = useState({});

  if (!isOpen) return null;

  const toggleTagGroup = (groupName) => {
    setExpandedTagGroups(prev => ({
      ...prev,
      [groupName]: !prev[groupName]
    }));
  };

  const handleTagToggle = (tag) => {
    if (selectedTags.includes(tag)) {
      onTagsChange(selectedTags.filter(t => t !== tag));
    } else {
      onTagsChange([...selectedTags, tag]);
    }
  };

  return ReactDOM.createPortal(
    <div className="fixed inset-0 z-50 flex items-center justify-center backdrop-blur-sm bg-black bg-opacity-75 md:pl-16 lg:pl-64">
      <div className="bg-white text-black w-11/12 max-w-3xl mx-auto overflow-hidden relative border border-black max-h-[90vh] flex flex-col">
        {/* Close button - absolute positioned */}
        <button 
          onClick={onClose}
          className="absolute top-4 right-4 text-black"
          aria-label="Close"
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
            <line x1="18" y1="6" x2="6" y2="18"></line>
            <line x1="6" y1="6" x2="18" y2="18"></line>
          </svg>
        </button>
        
        {/* Centered icon and title */}
        <div className="p-6 pt-12">
          <div className="flex flex-col items-center text-center mb-6">
            <div className="border border-black p-2 mb-4">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <path d="M20.59 13.41l-7.17 7.17a2 2 0 0 1-2.83 0L2 12V2h10l8.59 8.59a2 2 0 0 1 0 2.82z"></path>
                <line x1="7" y1="7" x2="7.01" y2="7"></line>
              </svg>
            </div>
            <h3 className="text-xl font-bold uppercase">Training Tags</h3>
          </div>
          
          <p className="text-sm text-gray-600 mb-4">
            Select tags that best describe your routine. These help others find your routine and match it to their training preferences.
          </p>
        </div>

        {/* Scrollable content */}
        <div className="overflow-y-auto flex-grow px-6 pb-4">
          <div className="space-y-4">
            {Object.entries(TRAINING_TAG_GROUPS).map(([groupName, tags]) => (
              <div key={groupName} className="border border-gray-200">
                <button
                  type="button"
                  onClick={() => toggleTagGroup(groupName)}
                  className="w-full p-3 text-left font-medium flex justify-between items-center bg-gray-100"
                >
                  {groupName.replace(/_/g, ' ')}
                  <span>{expandedTagGroups[groupName] ? '−' : '+'}</span>
                </button>
                {expandedTagGroups[groupName] && (
                  <div className="p-3 flex flex-wrap gap-2">
                    {tags.map(tag => (
                      <button
                        key={tag}
                        type="button"
                        onClick={() => handleTagToggle(tag)}
                        className={`py-2 px-3 text-sm transition-colors ${
                          selectedTags.includes(tag) 
                            ? 'bg-black text-white' 
                            : 'bg-gray-100 text-black border border-gray-200'
                        }`}
                      >
                        {tag}
                      </button>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>

        {/* Selected tags summary and buttons */}
        <div className="p-6 border-t border-gray-200">
          <div className="mb-4">
            <p className="font-medium mb-2">Selected Tags ({selectedTags.length}):</p>
            <div className="flex flex-wrap gap-2">
              {selectedTags.length > 0 ? (
                selectedTags.map(tag => (
                  <div key={tag} className="bg-black text-white px-3 py-1 text-sm flex items-center gap-1">
                    {tag}
                    <button 
                      type="button" 
                      onClick={(e) => {
                        e.stopPropagation();
                        handleTagToggle(tag);
                      }}
                      className="ml-1 text-white hover:text-gray-300"
                    >
                      ×
                    </button>
                  </div>
                ))
              ) : (
                <p className="text-sm text-gray-500">No tags selected</p>
              )}
            </div>
          </div>
          
          <button
            onClick={onClose}
            className="w-full bg-black hover:bg-gray-800 text-white font-bold py-2 px-4"
          >
            Done
          </button>
        </div>
      </div>
    </div>,
    document.body
  );
};

const NewRoutine = () => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [routineData, setRoutineData] = useState({
    title: '',
    description: '',
    duration: '1',
    schedule: {
      weeks: 1,
      daysPerWeek: 7,
      days: {},
      uniqueWeeks: false
    },
    tags: []
  });
  const [uniqueWeeks, setUniqueWeeks] = useState(false);
  const [isPublic, setIsPublic] = useState(false);
  const [isTagsModalOpen, setIsTagsModalOpen] = useState(false);
  const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);
  const [warningMessage, setWarningMessage] = useState('');
  const [thumbnailFile, setThumbnailFile] = useState(null);
  const [thumbnailPreview, setThumbnailPreview] = useState(null);

  const handleFileChange = (e) => {
    try {
      const file = e.target.files[0];
      
      if (!file) {
        return;
      }
      
      if (!file.type.startsWith('image/')) {
        setWarningMessage('Please upload an image file');
        setIsWarningModalOpen(true);
        return;
      }
      
      const previewUrl = URL.createObjectURL(file);
      
      setThumbnailFile(file);
      setThumbnailPreview(previewUrl);
    } catch (error) {
      console.error("Error in handleFileChange:", error);
      setWarningMessage('Error uploading file. Please try again.');
      setIsWarningModalOpen(true);
    }
  };

  // Cleanup preview URL when component unmounts
  useEffect(() => {
    return () => {
      if (thumbnailPreview) URL.revokeObjectURL(thumbnailPreview);
    };
  }, [thumbnailPreview]);

  const validateForm = () => {
    if (!routineData.title.trim()) {
      setWarningMessage('Please enter a title for your routine.');
      setIsWarningModalOpen(true);
      return false;
    }
    
    if (!routineData.description.trim()) {
      setWarningMessage('Please enter a description for your routine.');
      setIsWarningModalOpen(true);
      return false;
    }
    
    if (!thumbnailFile) {
      setWarningMessage('Please upload a thumbnail image for your routine.');
      setIsWarningModalOpen(true);
      return false;
    }
    
    // Ensure duration has a valid value
    if (routineData.duration === '') {
      setRoutineData(prev => ({
        ...prev,
        duration: '1'
      }));
    }
    
    // Check if any days are scheduled
    const hasScheduledDays = Object.keys(routineData.schedule.days).length > 0;
    if (!hasScheduledDays) {
      setWarningMessage('Please set up your training schedule by selecting at least one day.');
      setIsWarningModalOpen(true);
      return false;
    }
    
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Always ensure duration is valid before submission
    if (routineData.duration === '') {
      setRoutineData(prev => ({
        ...prev,
        duration: '1'
      }));
    }
    
    if (!validateForm()) {
      return;
    }
    
    try {
      // Check title and description for inappropriate content
      try {
        await checkTextForInappropriateContent(routineData.title);
        await checkTextForInappropriateContent(routineData.description);
      } catch (contentError) {
        setWarningMessage(contentError.message);
        setIsWarningModalOpen(true);
        return;
      }
      
      // Prepare final data with guaranteed valid duration
      const finalData = {
        ...routineData,
        duration: routineData.duration === '' ? '1' : routineData.duration
      };
      
      const newRoutine = {
        ...finalData,
        userId: currentUser.uid,
        isPublic,
        createdAt: Date.now(),
        updatedAt: Date.now(),
        progress: {},
        posts: {},
        subscribers: {},
      };
      await addRoutine(newRoutine, thumbnailFile);
      navigate('/routines');
    } catch (error) {
      console.error('Error creating routine:', error);
      setWarningMessage('Failed to create routine. Please try again.');
      setIsWarningModalOpen(true);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'duration') {
      // Allow empty string during typing, but use 1 as minimum for calculations
      const displayValue = value;
      const numValue = value === '' ? 1 : Math.max(1, parseInt(value) || 1);
      
      setRoutineData(prev => {
        // Store all week data in a temporary object but don't delete any
        const daysData = { ...prev.schedule.days };
        
        return {
          ...prev,
          // Allow empty value for display purposes, but ensure minimum of 1 for calculations
          [name]: displayValue,
          schedule: {
            ...prev.schedule,
            weeks: numValue, // Always use numValue for internal calculations
            uniqueWeeks: prev.schedule.uniqueWeeks,
            days: daysData
          }
        };
      });
    } else {
      setRoutineData(prev => ({
        ...prev,
        [name]: value
      }));
    }
  };

  const updateSchedule = (newSchedule) => {
    setRoutineData(prev => ({
      ...prev,
      schedule: newSchedule
    }));
  };

  const handleUniqueWeeksToggle = (event) => {
    const newUniqueWeeks = event.target.checked;
    setUniqueWeeks(newUniqueWeeks);
    
    setRoutineData(prev => {
      const currentDays = { ...prev.schedule.days };
      
      // When toggling between modes, preserve exercises by copying data
      if (newUniqueWeeks && currentDays.default) {
        // Switching from single week to multiple weeks
        // Deep copy 'default' week data to 'week1'
        currentDays.week1 = JSON.parse(JSON.stringify(currentDays.default));
      } else if (!newUniqueWeeks && currentDays.week1) {
        // Switching from multiple weeks to single week
        // Deep copy 'week1' data to 'default'
        currentDays.default = JSON.parse(JSON.stringify(currentDays.week1));
      }
      
      return {
        ...prev,
        schedule: {
          ...prev.schedule,
          uniqueWeeks: newUniqueWeeks,
          days: currentDays
        }
      };
    });
  };

  const handleTagsChange = (newTags) => {
    setRoutineData(prev => ({
      ...prev,
      tags: newTags
    }));
  };

  return (
    <div className="flex-1">
      <form onSubmit={handleSubmit}>
        {/* Title Section */}
        <div className="mb-6">
          <div className="bg-black text-white p-4">
            <span className="font-medium">TITLE</span>
          </div>
          <input
            type="text"
            name="title"
            value={routineData.title}
            onChange={handleInputChange}
            placeholder="Name your routine"
            className="w-full p-4 border border-gray-300 bg-white"
            
          />
        </div>

        {/* Description Section */}
        <div className="mb-6">
          <div className="bg-black text-white p-4">
            <span className="font-medium">DESCRIPTION</span>
          </div>
          <textarea
            name="description"
            value={routineData.description}
            onChange={handleInputChange}
            placeholder="Describe your routine..."
            className="w-full p-4 border border-gray-300 bg-white"
            rows="4"
          />
        </div>

        {/* Thumbnail Section */}
        <div className="mb-6">
          <div className="bg-black text-white p-4">
            <span className="font-medium">THUMBNAIL</span>
            <span className="text-red-500 ml-1">*</span>
          </div>
          <label 
            htmlFor="thumbnail-upload"
            className="block border-2 border-dashed border-gray-300 p-8 text-center cursor-pointer bg-white"
          >
            <div className="flex flex-col items-center justify-center">
              {!thumbnailPreview ? (
                <>
                  <svg className="w-12 h-12 mb-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12"/>
                  </svg>
                  <span>UPLOAD THUMBNAIL</span>
                  <span className="text-sm text-gray-500 mt-2">(Required)</span>
                </>
              ) : (
                <div className="w-full">
                  <div className="mb-2 text-sm text-gray-500">Preview:</div>
                  <img 
                    src={thumbnailPreview} 
                    alt="Thumbnail Preview" 
                    className="max-h-64 mx-auto border border-gray-300 rounded shadow-sm"
                  />
                  <div className="mt-2 text-sm text-blue-500">
                    Click to change thumbnail
                  </div>
                </div>
              )}
            </div>
            <input
              type="file"
              onChange={handleFileChange}
              className="hidden"
              accept="image/*"
              id="thumbnail-upload"
              required
            />
          </label>
        </div>

        {/* Duration Section */}
        <div className="mb-6">
          <div className="bg-black text-white p-4">
            <span className="font-medium">DURATION</span>
          </div>
          <div className="p-4 border border-gray-300 bg-white">
            <div className="flex items-center gap-4">
              <input
                type="number"
                name="duration"
                value={routineData.duration}
                onChange={handleInputChange}
                min="1"
                className="p-2 border border-gray-300 rounded w-24"
                required
              />
              <span>weeks</span>
              <label className="flex items-center gap-2 ml-auto">
                <input
                  type="checkbox"
                  checked={uniqueWeeks}
                  onChange={handleUniqueWeeksToggle}
                  className="w-4 h-4"
                />
                <span>Unique schedule per week</span>
              </label>
            </div>
          </div>
        </div>

        {/* Schedule Section */}
        <div className="mb-6">
          <div className="bg-black text-white p-4">
            <div className="flex items-center justify-between">
              <span className="font-medium">SCHEDULE</span>
              <div className="flex items-center gap-4 text-sm">
                <div className="flex items-center gap-2">
                  <FitnessCenterIcon fontSize="small" />
                  <span>Training Day</span>
                </div>
                <div className="flex items-center gap-2">
                  <HotelIcon fontSize="small" />
                  <span>Rest Day</span>
                </div>
              </div>
            </div>
          </div>
          <div className="border border-gray-300 bg-white">
            <WeekSchedule 
              schedule={routineData.schedule}
              onScheduleUpdate={updateSchedule}
              uniqueWeeks={uniqueWeeks}
              totalWeeks={parseInt(routineData.duration)}
            />
          </div>
        </div>

        {/* Training Tags Section */}
        <div className="mb-6">
          <div className="bg-black text-white p-4">
            <span className="font-medium">TRAINING TAGS</span>
          </div>
          <div className="p-4 border border-gray-300 bg-white">
            <div className="flex flex-col">
              <button
                type="button"
                onClick={() => setIsTagsModalOpen(true)}
                className="bg-gray-100 hover:bg-gray-200 text-black py-2 px-4 mb-4"
              >
                Select Training Tags
              </button>
              
              {routineData.tags.length > 0 && (
                <div>
                  <p className="font-medium mb-2">Selected Tags ({routineData.tags.length}):</p>
                  <div className="flex flex-wrap gap-2">
                    {routineData.tags.map(tag => (
                      <div key={tag} className="bg-black text-white px-3 py-1 text-sm flex items-center gap-1">
                        {tag}
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Visibility Section */}
        <div className="mb-6">
          <div className="bg-black text-white p-4">
            <span className="font-medium">VISIBILITY</span>
          </div>
          <div className="p-4 border border-gray-300 bg-white">
            <label className="flex items-center gap-2">
              <input
                type="checkbox"
                checked={isPublic}
                onChange={(e) => setIsPublic(e.target.checked)}
                className="w-4 h-4"
              />
              <span>Make this routine public</span>
            </label>
          </div>
        </div>

        {/* Submit Button */}
        <FixedBottomButton
          text="Create Routine"
          type="submit"
        />
      </form>

      {/* Training Tags Modal */}
      <TrainingTagsModal
        isOpen={isTagsModalOpen}
        onClose={() => setIsTagsModalOpen(false)}
        selectedTags={routineData.tags}
        onTagsChange={handleTagsChange}
      />

      {/* Warning Modal */}
      <WarningModal
        isOpen={isWarningModalOpen}
        onClose={() => setIsWarningModalOpen(false)}
        title="Incomplete Form"
        message={warningMessage}
        confirmText="OK"
        onConfirm={() => setIsWarningModalOpen(false)}
      />
    </div>
  );
};

export default NewRoutine;