import React from 'react';

const WarningModal = ({ isOpen, onClose, title, message, confirmText, onConfirm, cancelText, accountForSidebar = true }) => {
  if (!isOpen) return null;

  return (
    <div className={`fixed inset-0 z-50 flex items-center justify-center backdrop-blur-sm bg-black bg-opacity-75 ${accountForSidebar ? 'md:pl-16 lg:pl-64' : ''}`}>
      <div className="bg-white text-black w-11/12 max-w-md mx-auto overflow-hidden relative border border-black">
        {/* Close button - absolute positioned */}
        <button 
          onClick={onClose}
          className="absolute top-4 right-4 text-black"
          aria-label="Close"
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
            <line x1="18" y1="6" x2="6" y2="18"></line>
            <line x1="6" y1="6" x2="18" y2="18"></line>
          </svg>
        </button>
        
        {/* Centered warning icon and title */}
        <div className="p-6 pt-12">
          <div className="flex flex-col items-center text-center mb-6">
            <div className="border border-black p-2 mb-4">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <path d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"></path>
                <line x1="12" y1="9" x2="12" y2="13"></line>
                <line x1="12" y1="17" x2="12.01" y2="17"></line>
              </svg>
            </div>
            <h3 className="text-xl font-bold uppercase">{title || 'Warning'}</h3>
          </div>
          
          <p className="text-center">{message}</p>
        </div>
        
        {/* Striped border at bottom using CSS gradient with inverted colors */}
       
      </div>
    </div>
  );
};

export default WarningModal; 