import MuscleGroupSelector from './MuscleGroupSelector';

const MuscleGroupSelectorSection = ({ selectedMuscles, onMuscleGroupsChange }) => {
  // Convert muscle group names to lowercase for consistency
  const handleMuscleGroupsChange = (muscles) => {
    // Convert to uppercase for the API/database format
    const uppercaseMuscles = muscles.map(muscle => muscle.toUpperCase());
    onMuscleGroupsChange(uppercaseMuscles);
  };

  return (
    <div className="mb-6 bg-white relative z-0">
      <div className="bg-black text-white p-4">
        <span className="text-sm md:text-base">MUSCLE GROUPS</span>
      </div>
      <div className="p-2 md:p-4">
        <MuscleGroupSelector 
          onMuscleGroupsChange={handleMuscleGroupsChange}
        />
      </div>
    </div>
  );
};

export default MuscleGroupSelectorSection; 