import React, { useState, useEffect, useCallback, useMemo } from 'react';
import "../../styles/CustomDatePicker.css";
import { FaMars, FaVenus, FaGenderless } from 'react-icons/fa';

// Move GenderOption component outside the main component
const GenderOption = ({ value, icon, label, selectedGender, onChange }) => (
    <label className={`flex flex-col items-center justify-center p-4 border cursor-pointer transition-colors duration-200 ${selectedGender === value ? 'bg-gray-100 border-black' : 'hover:bg-gray-100'}`}>
        <input
            type="radio"
            name="gender"
            value={value}
            checked={selectedGender === value}
            onChange={onChange}
            className="sr-only"
        />
        {icon}
        <span className="mt-2 text-sm font-medium">{label}</span>
    </label>
);

const BasicInfoStep = ({ nextStep, handleChange, formData }) => {
    const { birthday, heightFeet, heightInches, gender } = formData;
    const [errors, setErrors] = useState({});
    const [submitted, setSubmitted] = useState(false);
    const [dateFields, setDateFields] = useState({
        month: birthday ? new Date(birthday).toLocaleString('default', { month: 'long' }) : '',
        day: birthday ? new Date(birthday).getDate() : '',
        year: birthday ? new Date(birthday).getFullYear() : ''
    });

    // Months array for dropdown
    const months = useMemo(() => [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
    ], []);

    const validateForm = useCallback(() => {
        let isValid = true;
        let newErrors = {};

        if (!dateFields.month || !dateFields.day || !dateFields.year) {
            newErrors.birthday = 'Please complete all birthday fields';
            isValid = false;
        } else {
            // Check age during final validation
            const monthIndex = months.indexOf(dateFields.month);
            const birthDate = new Date(dateFields.year, monthIndex, dateFields.day);
            const today = new Date();
            let age = today.getFullYear() - birthDate.getFullYear();
            const monthDiff = today.getMonth() - birthDate.getMonth();
            
            if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
                age--;
            }
            
            if (age < 18) {
                newErrors.birthday = 'You must be at least 18 years old to use this application';
                isValid = false;
            }
        }

        if (!heightFeet || heightFeet < 1 || heightFeet > 9) {
            newErrors.heightFeet = 'Please enter a valid height (feet) between 1 and 9';
            isValid = false;
        }

        if (heightInches === undefined || heightInches === '' || heightInches < 0 || heightInches > 11) {
            newErrors.heightInches = 'Please enter a valid height (inches) between 0 and 11';
            isValid = false;
        }

        if (!gender) {
            newErrors.gender = 'Please select your gender';
            isValid = false;
        }

        setErrors(newErrors);
        return isValid;
    }, [dateFields, heightFeet, heightInches, gender, months]);

    useEffect(() => {
        if (dateFields.month && dateFields.day && dateFields.year) {
            const monthIndex = months.indexOf(dateFields.month);
            if (monthIndex !== -1) {
                const newDate = new Date(dateFields.year, monthIndex, dateFields.day);
                handleChange('birthday')(newDate);
                
                // Only validate if form has been submitted already
                if (submitted) {
                    validateForm();
                }
            }
        }
    }, [dateFields, handleChange, months, submitted, validateForm]);

    const handleSubmit = (e) => {
        e.preventDefault();
        setSubmitted(true);
        if (validateForm()) {
            nextStep();
        }
    };

    const handleDateFieldChange = (field, value) => {
        setDateFields(prev => ({
            ...prev,
            [field]: value
        }));
    };

    // Create a memoized handler for gender changes
    const handleGenderChange = useCallback((value) => {
        handleChange('gender')(value);
    }, [handleChange]);

    return (
        <div className="w-full h-screen flex justify-center items-center p-2">
            <div className="bg-white w-full max-w-3xl text-gray-600 shadow-xl border flex flex-col h-[600px] min-h-[600px] max-h-[600px]">
                <div className="flex-1 flex flex-col justify-center items-center px-8 py-6">
                    <div className="w-full max-w-md">
                        <div className="text-center mb-6">
                            <h2 className="text-gray-800 text-xl font-semibold sm:text-2xl uppercase">Basic Information</h2>
                        </div>
                        <form onSubmit={handleSubmit} className="space-y-4">
                            <div>
                                <label className="text-sm text-gray-600 font-bold">Birthday</label>
                                <div className="flex space-x-4 mt-2">
                                    <div className="flex-1">
                                        <label className="text-xs text-gray-500 mb-1">Month</label>
                                        <div className="relative">
                                            <select
                                                value={dateFields.month}
                                                onChange={(e) => handleDateFieldChange('month', e.target.value)}
                                                className="w-full px-3 py-2 text-gray-500 bg-white border border-gray-300 focus:outline-none focus:border-black focus:ring-1 focus:ring-black focus:ring-opacity-50 appearance-none"
                                            >
                                                <option value="" disabled>Select</option>
                                                {months.map(month => (
                                                    <option key={month} value={month}>{month}</option>
                                                ))}
                                            </select>
                                            <div className="absolute top-1/2 right-3 transform -translate-y-1/2 text-gray-400 pointer-events-none">
                                                <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="w-1/4">
                                        <label className="text-xs text-gray-500 mb-1">Day</label>
                                        <input
                                            type="number"
                                            min="1"
                                            max="31"
                                            value={dateFields.day}
                                            onChange={(e) => handleDateFieldChange('day', e.target.value)}
                                            className="w-full px-3 py-2 text-gray-500 bg-white border border-gray-300 focus:outline-none focus:border-black focus:ring-1 focus:ring-black focus:ring-opacity-50"
                                            placeholder="DD"
                                        />
                                    </div>
                                    <div className="w-1/3">
                                        <label className="text-xs text-gray-500 mb-1">Year</label>
                                        <input
                                            type="number"
                                            min="1900"
                                            max={new Date().getFullYear()}
                                            value={dateFields.year}
                                            onChange={(e) => handleDateFieldChange('year', e.target.value)}
                                            className="w-full px-3 py-2 text-gray-500 bg-white border border-gray-300 focus:outline-none focus:border-black focus:ring-1 focus:ring-black focus:ring-opacity-50"
                                            placeholder="YYYY"
                                        />
                                    </div>
                                </div>
                                {submitted && errors.birthday && (
                                    <p className="text-red-500 text-xs mt-1">{errors.birthday}</p>
                                )}
                            </div>
                            <div className="flex space-x-4">
                                <div className="flex-1">
                                    <label className="text-sm text-gray-600 font-bold">Height (feet)</label>
                                    <input
                                        type="number"
                                        placeholder="Feet"
                                        value={heightFeet}
                                        onChange={(e) => handleChange('heightFeet')(e.target.value)}
                                        className="w-full mt-2 px-3 py-2 text-gray-500 bg-white border border-gray-300 focus:outline-none focus:border-black focus:ring-1 focus:ring-black focus:ring-opacity-50"
                                    />
                                    {submitted && errors.heightFeet && <p className="text-red-500 text-xs mt-1">{errors.heightFeet}</p>}
                                </div>
                                <div className="flex-1">
                                    <label className="text-sm text-gray-600 font-bold">Height (inches)</label>
                                    <input
                                        type="number"
                                        placeholder="Inches"
                                        value={heightInches}
                                        onChange={(e) => handleChange('heightInches')(e.target.value)}
                                        className="w-full mt-2 px-3 py-2 text-gray-500 bg-white border border-gray-300 focus:outline-none focus:border-black focus:ring-1 focus:ring-black focus:ring-opacity-50"
                                    />
                                    {submitted && errors.heightInches && <p className="text-red-500 text-xs mt-1">{errors.heightInches}</p>}
                                </div>
                            </div>
                            <div>
                                <label className="text-sm text-gray-600 font-bold">Gender</label>
                                <div className="grid grid-cols-3 gap-4 mt-2">
                                    <GenderOption
                                        value="male"
                                        icon={<FaMars size={24} className="text-blue-500" />}
                                        label="Male"
                                        selectedGender={gender}
                                        onChange={() => handleGenderChange("male")}
                                    />
                                    <GenderOption
                                        value="female"
                                        icon={<FaVenus size={24} className="text-pink-500" />}
                                        label="Female"
                                        selectedGender={gender}
                                        onChange={() => handleGenderChange("female")}
                                    />
                                    <GenderOption
                                        value="other"
                                        icon={<FaGenderless size={24} className="text-purple-500" />}
                                        label="Other"
                                        selectedGender={gender}
                                        onChange={() => handleGenderChange("other")}
                                    />
                                </div>
                                {submitted && errors.gender && <p className="text-red-500 text-xs mt-1">{errors.gender}</p>}
                            </div>
                            <button
                                type="submit"
                                className="w-full px-4 py-2 text-white font-medium bg-black hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-black focus:ring-opacity-50 transition duration-300"
                            >
                                Next
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BasicInfoStep;
