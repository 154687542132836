import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Accordion, 
  AccordionSummary, 
  AccordionDetails,
  Typography,
  Tabs,
  Tab,
  IconButton
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HotelIcon from '@mui/icons-material/Hotel';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import DaySchedule from './DaySchedule';

const DAYS_OF_WEEK = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

const WeekSchedule = ({ schedule, onScheduleUpdate, uniqueWeeks, totalWeeks }) => {
  const [expandedDay, setExpandedDay] = useState(false);
  const [currentWeek, setCurrentWeek] = useState(1);

  // Reset to week 1 when uniqueWeeks is toggled
  useEffect(() => {
    setCurrentWeek(1);
  }, [uniqueWeeks]);

  // Ensure currentWeek is valid when totalWeeks changes
  useEffect(() => {
    // Handle all possible input types for totalWeeks
    const maxWeeks = Math.max(1, parseInt(totalWeeks) || 1);
    if (currentWeek > maxWeeks) {
      setCurrentWeek(maxWeeks);
    }
  }, [totalWeeks, currentWeek]);

  // Helper function to ensure valid week number for calculations
  const getEffectiveTotalWeeks = () => {
    return Math.max(1, parseInt(totalWeeks) || 1);
  };

  const handleDayUpdate = (day, exercises) => {
    const weekKey = uniqueWeeks ? `week${currentWeek}` : 'default';
    const newSchedule = {
      ...schedule,
      days: {
        ...schedule.days,
        [weekKey]: {
          ...schedule.days[weekKey],
          [day]: { exercises, isRest: false }
        }
      }
    };
    onScheduleUpdate(newSchedule);
  };

  const handleRestDayToggle = (day) => {
    const weekKey = uniqueWeeks ? `week${currentWeek}` : 'default';
    const currentDay = schedule.days[weekKey]?.[day] || { exercises: [], isRest: false };
    
    const newSchedule = {
      ...schedule,
      days: {
        ...schedule.days,
        [weekKey]: {
          ...schedule.days[weekKey],
          [day]: {
            exercises: currentDay.isRest ? [] : currentDay.exercises,
            isRest: !currentDay.isRest
          }
        }
      }
    };
    onScheduleUpdate(newSchedule);
  };

  const getCurrentWeekExercises = (day) => {
    const weekKey = uniqueWeeks ? `week${currentWeek}` : 'default';
    return schedule.days[weekKey]?.[day]?.exercises || [];
  };

  const isRestDay = (day) => {
    const weekKey = uniqueWeeks ? `week${currentWeek}` : 'default';
    return schedule.days[weekKey]?.[day]?.isRest || false;
  };

  return (
    <Box sx={{ mb: 3 }}>
      {uniqueWeeks && (
        <Tabs
          value={currentWeek}
          onChange={(e, newValue) => setCurrentWeek(newValue)}
          sx={{ mb: 2 }}
        >
          {[...Array(getEffectiveTotalWeeks())].map((_, index) => (
            <Tab 
              key={index + 1} 
              label={`Week ${index + 1}`} 
              value={index + 1}
            />
          ))}
        </Tabs>
      )}

      {DAYS_OF_WEEK.map((day) => (
        <Box key={day} sx={{ mb: 1 }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Accordion 
              expanded={expandedDay === day}
              onChange={() => setExpandedDay(expandedDay === day ? false : day)}
              sx={{ flex: 1 }}
            >
              <AccordionSummary 
                expandIcon={<ExpandMoreIcon />}
                sx={{ 
                  flexDirection: 'row-reverse',
                  '& .MuiAccordionSummary-expandIconWrapper': {
                    transform: 'none',
                    marginRight: 1,
                    '&.Mui-expanded': {
                      transform: 'rotate(90deg)'
                    }
                  },
                  '& .MuiAccordionSummary-content': { 
                    display: 'flex', 
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    margin: 0
                  }
                }}
              >
                <Typography>
                  {day} - {isRestDay(day) ? 'Rest Day' : `${getCurrentWeekExercises(day).length || 0} exercises`}
                </Typography>
                <IconButton
                  size="small"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleRestDayToggle(day);
                  }}
                  sx={{ 
                    ml: 2,
                    color: isRestDay(day) ? 'primary.main' : 'text.secondary'
                  }}
                >
                  {isRestDay(day) ? <HotelIcon /> : <FitnessCenterIcon />}
                </IconButton>
              </AccordionSummary>
              <AccordionDetails>
                {isRestDay(day) ? (
                  <Typography color="text.secondary" sx={{ py: 2, textAlign: 'center' }}>
                    This is a rest day
                  </Typography>
                ) : (
                  <DaySchedule
                    exercises={getCurrentWeekExercises(day)}
                    onUpdate={(exercises) => handleDayUpdate(day, exercises)}
                  />
                )}
              </AccordionDetails>
            </Accordion>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default WeekSchedule; 