import {
  collection,
  addDoc,
  doc,
  getDoc,
  updateDoc,
  onSnapshot,
  getDocs
} from "firebase/firestore";
import {
  ref as storageRef,
  uploadBytes,
  getDownloadURL,
  deleteObject
} from "firebase/storage";
import { firestore, storage } from "./Init";
import { getPublicProfileByUserId as getUserProfile } from "./PublicProfile";
import { checkImageForNSFW } from "./NSFWCheck";

// Create a new exercise with media upload
export const createExercise = async (exerciseData, mediaFile) => {
  try {
    const exercisesCollectionRef = collection(firestore, "exercises");
    const timestamp = Date.now();

    // Get creator's profile (public profile)
    const creatorProfile = await getUserProfile(exerciseData.userId);
    if (!creatorProfile) throw new Error("Creator profile not found");

    // Check for NSFW content before uploading (only for images)
    if (mediaFile && mediaFile.type.startsWith("image/")) {
      await checkImageForNSFW(mediaFile);
    }

    // Upload media file if passed NSFW check
    const fileRef = storageRef(storage, "exercises/" + mediaFile.name);
    await uploadBytes(fileRef, mediaFile);
    const mediaUrl = await getDownloadURL(fileRef);

    const newExercise = {
      ...exerciseData,
      createdBy: {
        userId: exerciseData.userId,
        username: creatorProfile.username
      },
      mediaUrl,
      mediaType: mediaFile.type.startsWith("video/") ? "video" : "image",
      createdAt: timestamp,
      updatedAt: timestamp,
      posts: {}
    };

    const docRef = await addDoc(exercisesCollectionRef, newExercise);
    return docRef.id;
  } catch (error) {
    console.error("Error creating exercise:", error);
    throw error;
  }
};

// Update an exercise with new media (if provided)
export const updateExercise = async (exerciseId, updateData, mediaFile) => {
  try {
    const exerciseDocRef = doc(firestore, "exercises", exerciseId);

    if (mediaFile) {
      // Check for NSFW content before uploading (only for images)
      if (mediaFile.type.startsWith("image/")) {
        await checkImageForNSFW(mediaFile);
      }

      // Delete old media if it exists
      const oldExerciseSnap = await getDoc(exerciseDocRef);
      if (oldExerciseSnap.exists()) {
        const oldData = oldExerciseSnap.data();
        if (oldData.mediaUrl) {
          const oldFileRef = storageRef(storage, oldData.mediaUrl);
          await deleteObject(oldFileRef);
        }
      }

      // Upload new media
      const newFileRef = storageRef(storage, `exercises/${exerciseId}/${mediaFile.name}`);
      await uploadBytes(newFileRef, mediaFile);
      const mediaUrl = await getDownloadURL(newFileRef);

      updateData = {
        ...updateData,
        mediaUrl,
        mediaType: mediaFile.type.startsWith("video/") ? "video" : "image"
      };
    }

    await updateDoc(exerciseDocRef, {
      ...updateData,
      updatedAt: Date.now()
    });
  } catch (error) {
    console.error("Error updating exercise:", error);
    throw error;
  }
};

// Fetch all exercises (one-time snapshot)
export const fetchExercises = async () => {
  try {
    const exercisesCollectionRef = collection(firestore, "exercises");
    const snapshot = await getDocs(exercisesCollectionRef);
    const exercisesArray = snapshot.docs.map((docSnap) => {
      const data = docSnap.data();
      return {
        id: docSnap.id,
        ...data,
        createdBy: data.createdBy || { username: "Unknown" },
        postCount: data.posts ? Object.keys(data.posts).length : 0
      };
    });
    return exercisesArray;
  } catch (error) {
    console.error("Error fetching exercises:", error);
    throw error;
  }
};

// Fetch a single exercise in real time
export const fetchExerciseById = (exerciseId, callback) => {
  const exerciseDocRef = doc(firestore, "exercises", exerciseId);
  return onSnapshot(exerciseDocRef, (docSnap) => {
    if (docSnap.exists()) {
      const data = docSnap.data();
      const postCount = data.posts ? Object.keys(data.posts).length : 0;
      callback({
        id: exerciseId,
        ...data,
        createdBy: data.createdBy || { username: "Unknown" },
        postCount,
        posts: data.posts || {}
      });
    } else {
      callback(null);
    }
  });
};

// Promise-based version of fetchExerciseById for one-time fetch
export const getExerciseById = async (exerciseId) => {
  try {
    const exerciseDocRef = doc(firestore, "exercises", exerciseId);
    const docSnap = await getDoc(exerciseDocRef);
    
    if (docSnap.exists()) {
      const data = docSnap.data();
      const postCount = data.posts ? Object.keys(data.posts).length : 0;
      return {
        id: exerciseId,
        ...data,
        createdBy: data.createdBy || { username: "Unknown" },
        postCount,
        posts: data.posts || {}
      };
    } else {
      return null;
    }
  } catch (error) {
    console.error(`Error fetching exercise ${exerciseId}:`, error);
    throw error;
  }
};
