import React, { useContext, useState, useEffect } from "react";
import { auth } from "../firebase/Init";
import { GoogleAuthProvider, signOut } from "firebase/auth";
import { onAuthStateChanged } from "firebase/auth";
import { getUserOnboardingStatus } from "../firebase/User";

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [userLoggedIn, setUserLoggedIn] = useState(false);
  const [isEmailUser, setIsEmailUser] = useState(false);
  const [isGoogleUser, setIsGoogleUser] = useState(false);
  const [isOnboardingComplete, setIsOnboardingComplete] = useState(false);
  const [loading, setLoading] = useState(true);
  const [onboardingLoading, setOnboardingLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, initializeUser);
    return unsubscribe;
  }, []);

  async function initializeUser(user) {
    try {
      if (user) {
        setCurrentUser({ ...user });

        // Check auth providers
        const isEmail = user.providerData.some(
          (provider) => provider.providerId === "password"
        );
        setIsEmailUser(isEmail);

        const isGoogle = user.providerData.some(
          (provider) => provider.providerId === GoogleAuthProvider.PROVIDER_ID
        );
        setIsGoogleUser(isGoogle);

        setUserLoggedIn(true);

        // Get onboarding status
        setOnboardingLoading(true);
        const onboardingStatus = await getUserOnboardingStatus(user.uid);
        setIsOnboardingComplete(onboardingStatus);
        setOnboardingLoading(false);
      } else {
        setCurrentUser(null);
        setUserLoggedIn(false);
        setIsOnboardingComplete(false);
        setOnboardingLoading(false);
      }
    } catch (error) {
      console.error("Error initializing user:", error);
      setOnboardingLoading(false);
    } finally {
      setLoading(false);
    }
  }

  async function refreshOnboardingStatus() {
    if (currentUser) {
      setOnboardingLoading(true);
      try {
        const onboardingStatus = await getUserOnboardingStatus(currentUser.uid);
        setIsOnboardingComplete(onboardingStatus);
      } catch (error) {
        console.error("Error refreshing onboarding status:", error);
      } finally {
        setOnboardingLoading(false);
      }
    }
  }

  // Add logout function
  async function logout() {
    try {
      await signOut(auth);
      // Auth state change will trigger onAuthStateChanged which will reset user state
    } catch (error) {
      console.error("Error signing out:", error);
      throw error;
    }
  }

  const value = {
    userLoggedIn,
    isEmailUser,
    isGoogleUser,
    currentUser,
    isOnboardingComplete,
    onboardingLoading,
    refreshOnboardingStatus,
    setCurrentUser,
    logout // Add logout to the context value
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}