import { firestore } from "./Init";
import {
  collection,
  doc,
  onSnapshot,
  deleteDoc,
  addDoc
} from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";

// Listen for changes to the user's scan history
export const fetchScanHistory = (userId, callback) => {
  if (!userId) return null;
  const scansCollectionRef = collection(firestore, "users", userId, "scans");
  const unsubscribe = onSnapshot(
    scansCollectionRef,
    (snapshot) => {
      const scansArray = snapshot.docs
        .map((docSnap) => ({
          id: docSnap.id,
          ...docSnap.data()
        }))
        .sort((a, b) => b.timestamp - a.timestamp);
      callback(scansArray);
    },
    (error) => {
      console.error("Error fetching scan history:", error);
      callback([]);
    }
  );
  return unsubscribe;
};

// Delete a scan from the user's scans subcollection
export const deleteScan = async (userId, scanId) => {
  if (!userId) return;
  try {
    const scanDocRef = doc(firestore, "users", userId, "scans", scanId);
    await deleteDoc(scanDocRef);
    console.log(`Scan ${scanId} deleted successfully`);
  } catch (error) {
    console.error(`Error deleting scan ${scanId}:`, error);
    throw error;
  }
};

// Save a new scan into the user's scans subcollection
export const saveScan = async (userId, scanData) => {
  if (!userId) return;
  try {
    const scansCollectionRef = collection(firestore, "users", userId, "scans");
    const scanToSave = {
      ...scanData,
      timestamp: Date.now()
    };
    const newScanDoc = await addDoc(scansCollectionRef, scanToSave);
    return newScanDoc.id;
  } catch (error) {
    console.error("Error saving scan:", error);
    throw error;
  }
};

// Score a body scan by calling a cloud function and saving the result as a new scan
export const scoreBodyScan = async (userId, imageUrls, threadId, previousScan) => {
  if (!userId || !imageUrls || !threadId) {
    throw new Error(
      `Missing required parameters: ${!userId ? "userId" : ""} ${!imageUrls ? "imageUrls" : ""} ${!threadId ? "threadId" : ""}`.trim()
    );
  }

  try {
    const functions = getFunctions();
    const scoreBodyScanFunction = httpsCallable(functions, "scoreBodyScan");

    // Get analysis from cloud function
    const result = await scoreBodyScanFunction({
      imageUrls,
      threadId,
      previousScan
    });

    // Prepare scan data with scores
    const scanData = {
      imageUrls,
      scores: result.data.scores,
      timestamp: Date.now()
    };

    // Save scan data using Firestore
    await saveScan(userId, scanData);
    return result.data;
  } catch (error) {
    console.error("Error scoring body scan:", error);
    throw error;
  }
};
