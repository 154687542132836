// Layout.jsx - Updated to include Header from HeaderContext
import React from 'react';
import Sidebar from './Sidebar';
import Header from './Header';
import { useHeader } from '../contexts/HeaderContext';

const Layout = ({ children }) => {
  const { headerTitle, headerTrailing, headerLeading } = useHeader();

  return (
    <div className="flex flex-col w-full h-[100dvh] overflow-hidden bg-gray-100">
      {/* Main content area with grid layout for sidebar and content */}
      <div className="grid md:grid-cols-[auto_1fr] grid-rows-[auto_1fr_auto] md:grid-rows-[auto_1fr] h-full">
        {/* Sidebar - spans all rows on desktop, only bottom row on mobile */}
        <div className="row-start-3 md:row-span-2 md:row-start-1">
          <Sidebar />
        </div>
        
        {/* Header - spans the first row, second column on desktop */}
        {headerTitle && (
          <div className="col-span-full md:col-start-2 md:col-span-1 row-start-1">
            <Header title={headerTitle} trailing={headerTrailing} leading={headerLeading} />
          </div>
        )}
        
        {/* Main content - spans the second row, second column on desktop */}
        <div className="col-span-full md:col-start-2 md:col-span-1 row-start-2 overflow-hidden bg-gray-100 md:pb-20 pb-100 flex-1 ">
          {children}
        </div>
      </div>
    </div>
  );
};

export default Layout;