export const calculateAngle = (p1, p2, p3) => {
    if (!p1 || !p2 || !p3) return null;

    const v1 = { x: p1.x - p2.x, y: p1.y - p2.y };
    const v2 = { x: p3.x - p2.x, y: p3.y - p2.y };

    const dotProduct = v1.x * v2.x + v1.y * v2.y;
    const v1mag = Math.sqrt(v1.x * v1.x + v1.y * v1.y);
    const v2mag = Math.sqrt(v2.x * v2.x + v2.y * v2.y);

    const angle = Math.acos(dotProduct / (v1mag * v2mag));
    return (angle * 180) / Math.PI;
};

export const isDoubleBicepPose = (pose) => {
    if (!pose || !pose.keypoints) return false;

    const getKeypoint = (name) => pose.keypoints.find(kp => kp.name === name);

    const leftShoulder = getKeypoint('left_shoulder');
    const leftElbow = getKeypoint('left_elbow');
    const leftWrist = getKeypoint('left_wrist');
    const rightShoulder = getKeypoint('right_shoulder');
    const rightElbow = getKeypoint('right_elbow');
    const rightWrist = getKeypoint('right_wrist');

    const requiredPoints = [leftShoulder, leftElbow, leftWrist, rightShoulder, rightElbow, rightWrist];
    if (requiredPoints.some(point => !point || point.score < 0.3)) return false;

    const leftElbowAngle = calculateAngle(leftShoulder, leftElbow, leftWrist);
    const rightElbowAngle = calculateAngle(rightShoulder, rightElbow, rightWrist);

    const leftShoulderAngle = calculateAngle(leftElbow, leftShoulder, { x: leftShoulder.x, y: leftShoulder.y + 1 });
    const rightShoulderAngle = calculateAngle(rightElbow, rightShoulder, { x: rightShoulder.x, y: rightShoulder.y + 1 });

    const ELBOW_ANGLE_MIN = 45;
    const ELBOW_ANGLE_MAX = 120;
    const SHOULDER_ANGLE_MIN = 70;
    const SHOULDER_ANGLE_MAX = 110;

    const isValidElbowAngles =
        leftElbowAngle >= ELBOW_ANGLE_MIN && leftElbowAngle <= ELBOW_ANGLE_MAX &&
        rightElbowAngle >= ELBOW_ANGLE_MIN && rightElbowAngle <= ELBOW_ANGLE_MAX;

    const isValidShoulderAngles =
        leftShoulderAngle >= SHOULDER_ANGLE_MIN && leftShoulderAngle <= SHOULDER_ANGLE_MAX &&
        rightShoulderAngle >= SHOULDER_ANGLE_MIN && rightShoulderAngle <= SHOULDER_ANGLE_MAX;

    const wristsAboveShoulders =
        leftWrist.y < leftShoulder.y &&
        rightWrist.y < rightShoulder.y;

    return isValidElbowAngles && isValidShoulderAngles && wristsAboveShoulders;
};

export const isBodyInFrame = (pose, canvasRef) => {
    if (!pose || !pose.keypoints || !canvasRef.current) return false;

    const getKeypoint = (name) => pose.keypoints.find(kp => kp.name === name);

    const nose = getKeypoint('nose');
    const leftAnkle = getKeypoint('left_ankle');
    const rightAnkle = getKeypoint('right_ankle');
    const leftWrist = getKeypoint('left_wrist');
    const rightWrist = getKeypoint('right_wrist');

    const keypoints = [nose, leftAnkle, rightAnkle, leftWrist, rightWrist];
    if (keypoints.some(point => !point || point.score < 0.3)) {
        return {
            inFrame: false,
            message: "Can't see full body. Please step back."
        };
    }

    const canvasWidth = canvasRef.current.width;
    const canvasHeight = canvasRef.current.height;
    const margin = 0.1;

    const tooLeft = keypoints.some(point => point.x < canvasWidth * margin);
    const tooRight = keypoints.some(point => point.x > canvasWidth * (1 - margin));
    const tooHigh = keypoints.some(point => point.y < canvasHeight * margin);
    const tooLow = keypoints.some(point => point.y > canvasHeight * (1 - margin));

    if (tooLeft) {
        return { inFrame: false, message: "Move right" };
    }
    if (tooRight) {
        return { inFrame: false, message: "Move left" };
    }
    if (tooHigh) {
        return { inFrame: false, message: "Move down" };
    }
    if (tooLow) {
        return { inFrame: false, message: "Move up" };
    }

    const bodyHeight = Math.max(leftAnkle.y, rightAnkle.y) - nose.y;
    if (bodyHeight < canvasHeight * 0.5) {
        return { inFrame: false, message: "Step closer" };
    }
    if (bodyHeight > canvasHeight * 0.8) {
        return { inFrame: false, message: "Step back" };
    }

    return { inFrame: true, message: "Perfect position!" };
};

export const drawSkeleton = (ctx, pose) => {
    const connections = [
        ['nose', 'left_eye'], ['left_eye', 'left_ear'], ['nose', 'right_eye'],
        ['right_eye', 'right_ear'], ['nose', 'left_shoulder'],
        ['nose', 'right_shoulder'], ['left_shoulder', 'right_shoulder'],
        ['left_shoulder', 'left_elbow'], ['left_elbow', 'left_wrist'],
        ['right_shoulder', 'right_elbow'], ['right_elbow', 'right_wrist'],
        ['left_shoulder', 'left_hip'], ['right_shoulder', 'right_hip'],
        ['left_hip', 'right_hip'], ['left_hip', 'left_knee'],
        ['left_knee', 'left_ankle'], ['right_hip', 'right_knee'],
        ['right_knee', 'right_ankle']
    ];

    connections.forEach(([p1, p2]) => {
        const point1 = pose.keypoints.find(k => k.name === p1);
        const point2 = pose.keypoints.find(k => k.name === p2);

        if (point1?.score > 0.3 && point2?.score > 0.3) {
            ctx.beginPath();
            ctx.moveTo(point1.x, point1.y);
            ctx.lineTo(point2.x, point2.y);
            ctx.stroke();
        }
    });
}; 