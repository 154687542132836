import React, { useEffect, useRef, useState } from 'react';

const UserAgreementModal = ({ isOpen, onClose, onRead }) => {
    const [hasScrolledToBottom, setHasScrolledToBottom] = useState(false);
    const contentRef = useRef(null);

    const handleScroll = () => {
        if (contentRef.current) {
            const { scrollHeight, scrollTop, clientHeight } = contentRef.current;
            const isAtBottom = Math.abs(scrollHeight - scrollTop - clientHeight) < 5;
            setHasScrolledToBottom(isAtBottom);
        }
    };

    // Check if content is fully visible (no scrolling needed)
    useEffect(() => {
        if (isOpen && contentRef.current) {
            const { scrollHeight, clientHeight } = contentRef.current;
            // If content height is less than or equal to the container height, 
            // it means all content is visible without scrolling
            if (scrollHeight <= clientHeight) {
                setHasScrolledToBottom(true);
            } else {
                setHasScrolledToBottom(false);
            }
        }
    }, [isOpen]);

    const handleAgree = () => {
        onRead();
        onClose();
    };

    useEffect(() => {
        if (!isOpen) {
            setHasScrolledToBottom(false);
        }
    }, [isOpen]);

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 flex">
            <div className="fixed inset-0 bg-black bg-opacity-50" onClick={onClose} />
            <div className="relative bg-white w-full max-w-2xl m-auto">
                <div className="flex flex-col max-h-[90vh]">
                    <div className="p-4 border-b">
                        <h2 className="text-xl font-semibold">User Agreement</h2>
                    </div>

                    <div
                        ref={contentRef}
                        onScroll={handleScroll}
                        className="p-4 overflow-y-auto flex-grow"
                    >
                        <div className="space-y-4 text-sm">
                            <div className="mt-2 border bg-gray-50 p-4">
                                <h3 className="font-bold mb-2">Welcome to MOGGED!</h3>
                                <p className="mb-2">
                                    These Terms of Use ("Terms") govern your use of the MOGGED app ("Service"). By creating an account or using the MOGGED Service, you agree to these Terms.
                                </p>

                                <h4 className="font-bold mt-4 mb-1">The MOGGED Service</h4>
                                <p className="mb-2">
                                    We agree to provide you with the MOGGED Service, which includes personalized fitness evaluations, meal and workout plans, progress tracking, and social features for comparing fitness metrics.
                                </p>

                                <h4 className="font-bold mt-4 mb-1">Arbitration Notice</h4>
                                <p className="mb-2">
                                    By agreeing to these Terms, you agree that disputes between you and MOGGED will be resolved through binding, individual arbitration, and you waive your right to participate in class action lawsuits or class-wide arbitration. Exceptions and opt-out details are explained below.
                                </p>

                                <h4 className="font-bold mt-4 mb-1">How Our Service is Funded</h4>
                                <p className="mb-2">
                                    MOGGED is funded through a combination of user subscriptions and partnerships with fitness brands. You may see ads or sponsored content relevant to your fitness journey. We do not sell your personal data, and all data collected is used to improve your experience and offer personalized recommendations.
                                </p>

                                <h4 className="font-bold mt-4 mb-1">Privacy Policy</h4>
                                <p className="mb-2">
                                    Using MOGGED requires the collection and use of your information. Please review our Privacy Policy to understand how your data is handled.
                                </p>

                                <h4 className="font-bold mt-4 mb-1">Your Commitments</h4>
                                <ul className="list-disc pl-5 mb-2">
                                    <li>You must be at least 13 years old to use MOGGED.</li>
                                    <li>You must provide accurate and up-to-date information during registration.</li>
                                    <li>You may not use MOGGED for any unlawful, fraudulent, or unauthorized purposes.</li>
                                    <li>You may not impersonate others or misrepresent your identity.</li>
                                </ul>

                                <h4 className="font-bold mt-4 mb-1">Termination and Account Deletion</h4>
                                <p className="mb-2">
                                    We may remove content or terminate your account if we believe you have violated these Terms or if your content poses a risk to other users or the platform. You may request to delete your account at any time, and the deletion process will begin within 30 days of your request.
                                </p>

                                <p className="mt-4">
                                    By checking the box below, you agree to all terms and conditions outlined in this agreement.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="p-4 border-t flex justify-between items-center">
                        <button
                            onClick={onClose}
                            className="px-4 py-2 bg-gray-100 text-gray-700 hover:bg-gray-200 transition-colors rounded"
                        >
                            Close
                        </button>
                        <button
                            onClick={handleAgree}
                            disabled={!hasScrolledToBottom}
                            className={`
                                px-4 py-2 rounded transition-colors
                                ${hasScrolledToBottom
                                    ? 'bg-black text-white hover:bg-gray-800'
                                    : 'bg-gray-300 text-gray-500 cursor-not-allowed'
                                }
                            `}
                        >
                            {hasScrolledToBottom ? 'I Agree' : 'Please read the agreement'}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UserAgreementModal;