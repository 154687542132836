import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { hasUserLiked, toggleLike, getLikeCount } from '../firebase/interactions/Likes';
import { getCommentCount } from '../firebase/interactions/Comments';

const PostPreview = ({ post }) => {
    const navigate = useNavigate();
    const { currentUser } = useAuth();
    const [imageLoaded, setImageLoaded] = useState(false);
    const [isLiked, setIsLiked] = useState(false);
    const [likeCount, setLikeCount] = useState(post.likeCount || 0);
    const [commentCount, setCommentCount] = useState(post.commentCount || 0);
    const [isLiking, setIsLiking] = useState(false);

    // Check if the post is liked by the current user when component mounts
    useEffect(() => {
        const checkLikeStatus = async () => {
            if (currentUser && post.id) {
                try {
                    const liked = await hasUserLiked(post.id, currentUser.uid);
                    setIsLiked(liked);
                } catch (error) {
                    console.error('Error checking like status:', error);
                }
            }
        };
        
        // Get the latest like and comment counts
        const refreshCounts = async () => {
            if (post.id) {
                try {
                    const [likes, comments] = await Promise.all([
                        getLikeCount(post.id),
                        getCommentCount(post.id)
                    ]);
                    setLikeCount(likes);
                    setCommentCount(comments);
                } catch (error) {
                    console.error('Error refreshing counts:', error);
                }
            }
        };
        
        checkLikeStatus();
        refreshCounts();
    }, [post.id, currentUser]);

    // Update like and comment counts when they change in the post prop
    useEffect(() => {
        setLikeCount(post.likeCount || 0);
        setCommentCount(post.commentCount || 0);
    }, [post.likeCount, post.commentCount]);

    const handleExerciseClick = (e) => {
        e.stopPropagation();
        if (post.exerciseId) {
            navigate(`/exercises/${post.exerciseId}`);
        }
    };

    const navigateToPostDetail = (e) => {
        // Don't navigate if clicking on username, exercise overlay, or options button
        if (e.target.closest('.username-link') || 
            e.target.closest('.exercise-header') || 
            e.target.closest('.options-button') ||
            e.target.closest('.action-button')) {
            return;
        }
        navigate(`/posts/${post.id}`);
    };

    const handleLike = async (e) => {
        e.stopPropagation();
        if (!currentUser) {
            navigate('/login');
            return;
        }
        
        if (isLiking) return; // Prevent multiple clicks
        
        setIsLiking(true);
        try {
            const newLikeState = await toggleLike(post.id, currentUser.uid);
            setIsLiked(newLikeState);
            setLikeCount(prev => newLikeState ? prev + 1 : prev - 1);
        } catch (error) {
            console.error('Error toggling like:', error);
        } finally {
            setIsLiking(false);
        }
    };

    const navigateToComments = (e) => {
        e.stopPropagation();
        navigate(`/posts/${post.id}`, { state: { openComments: true } });
    };

    return (
        <div 
            className={`bg-white shadow-md overflow-hidden cursor-pointer hover:shadow-lg transition-shadow mb-4 w-full mx-auto max-w-2xl`}
            onClick={navigateToPostDetail}
        >
            {/* Media Container with Exercise Header Overlay */}
            <div className="relative aspect-[4/3]">
                {/* Exercise Reference Header (if available) */}
                {post.exerciseId && (
                    <div 
                        className="absolute top-0 left-0 right-0 z-10 bg-black bg-opacity-75 text-white p-3 flex items-center exercise-header"
                        onClick={handleExerciseClick}
                    >
                        <div className="flex-grow">
                            <div className="flex items-center gap-2">
                                <span className="material-icons text-xl">fitness_center</span>
                                <div className="flex items-center">
                                    <h2 className="font-bold uppercase">{post.exerciseTitle}</h2>
                                    <span className="mx-2">•</span>
                                    <span className="text-sm">@{post.exerciseAuthor}</span>
                                </div>
                            </div>
                        </div>
                        <button className="text-white">
                            <span className="material-icons text-xl">chevron_right</span>
                        </button>
                    </div>
                )}

                {post.mediaUrl ? (
                    <>
                        {/* Loading placeholder */}
                        <div className={`absolute inset-0 bg-gray-200 transition-opacity duration-300 ${imageLoaded ? 'opacity-0' : 'opacity-100'}`} />
                        
                        {/* Image or Video */}
                        {post.mediaType === 'video' ? (
                            <video
                                src={post.mediaUrl}
                                className="w-full h-full object-cover"
                                controls
                            />
                        ) : (
                            <img
                                src={post.mediaUrl}
                                alt="Post"
                                className={`w-full h-full object-cover transition-opacity duration-300 ${imageLoaded ? 'opacity-100' : 'opacity-0'}`}
                                onLoad={() => setImageLoaded(true)}
                            />
                        )}
                    </>
                ) : (
                    // Placeholder for posts without media
                    <div className="w-full h-full bg-gray-100 flex items-center justify-center">
                        <span className="material-icons text-gray-400 text-6xl">image</span>
                    </div>
                )}
            </div>

            {/* User Info - Inline with profile pic */}
            <div className="flex items-center p-3 border-b border-gray-200">
                <div 
                    className="w-8 h-8 rounded-full bg-gray-200 mr-2 shrink-0 cursor-pointer username-link"
                    onClick={() => navigate(`/profile/${post.username}`)}
                    style={{
                        backgroundImage: `url(${post.userPhotoURL || '/images/default-profile.png'})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center'
                    }}
                />
                <span 
                    className="font-semibold text-sm cursor-pointer hover:underline username-link"
                    onClick={() => navigate(`/profile/${post.username}`)}
                >
                    @{post.username}
                </span>
                <button className="text-gray-400 hover:text-gray-600 ml-auto options-button">
                    <span className="material-icons text-xl">more_horiz</span>
                </button>
            </div>

            {/* Post Info */}
            <div className="p-3">
                {/* Caption */}
                {post.caption && (
                    <p className="text-sm text-gray-700 mb-3">{post.caption}</p>
                )}

                {/* Action Buttons */}
                <div className="flex items-center justify-between border-t pt-2">
                    <div className="flex items-center action-button">
                        <button 
                            className={`${isLiked ? 'text-red-500' : 'text-gray-800'} hover:opacity-80`}
                            onClick={handleLike}
                        >
                            <span className="material-icons text-xl">
                                {isLiked ? 'favorite' : 'favorite_border'}
                            </span>
                        </button>
                        <span className="text-sm ml-1 mr-4">{likeCount}</span>
                    </div>
                    <div className="flex items-center action-button">
                        <button 
                            className="text-gray-800 hover:text-gray-600"
                            onClick={navigateToComments}
                        >
                            <span className="material-icons text-xl">chat_bubble_outline</span>
                        </button>
                        <span className="text-sm ml-1 mr-4">{commentCount}</span>
                    </div>
                    <div className="flex items-center action-button">
                        <button className="text-gray-800 hover:text-gray-600">
                            <span className="material-icons text-xl">share</span>
                        </button>
                        <span className="text-sm ml-1">{post.shares || 0}</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PostPreview; 