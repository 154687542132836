import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { useAuth } from './AuthContext';
import {
    createExercise,
    updateExercise,
    fetchExercises,
    fetchExerciseById
} from '../firebase/Exercises';

const ExerciseContext = createContext();

export const useExercise = () => useContext(ExerciseContext);

export const ExerciseProvider = ({ children }) => {
    const [exercises, setExercises] = useState([]);
    const [currentExercise, setCurrentExercise] = useState(null);
    const [loading, setLoading] = useState(true);
    const { currentUser } = useAuth();

    // Fetch all exercises
    useEffect(() => {
        const loadExercises = async () => {
            try {
                const exercisesData = await fetchExercises((exercisesData) => {
                    setExercises(exercisesData);
                    setLoading(false);
                });
                
                // Only call unsubscribe if it's a function
                return () => {
                    if (typeof exercisesData === 'function') {
                        exercisesData();
                    }
                };
            } catch (error) {
                console.error('Error fetching exercises:', error);
                setLoading(false);
            }
        };

        loadExercises();
    }, []);

    // Create new exercise
    const addExercise = useCallback(async (exerciseData, mediaFile) => {
        if (!currentUser) {
            throw new Error('Must be logged in to create an exercise');
        }
        try {
            const exerciseId = await createExercise(exerciseData, mediaFile);
            return exerciseId;
        } catch (error) {
            console.error('Error adding exercise:', error);
            throw error;
        }
    }, [currentUser]);

    // Update existing exercise
    const modifyExercise = useCallback(async (exerciseId, updateData) => {
        try {
            await updateExercise(exerciseId, updateData);
        } catch (error) {
            console.error('Error modifying exercise:', error);
            throw error;
        }
    }, []);

    // Load single exercise
    const loadExercise = useCallback((exerciseId) => {
        return fetchExerciseById(exerciseId, (exercise) => {
            setCurrentExercise(exercise);
        });
    }, []);

    const value = {
        exercises,
        currentExercise,
        loading,
        addExercise,
        modifyExercise,
        loadExercise,
        setCurrentExercise,
    };

    return (
        <ExerciseContext.Provider value={value}>
            {children}
        </ExerciseContext.Provider>
    );
}; 