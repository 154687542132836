import React, { useEffect } from 'react';
import { useWorkout } from '../contexts/WorkoutContext';

const ExerciseCard = ({ exercise, index }) => (
    <div className="bg-white rounded-lg shadow-md p-6 mb-4">
        <div className="flex justify-between items-center mb-4">
            <h3 className="text-xl font-semibold text-gray-800">
                {index + 1}. {exercise.title}
            </h3>
            <div className="text-sm font-medium text-gray-500">
                {exercise.sets} {exercise.sets > 1 ? 'sets' : 'set'}
            </div>
        </div>

        <div className="space-y-3">
            {/* Reps or Duration */}
            {exercise.repsPerSet && (
                <div className="text-gray-700">
                    <span className="font-medium">Reps: </span>
                    {exercise.repsPerSet}
                </div>
            )}
            {exercise.duration && (
                <div className="text-gray-700">
                    <span className="font-medium">Duration: </span>
                    {exercise.duration}
                </div>
            )}

            {/* Description */}
            <div className="text-gray-600">
                <p>{exercise.description}</p>
            </div>

            {/* Break Information */}
            <div className="mt-4 bg-gray-50 p-3 rounded-md">
                <div className="text-sm text-gray-600">
                    {exercise.breakDuration.betweenSets && (
                        <div>
                            <span className="font-medium">Rest between sets: </span>
                            {exercise.breakDuration.betweenSets}
                        </div>
                    )}
                    {exercise.breakDuration.afterExercise && (
                        <div>
                            <span className="font-medium">Rest after exercise: </span>
                            {exercise.breakDuration.afterExercise}
                        </div>
                    )}
                </div>
                {exercise.breakDescription && (
                    <div className="text-sm text-gray-500 mt-2">
                        <em>{exercise.breakDescription}</em>
                    </div>
                )}
            </div>
        </div>
    </div>
);

const WorkoutPage = () => {
    const {
        generateWorkout,
        currentWorkout,
        workoutHistory,
        loading: contextLoading,
        setCurrentWorkout
    } = useWorkout();
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState(null);

    // Load most recent workout when page loads
    useEffect(() => {
        if (!currentWorkout && workoutHistory.length > 0 && !contextLoading) {
            const mostRecentWorkout = workoutHistory[0]?.workout;
            if (mostRecentWorkout) {
                setCurrentWorkout(mostRecentWorkout);
            }
        }
    }, [workoutHistory, currentWorkout, contextLoading, setCurrentWorkout]);

    const handleGenerateWorkout = async () => {
        setLoading(true);
        setError(null);
        try {
            await generateWorkout();
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    if (contextLoading) {
        return (
            <div className="flex items-center justify-center min-h-screen">
                <div className="text-center">
                    <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-gray-900 mx-auto"></div>
                    <p className="mt-4 text-gray-600">Loading workout data...</p>
                </div>
            </div>
        );
    }

    if (loading) {
        return (
            <div className="flex items-center justify-center min-h-screen">
                <div className="text-center">
                    <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-gray-900 mx-auto"></div>
                    <p className="mt-4 text-gray-600">Generating your workout plan...</p>
                </div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="min-h-screen p-6">
                <div className="bg-red-50 border border-red-200 rounded-lg p-4 mb-6">
                    <p className="text-red-700">{error}</p>
                    <button
                        onClick={handleGenerateWorkout}
                        className="mt-4 text-red-600 hover:text-red-800 font-medium"
                    >
                        Try Again
                    </button>
                </div>
            </div>
        );
    }

    if (!currentWorkout) {
        return (
            <div className="min-h-screen p-6 flex flex-col items-center justify-center">
                <button
                    onClick={handleGenerateWorkout}
                    className="bg-blue-600 text-white px-6 py-3 rounded-lg hover:bg-blue-700 transition-colors"
                >
                    Generate Workout Plan
                </button>
            </div>
        );
    }

    return (
        <div className="min-h-screen bg-gray-50 p-6">
            <div className="max-w-4xl mx-auto mb-8">
                {/* Workout Header */}
                <div className="bg-white rounded-lg shadow-md p-6 mb-6">
                    <div className="flex justify-between items-center">
                        <div>
                            <h1 className="text-3xl font-bold text-gray-900 mb-2">
                                {currentWorkout.workoutName}
                            </h1>
                            <p className="text-gray-600">
                                <span className="font-medium">Total Time: </span>
                                {currentWorkout.totalTime}
                            </p>
                        </div>
                        <button
                            onClick={handleGenerateWorkout}
                            className="bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 transition-colors text-sm"
                        >
                            Generate New
                        </button>
                    </div>
                </div>

                {/* Exercises */}
                <div className="space-y-6">
                    {currentWorkout.exercises.map((exercise, index) => (
                        <ExerciseCard
                            key={index}
                            exercise={exercise}
                            index={index}
                        />
                    ))}
                </div>

                {/* Notes */}
                {currentWorkout.notes && currentWorkout.notes.length > 0 && (
                    <div className="bg-yellow-50 border border-yellow-100 rounded-lg p-6 mt-8">
                        <h3 className="text-lg font-semibold text-gray-800 mb-4">
                            Important Notes
                        </h3>
                        <ul className="list-disc list-inside space-y-2">
                            {currentWorkout.notes.map((note, index) => (
                                <li key={index} className="text-gray-700">
                                    {note}
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
            </div>
        </div>
    );
};

export default WorkoutPage; 