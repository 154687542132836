import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getFunctions } from "firebase/functions";
import { getAnalytics } from "firebase/analytics";
import devConfig from './config.dev';
import prodConfig from './config.prod';

const config = process.env.REACT_APP_ENV === 'prod' ? prodConfig : devConfig;
const database = process.env.REACT_APP_ENV === 'prod' ? 'mogged-mvp-prod' : 'mogged-mvp-dev';
// Initialize Firebase
const app = initializeApp(config);

// Initialize services
const auth = getAuth(app);
const firestore = getFirestore(app, database); // Using Firestore instead of Realtime Database
const storage = getStorage(app);
const functions = getFunctions(app, 'us-central1');
const analytics = getAnalytics(app);
// Export initialized services
export {
  app,
  auth,
  firestore,
  storage,
  functions,
  analytics
};
