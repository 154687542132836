import React from 'react';
import { AiOutlineCheck, AiOutlineClose } from 'react-icons/ai';
import ReactDOM from 'react-dom';

const GuidelinesModal = ({ isOpen, onClose }) => {

    if (!isOpen) return null;

    return ReactDOM.createPortal(
        <div className="fixed inset-0 z-50 flex items-center justify-center backdrop-blur-sm bg-black bg-opacity-75 md:pl-16 lg:pl-64">
            <div className="bg-white text-black w-11/12 max-w-md mx-auto overflow-hidden relative border border-black">
                {/* Close button - absolute positioned */}
                <button 
                    onClick={onClose}
                    className="absolute top-4 right-4 text-black"
                    aria-label="Close"
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                        <line x1="18" y1="6" x2="6" y2="18"></line>
                        <line x1="6" y1="6" x2="18" y2="18"></line>
                    </svg>
                </button>
                
                {/* Centered icon and title */}
                <div className="p-6 pt-12">
                    <div className="flex flex-col items-center text-center mb-6">
                        <div className="border border-black p-2 mb-4">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                <path d="M9 11l3 3L22 4"></path>
                                <path d="M21 12v7a2 2 0 01-2 2H5a2 2 0 01-2-2V5a2 2 0 012-2h11"></path>
                            </svg>
                        </div>
                        <h3 className="text-xl font-bold uppercase">Guidelines</h3>
                    </div>
                    
                    <div className="space-y-6">
                        <div>
                            <h4 className="font-bold mb-2">Do:</h4>
                            <ul className="space-y-2">
                                <li className="flex items-center">
                                    <AiOutlineCheck className="text-green-600 mr-2 flex-shrink-0" size={16} />
                                    <span>Fitted clothing</span>
                                </li>
                                <li className="flex items-center">
                                    <AiOutlineCheck className="text-green-600 mr-2 flex-shrink-0" size={16} />
                                    <span>Good lighting</span>
                                </li>
                                <li className="flex items-center">
                                    <AiOutlineCheck className="text-green-600 mr-2 flex-shrink-0" size={16} />
                                    <span>Neutral background</span>
                                </li>
                            </ul>
                        </div>
                        
                        <div>
                            <h4 className="font-bold mb-2">Don't:</h4>
                            <ul className="space-y-2">
                                <li className="flex items-center">
                                    <AiOutlineClose className="text-red-600 mr-2 flex-shrink-0" size={16} />
                                    <span>Loose clothing</span>
                                </li>
                                <li className="flex items-center">
                                    <AiOutlineClose className="text-red-600 mr-2 flex-shrink-0" size={16} />
                                    <span>Blurry photos</span>
                                </li>
                                <li className="flex items-center">
                                    <AiOutlineClose className="text-red-600 mr-2 flex-shrink-0" size={16} />
                                    <span>Photo filters</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    
                    <button
                        onClick={onClose}
                        className="w-full mt-8 bg-black hover:bg-gray-800 text-white font-bold py-2 px-4"
                    >
                        Got it
                    </button>
                </div>
            </div>
        </div>,
        document.body
    );
};

export default GuidelinesModal; 