import { httpsCallable } from "firebase/functions";
import { functions } from "./Init";

/**
 * Checks an image file for NSFW content using the Gemini AI model
 * 
 * @param {File} imageFile - The image file to check
 * @returns {Promise<Object>} - The NSFW check result
 */
export const checkImageForNSFW = async (imageFile) => {
  try {
    if (!imageFile) {
      throw new Error("No image file provided");
    }

    // Convert the file to a base64 string
    const buffer = await imageFile.arrayBuffer();
    const base64String = arrayBufferToBase64(buffer);

    // Call the Cloud Function
    const checkNSFWContent = httpsCallable(functions, 'checkNSFWContent');
    const result = await checkNSFWContent({
      imageBuffer: base64String,
      mimeType: imageFile.type
    });

    // Check the result
    console.log(result);
    const data = result.data;

    if (!data.success) {
      console.error("NSFW check failed:", data.error || "Unknown error");
      throw new Error("NSFW check failed");
    }

    if (data.verdict === "NSFW") {
      throw new Error(`This image contains inappropriate content: ${data.explanation}`);
    }

    return data;
  } catch (error) {
    console.error("Error checking image for NSFW content:", error);
    throw error;
  }
};

/**
 * Checks text content for inappropriate or hateful language
 * 
 * @param {string} text - The text content to check
 * @returns {Promise<boolean>} - Returns true if the text is safe, throws an error if inappropriate
 */
export const checkTextForInappropriateContent = async (text) => {
  try {
    if (!text || typeof text !== 'string') {
      return true; // Empty or non-string text is considered safe
    }

    // Convert to lowercase for case-insensitive matching
    const lowerText = text.toLowerCase();

    // --- Expanded Inappropriate Terms List (Categorized) ---

    const inappropriateTerms = [
      // 1. Hate Speech & Slurs (Racial, Ethnic, Religious, National Origin)
      'nigger', 'nigga', 'chink', 'spic', 'gook', 'kike', 'faggot', 'tranny', 'dyke',
      'wetback', 'cracker', 'honky', 'towelhead', 'sand nigger', 'raghead',
      'jungle bunny', 'porch monkey', 'goyim', 'goy', 'infidel', 'kafir',
      'slant-eye', 'zipperhead', 'camel jockey',

      // 2. Hate Speech (Gender, Sexual Orientation, Disability)
      'retard', 'retarded', 'fag', 'queer', 'homo', 'tranny',
      'shemale', 'cripple', 'gimp', 'sped', 'midget',

      // 3. Profanity & Strong Swear Words
      'motherfucker',
      'cunt', 'cock',
      'bastard',

      // 4. Threats & Violence
      'kill', 'murder', 'attack', 'rape', 'assault', 'beat', 'stab', 'shoot',
      'bomb', 'terrorize', 'destroy', 'harm', 'maim', 'torture', 'die', 'hang',

      // 5. Explicit Sexual Content (Beyond Profanity)
      // (This section needs careful consideration and might be better handled
      //  with a dedicated adult content filter or API)
      'porn', 'sex' /*context-dependent*/, 'blowjob', 'handjob', 'gangbang',
       'bdsm', 'rape', 'molest',

      // 6. Discriminatory Language & Dehumanization
      'vermin', 'cockroach', 'parasite',  // Used to dehumanize groups
      'subhuman',  // Implies inferiority

      // 7. Doxxing and Personal Attacks
      'dox', //revealing personal info
      //revealing private conversations
      'I know where you live', //Implied threat
      'I\'m going to find you', //Implied threat

      //8. Incitement to harm, suicide or violence
      'kill yourself',
      'jump off a bridge',
      'go die in a hole',
      'you should be shot',

    ];

    // --- Regex and Matching Improvements ---

    for (const term of inappropriateTerms) {
      // 1. Word Boundary Check (Improved):  \b matches word boundaries, BUT it has issues with
      //    non-ASCII characters and some punctuation.  A more robust (but slightly more complex)
      //    approach is to use lookarounds:
      const regex = new RegExp(`(?<!\\w)${term}(?!\\w)`, 'i'); // Case-insensitive (i flag)
      //   (?<!\w)  - Negative lookbehind:  Asserts that the match is NOT preceded by a word character (\w = [a-zA-Z0-9_])
      //   (?!\\w)  - Negative lookahead:  Asserts that the match is NOT followed by a word character.
      //   This handles cases like "my-asshole-friend" better, preventing a match on "asshole".

      if (regex.test(lowerText)) {
        throw new Error(`Your content contains inappropriate language that violates our community guidelines.`);
      }
    }

    // --- Contextual Analysis (Basic Example) ---
    //   The simple keyword check above is prone to false positives.  Some words are
    //   offensive in some contexts, but not others ("queer", "sex", "hell", etc.).
    //   This is a *very* simplified example of how you *could* start to add
    //   contextual analysis.  A real-world system would be MUCH more complex.

    const potentiallyContextualTerms = ['queer', 'sex', 'hell', 'damn', 'gay', 'lesbian'];
    for (const term of potentiallyContextualTerms) {
        const regex = new RegExp(`(?<!\\w)${term}(?!\\w)`, 'i');
        if (regex.test(lowerText)) {
            // Example: "Queer" is more likely to be offensive if used as a noun
            // targeting a person, less likely as an adjective describing something unusual.
            if (term === 'queer') {
                const match = lowerText.match(regex); //find where the term occurs
                if(match){
                    const index = match.index;
                  //Check for derogatory usage indicators.
                  const precedingWords = lowerText.substring(0, index).split(/\s+/).slice(-3); // Get the 3 words before
                  const followingWords = lowerText.substring(index + term.length).split(/\s+/).slice(0, 3);  // Get the 3 words after
                  const derogatoryIndicators = ['that', 'those', 'the', 'a', 'you', 'you\'re', 'is', 'are', 'stupid', 'dumb', 'idiot'];
                    if (precedingWords.some(w => derogatoryIndicators.includes(w)) || followingWords.some(w => derogatoryIndicators.includes(w)))
                    {
                      throw new Error(`Your content contains inappropriate language that violates our community guidelines.`);
                    }
                }
            }

            if (term === 'sex'){
                const match = lowerText.match(regex); //find where the term occurs
                if(match){
                    const index = match.index;
                    const precedingWords = lowerText.substring(0, index).split(/\s+/).slice(-3);
                    const followingWords = lowerText.substring(index + term.length).split(/\s+/).slice(0, 3);
                    const problematicIndicators = ['minor', 'child', 'teen', 'underage', 'rape', 'nonconsensual', 'force', 'forced'];
                    if(precedingWords.some(w => problematicIndicators.includes(w)) || followingWords.some(w => problematicIndicators.includes(w))){
                        throw new Error(`Your content contains inappropriate language that violates our community guidelines.`);
                    }
                }
            }
        }
    }
    // --- External API Integration (Recommended for Production) ---
    // For a real-world application, using a dedicated content moderation API is HIGHLY recommended.
    // This example uses a placeholder function name; replace with your actual API call.

    /*
    const moderationResult = await callContentModerationAPI(text);  // Replace with your API call
    if (moderationResult.isFlagged) {
        throw new Error(`Your content contains inappropriate language: ${moderationResult.reason}`);
    }
    */

    return true; // Text is considered safe
  } catch (error) {
    console.error("Error checking text for inappropriate content:", error);
    throw error;
  }
};

/**
 * Converts an ArrayBuffer to a base64 string
 * 
 * @param {ArrayBuffer} buffer - The array buffer to convert
 * @returns {string} - The base64 string
 */
function arrayBufferToBase64(buffer) {
  let binary = '';
  const bytes = new Uint8Array(buffer);
  const len = bytes.byteLength;

  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }

  return btoa(binary);
} 