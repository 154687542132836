import { firestore } from '../../firebase/Init';
import { 
  collection, 
  doc, 
  setDoc, 
  deleteDoc, 
  query,  
  getDocs,
  getDoc,
  updateDoc,
  increment,
  orderBy
} from 'firebase/firestore';

/**
 * Add a like to a post
 * @param {string} postId - The ID of the post
 * @param {string} userId - The ID of the user liking the post
 * @returns {Promise<void>}
 */
export const addLike = async (postId, userId) => {
  try {
    // Create a batch to perform multiple operations atomically
    const postRef = doc(firestore, `posts/${postId}`);
    const likeRef = doc(firestore, `posts/${postId}/likes/${userId}`);
    
    // First add the like document
    await setDoc(likeRef, {
      userId: userId,
      createdAt: Date.now()
    });
    
    // Then increment the likeCount on the post document
    await updateDoc(postRef, {
      likeCount: increment(1)
    });
    
    return true;
  } catch (error) {
    console.error("Error adding like: ", error);
    throw error;
  }
};

/**
 * Remove a like from a post
 * @param {string} postId - The ID of the post
 * @param {string} userId - The ID of the user who liked the post
 * @returns {Promise<void>}
 */
export const removeLike = async (postId, userId) => {
  try {
    const postRef = doc(firestore, `posts/${postId}`);
    const likeRef = doc(firestore, `posts/${postId}/likes/${userId}`);
    
    // First check if the like exists
    const likeDoc = await getDoc(likeRef);
    if (!likeDoc.exists()) {
      return true; // Already removed or never existed
    }
    
    // Delete the like document
    await deleteDoc(likeRef);
    
    // Decrement the likeCount on the post document
    await updateDoc(postRef, {
      likeCount: increment(-1)
    });
    
    return true;
  } catch (error) {
    console.error("Error removing like: ", error);
    throw error;
  }
};

/**
 * Toggle like status for a post
 * @param {string} postId - The ID of the post
 * @param {string} userId - The ID of the user
 * @returns {Promise<boolean>} - The new like state (true if liked, false if unliked)
 */
export const toggleLike = async (postId, userId) => {
  try {
    const isLiked = await hasUserLiked(postId, userId);
    
    if (isLiked) {
      await removeLike(postId, userId);
      return false;
    } else {
      await addLike(postId, userId);
      return true;
    }
  } catch (error) {
    console.error("Error toggling like: ", error);
    throw error;
  }
};

/**
 * Check if a user has liked a post
 * @param {string} postId - The ID of the post
 * @param {string} userId - The ID of the user
 * @returns {Promise<boolean>} - Whether the user has liked the post
 */
export const hasUserLiked = async (postId, userId) => {
  try {
    const likeRef = doc(firestore, `posts/${postId}/likes/${userId}`);
    const likeDoc = await getDoc(likeRef);
    return likeDoc.exists();
  } catch (error) {
    console.error("Error checking if user liked post: ", error);
    throw error;
  }
};

/**
 * Get the number of likes for a post
 * @param {string} postId - The ID of the post
 * @returns {Promise<number>} - The number of likes
 */
export const getLikeCount = async (postId) => {
  try {
    const postRef = doc(firestore, `posts/${postId}`);
    const postDoc = await getDoc(postRef);
    
    if (!postDoc.exists()) {
      return 0;
    }
    
    return postDoc.data().likeCount || 0;
  } catch (error) {
    console.error("Error getting like count: ", error);
    throw error;
  }
};

/**
 * Get all users who liked a post
 * @param {string} postId - The ID of the post
 * @returns {Promise<Array>} - Array of user IDs who liked the post
 */
export const getLikedUsers = async (postId) => {
  try {
    const likesRef = collection(firestore, `posts/${postId}/likes`);
    const q = query(likesRef, orderBy("createdAt", "desc"));
    const likesSnapshot = await getDocs(q);
    
    return likesSnapshot.docs.map(doc => ({
      userId: doc.data().userId,
      createdAt: doc.data().createdAt
    }));
  } catch (error) {
    console.error("Error getting liked users: ", error);
    throw error;
  }
}; 