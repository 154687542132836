import React, { useState, useEffect } from "react";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import { useUser } from "../contexts/UserContext";
import { doSignOut } from "../firebase/Auth";
import {
  AiOutlineHome,
  AiOutlineUser,
  AiOutlineLogout,
  AiOutlineSearch,
  AiOutlinePlusCircle,
} from "react-icons/ai";
import logoImage from "../assets/logo.png";
import logoCondensed from "../assets/logo-condensed.png";
import Joyride, { STATUS } from "react-joyride";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { firestore } from "../firebase/Init";

const Sidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { userLoggedIn, currentUser } = useAuth();
  const { userData } = useUser();
  const [hoveredItem, setHoveredItem] = useState(null);
  const [runTour, setRunTour] = useState(false);

  const steps = [
    {
      target: ".create-button",
      content: "Click here to create a new scan, exercise, or post!",
      placement: "right",
      disableBeacon: true,
    },
    {
      target: ".account-button",
      content:
        "View your profile, scans, and manage your account settings here.",
      placement: "right",
    },
  ];

  useEffect(() => {
    const checkFirstTimeUser = async () => {
      if (currentUser) {
        const userDocRef = doc(firestore, "users", currentUser.uid);
        const snapshot = await getDoc(userDocRef);
        if (snapshot.exists()) {
          const userData = snapshot.data();
          if (!userData.hasSeenIntro) {
            setRunTour(true);
          }
        }
      }
    };

    checkFirstTimeUser();
  }, [currentUser]);

  const handleHover = (label) => {
    setHoveredItem(null);
    if (window.matchMedia("(hover: hover)").matches) {
      setHoveredItem(label);
    }
  };

  const handleJoyrideCallback = async (data) => {
    const { status } = data;
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      setRunTour(false);
      if (currentUser) {
        const userDocRef = doc(firestore, "users", currentUser.uid);
        await updateDoc(userDocRef, {
          hasSeenIntro: true,
        });
      }
    }
  };

  const NavItem = ({
    to,
    icon,
    label,
    onClick,
    isActive,
    isNewScan,
    className,
  }) => {
    const handleClick = (e) => {
      if (label === "ACCOUNT" && userData?.username) {
        e.preventDefault();
        navigate(`/profile/${userData.username}`);
        return;
      }
      if (onClick) {
        e.preventDefault();
        onClick();
      }
      setHoveredItem(null);
    };

    const isSelected =
      isActive || (to === location.pathname && !isNewScan);

    return (
      <NavLink
        to={to}
        className={`flex items-center transition-colors duration-200 ${
          isSelected && !runTour
            ? "bg-gray-100 text-black"
            : "text-white"
        } ${
          !isSelected && hoveredItem === label
            ? "bg-gray-800 text-white"
            : ""
        } md:justify-start justify-center md:p-4 p-2 w-full ${className}`}
        onClick={handleClick}
        onMouseEnter={() => !isSelected && handleHover(label)}
        onMouseLeave={() => setHoveredItem(null)}
      >
        <div
          className={`flex items-center justify-center w-8 h-8 rounded-full transition-colors duration-200 ${
            isSelected && !runTour ? "font-bold" : ""
          }`}
        >
          {React.cloneElement(icon, {
            strokeWidth: isSelected && !runTour ? 2 : 1,
            className: isSelected && !runTour ? "text-black" : "text-white",
          })}
        </div>
        <span className={`hidden lg:block ml-3 ${isSelected && !runTour ? "font-bold" : ""}`}>
          {label}
        </span>
      </NavLink>
    );
  };

  return (
    <>
      <div
        className="sidebar bg-black text-white md:relative md:h-screen md:w-16 lg:w-64 
        grid md:grid-rows-[auto_1fr_auto] transition-all duration-300 font-sans
        fixed w-full bottom-0 h-12 row-start-2 md:row-start-1 md:col-start-1 z-50"
      >
        <div className="p-4 hidden md:flex items-center justify-center">
          <img
            src={logoImage}
            alt="MOGGED"
            className="hidden lg:block h-12 w-auto"
          />
          <img
            src={logoCondensed}
            alt="MOGGED"
            className="hidden md:block lg:hidden h-8 w-auto"
          />
        </div>
        <nav className="flex md:flex-col flex-grow justify-around md:mt-4">
          <NavItem to="/home" icon={<AiOutlineHome size={22} />} label="HOME" />
          <NavItem
            to="/discover"
            icon={<AiOutlineSearch size={22} />}
            label="DISCOVER"
          />
          <NavItem
            to="/create"
            icon={<AiOutlinePlusCircle size={22} />}
            label="CREATE"
            className="create-button"
          />
          <NavItem
            to={
              userData?.username ? `/profile/${userData.username}` : "/404"
            }
            icon={<AiOutlineUser size={22} />}
            label="ACCOUNT"
            className="account-button md:hidden"
          />
          <div className="hidden md:block mt-auto">
            <NavItem
              to={
                userData?.username ? `/profile/${userData.username}` : "/404"
              }
              icon={<AiOutlineUser size={24} />}
              label="ACCOUNT"
              className="account-button"
            />
            {userLoggedIn && (
              <button
                onClick={() =>
                  doSignOut().then(() => navigate("/login"))
                }
                className={`flex items-center p-4 w-full transition-colors duration-200 text-white ${
                  hoveredItem === "LOGOUT" ? "bg-gray-800" : ""
                }`}
                onMouseEnter={() => handleHover("LOGOUT")}
                onMouseLeave={() => handleHover(null)}
              >
                <div className="flex items-center justify-center w-8 h-8 rounded-full transition-colors duration-200">
                  <AiOutlineLogout size={24} />
                </div>
                <span className="ml-3 hidden lg:inline">LOGOUT</span>
              </button>
            )}
          </div>
        </nav>
      </div>

      <Joyride
        steps={steps}
        run={runTour}
        continuous
        locale={{
          last: "Done",
        }}
        styles={{
          options: {
            primaryColor: "#3B82F6",
            textColor: "#1F2937",
            backgroundColor: "#FFFFFF",
          },
        }}
        callback={handleJoyrideCallback}
      />
    </>
  );
};

export default Sidebar;
