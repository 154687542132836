import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import BasicInfoStep from './onboarding/BasicInfoStep';
import UsernameStep from './onboarding/UsernameStep';
import FitnessGoalsStep from './onboarding/FitnessGoalsStep';
import TrainingPreferencesStep from './onboarding/TrainingPreferencesStep';
import FinalizeStep from './onboarding/FinalizeStep';
import {  useOnboarding } from '../contexts/OnboardingContext';

const Onboarding = () => {
  const { state, dispatch } = useOnboarding();

  const handleChange = (input) => (value) => {
    dispatch({ type: 'SET_FORM_DATA', payload: { field: input, value } });
  };

  const nextStep = () => dispatch({ type: 'NEXT_STEP' });
  const prevStep = () => dispatch({ type: 'PREV_STEP' });

  const renderStep = () => {
    switch (state.currentStep) {
      case 1:
        return <UsernameStep nextStep={nextStep} handleChange={handleChange} formData={state.formData} />;
      case 2:
        return <BasicInfoStep nextStep={nextStep} prevStep={prevStep} handleChange={handleChange} formData={state.formData} />;
      case 3:
        return <FitnessGoalsStep nextStep={nextStep} prevStep={prevStep} handleChange={handleChange} formData={state.formData} />;
      case 4:
        return <TrainingPreferencesStep nextStep={nextStep} prevStep={prevStep} handleChange={handleChange} formData={state.formData} />;
      case 5:
        return <FinalizeStep prevStep={prevStep} handleChange={handleChange} formData={state.formData} />;
      default:
        return null;
    }
  };

  return (
      <div className="w-screen h-screen flex items-center justify-center bg-gray-100">
        <div className="w-full h-100dvh flex flex-col">
          <AnimatePresence mode="wait">
            <motion.div
              key={state.currentStep}
              initial="initial"
              animate="in"
              exit="out"
              variants={{
                initial: { opacity: 0, x: '-100%' },
                in: { opacity: 1, x: 0 },
                out: { opacity: 0, x: '100%' },
              }}
              transition={{ type: 'tween', ease: 'anticipate', duration: 0.5 }}
              className="flex-grow"
            >
              {renderStep()}
            </motion.div>
          </AnimatePresence>
        </div>
      </div>
  );
};

export default Onboarding;
