// Create.jsx - Fixed scrolling behavior
import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { useLocation } from 'react-router-dom';
// import { useAuth } from '../contexts/AuthContext';
// import { useNavigate } from 'react-router-dom';
import { useCreate } from '../contexts/CreateContext';
import { useHeader } from '../contexts/HeaderContext';

import NewScan from './create/NewScan';
import CreateExercise from './create/CreateExercise';
import NewRoutine from './create/NewRoutine';
import CreatePost from './create/CreatePost';

const Create = () => {
    const location = useLocation();
    // const navigate = useNavigate();
    // const { currentUser } = useAuth();
    const { createData, setCreateTab } = useCreate();
    const [activeTab, setActiveTab] = useState(createData.activeTab);
    const { updateHeader } = useHeader();

    const [mediaPreview, setMediaPreview] = useState(null);

    useEffect(() => {
        return () => {
            if (mediaPreview) URL.revokeObjectURL(mediaPreview);
        };
    }, [mediaPreview]);

    useEffect(() => {
        setMediaPreview(null);
    }, [activeTab]);

    useEffect(() => {
        updateHeader('CREATE');
        
        return () => updateHeader('');
    }, [updateHeader]);

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
        setCreateTab(newValue);
    };

    return (
        <div className="flex flex-col h-full">
            {/* Fixed Tab Navigation */}
            <div className="border-b border-gray-300 bg-white">
                <div className="w-[90%] mx-auto">
                    <div className="flex w-full">
                        {['Exercise', 'Post', 'Scan', 'Routine'].map((tab, index) => (
                            <button
                                key={tab}
                                onClick={(e) => handleTabChange(e, index)}
                                className={`px-4 py-2 font-bold transition-colors duration-200 relative flex-1 text-center ${
                                    activeTab === index
                                        ? 'text-black'
                                        : 'text-gray-600 hover:text-black'
                                }`}
                            >
                                <span>{tab.toUpperCase()}</span>
                                {activeTab === index && (
                                    <motion.div
                                        layoutId="tabUnderline"
                                        className="absolute bottom-0 left-0 right-0 h-0.5 bg-black"
                                    />
                                )}
                            </button>
                        ))}
                    </div>
                </div>
            </div>

            {/* Content Area - This is where scrolling should happen */}
            <div className="flex-1 overflow-y-auto">
                <div className="w-[90%] mx-auto py-4">
                    <div style={{ display: activeTab === 0 ? 'block' : 'none' }}>
                        <CreateExercise />
                    </div>
                    <div style={{ display: activeTab === 1 ? 'block' : 'none' }}>
                        <CreatePost exerciseData={location.state} />
                    </div>
                    <div style={{ display: activeTab === 2 ? 'block' : 'none' }}>
                        <NewScan />
                    </div>
                    <div style={{ display: activeTab === 3 ? 'block' : 'none' }}>
                        <NewRoutine />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Create;