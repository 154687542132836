import React from 'react';
import { useNavigate } from 'react-router-dom';

const ScoreBar = ({ label, score }) => (
  <div className="flex items-center mb-1 min-w-0">
    <span className="w-20 sm:w-20 md:w-24 text-[10px] sm:text-xs font-semibold truncate mr-1 sm:mr-2">{label}</span>
    <div className="flex-grow bg-gray-200  h-1.5 sm:h-2 min-w-[40px] sm:min-w-[50px]">
      <div
        className="bg-black  h-1.5 sm:h-2"
        style={{ width: `${score * 10}%` }}
      ></div>
    </div>
    <span className="ml-1 sm:ml-2 text-[10px] sm:text-xs font-semibold w-6 sm:w-8 text-right">{score.toFixed(1)}</span>
  </div>
);

const ScoreCard = ({ imageUrl, timestamp, scores, scanId }) => {
  const navigate = useNavigate();
  const date = new Date(timestamp).toLocaleDateString();
  const evaluationScores = scores.Evaluation;
  const overall = scores["Overall Score"];

  const handleClick = () => {
    if (scanId) {
      navigate(`/scan/${scanId}`);
    }
  };

  return (
    <div 
      className="p-2 sm:p-4 flex cursor-pointer hover:bg-gray-50 transition-colors duration-200"
      onClick={handleClick}
    >
      <div className="flex-shrink-0 flex flex-col items-center mr-2 sm:mr-4 relative">
        <div className="text-[8px] sm:text-xs font-semibold mb-1 sm:mb-2 absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 text-white p-0.5 sm:p-1  text-center">
          {date}
        </div>
        <div className="w-12 h-12 sm:w-16 sm:h-16 md:w-24 md:h-24 bg-gray-200  overflow-hidden">
          <img src={imageUrl} alt="Scan" className="w-full h-full object-cover" />
        </div>
      </div>
      <div className="flex-grow flex flex-col justify-center min-w-0">
        {Object.entries(evaluationScores).map(([label, data]) => (
          <ScoreBar key={label} label={label.toUpperCase()} score={data.Score} />
        ))}
      </div>
      <div className="flex flex-col items-center justify-center ml-2 sm:ml-4">
        <div className="w-12 h-12 sm:w-16 sm:h-16 rounded-full border-4 border-black flex items-center justify-center">
          <span className="text-lg sm:text-xl font-bold">{overall.toFixed(1)}</span>
        </div>
        <p className="mt-1 text-xs font-semibold">OVERALL</p>
      </div>
    </div>
  );
};

export default ScoreCard;
