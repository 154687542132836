import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDiscover } from '../contexts/DiscoverContext';
import PostPreview from '../components/PostPreview';
import ExercisePreview from '../components/ExercisePreview';
import { Box, Grid, Typography, TextField, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import RoutinePreview from '../components/routine/RoutinePreview';
import { motion } from 'framer-motion';
import { useHeader } from '../contexts/HeaderContext';
import LoadingProfile from '../components/LoadingProfile';
import { useUser } from '../contexts/UserContext';
import { useFollowing } from '../contexts/FollowingContext';
import { addFollowing, removeFollowing } from '../firebase/Following';
const { algoliasearch } = require("algoliasearch");

const appId = process.env.REACT_APP_ALGOLIA_APP_ID;
const searchKey = process.env.REACT_APP_ALGOLIA_SEARCH_KEY;

const searchClient = algoliasearch(appId, searchKey);

const INDICES =  {
    ROUTINES: "routines",
    EXERCISES: "exercises",
    USERS: "users",
    POSTS: "posts"
  };

const Discover = () => {
    const [tab, setTab] = useState(0);
    const [searchQuery, setSearchQuery] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [isSearching, setIsSearching] = useState(false);
    const navigate = useNavigate();
    const { updateHeader } = useHeader();
    const { getCurrentUserId } = useUser();
    const { isFollowing } = useFollowing();
    const [profilesWithFollowStatus, setProfilesWithFollowStatus] = useState([]);

    const { 
        // Posts
        currentPosts, 
        postsLoading, 
        loadFeedPosts,
        
        // Discover content
        publicProfiles,
        publicRoutines,
        publicExercises,
        profilesLoading,
        routinesLoading,
        exercisesLoading,
        loadPublicProfiles,
        loadPublicRoutines,
        loadPublicExercises
    } = useDiscover();

    // Load posts for feed
    useEffect(() => {
        if (tab === 0) {
            const unsubscribe = loadFeedPosts();
            return () => unsubscribe && unsubscribe();
        }
    }, [tab, loadFeedPosts]);

    // Load routines
    useEffect(() => {
        if (tab === 2) {
            loadPublicRoutines();
        }
    }, [tab, loadPublicRoutines]);

    // Load exercises
    useEffect(() => {
        if (tab === 1) {
            loadPublicExercises();
        }
    }, [tab, loadPublicExercises]);
    
    // Load profiles
    useEffect(() => {
        if (tab === 3) {
            loadPublicProfiles().then(profiles => {
                // Make sure each profile has an id property using the uid value
                const profilesWithId = profiles.map(profile => ({
                    ...profile,
                    id: profile.uid // Ensure id is always set from uid
                }));
                console.log('[Discover] Processed profiles with IDs:', 
                    profilesWithId.map(p => ({ username: p.username, id: p.id, uid: p.uid })));
            });
        }
    }, [tab, loadPublicProfiles]);

    // Add following status to profiles in Users tab
    useEffect(() => {
        const updateProfilesWithFollowStatus = async () => {
            if (tab !== 3) return;

            const currentUserId = getCurrentUserId();
            if (!currentUserId) {
                // If not logged in, use profiles as-is
                const displayProfiles = searchQuery.trim() ? searchResults : publicProfiles;
                
                // Ensure each profile has an id field
                const profilesWithId = displayProfiles.map(profile => ({
                    ...profile,
                    id: profile.uid || profile.id || profile.objectID // Prioritize uid, then fallback
                }));
                
                setProfilesWithFollowStatus(profilesWithId);
                return;
            }

            console.log('[Discover] Updating follow status for profiles');
            const profiles = searchQuery.trim() ? searchResults : publicProfiles;
            
            // Ensure profiles have IDs before checking following status
            const normalizedProfiles = profiles.map(profile => ({
                ...profile,
                id: profile.uid || profile.id || profile.objectID // Prioritize uid, then fallback
            }));
            
            try {
                const profilesWithStatus = await Promise.all(
                    normalizedProfiles.map(async (profile) => {
                        // Skip checking for current user's profile
                        if (profile.id === currentUserId) {
                            return { ...profile, isFollowing: false }; // Can't follow yourself
                        }
                        
                        // Ensure we're using the profile ID consistently
                        const profileId = profile.id;
                        
                        if (!profileId) {
                            console.error(`[Discover] Profile missing ID:`, profile);
                            return { ...profile, isFollowing: false };
                        }
                        
                        // Make sure we're passing the profileId correctly
                        console.log(`[Discover] Checking if ${currentUserId} follows ${profileId}`);
                        const following = await isFollowing(profileId);
                        console.log(`[Discover] User ${currentUserId} follows ${profileId}: ${following}`);
                        return { ...profile, isFollowing: following };
                    })
                );
                
                console.log('[Discover] Updated profiles with follow status:', 
                    profilesWithStatus.map(p => ({ 
                        username: p.username, 
                        id: p.id,
                        uid: p.uid, 
                        isFollowing: p.isFollowing 
                    }))
                );
                
                setProfilesWithFollowStatus(profilesWithStatus);
            } catch (error) {
                console.error('[Discover] Error updating follow status:', error);
                setProfilesWithFollowStatus(normalizedProfiles);
            }
        };

        updateProfilesWithFollowStatus();
    }, [tab, publicProfiles, searchResults, searchQuery, getCurrentUserId, isFollowing]);

    // Handle search with updated Algolia syntax
    useEffect(() => {
        const performSearch = async () => {
            if (!searchQuery.trim()) {
                setSearchResults([]);
                return;
            }

            setIsSearching(true);
            try {
                let indexName;
                switch (tab) {
                    case 0: indexName = INDICES.POSTS; break;
                    case 1: indexName = INDICES.EXERCISES; break;
                    case 2: indexName = INDICES.ROUTINES; break;
                    case 3: indexName = INDICES.USERS; break;
                    default: indexName = INDICES.POSTS;
                }

                // Using the new Algolia search syntax
                const response = await searchClient.search({
                    requests: [{ 
                        indexName: indexName, 
                        query: searchQuery,
                        hitsPerPage: 50 
                    }],
                });
                
                // Extract hits from the response
                const hits = response.results[0].hits;
                
                // Map the Algolia objectID to id for consistency
                const processedHits = hits.map(hit => ({
                    ...hit,
                    id: hit.objectID || hit.id
                }));
                
                setSearchResults(processedHits);
            } catch (error) {
                console.error('Error searching with Algolia:', error);
                setSearchResults([]);
            } finally {
                setIsSearching(false);
            }
        };

        const debounceTimer = setTimeout(() => {
            performSearch();
        }, 300);

        return () => clearTimeout(debounceTimer);
    }, [searchQuery, tab]);

    // Set the header title when component mounts
    useEffect(() => {
        updateHeader('DISCOVER');
        
        // Clean up when component unmounts
        return () => updateHeader('');
    }, [updateHeader]);

    const handleExerciseClick = (exerciseId) => {
        navigate(`/exercises/${exerciseId}`);
    };

    const handleTabChange = (newValue) => {
        setTab(newValue);
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    // Handle follow/unfollow user
    const handleFollowToggle = async (e, profileId) => {
        e.stopPropagation(); // Prevent navigation

        const currentUserId = getCurrentUserId();
        if (!currentUserId) {
            // Redirect to login if not logged in
            navigate("/login");
            return;
        }

        if (!profileId) {
            console.error('[Discover] Cannot toggle follow: profile ID is missing');
            return;
        }

        console.log(`[Discover] Toggling follow status for ${profileId}`);
        
        try {
            // Get current follow status
            const following = await isFollowing(profileId);
            console.log(`[Discover] Current follow status: ${following}`);
            
            if (following) {
                await removeFollowing(currentUserId, profileId);
            } else {
                await addFollowing(currentUserId, profileId);
            }

            // Update the UI to reflect the change immediately
            setProfilesWithFollowStatus(profiles => 
                profiles.map(profile => {
                    // Check multiple ID fields to ensure we find the right profile
                    if (profile.id === profileId) {
                        const newStatus = !profile.isFollowing;
                        console.log(`[Discover] Updated follow status for ${profile.username}: ${newStatus}`);
                        return { ...profile, isFollowing: newStatus };
                    }
                    return profile;
                })
            );
        } catch (error) {
            console.error("[Discover] Error toggling follow status:", error);
        }
    };

    // Determine which content to display based on search state
    const getContentToDisplay = () => {
        if (searchQuery.trim() && !isSearching) {
            return searchResults;
        }
        
        switch (tab) {
            case 0: return currentPosts;
            case 1: return publicExercises;
            case 2: return publicRoutines;
            case 3: return tab === 3 ? profilesWithFollowStatus : publicProfiles;
            default: return [];
        }
    };

    return (
        <div className="flex flex-col h-[100dvh] overflow-hidden ">
            {/* Tab Navigation - Sticky */}
            <div className="border-b border-gray-300 flex-shrink-0 bg-white sticky top-0 z-10">
                <div className="w-[90%] mx-auto">
                    <div className="flex w-full">
                        {['Posts', 'Exercises', 'Routines', 'Users'].map((tabName, index) => (
                            <button
                                key={tabName}
                                onClick={() => handleTabChange(index)}
                                className={`px-4 py-2 font-bold transition-colors duration-200 relative flex-1 text-center ${
                                    tab === index
                                        ? 'text-black'
                                        : 'text-gray-600 hover:text-black'
                                }`}
                            >
                                <span>{tabName.toUpperCase()}</span>
                                {tab === index && (
                                    <motion.div
                                        layoutId="tabUnderline"
                                        className="absolute bottom-0 left-0 right-0 h-0.5 bg-black"
                                    />
                                )}
                            </button>
                        ))}
                    </div>
                </div>
            </div>

            {/* Search Bar - Sticky */}
            <div className="bg-white py-3 px-4 border-b border-gray-300 sticky top-[41px] z-10">
                <TextField
                    fullWidth
                    placeholder={`Search ${['posts', 'exercises', 'routines', 'users'][tab]}...`}
                    value={searchQuery}
                    onChange={handleSearchChange}
                    variant="outlined"
                    size="small"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                />
            </div>

            {/* Content Area */}
            <main className="flex-1 overflow-y-auto">
                <div className="w-[90%] mx-auto pt-8 pb-24">
                    {/* Posts Tab */}
                    {tab === 0 && (
                        <div className="space-y-4">
                            {postsLoading || isSearching ? (
                                <Box sx={{ 
                                    display: 'flex', 
                                    justifyContent: 'center', 
                                    alignItems: 'center',
                                    minHeight: '200px',
                                    py: 4 
                                }}>
                                    <LoadingProfile size="lg" />
                                </Box>
                            ) : searchQuery.trim() && searchResults.length === 0 ? (
                                <Typography variant="body1" sx={{ textAlign: 'center', py: 4 }}>
                                    No posts found matching "{searchQuery}"
                                </Typography>
                            ) : (
                                <Grid container spacing={3}>
                                    {getContentToDisplay().map(post => (
                                        <Grid item xs={12} sm={6} md={4} key={post.id || post.objectID}>
                                            <PostPreview 
                                                key={post.id || post.objectID} 
                                                post={{
                                                    ...post,
                                                    id: post.id || post.objectID // Ensure id is always available
                                                }} 
                                            />
                                        </Grid>
                                    ))}
                                </Grid>
                            )}
                        </div>
                    )}

                    {/* Exercises Tab */}
                    {tab === 1 && (
                        <div className="space-y-4">
                            {exercisesLoading || isSearching ? (
                                <Box sx={{ 
                                    display: 'flex', 
                                    justifyContent: 'center', 
                                    alignItems: 'center',
                                    minHeight: '200px',
                                    py: 4 
                                }}>
                                    <LoadingProfile size="lg" />
                                </Box>
                            ) : searchQuery.trim() && searchResults.length === 0 ? (
                                <Typography variant="body1" sx={{ textAlign: 'center', py: 4 }}>
                                    No exercises found matching "{searchQuery}"
                                </Typography>
                            ) : (
                                <Grid container spacing={3}>
                                    {getContentToDisplay().map(exercise => (
                                        <Grid item xs={12} sm={6} md={4} key={exercise.id || exercise.objectID}>
                                            <ExercisePreview 
                                                exercise={exercise}
                                                onClick={() => handleExerciseClick(exercise.id || exercise.objectID)}
                                            />
                                        </Grid>
                                    ))}
                                </Grid>
                            )}
                        </div>
                    )}

                    {/* Routines Tab */}
                    {tab === 2 && (
                        <div className="space-y-4">
                            {routinesLoading || isSearching ? (
                                <Box sx={{ 
                                    display: 'flex', 
                                    justifyContent: 'center', 
                                    alignItems: 'center',
                                    minHeight: '200px',
                                    py: 4 
                                }}>
                                    <LoadingProfile size="lg" />
                                </Box>
                            ) : searchQuery.trim() && searchResults.length === 0 ? (
                                <Typography variant="body1" sx={{ textAlign: 'center', py: 4 }}>
                                    No routines found matching "{searchQuery}"
                                </Typography>
                            ) : (
                                <Grid container spacing={3}>
                                    {getContentToDisplay().map((routine) => (
                                        <Grid item xs={12} sm={6} md={4} key={routine.id || routine.objectID}>
                                            <RoutinePreview routine={routine} />
                                        </Grid>
                                    ))}
                                </Grid>
                            )}
                        </div>
                    )}

                    {/* Users Tab */}
                    {tab === 3 && (
                        <>
                            {profilesLoading || isSearching ? (
                                <Box sx={{ 
                                    display: 'flex', 
                                    justifyContent: 'center', 
                                    alignItems: 'center',
                                    minHeight: '200px',
                                    py: 4 
                                }}>
                                    <LoadingProfile size="lg" />
                                </Box>
                            ) : searchQuery.trim() && searchResults.length === 0 ? (
                                <Typography variant="body1" sx={{ textAlign: 'center', py: 4 }}>
                                    No users found matching "{searchQuery}"
                                </Typography>
                            ) : (
                                <Box sx={{ width: '100%' }}>
                                    {getContentToDisplay().map(profile => {
                                        // Ensure profile has an ID (debugging line)
                                        if (!profile.id) {
                                            console.error('[Discover] Profile missing ID in render:', profile);
                                        }
                                        
                                        return (
                                            <div key={profile.id || `user-${profile.username}`} className="mb-4">
                                                <div className="flex items-center p-2 border border-gray-200 rounded-md">
                                                    <div 
                                                        className="flex items-center flex-grow cursor-pointer" 
                                                        onClick={() => navigate(`/profile/${profile.username}`)}
                                                    >
                                                        <div className="w-14 h-14 rounded-full overflow-hidden bg-gray-200 mr-4">
                                                            <img 
                                                                src={profile.photoURL || "/images/default-profile.png"} 
                                                                alt={profile.username || "User"} 
                                                                className="w-full h-full object-cover"
                                                            />
                                                        </div>
                                                        <div>
                                                            <div className="font-bold">@{profile.username || "User"}</div>
                                                            <div className="text-sm text-gray-600">{profile.bio || "No bio available"}</div>
                                                        </div>
                                                    </div>
                                                    
                                                    {getCurrentUserId() && 
                                                     (profile.id !== getCurrentUserId()) && (
                                                        <button
                                                            onClick={(e) => handleFollowToggle(e, profile.id)}
                                                            className={`px-3 py-1 text-sm border rounded-md hover:bg-gray-100 ${
                                                                profile.isFollowing 
                                                                    ? 'border-gray-300 text-gray-600' 
                                                                    : 'border-black text-black'
                                                            }`}
                                                        >
                                                            {profile.isFollowing ? 'Unfollow' : 'Follow'}
                                                        </button>
                                                    )}
                                                </div>
                                            </div>
                                        );
                                    })}
                                </Box>
                            )}
                        </>
                    )}
                </div>
            </main>
        </div>
    );
};

export default Discover; 