import React, { createContext, useContext, useReducer } from 'react';
import { doSignOut } from '../firebase/Auth'; // Import the direct doSignOut function
import { useNavigate } from 'react-router-dom';

// Define your initial state
const initialState = {
  currentStep: 1,
  isOnboardingInProgress: false,
  formData: {
    username: '',
    birthday: null,
    heightFeet: '',
    heightInches: '',
    weight: '',
    fitnessGoal: {},
    fitnessGoalTags: [],
    trainingStyle: {},
    trainingStyleTags: [],
    agreementRead: false,
    isOver18: false,
    onboardingComplete: false,
    skippedFitnessGoals: false,
    skippedTrainingPreferences: false,
  },
};

// Create your context
const OnboardingContext = createContext();

// Create a reducer for handling state updates
function onboardingReducer(state, action) {
  switch (action.type) {
    case 'SET_FORM_DATA':
      return {
        ...state,
        formData: { ...state.formData, [action.payload.field]: action.payload.value },
      };
    case 'START_ONBOARDING':
      console.log('START_ONBOARDING');
      return {
        ...state,
        isOnboardingInProgress: true,
      };
    case 'SET_ONBOARDING_COMPLETE':
      return {
        ...state,
        isOnboardingInProgress: false,
        formData: { ...state.formData, onboardingComplete: true },
      };
    case 'NEXT_STEP':
      return { ...state, currentStep: state.currentStep + 1 };
    case 'PREV_STEP':
      return { ...state, currentStep: state.currentStep - 1 };
    case 'QUIT_ONBOARDING':
      return { ...initialState }; // Reset to initial state
    case 'SET_SKIPPED_STEP': 
      return {
        ...state,
        formData: { 
          ...state.formData, 
          [action.payload.step]: action.payload.value 
        },
      };
    default:
      return state;
  }
}

// Create the provider component
export const OnboardingProvider = ({ children }) => {
  const [state, dispatch] = useReducer(onboardingReducer, initialState);
  const navigate = useNavigate(); // Get the navigate function
  
  // Function to quit onboarding
  const quitOnboarding = () => {
    dispatch({ type: 'QUIT_ONBOARDING' });
    // Use the direct doSignOut function with navigation
    doSignOut().then(() => navigate("/login"));
  };
  
  return (
    <OnboardingContext.Provider value={{ state, dispatch, quitOnboarding }}>
      {children}
    </OnboardingContext.Provider>
  );
};

// Custom hook for easier consumption
export const useOnboarding = () => {
  const context = useContext(OnboardingContext);
  if (!context) {
    throw new Error('useOnboarding must be used within an OnboardingProvider');
  }
  return context;
}; 