import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { useAuth } from './AuthContext';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { 
    fetchUserData, 
    updateUserData, 
    updatePublicUserData,
    updateUserPrivacySettings,
    containsPublicFields,
    initializeAssistants,
    fetchUserByUsername
} from '../firebase/User';

const UserContext = createContext();

export const useUser = () => useContext(UserContext);

export const UserProvider = ({ children }) => {
    const [userData, setUserData] = useState(null);
    const [loading, setLoading] = useState(true);
    const { currentUser } = useAuth();
    const functions = getFunctions();

    // Initialize or get assistants
    const initializeUserThreads = useCallback(async () => {
        if (!currentUser || !userData) return;

        // Check if threads already exist
        if (userData.assistants?.planningAssistant?.threadId &&
            userData.assistants?.scanningAssistant?.threadId) {
            return; // Threads already exist, no need to create new ones
        }

        try {
            // Use the existing skipped flags from userData
            const skippedFitnessGoals = userData.skippedFitnessGoals === true;
            const skippedTrainingPreferences = userData.skippedTrainingPreferences === true;

            // If both fitness goals and training preferences were skipped, don't create threads
            if (skippedFitnessGoals && skippedTrainingPreferences) {
                console.log('Skipping thread creation - both fitness goals and training preferences were skipped during onboarding');
                return;
            }

            console.log('Creating threads with:', {
                fitnessGoalsSkipped: skippedFitnessGoals,
                trainingPreferencesSkipped: skippedTrainingPreferences
            });

            const createCustomizedThreads = httpsCallable(functions, 'createCustomizedThreads', {mode: 'no-cors'});
            
            // Pass the information about skipped sections along with the user data
            const { data } = await createCustomizedThreads({
                fitnessGoal: userData?.fitnessGoal,
                trainingStyle: userData?.trainingStyle
            });

            const assistants = {
                planningAssistant: {
                    threadId: data.planningAssistant.threadId
                },
                scanningAssistant: {
                    threadId: data.scanningAssistant.threadId
                }
            };

            await initializeAssistants(currentUser.uid, assistants);
        } catch (error) {
            console.error('Error initializing assistants:', error);
        }
    }, [currentUser, functions, userData]);

    // Fetch user data
    const loadUserData = useCallback(() => {
        if (!currentUser) {
            setUserData(null);
            setLoading(false);
            return null;
        }

        return fetchUserData(currentUser.uid, (data) => {
            setUserData(data);
            setLoading(false);
        });
    }, [currentUser]);

    // Initialize data when user changes
    useEffect(() => {
        setLoading(true);
        const unsubscribeUserData = loadUserData();

        return () => {
            if (unsubscribeUserData) unsubscribeUserData();
        };
    }, [loadUserData]);

    // Separate useEffect for initializing threads
    useEffect(() => {
        if (!loading && userData) {
            initializeUserThreads();
        }
    }, [loading, userData, initializeUserThreads]);

    // Update user data with proper handling of public/private fields
    const handleUpdateUserData = useCallback(async (updates) => {
        if (!currentUser) return;

        try {
            if (containsPublicFields(updates)) {
                // Handle updates that include public fields
                await updatePublicUserData(currentUser.uid, updates);
            } else {
                // Handle private-only updates
                await updateUserData(currentUser.uid, updates);
            }
        } catch (error) {
            console.error('Error updating user data:', error);
            throw error;
        }
    }, [currentUser]);

    // New function to handle privacy settings
    const handlePrivacySettings = useCallback(async (isPrivate) => {
        if (!currentUser) return;
        
        try {
            await updateUserPrivacySettings(currentUser.uid, isPrivate);
        } catch (error) {
            console.error('Error updating privacy settings:', error);
            throw error;
        }
    }, [currentUser]);

    // Add this new function
    const getUserByUsername = useCallback(async (username) => {
        try {
            const user = await fetchUserByUsername(username);
            return user;
        } catch (error) {
            console.error('Error fetching user by username:', error);
            return null;
        }
    }, []);

    // Add this function to the UserContext
    const getCurrentUserId = useCallback(() => {
        return currentUser?.uid;
    }, [currentUser]);

    const value = {
        userData,
        loading,
        updateUserData: handleUpdateUserData,
        updatePrivacySettings: handlePrivacySettings,
        getUserByUsername,
        getCurrentUserId, // Add this to the context value
    };

    return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};
