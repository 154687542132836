import React, { createContext, useContext, useState, useCallback } from 'react';
import { useAuth } from './AuthContext';
import {
    createPost,
    deletePost,
    fetchPostsByExercise,
    fetchAllPosts
} from '../firebase/Posts';
// Import interaction functions
import { addLike, removeLike, hasUserLiked, getLikeCount } from '../firebase/interactions/Likes';
import { 
    addComment as createNewComment, 
    deleteComment as removeExistingComment,
    getComments,
    getCommentCount
} from '../firebase/interactions/Comments';
import { fetchExercises } from '../firebase/Exercises';
import { getPublicRoutines } from '../firebase/Routines';
import { listPublicProfiles } from '../firebase/PublicProfile';

const DiscoverContext = createContext();

export const useDiscover = () => {
    const context = useContext(DiscoverContext);
    if (!context) {
        throw new Error('useDiscover must be used within a DiscoverProvider');
    }
    return context;
};

export const DiscoverProvider = ({ children }) => {
    // Posts state
    const [exercisePosts, setExercisePosts] = useState({});
    const [feedPosts, setFeedPosts] = useState([]);
    const [currentPosts, setCurrentPosts] = useState([]); // Current active posts
    
    // Discover content state
    const [publicProfiles, setPublicProfiles] = useState([]);
    const [publicRoutines, setPublicRoutines] = useState([]);
    const [publicExercises, setPublicExercises] = useState([]);
    
    // Loading states
    const [postsLoading, setPostsLoading] = useState(false);
    const [profilesLoading, setProfilesLoading] = useState(false);
    const [routinesLoading, setRoutinesLoading] = useState(false);
    const [exercisesLoading, setExercisesLoading] = useState(false);
    
    const { currentUser } = useAuth();

    // Load posts for an exercise
    const loadExercisePosts = useCallback((exerciseId) => {
        setPostsLoading(true);
        console.log(`[DiscoverContext] Loading posts for exercise: ${exerciseId}`);
        return fetchPostsByExercise(exerciseId, (postsData) => {
            console.log(`[DiscoverContext] Received ${postsData.length} posts for exercise ${exerciseId}`);
            setExercisePosts(prev => ({...prev, [exerciseId]: postsData}));
            setCurrentPosts(postsData); // Set as current active posts
            setPostsLoading(false);
        });
    }, []);

    // Load all posts for the feed
    const loadFeedPosts = useCallback(() => {
        setPostsLoading(true);
        console.log('[DiscoverContext] Loading all posts for feed');
        return fetchAllPosts((postsData) => {
            console.log(`[DiscoverContext] Received ${postsData.length} posts for feed`);
            setFeedPosts(postsData);
            setCurrentPosts(postsData); // Set as current active posts
            setPostsLoading(false);
        });
    }, []);

    // Load public profiles for discovery
    const loadPublicProfiles = useCallback(async () => {
        setProfilesLoading(true);
        try {
            console.log('[DiscoverContext] Loading public profiles');
            const profiles = await listPublicProfiles();
            console.log(`[DiscoverContext] Loaded ${profiles.length} public profiles`);
            setPublicProfiles(profiles);
            return profiles;
        } catch (error) {
            console.error('[DiscoverContext] Error loading public profiles:', error);
            return [];
        } finally {
            setProfilesLoading(false);
        }
    }, []);

    // Load public routines for discovery
    const loadPublicRoutines = useCallback(async () => {
        setRoutinesLoading(true);
        try {
            console.log('[DiscoverContext] Loading public routines');
            const routines = await getPublicRoutines();
            console.log(`[DiscoverContext] Loaded ${routines.length} public routines`);
            setPublicRoutines(routines);
            return routines;
        } catch (error) {
            console.error('[DiscoverContext] Error loading public routines:', error);
            return [];
        } finally {
            setRoutinesLoading(false);
        }
    }, []);

    // Load public exercises for discovery
    const loadPublicExercises = useCallback(async () => {
        setExercisesLoading(true);
        try {
            console.log('[DiscoverContext] Loading public exercises');
            const exercises = await fetchExercises();
            console.log(`[DiscoverContext] Loaded ${exercises.length} public exercises`);
            setPublicExercises(exercises);
            return exercises;
        } catch (error) {
            console.error('[DiscoverContext] Error loading public exercises:', error);
            return [];
        } finally {
            setExercisesLoading(false);
        }
    }, []);

    // Create new post with media
    const addPost = useCallback(async (postData, mediaFile) => {
        if (!currentUser) {
            throw new Error('Must be logged in to create a post');
        }
        try {
            const postId = await createPost(currentUser.uid, postData, mediaFile);
            return postId;
        } catch (error) {
            console.error("[DiscoverContext] Error in addPost:", error);
            throw error;
        }
    }, [currentUser]);

    // Delete post
    const removePost = useCallback(async (postId) => {
        try {
            await deletePost(postId);
        } catch (error) {
            console.error('[DiscoverContext] Error removing post:', error);
            throw error;
        }
    }, []);

    // Handle like/unlike
    const handleLike = useCallback(async (postId) => {
        if (!currentUser) {
            throw new Error('Must be logged in to like posts');
        }
        try {
            // Check if user already liked the post
            const alreadyLiked = await hasUserLiked(postId, currentUser.uid);
            
            if (alreadyLiked) {
                await removeLike(postId, currentUser.uid);
            } else {
                await addLike(postId, currentUser.uid);
            }
            
            // Update the post in the local state with new like count
            const likeCount = await getLikeCount(postId);
            setCurrentPosts(prevPosts => 
                prevPosts.map(post => 
                    post.id === postId 
                        ? { 
                            ...post, 
                            likeCount,
                            isLikedByCurrentUser: !alreadyLiked
                          } 
                        : post
                )
            );
            
            return !alreadyLiked; // Return the new like state
        } catch (error) {
            console.error('[DiscoverContext] Error toggling like:', error);
            throw error;
        }
    }, [currentUser]);

    // Check if a post is liked by current user
    const checkPostLiked = useCallback(async (postId) => {
        if (!currentUser) return false;
        try {
            return await hasUserLiked(postId, currentUser.uid);
        } catch (error) {
            console.error('[DiscoverContext] Error checking if post is liked:', error);
            return false;
        }
    }, [currentUser]);

    // Add comment
    const createComment = useCallback(async (postId, text) => {
        if (!currentUser) {
            throw new Error('Must be logged in to comment');
        }
        try {
            // We need the username for the comment
            const username = currentUser.displayName || 'Anonymous';
            const commentId = await createNewComment(postId, currentUser.uid, username, text);
            
            // Update the post in the local state with new comment count
            const commentCount = await getCommentCount(postId);
            setCurrentPosts(prevPosts => 
                prevPosts.map(post => 
                    post.id === postId 
                        ? { ...post, commentCount } 
                        : post
                )
            );
            
            return commentId;
        } catch (error) {
            console.error('[DiscoverContext] Error adding comment:', error);
            throw error;
        }
    }, [currentUser]);

    // Remove comment
    const removeComment = useCallback(async (postId, commentId) => {
        try {
            await removeExistingComment(postId, commentId);
            
            // Update the post in the local state with new comment count
            const commentCount = await getCommentCount(postId);
            setCurrentPosts(prevPosts => 
                prevPosts.map(post => 
                    post.id === postId 
                        ? { ...post, commentCount } 
                        : post
                )
            );
        } catch (error) {
            console.error('[DiscoverContext] Error removing comment:', error);
            throw error;
        }
    }, []);

    // Get comments for a post
    const getPostComments = useCallback(async (postId) => {
        try {
            return await getComments(postId);
        } catch (error) {
            console.error('[DiscoverContext] Error getting comments:', error);
            return [];
        }
    }, []);

    const value = {
        // Posts
        currentPosts,
        exercisePosts,
        feedPosts,
        postsLoading,
        loadExercisePosts,
        loadFeedPosts,
        
        // Discover content
        publicProfiles,
        publicRoutines,
        publicExercises,
        profilesLoading,
        routinesLoading,
        exercisesLoading,
        loadPublicProfiles,
        loadPublicRoutines,
        loadPublicExercises,
        
        // Post actions
        addPost,
        removePost,
        handleLike,
        checkPostLiked,
        createComment,
        removeComment,
        getPostComments,
        isLiked: useCallback(async (postId) => {
            if (!currentUser) return false;
            return await checkPostLiked(postId);
        }, [checkPostLiked, currentUser])
    };

    return (
        <DiscoverContext.Provider value={value}>
            {children}
        </DiscoverContext.Provider>
    );
}; 