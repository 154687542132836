const firebaseConfig = {
  apiKey: "AIzaSyASG0A6vUBRedZvACmCJnt149b9Pv0Os3k",
  authDomain: "mogged-mvp.firebaseapp.com",
  databaseURL: "https://mogged-mvp-default-rtdb.firebaseio.com",
  projectId: "mogged-mvp",
  storageBucket: "mogged-mvp.appspot.com",
  messagingSenderId: "903933903936",
  appId: "1:903933903936:web:07b957bddfd899d5f5cba5",
  measurementId: "G-0D1TD08TRC"
};
export default firebaseConfig;
