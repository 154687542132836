import { firestore } from '../../firebase/Init';
import { 
  collection, 
  doc, 
  addDoc, 
  updateDoc, 
  deleteDoc, 
  query, 
  where, 
  getDocs,
  getDoc,
  increment,
  orderBy,
  limit
} from 'firebase/firestore';

/**
 * Add a comment to a post
 * @param {string} postId - The ID of the post
 * @param {string} userId - The ID of the user commenting
 * @param {string} username - The username of the commenter
 * @param {string} comment - The comment text
 * @returns {Promise<string>} - The ID of the new comment
 */
export const addComment = async (postId, userId, username, comment) => {
  try {
    const postRef = doc(firestore, `posts/${postId}`);
    const commentsRef = collection(firestore, `posts/${postId}/comments`);
    
    // Create the comment document
    const newComment = {
      userId,
      username,
      comment,
      createdAt: Date.now()
    };
    
    const docRef = await addDoc(commentsRef, newComment);
    
    // Increment the commentCount on the post document
    await updateDoc(postRef, {
      commentCount: increment(1)
    });
    
    return docRef.id;
  } catch (error) {
    console.error("Error adding comment: ", error);
    throw error;
  }
};

/**
 * Update a comment
 * @param {string} postId - The ID of the post
 * @param {string} commentId - The ID of the comment
 * @param {string} newComment - The updated comment text
 * @returns {Promise<void>}
 */
export const updateComment = async (postId, commentId, newComment) => {
  try {
    const commentRef = doc(firestore, `posts/${postId}/comments/${commentId}`);
    await updateDoc(commentRef, {
      comment: newComment,
      // Optional: Add an "edited" flag or timestamp
      editedAt: Date.now()
    });
    return true;
  } catch (error) {
    console.error("Error updating comment: ", error);
    throw error;
  }
};

/**
 * Delete a comment
 * @param {string} postId - The ID of the post
 * @param {string} commentId - The ID of the comment
 * @returns {Promise<void>}
 */
export const deleteComment = async (postId, commentId) => {
  try {
    const postRef = doc(firestore, `posts/${postId}`);
    const commentRef = doc(firestore, `posts/${postId}/comments/${commentId}`);
    
    // First check if the comment exists
    const commentDoc = await getDoc(commentRef);
    if (!commentDoc.exists()) {
      return true; // Already deleted or never existed
    }
    
    // Delete the comment
    await deleteDoc(commentRef);
    
    // Decrement the commentCount on the post document
    await updateDoc(postRef, {
      commentCount: increment(-1)
    });
    
    return true;
  } catch (error) {
    console.error("Error deleting comment: ", error);
    throw error;
  }
};

/**
 * Get all comments for a post
 * @param {string} postId - The ID of the post
 * @returns {Promise<Array>} - Array of comments
 */
export const getComments = async (postId) => {
  try {
    const commentsRef = collection(firestore, `posts/${postId}/comments`);
    const q = query(commentsRef, orderBy("createdAt", "desc"));
    const commentsSnapshot = await getDocs(q);
    
    return commentsSnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
  } catch (error) {
    console.error("Error getting comments: ", error);
    throw error;
  }
};

/**
 * Get a specific number of comments for a post
 * @param {string} postId - The ID of the post
 * @param {number} count - The number of comments to retrieve
 * @returns {Promise<Array>} - Array of comments
 */
export const getRecentComments = async (postId, count = 5) => {
  try {
    const commentsRef = collection(firestore, `posts/${postId}/comments`);
    const q = query(commentsRef, orderBy("createdAt", "desc"), limit(count));
    const commentsSnapshot = await getDocs(q);
    
    return commentsSnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
  } catch (error) {
    console.error("Error getting recent comments: ", error);
    throw error;
  }
};

/**
 * Get the number of comments for a post
 * @param {string} postId - The ID of the post
 * @returns {Promise<number>} - The number of comments
 */
export const getCommentCount = async (postId) => {
  try {
    const postRef = doc(firestore, `posts/${postId}`);
    const postDoc = await getDoc(postRef);
    
    if (!postDoc.exists()) {
      return 0;
    }
    
    return postDoc.data().commentCount || 0;
  } catch (error) {
    console.error("Error getting comment count: ", error);
    throw error;
  }
};

/**
 * Get comments by a specific user
 * @param {string} postId - The ID of the post
 * @param {string} userId - The ID of the user
 * @returns {Promise<Array>} - Array of comments by the user
 */
export const getUserComments = async (postId, userId) => {
  try {
    const commentsRef = collection(firestore, `posts/${postId}/comments`);
    const q = query(commentsRef, where("userId", "==", userId), orderBy("createdAt", "desc"));
    const commentsSnapshot = await getDocs(q);
    
    return commentsSnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
  } catch (error) {
    console.error("Error getting user comments: ", error);
    throw error;
  }
}; 