import { auth, firestore } from "./Init";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  sendEmailVerification,
  updatePassword,
  signInWithPopup,
  GoogleAuthProvider,
  deleteUser,
  reauthenticateWithCredential,
  EmailAuthProvider,
} from "firebase/auth";
import { doc, getDoc, updateDoc, deleteDoc } from "firebase/firestore";
import { createUserDocument, updateUserLastSignIn } from "./User";

// Create user with email/password
export const doCreateUserWithEmailAndPassword = async (email, password) => {
  try {
    // Step 1: Create Firebase Auth user
    let userCredential;
    try {
      userCredential = await createUserWithEmailAndPassword(auth, email, password);
    } catch (error) {
      console.error("Error creating Firebase Auth user:", error);
      throw error;
    }

    const user = userCredential.user;

    // Step 2: Create initial user document (without username)
    try {
      await createUserDocument(user, {
        uid: user.uid,
        email: user.email,
        createdAt: new Date().toISOString(),
        hasSeenIntro: false,
        onboardingComplete: false,
        photoURL: user.photoURL || "",
        bio: "",
      });
    } catch (error) {
      console.error("Error creating user document:", error);
      // If document creation fails, clean up the auth user
      await user.delete();
      throw error;
    }

    return userCredential;
  } catch (error) {
    console.error("Full error in doCreateUserWithEmailAndPassword:", {
      code: error.code,
      message: error.message,
      stack: error.stack,
      name: error.name,
      fullError: error,
    });
    throw error;
  }
};

// Sign in with email/password
export const doSignInWithEmailAndPassword = async (email, password) => {
  try {
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    const user = userCredential.user;

    await updateUserLastSignIn(user.uid);
    return user;
  } catch (error) {
    console.error("Error in signInWithEmailAndPassword: ", error);
    throw error;
  }
};

// Sign in with Google
export const doSignInWithGoogle = async () => {
  try {
    const provider = new GoogleAuthProvider();
    const result = await signInWithPopup(auth, provider);
    const user = result.user;

    const userRef = doc(firestore, "users", user.uid);
    const snapshot = await getDoc(userRef);

    if (!snapshot.exists()) {
      // For new users, create both user document and profile
      await createUserDocument(user, {
        uid: user.uid,
        email: user.email,
        displayName: user.displayName,
        photoURL: user.photoURL,
        emailVerified: user.emailVerified,
        createdAt: new Date().toISOString(),
        onboardingComplete: false,
        hasSeenIntro: false,
        bio: "",
      });
    } else {
      // For existing users, just update the last sign in
      await updateDoc(userRef, {
        lastSignInTime: new Date().toISOString(),
      });
    }

    await user.getIdToken(true);
    await updateUserLastSignIn(user.uid);
    return { user };
  } catch (error) {
    console.error("Error during Google sign-in:", error);
    throw error;
  }
};

// Basic auth operations
export const doSignOut = () => auth.signOut();
export const doPasswordReset = (email) => sendPasswordResetEmail(auth, email);
export const doPasswordChange = (password) => updatePassword(auth.currentUser, password);
export const doSendEmailVerification = () => {
  return sendEmailVerification(auth.currentUser, {
    url: `${window.location.origin}/home`,
  });
};

// Delete user account
export const doDeleteAccount = async (password = null) => {
  const user = auth.currentUser;
  if (!user) throw new Error("No user is currently signed in.");

  try {
    const isEmailUser = user.providerData.some(
      (provider) => provider.providerId === "password"
    );

    if (isEmailUser && password) {
      const credential = EmailAuthProvider.credential(user.email, password);
      await reauthenticateWithCredential(user, credential);
    }

    await deleteDoc(doc(firestore, "users", user.uid));
    await deleteUser(user);
  } catch (error) {
    console.error("Error deleting account:", error);
    throw error;
  }
};
