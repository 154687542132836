import {
    collection,
    addDoc,
    doc,
    getDoc,
    setDoc,
    query,
    where,
    getDocs
  } from "firebase/firestore";
  import { firestore, storage } from "./Init";
  import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
  
  export const addRoutine = async (routineData, thumbnailFile) => {
    if (!thumbnailFile) {
      throw new Error("Thumbnail image is required");
    }

    try {
      const timestamp = Date.now();
      
      // First create the routine document to get the ID
      const routinesCollectionRef = collection(firestore, "routines");
      const newRoutineDoc = await addDoc(routinesCollectionRef, {
        // Add a temporary placeholder for the thumbnail URL
        thumbnailUrl: "pending",
        // Other routine data
        ...routineData,
        title: routineData.title.toUpperCase(),
        tags: routineData.tags || [],
        sections: routineData.sections || [],
        userId: routineData.userId,
        isPublic: routineData.isPublic || false,
        createdAt: timestamp,
        updatedAt: timestamp,
      });
      
      const routineId = newRoutineDoc.id;
      
      // Upload the thumbnail to Firebase Storage
      const storageRef = ref(storage, `routines/${routineId}/thumbnail`);
      const uploadTask = await uploadBytes(storageRef, thumbnailFile);
      const thumbnailUrl = await getDownloadURL(uploadTask.ref);
      
      // Update the routine document with the thumbnail URL
      await setDoc(newRoutineDoc, { thumbnailUrl }, { merge: true });
      
      // Save a reference to the routine in the user's subcollection
      const userRoutineDocRef = doc(
        firestore,
        "users",
        routineData.userId,
        "routines",
        routineId
      );
      await setDoc(userRoutineDocRef, { exists: true, createdAt: timestamp });
  
      return routineId;
    } catch (error) {
      console.error("Error adding routine:", error);
      throw error;
    }
  };
  
  export const getRoutine = async (routineId) => {
    try {
      const routineDocRef = doc(firestore, "routines", routineId);
      const docSnap = await getDoc(routineDocRef);
      if (docSnap.exists()) {
        return { id: docSnap.id, ...docSnap.data() };
      }
      return null;
    } catch (error) {
      console.error("Error getting routine:", error);
      throw error;
    }
  };
  
  export const getUserRoutines = async (userId) => {
    try {
      const userRoutinesCollectionRef = collection(
        firestore,
        "users",
        userId,
        "routines"
      );
      const routinesSnapshot = await getDocs(userRoutinesCollectionRef);
      if (routinesSnapshot.empty) {
        return [];
      }
      const routineIds = routinesSnapshot.docs.map(docSnap => docSnap.id);
      
      // Fetch each routine's data from the "routines" collection.
      const routinePromises = routineIds.map(async (id) => {
        const routineDocRef = doc(firestore, "routines", id);
        const routineSnap = await getDoc(routineDocRef);
        return routineSnap.exists() ? { id, ...routineSnap.data() } : null;
      });
      
      const routines = await Promise.all(routinePromises);
      return routines.filter(routine => routine !== null);
    } catch (error) {
      console.error("Error getting user routines:", error);
      throw error;
    }
  };
  
  export const getPublicRoutines = async (limit = 20) => {
    try {
      const routinesCollectionRef = collection(firestore, "routines");
      const q = query(routinesCollectionRef, where("isPublic", "==", true));
      const snapshot = await getDocs(q);
      if (snapshot.empty) {
        return [];
      }
      const routines = [];
      snapshot.forEach((docSnap) => {
        routines.push({
          id: docSnap.id,
          ...docSnap.data(),
        });
      });
      return routines;
    } catch (error) {
      console.error("Error getting public routines:", error);
      throw error;
    }
  };
  