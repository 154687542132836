import { firestore } from "./Init";
import { doc, getDoc, setDoc, deleteDoc, runTransaction } from "firebase/firestore";

const USERNAMES_COLLECTION = "usernames";

export const isUsernameTaken = async (username) => {
  if (!username) return true;
  const sanitizedUsername = username.toLowerCase().trim();
  const usernameDoc = doc(firestore, USERNAMES_COLLECTION, sanitizedUsername);
  try {
    const snapshot = await getDoc(usernameDoc);
    return snapshot.exists();
  } catch (error) {
    console.error("Error checking username:", error);
    throw error;
  }
};

export const reserveUsername = async (username, uid, isTemporary = false) => {
  if (!username || !uid) return false;
  const sanitizedUsername = username.toLowerCase().trim();
  const usernameDoc = doc(firestore, USERNAMES_COLLECTION, sanitizedUsername);
  try {
    const result = await runTransaction(firestore, async (transaction) => {
      const docSnapshot = await transaction.get(usernameDoc);
      if (!docSnapshot.exists()) {
        transaction.set(usernameDoc, {
          uid,
          timestamp: new Date().toISOString(),
          isTemporary,
        });
        return true;
      }
      return false; // Abort transaction if username is taken
    });
    return result;
  } catch (error) {
    console.error("Error reserving username:", error);
    throw error;
  }
};

export const releaseUsername = async (username) => {
  console.log("releasing username", username);
  if (!username) return;
  const sanitizedUsername = username.toLowerCase().trim();
  const usernameDoc = doc(firestore, USERNAMES_COLLECTION, sanitizedUsername);
  try {
    await deleteDoc(usernameDoc);
  } catch (error) {
    console.error("Error releasing username:", error);
    throw error;
  }
};

export const confirmUsername = async (username, uid) => {
  if (!username || !uid) return false;
  console.log("confirming username", username, uid);
  const sanitizedUsername = username.toLowerCase().trim();
  const usernameDoc = doc(firestore, USERNAMES_COLLECTION, sanitizedUsername);
  try {
    const snapshot = await getDoc(usernameDoc);
    if (!snapshot.exists()) {
      console.log("username not found");
      return false;
    }
    const data = snapshot.data();
    if (data.uid !== uid) {
      console.log(`data.uid ${data.uid} does not match uid ${uid}`);
      console.log("username already taken by another user");
      return false;
    }
    await setDoc(usernameDoc, {
      uid,
      timestamp: new Date().toISOString(),
      isTemporary: false,
    });
    console.log("username confirmed");
    return true;
  } catch (error) {
    console.error("Error confirming username:", error);
    throw error;
  }
};

export const isUsernameAvailable = async (username) => {
  if (!username || username.length < 3) return false;
  const isValidFormat = /^[a-zA-Z0-9_]+$/.test(username);
  if (!isValidFormat) return false;
  const sanitizedUsername = username.toLowerCase().trim();
  try {
    const usernameDoc = doc(firestore, USERNAMES_COLLECTION, sanitizedUsername);
    const snapshot = await getDoc(usernameDoc);
    if (!snapshot.exists()) return true;
    const data = snapshot.data();
    if (!data.isTemporary) return false;
    const reservationTime = new Date(data.timestamp);
    const oneHourAgo = new Date(Date.now() - 60 * 60 * 1000);
    if (reservationTime < oneHourAgo) {
      await deleteDoc(usernameDoc);
      return true;
    }
    return false;
  } catch (error) {
    console.error("Error checking username availability:", error);
    return false;
  }
};

export const validateUsername = (username) => {
  if (!username) return "Username is required";
  if (username.length < 3) return "Username must be at least 3 characters";
  if (username.length > 20) return "Username must be less than 20 characters";
  if (!/^[a-zA-Z0-9_]+$/.test(username)) {
    return "Username can only contain letters, numbers, and underscores";
  }
  return null;
};
