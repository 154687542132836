import React, { createContext, useContext, useEffect, useState, useCallback } from 'react';
import { listenToFollowers } from '../firebase/Followers';
import { getUserProfilesByIds } from '../firebase/User'; 
import { useUser } from './UserContext';

const FollowersContext = createContext();

export const useFollowers = () => {
    const context = useContext(FollowersContext);
    if (!context) {
        throw new Error('useFollowers must be used within a FollowersProvider');
    }
    return context;
};

export const FollowersProvider = ({ children }) => {
    const [followersMap, setFollowersMap] = useState({});
    const [followerProfilesMap, setFollowerProfilesMap] = useState({});
    const [isLoadingProfiles, setIsLoadingProfiles] = useState({});
    const { loading: userLoading, userData } = useUser();

    // Wrap the function in useCallback to ensure it is stable across renders.
    const listenToUserFollowers = useCallback((userId) => {
        if (!userId) return;
        const unsubscribe = listenToFollowers(userId, (followersList) => {
            setFollowersMap(prev => ({
                ...prev,
                [userId]: followersList
            }));
        });
        return unsubscribe;
    }, []);

    // Set up listener for current user automatically
    useEffect(() => {
        if (userLoading || !userData?.uid) return;
        const unsubscribe = listenToUserFollowers(userData.uid);
        return () => {
            if (unsubscribe) unsubscribe();
        };
    }, [userLoading, userData?.uid, listenToUserFollowers]);

    const getFollowersForUser = (userId) => {
        return followersMap[userId] || [];
    };

    const getFollowerProfiles = useCallback(async (userId) => {
        if (!userId || !followersMap[userId]) return [];
        
        // If we're already loading or have loaded the profiles, return the cached values
        if (followerProfilesMap[userId] && !isLoadingProfiles[userId]) {
            return followerProfilesMap[userId];
        }
        
        setIsLoadingProfiles(prev => ({ ...prev, [userId]: true }));
        
        try {
            const followerIds = followersMap[userId].map(follower => 
                typeof follower === 'string' ? follower : follower.userId
            );
            
            const profiles = await getUserProfilesByIds(followerIds);
            
            setFollowerProfilesMap(prev => ({
                ...prev,
                [userId]: profiles
            }));
            
            return profiles;
        } catch (error) {
            console.error("Error fetching follower profiles:", error);
            return [];
        } finally {
            setIsLoadingProfiles(prev => ({ ...prev, [userId]: false }));
        }
    }, [followersMap, followerProfilesMap, isLoadingProfiles]);

    const value = {
        getFollowersForUser,
        listenToUserFollowers,
        hasFollower: (userId) => getFollowersForUser(userData?.uid).includes(userId),
        loading: userLoading,
        getFollowerProfiles,
        isLoadingProfiles: (userId) => isLoadingProfiles[userId] || false,
        followerProfiles: (userId) => followerProfilesMap[userId] || []
    };

    return (
        <FollowersContext.Provider value={value}>
            {children}
        </FollowersContext.Provider>
    );
};

export default FollowersProvider;
