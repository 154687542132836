import React, { useState, useEffect } from 'react';
import { Box, Typography, Chip, Card, CardContent, CardMedia } from '@mui/material';
import { useNavigate } from 'react-router-dom';

// Default images with constants
const DEFAULT_ROUTINE_IMAGE = '/images/default-routine.png';
const DEFAULT_PROFILE_IMAGE = '/images/default-profile.png';

const RoutinePreview = ({ routine }) => {
  const navigate = useNavigate();
  const [imageLoaded, setImageLoaded] = useState(false);
  const [imageError, setImageError] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const handleUserClick = (e) => {
    e.stopPropagation();
    navigate(`/profile/${routine.createdBy?.username}`);
  };

  // Determine which image to display
  const thumbnailImage = imageError 
    ? DEFAULT_ROUTINE_IMAGE 
    : (routine.thumbnailUrl || routine.coverImage || DEFAULT_ROUTINE_IMAGE);

  // Preload default images when component mounts
  useEffect(() => {
    const preloadDefaultImages = () => {
      const routineImg = new Image();
      routineImg.src = DEFAULT_ROUTINE_IMAGE;
      
      const profileImg = new Image();
      profileImg.src = DEFAULT_PROFILE_IMAGE;
    };
    
    preloadDefaultImages();
  }, []);

  return (
    <div className={`p-2 md:p-4 w-full`}>
      <div 
        className="relative mb-6 mx-auto w-full max-w-2xl" 
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {/* Staggered background cards to indicate multiple exercises */}
        <div 
          className="absolute bg-gray-300 shadow-md transition-all duration-300 border-2 border-gray-400" 
          style={{ 
            width: '100%', 
            height: '100%', 
            top: isHovered ? '30px' : '20px', 
            left: isHovered ? '30px' : '20px', 
            zIndex: 1,
            borderRadius: 0,
            transform: isHovered ? 'rotate(3deg)' : 'rotate(0deg)'
          }} 
        />
        <div 
          className="absolute bg-gray-400 shadow-md transition-all duration-300 border-2 border-gray-500" 
          style={{ 
            width: '100%', 
            height: '100%', 
            top: isHovered ? '15px' : '10px', 
            left: isHovered ? '15px' : '10px', 
            zIndex: 2,
            borderRadius: 0,
            transform: isHovered ? 'rotate(1.5deg)' : 'rotate(0deg)'
          }} 
        />
        
        {/* Main card */}
        <Card 
          sx={{ 
            cursor: 'pointer',
            position: 'relative',
            zIndex: 3,
            borderRadius: 0,
            transition: 'transform 0.3s ease-in-out',
            transform: isHovered ? 'scale(1.02)' : 'scale(1)',
            width: '100%'
          }}
          onClick={() => navigate(`/routine/${routine.id}`)}
          className="bg-white shadow-md overflow-hidden"
        >
          {/* Media */}
          <div className="relative aspect-[4/3]">
            {/* Loading placeholder */}
            <div className={`absolute inset-0 bg-gray-200 transition-opacity duration-300 ${imageLoaded ? 'opacity-0' : 'opacity-100'}`} />
            
            <CardMedia
              component="img"
              image={thumbnailImage}
              alt={routine.title}
              className={`w-full h-full object-cover transition-opacity duration-300 ${imageLoaded ? 'opacity-100' : 'opacity-0'}`}
              onLoad={() => setImageLoaded(true)}
              onError={(e) => {
                // Only set error state if we're not already using the default image
                if (!imageError) {
                  setImageError(true);
                  setImageLoaded(false);
                }
              }}
            />
          </div>

          {/* User Info - Inline with profile pic */}
          <div className="flex bg-black items-center p-3 border-b border-gray-200">
            <div 
              className="w-8 h-8 rounded-full bg-gray-200 mr-2 shrink-0 cursor-pointer username-link"
              onClick={handleUserClick}
              style={{
                backgroundImage: `url(${routine.createdBy?.photoURL || DEFAULT_PROFILE_IMAGE})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center'
              }}
            />
            <span 
              className="font-semibold text-sm text-white cursor-pointer hover:underline username-link"
              onClick={handleUserClick}
            >
              @{routine.createdBy?.username || 'unknown'}
            </span>
            <div className="ml-auto">
              <span className="text-white material-icons text-xl">more_horiz</span>
            </div>
          </div>

          {/* Routine Info */}
          <CardContent sx={{ p: 3 }}>
            {/* Title with routine icon */}
            <div className="flex items-center mb-2">
              <Typography variant="h6" className="flex-grow uppercase" sx={{ fontWeight: 'bold' }}>
                {routine.title}
              </Typography>
              <span className="text-blue-500">
                <span className="material-icons text-xl">format_list_bulleted</span>
              </span>
            </div>
            
            {/* Divider */}
            <div className="border-b border-gray-200 mb-3"></div>

            {/* Description */}
            <Typography variant="body2" sx={{ mb: 2 }}>
              {routine.description}
            </Typography>

            {/* Tags */}
            <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
              {routine.tags?.map((tag, index) => (
                <Chip 
                  key={index} 
                  label={tag} 
                  size="small"
                  sx={{ 
                    bgcolor: 'black',
                    color: 'white',
                    borderRadius: '0',
                    '& .MuiChip-label': {
                      padding: '0 8px'
                    }
                  }} 
                />
              ))}
            </Box>

            {/* Exercise count */}
            <div className="flex items-center border-t pt-2 mt-3">
              <div className="flex items-center mr-4">
                <span className="material-icons text-gray-800 text-sm mr-1">calendar_month</span>
                <span className="text-sm">{routine.schedule?.weeks || '1'} {routine.schedule?.weeks === 1 ? 'week' : 'weeks'}</span>
              </div>
              <div className="flex items-center">
                <span className="material-icons text-gray-800 text-sm mr-1">fitness_center</span>
                <span className="text-sm">{routine.exerciseCount || 0} {routine.exerciseCount === 1 ? 'exercise' : 'exercises'}</span>
              </div>
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default RoutinePreview; 