import React from 'react';

const Header = ({ title, trailing, leading }) => {
  return (
    <div className="border-b border-gray-200">
      <div className="w-[90%] mx-auto flex justify-between items-end h-[60px] md:h-[80px]">
        <div className="flex items-center">
          {leading && (
            <div className="mr-2 md:mr-4">
              {leading}
            </div>
          )}
          <div className="py-2 md:py-4">
            <h1 className=" text-2xl md:text-4xl font-black uppercase h-8 md:h-12 flex items-end">
              {title}
            </h1>
          </div>
        </div>
        {trailing && (
          <div className="flex items-center p-2 md:p-4">
            {trailing}
          </div>
        )}
      </div>
    </div>
  );
};

export default Header; 