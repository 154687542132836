import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useExercise } from '../contexts/ExerciseContext';
import { usePost } from '../contexts/PostContext';
import { motion, useScroll, useTransform } from 'framer-motion';
import { useCreate } from '../contexts/CreateContext';
import FixedBottomButton from '../components/ui/FixedBottomButton';
import { useHeader } from '../contexts/HeaderContext';

const Exercise = () => {
  const { exerciseId } = useParams();
  const navigate = useNavigate();
  const { loadExercise, currentExercise } = useExercise();
  const { posts, loadExercisePosts } = usePost();
  const [timeLeft, setTimeLeft] = useState(60);
  const [isTimerRunning, setIsTimerRunning] = useState(false);
  const { setExerciseData, setCreateTab } = useCreate();
  const scrollRef = useRef(null);
  const { scrollY } = useScroll({ container: scrollRef });
  const mediaY = useTransform(scrollY, [0, 200], [0, -250]);
  const headerOpacity = useTransform(scrollY, [0, 150], [1, 0]);
  const { updateHeader } = useHeader();

  useEffect(() => {
    loadExercise(exerciseId);
    loadExercisePosts(exerciseId);
  }, [exerciseId, loadExercise, loadExercisePosts]);

  // Set up header with back button
  useEffect(() => {
    const backButton = (
      <button 
        onClick={() => navigate(-1)}
        className="text-gray-600 hover:text-gray-900"
      >
        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 19l-7-7m0 0l7-7m-7 7h18" />
        </svg>
      </button>
    );
    
    updateHeader('EXERCISE', null, backButton);
    
    return () => updateHeader('');
  }, [navigate, updateHeader]);

  useEffect(() => {
    let timer;
    if (isTimerRunning && timeLeft > 0) {
      timer = setInterval(() => setTimeLeft(prev => prev - 1), 1000);
    } else if (timeLeft === 0) {
      setIsTimerRunning(false);
    }
    return () => clearInterval(timer);
  }, [isTimerRunning, timeLeft]);

  const handleRepIt = () => {
    console.log("Setting exercise data:", {
      exerciseId: currentExercise.id,
      exerciseTitle: currentExercise.title,
      fromExercise: true
    });
    
    setExerciseData({
      exerciseId: currentExercise.id,
      exerciseTitle: currentExercise.title,
      fromExercise: true
    });
    setCreateTab(1);
    navigate('/create');
  };

  if (!currentExercise) {
    return (
      <div className="flex-1 bg-black text-white p-8">
        Loading...
      </div>
    );
  }


  return (
    <div className="flex flex-col h-[100dvh] overflow-hidden bg-white">
      {/* Header is now managed by HeaderContext */}
      
      {/* Scrollable Content */}
      <div className="flex-1 overflow-y-auto" ref={scrollRef}>
        {/* Exercise Title */}
        <div className="sticky top-0 z-20 bg-black text-white py-2">
          <div className="w-[90%] mx-auto">
            <h1 className="text-2xl md:text-2xl font-bold uppercase tracking-wider">
              {currentExercise.title}
            </h1>
          </div>
        </div>

        {/* Description Section */}
        <div className="bg-black text-white border-b border-gray-800">
          <div className="w-[90%] mx-auto py-2">
            <p className="text-gray-400 uppercase tracking-wider">
              {currentExercise.description}
            </p>
          </div>
        </div>

        {/* Media Section - Collapses on Scroll */}
        <div className='bg-black' style={{ height: 'calc(100vh / 3)', overflow: 'hidden', position: 'relative' }}>
          <motion.div
            style={{
              y: mediaY,
              opacity: headerOpacity,
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
            }}
            transition={{ type: 'spring', stiffness: 100, damping: 20 }}
          >
            {currentExercise.mediaUrl ? (
              <div className="relative h-full">
                {/* Background blurred media */}
                <div className="absolute inset-0 overflow-hidden">
                  {currentExercise.mediaType === 'video' ? (
                    <video
                      src={currentExercise.mediaUrl}
                      className="w-full h-full object-cover scale-110 blur-xl opacity-50"
                      autoPlay
                      loop
                      muted
                    />
                  ) : (
                    <img
                      src={currentExercise.mediaUrl}
                      alt=""
                      className="w-full h-full object-cover scale-110 blur-xl opacity-50"
                    />
                  )}
                </div>
                
                {/* Exercise Media (foreground) */}
                <div className="max-w-4xl mx-auto h-full relative z-10">
                  {currentExercise.mediaType === 'video' ? (
                    <video
                      src={currentExercise.mediaUrl}
                      className="w-full h-full object-contain"
                      autoPlay
                      loop
                      muted
                    />
                  ) : (
                    <img
                      src={currentExercise.mediaUrl}
                      alt={currentExercise.title}
                      className="w-full h-full object-contain"
                    />
                  )}
                </div>
              </div>
            ) : (
              <div className="w-full h-full bg-gray-100 flex items-center justify-center">
                <span className="text-gray-500">No media available</span>
              </div>
            )}
          </motion.div>
        </div>

        {/* Creator Info Section - Always Visible */}
        <div className="bg-black text-white border-b border-gray-800">
          <div className="w-[90%] mx-auto py-4">
            <div className="flex justify-between items-center">
              <div className="flex items-center">
                <span className="font-bold mr-2">CREATED BY:</span>
                <span className="text-gray-400">@{currentExercise.createdBy?.username || 'ADMIN'}</span>
              </div>
              <div className="flex items-center">
                <span className="font-bold mr-2">POSTS</span>
                <span className="text-gray-400 mr-2">
                  {currentExercise.postCount || 0}
                </span>
              </div>
            </div>
          </div>
        </div>

        {/* Posts Section - Always visible */}
        <div className="bg-white">
          <div className="w-[90%] mx-auto py-8">
            <h2 className="text-2xl font-bold mb-4">POSTS</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
              {posts.length > 0 ? (
                posts.map((post) => (
                  <div 
                    key={post.id} 
                    className="bg-white shadow-md overflow-hidden cursor-pointer hover:shadow-lg transition-shadow"
                    onClick={() => navigate(`/posts/${post.id}`)}
                  >
                    {/* Post Media with Overlay */}
                    <div className="aspect-square relative">
                      {post.mediaUrl && (
                        post.mediaType === 'video' ? (
                          <video
                            src={post.mediaUrl}
                            className="w-full h-full object-cover"
                          />
                        ) : (
                          <img
                            src={post.mediaUrl}
                            alt={post.caption}
                            className="w-full h-full object-cover"
                          />
                        )
                      )}
                      
                      {/* Overlay with Post Info */}
                      <div className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-70 text-white p-3">
                        {/* Username and date */}
                        <div className="flex items-center mb-1">
                          <span className="font-semibold text-sm">
                            @{post.user?.username || 'unknown'}
                          </span>
                          <span className="text-xs text-gray-300 ml-auto">
                            {post.createdAt ? new Date(post.createdAt).toLocaleDateString() : ''}
                          </span>
                        </div>
                        
                        {/* Caption preview */}
                        {post.caption && (
                          <p className="text-sm text-gray-200 truncate">{post.caption}</p>
                        )}
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="col-span-full text-center py-8 text-gray-500">
                  No posts yet for this exercise
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Rep It Button */}
      <FixedBottomButton
        text="REP IT"
        loadingText="Loading..."
        isLoading={false}
        disabled={isTimerRunning}
        onClick={handleRepIt}
      />
    </div>
  );
};

export default Exercise;
