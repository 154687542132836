import React, { createContext, useContext, useEffect, useState, useCallback } from "react";
import { useUser } from "./UserContext";
import { listenToFollowing, isFollowing as checkIsFollowing } from "../firebase/Following";
import { getUserProfilesByIds } from "../firebase/User";

// Helper: shallow comparison for arrays
const arraysEqual = (a, b) => {
  if (a === b) return true;
  if (!a || !b) return false;
  if (a.length !== b.length) return false;
  for (let i = 0; i < a.length; i++) {
    if (a[i] !== b[i]) return false;
  }
  return true;
};

const FollowingContext = createContext();

export const useFollowing = () => {
  const context = useContext(FollowingContext);
  if (!context) {
    throw new Error("useFollowing must be used within a FollowingProvider");
  }
  return context;
};

export const FollowingProvider = ({ children }) => {
  const [following, setFollowing] = useState([]);
  const [userFollowingMap, setUserFollowingMap] = useState({});
  const [followingProfilesMap, setFollowingProfilesMap] = useState({});
  const [isLoadingProfiles, setIsLoadingProfiles] = useState({});
  const { loading: userLoading, userData } = useUser();

  // Returns true if the current user is following the targetUserId.
  const isFollowing = useCallback(
    async (targetUserId) => {
      if (!userData?.uid || !targetUserId) return false;
      return await checkIsFollowing(userData.uid, targetUserId);
    },
    [userData?.uid]
  );

  // Listen to current user's following list.
  useEffect(() => {
    if (userLoading || !userData?.uid) return;
    const unsubscribe = listenToFollowing(userData.uid, (followingList) => {
      // Only update if the new array differs from the current state.
      setFollowing((prevFollowing) =>
        arraysEqual(prevFollowing, followingList) ? prevFollowing : followingList
      );
    });
    return () => {
      if (unsubscribe) unsubscribe();
    };
  }, [userLoading, userData?.uid]);

  // Get following list for a specific user from the map.
  const getFollowingForUser = useCallback(
    (userId) => (userId ? userFollowingMap[userId] || [] : []),
    [userFollowingMap]
  );

  // Listen to a specific user's following list.
  const listenToUserFollowing = useCallback((userId) => {
    if (!userId) return null;
    return listenToFollowing(userId, (followingList) => {
      setUserFollowingMap((prev) => {
        if (prev[userId] && arraysEqual(prev[userId], followingList)) {
          return prev;
        }
        return { ...prev, [userId]: followingList };
      });
    });
  }, []);

  const getFollowingProfiles = useCallback(async (userId) => {
    if (!userId || !userFollowingMap[userId]) return [];
    
    // If we're already loading or have loaded the profiles, return the cached values
    if (followingProfilesMap[userId] && !isLoadingProfiles[userId]) {
      return followingProfilesMap[userId];
    }
    
    setIsLoadingProfiles(prev => ({ ...prev, [userId]: true }));
    
    try {
      const followingIds = userFollowingMap[userId].map(following => 
        typeof following === 'string' ? following : following.userId
      );
      
      const profiles = await getUserProfilesByIds(followingIds);
      
      setFollowingProfilesMap(prev => ({
        ...prev,
        [userId]: profiles
      }));
      
      return profiles;
    } catch (error) {
      console.error("Error fetching following profiles:", error);
      return [];
    } finally {
      setIsLoadingProfiles(prev => ({ ...prev, [userId]: false }));
    }
  }, [userFollowingMap, followingProfilesMap, isLoadingProfiles]);

  const value = {
    following,
    isFollowing,
    getFollowingForUser,
    listenToUserFollowing,
    loading: userLoading,
    getFollowingProfiles,
    isLoadingProfiles: (userId) => isLoadingProfiles[userId] || false,
    followingProfiles: (userId) => followingProfilesMap[userId] || []
  };

  return (
    <FollowingContext.Provider value={value}>
      {children}
    </FollowingContext.Provider>
  );
};
