import { firestore } from "./Init";
import { doc, getDoc, updateDoc, setDoc } from "firebase/firestore";

// Retrieve a user's private profile from Firestore
export const getPrivateProfileByUserId = async (uid) => {
  if (!uid) return null;
  try {
    const privateProfileRef = doc(firestore, "users", uid, "privateProfile", "info");
    const snapshot = await getDoc(privateProfileRef);
    if (!snapshot.exists()) return null;
    return snapshot.data();
  } catch (error) {
    console.error("Error fetching private profile for user:", error);
    throw error;
  }
};

// Update the private profile for a given user with provided updates
export const updatePrivateProfile = async (uid, updates) => {
  if (!uid) return;
  try {
    const privateProfileRef = doc(firestore, "users", uid, "privateProfile", "info");
    await updateDoc(privateProfileRef, {
      ...updates,
      updatedAt: new Date().toISOString(),
    });
  } catch (error) {
    console.error("Error updating private profile:", error);
    throw error;
  }
};

// Set the private profile data for a new user
export const setPrivateProfile = async (uid, profileData) => {
  if (!uid) return;
  try {
    const privateProfileRef = doc(firestore, "users", uid, "privateProfile", "info");
    await setDoc(privateProfileRef, {
      ...profileData,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
    });
  } catch (error) {
    console.error("Error setting private profile:", error);
    throw error;
  }
};

// Update chatbot threads in the private profile
export const updateChatbotThreads = async (uid, threads) => {
  if (!uid) return;
  try {
    const privateProfileRef = doc(firestore, "users", uid, "privateProfile", "info");
    await updateDoc(privateProfileRef, {
      chatbotThreads: threads,
      updatedAt: new Date().toISOString(),
    });
  } catch (error) {
    console.error("Error updating chatbot threads:", error);
    throw error;
  }
};
