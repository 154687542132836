const firebaseConfig = {
    apiKey: "AIzaSyDf7zJ4mVcY_nUQ4oWkqdc2MGAc87F1PfY",
    authDomain: "mogged-mvp-dev.firebaseapp.com",
    databaseURL: "https://mogged-mvp-dev-default-rtdb.firebaseio.com",
    projectId: "mogged-mvp-dev",
    storageBucket: "mogged-mvp-dev.firebasestorage.app",
    messagingSenderId: "76832623784",
    appId: "1:76832623784:web:42defa4d26bdfabeb6869d",
    measurementId: "G-HRGHL4GPNX"
  };

export default firebaseConfig;