import { firestore } from "./Init";
import {
  doc,
  getDoc,
  setDoc,
  deleteDoc,
  collection,
  query,
  getDocs,
  onSnapshot,
  serverTimestamp,
  orderBy,
  limit,
  increment,
  updateDoc
} from "firebase/firestore";

/**
 * Add a follower to a user's public profile
 * @param {string} profileOwnerId - The ID of the profile owner
 * @param {string} followerId - The ID of the follower
 * @returns {Promise<boolean>} - Whether the operation was successful
 */
export const addFollower = async (profileOwnerId, followerId) => {
  console.log(`[Followers] Adding follower relationship: ${followerId} -> ${profileOwnerId}`);
  
  if (!profileOwnerId || !followerId) {
    console.warn("[Followers] Missing user IDs in addFollower");
    return false;
  }
  
  try {
    // First check if the follower already exists to avoid duplicate increments
    const followerExists = await hasFollower(profileOwnerId, followerId);
    if (followerExists) {
      console.log(`[Followers] Relationship already exists: ${followerId} -> ${profileOwnerId}`);
      return true; // Already a follower, no need to create document or increment count
    }
    
    const followerRef = doc(
      firestore, 
      `users/${profileOwnerId}/publicProfile/info/followers/${followerId}`
    );
    
    // Get reference to the profile owner's public profile document
    const profileOwnerRef = doc(
      firestore,
      `users/${profileOwnerId}/publicProfile/info`
    );
    
    console.log(`[Followers] Creating document at path: ${followerRef.path}`);
    
    await setDoc(followerRef, {
      userId: followerId,
      createdAt: serverTimestamp()
    });
    
    // Update the follower count
    try {
      await updateDoc(profileOwnerRef, {
        followerCount: increment(1)
      });
    } catch (error) {
      // If the document doesn't exist, create it with initial count
      if (error.code === 'not-found') {
        await setDoc(profileOwnerRef, {
          followerCount: 1
        }, { merge: true });
      } else {
        throw error;
      }
    }
    
    console.log(`[Followers] Successfully added follower: ${followerId} to ${profileOwnerId}`);
    return true;
  } catch (error) {
    console.error("[Followers] Error adding follower:", error);
    throw error;
  }
};

/**
 * Remove a follower from a user's public profile
 * @param {string} profileOwnerId - The ID of the profile owner
 * @param {string} followerId - The ID of the follower
 * @returns {Promise<boolean>} - Whether the operation was successful
 */
export const removeFollower = async (profileOwnerId, followerId) => {
  console.log(`[Followers] Removing follower relationship: ${followerId} -> ${profileOwnerId}`);
  
  if (!profileOwnerId || !followerId) {
    console.warn("[Followers] Missing user IDs in removeFollower");
    return false;
  }
  
  try {
    // First check if the follower exists
    const hasFollowerStatus = await hasFollower(profileOwnerId, followerId);
    if (!hasFollowerStatus) {
      console.log(`[Followers] Relationship doesn't exist: ${followerId} -> ${profileOwnerId}`);
      return true; // Already not a follower
    }
    
    const followerRef = doc(
      firestore, 
      `users/${profileOwnerId}/publicProfile/info/followers/${followerId}`
    );
    
    // Get reference to the profile owner's public profile document
    const profileOwnerRef = doc(
      firestore,
      `users/${profileOwnerId}/publicProfile/info`
    );
    
    console.log(`[Followers] Deleting document at path: ${followerRef.path}`);
    
    await deleteDoc(followerRef);
    
    // Update the follower count
    await updateDoc(profileOwnerRef, {
      followerCount: increment(-1)
    });
    
    console.log(`[Followers] Successfully removed follower: ${followerId} from ${profileOwnerId}`);
    return true;
  } catch (error) {
    console.error("[Followers] Error removing follower:", error);
    throw error;
  }
};

/**
 * Retrieve a list of followers for a user
 * @param {string} userId - The ID of the user
 * @param {number} [maxResults=100] - Maximum number of results to return
 * @returns {Promise<Array>} - Array of follower objects
 */
export const getFollowers = async (userId, maxResults = 100) => {
  console.log(`[Followers] Getting followers list for user: ${userId}`);
  
  if (!userId) {
    console.warn("[Followers] Missing userId in getFollowers");
    return [];
  }
  
  try {
    const followersRef = collection(
      firestore,
      `users/${userId}/publicProfile/info/followers`
    );
    
    const q = query(
      followersRef,
      orderBy("createdAt", "desc"),
      limit(maxResults)
    );
    
    const querySnapshot = await getDocs(q);
    const followers = querySnapshot.docs.map(doc => ({
      userId: doc.id,
      ...doc.data()
    }));
    
    console.log(`[Followers] Found ${followers.length} followers for ${userId}`);
    return followers;
  } catch (error) {
    console.error("[Followers] Error getting followers list:", error);
    throw error;
  }
};

/**
 * Get the count of followers for a user
 * @param {string} userId - The ID of the user
 * @returns {Promise<number>} - The number of followers
 */
export const getFollowersCount = async (userId) => {
  if (!userId) return 0;
  try {
    const followers = await getFollowers(userId);
    return followers.length;
  } catch (error) {
    console.error("Error getting followers count:", error);
    return 0;
  }
};

/**
 * Check if a given follower exists in the owner's public profile
 * @param {string} profileOwnerId - The ID of the profile owner
 * @param {string} followerId - The ID of the follower
 * @returns {Promise<boolean>} - Whether the follower exists
 */
export const hasFollower = async (profileOwnerId, followerId) => {
  if (!profileOwnerId || !followerId) return false;
  try {
    const followerRef = doc(
      firestore,
      `users/${profileOwnerId}/publicProfile/info/followers/${followerId}`
    );
    
    const docSnap = await getDoc(followerRef);
    return docSnap.exists();
  } catch (error) {
    console.error("Error checking follower status:", error);
    return false;
  }
};

/**
 * Listen in real time to changes in the followers list for a user
 * @param {string} userId - The ID of the user
 * @param {Function} callback - Callback function with array of follower objects
 * @param {number} [maxResults=100] - Maximum number of results to return
 * @returns {Function} - Unsubscribe function
 */
export const listenToFollowers = (userId, callback, maxResults = 100) => {
  if (!userId) return () => {};
  
  const followersRef = collection(
    firestore,
    `users/${userId}/publicProfile/info/followers`
  );
  
  const q = query(
    followersRef,
    orderBy("createdAt", "desc"),
    limit(maxResults)
  );
  
  const unsubscribe = onSnapshot(q, (querySnapshot) => {
    const followers = querySnapshot.docs.map(doc => ({
      userId: doc.id,
      ...doc.data()
    }));
    callback(followers);
  }, (error) => {
    console.error("Error listening to followers list:", error);
    callback([]);
  });
  
  return unsubscribe;
};

/**
 * Listen in real time to the follower status for a specific follower
 * @param {string} profileOwnerId - The ID of the profile owner
 * @param {string} followerId - The ID of the follower
 * @param {Function} callback - Callback function with follower status
 * @returns {Function} - Unsubscribe function
 */
export const listenToFollowerStatus = (profileOwnerId, followerId, callback) => {
  if (!profileOwnerId || !followerId) return () => {};
  
  const followerRef = doc(
    firestore,
    `users/${profileOwnerId}/publicProfile/info/followers/${followerId}`
  );
  
  const unsubscribe = onSnapshot(followerRef, (docSnap) => {
    callback(docSnap.exists());
  }, (error) => {
    console.error("Error listening to follower status:", error);
    callback(false);
  });
  
  return unsubscribe;
};
