import React from 'react';

const LoadingProfile = ({ size = 'md', className = '' }) => {
  // Size classes for the image
  const sizeClasses = {
    sm: 'w-5 h-5',
    md: 'w-8 h-8',
    lg: 'w-12 h-12',
    xl: 'w-16 h-16'
  };

  // Size classes for the container
  const containerSizeClasses = {
    sm: 'w-10 h-10',
    md: 'w-16 h-16',
    lg: 'w-24 h-24',
    xl: 'w-32 h-32'
  };

  // Size classes for the inner black circle
  const innerCircleSizeClasses = {
    sm: 'w-8 h-8',
    md: 'w-12 h-12',
    lg: 'w-18 h-18',
    xl: 'w-24 h-24'
  };

  const sizeClass = sizeClasses[size] || sizeClasses.md;
  const containerSizeClass = containerSizeClasses[size] || containerSizeClasses.md;
  const innerCircleSizeClass = innerCircleSizeClasses[size] || innerCircleSizeClasses.md;

  return (
    <div className={`flex items-center justify-center ${className}`}>
      <div className={`${containerSizeClass} relative flex items-center justify-center`}>
        {/* Circular indicator */}
        <div className="absolute inset-0 rounded-full border-2 border-black opacity-25"></div>
        
        {/* Spinning border */}
        <div className="absolute inset-0 rounded-full border-t-2 border-black animate-spin"></div>
        
        {/* Black background circle */}
        <div className={`${innerCircleSizeClass} rounded-full bg-black flex items-center justify-center p-2`}>
          {/* Profile SVG */}
          <img 
            src="/images/profile.svg" 
            alt="Loading..." 
            className={`animate-pulse ${sizeClass}`}
          />
        </div>
      </div>
    </div>
  );
};

export default LoadingProfile; 