import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { useAuth } from './AuthContext';
import { useUser } from './UserContext';
import {
    generateWorkout as generateWorkoutDb,
    fetchWorkoutHistory as fetchWorkoutHistoryDb,
    deleteWorkout as deleteWorkoutFromDb
} from '../firebase/Workouts';

const WorkoutContext = createContext();

export const useWorkout = () => useContext(WorkoutContext);

export const WorkoutProvider = ({ children }) => {
    const [workoutHistory, setWorkoutHistory] = useState([]);
    const [currentWorkout, setCurrentWorkout] = useState(null);
    const [loading, setLoading] = useState(true);
    const { currentUser } = useAuth();
    const { userData } = useUser();

    // Fetch workout history
    const loadWorkoutHistory = useCallback(() => {
        if (!currentUser) {
            setWorkoutHistory([]);
            setLoading(false);
            return null;
        }

        return fetchWorkoutHistoryDb(currentUser.uid, (workouts) => {
            setWorkoutHistory(workouts);
            setLoading(false);
        });
    }, [currentUser]);

    // Initialize data when user changes
    useEffect(() => {
        setLoading(true);
        const unsubscribe = loadWorkoutHistory();
        return () => {
            if (unsubscribe) unsubscribe();
        };
    }, [loadWorkoutHistory]);

    // Delete workout
    const deleteWorkout = useCallback(async (workoutId) => {
        if (!currentUser) return;
        await deleteWorkoutFromDb(currentUser.uid, workoutId);
    }, [currentUser]);

    // Generate workout
    const generateWorkout = useCallback(async () => {
        const threadId = userData?.assistants?.planningAssistant?.threadId;
        const fitnessGoal = userData?.fitnessGoal;

        if (!currentUser) {
            throw new Error("User must be logged in to generate workouts");
        }

        if (!threadId) {
            throw new Error("AI Assistant not initialized. Please try again in a few moments.");
        }

        try {
            const result = await generateWorkoutDb(
                currentUser.uid,
                threadId,
                fitnessGoal
            );
            setCurrentWorkout(result.workout);
            return result;
        } catch (error) {
            console.error('Error generating workout:', error);
            throw error;
        }
    }, [currentUser, userData]);

    const value = {
        workoutHistory,
        currentWorkout,
        loading,
        deleteWorkout,
        generateWorkout,
        setCurrentWorkout,
    };

    return <WorkoutContext.Provider value={value}>{children}</WorkoutContext.Provider>;
}; 