import React, { useState, useMemo } from 'react';
import { TrainingPreferences, TRAINING_TAG_GROUPS } from '../../models/training-preferences';

const TrainingPreferencesStep = ({ nextStep, prevStep, handleChange, formData }) => {
    const [selectedTrainingPreferencesTags, setSelectedTrainingPreferencesTags] = useState(formData.trainingStyleTags || []);
    const [errors, setErrors] = useState({});

    // Use TRAINING_TAG_GROUPS directly for grouping
    const tagCategories = useMemo(() => {
        return Object.entries(TRAINING_TAG_GROUPS).reduce((acc, [category, tags]) => {
            // Convert category name to display format (e.g., "TRAINING_METHOD" -> "Training Method")
            const displayCategory = category
                .split('_')
                .map(word => word.charAt(0) + word.slice(1).toLowerCase())
                .join(' ');

            acc[displayCategory] = tags;
            return acc;
        }, {});
    }, []);

    const toggleTag = (tag) => {
        setSelectedTrainingPreferencesTags(prev =>
            prev.includes(tag)
                ? prev.filter(t => t !== tag)
                : [...prev, tag]
        );
    };

    const validateForm = () => {
        let isValid = true;
        let newErrors = {};

        if (selectedTrainingPreferencesTags.length < 3) {
            newErrors.tags = 'Please select at least 3 tags';
            isValid = false;
        }

        setErrors(newErrors);
        return isValid;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
            const { definition, score } = TrainingPreferences.findBestMatch(selectedTrainingPreferencesTags);
            console.log(score);
            handleChange('trainingStyle')(definition);
            handleChange('trainingStyleTags')(selectedTrainingPreferencesTags);
            nextStep();
        }
    };

    const handleSkip = () => {
        // Clear any existing training style tags
        handleChange('trainingStyleTags')([]);
        // Set an empty object for trainingStyle
        handleChange('trainingStyle')({});
        // Move to the next step
        nextStep();
    };

    return (
        <div className="w-full h-screen flex justify-center items-center p-2">
            <div className="bg-white w-full max-w-3xl text-gray-600 shadow-xl border flex flex-col h-[600px] min-h-[600px] max-h-[600px] relative">
                {/* Fixed Header */}
                <div className="bg-white border-b px-4 py-4 sticky top-0 z-20">
                    <div className="text-center">
                        <h2 className="text-gray-800 text-xl font-semibold sm:text-2xl uppercase">
                            Training Preferences
                        </h2>
                        <p className="text-gray-600 mt-2">
                            Select tags that match your preferred training style (selected: {selectedTrainingPreferencesTags.length}/3 minimum)
                        </p>
                    </div>
                </div>

                <form onSubmit={handleSubmit} className="flex flex-col flex-1 overflow-hidden">
                    {/* Scrollable Content */}
                    <div className="flex-1 overflow-auto px-4">
                        <div className="space-y-4">
                            {Object.entries(tagCategories).map(([category, tags]) => (
                                <div key={category} className="space-y-2">
                                    <h3 className="font-light text-white bg-black py-2 px-4 sticky top-0 z-10 uppercase">
                                        {category}
                                    </h3>
                                    <div className="flex flex-wrap gap-2">
                                        {tags.map(tag => (
                                            <button
                                                key={tag}
                                                type="button"
                                                onClick={() => toggleTag(tag)}
                                                className={`
                          px-4 py-2 rounded-full text-sm font-medium transition-colors
                          border border-gray-300
                          ${selectedTrainingPreferencesTags.includes(tag)
                                                        ? 'bg-black text-white border-black hover:bg-gray-800 hover:border-gray-800'
                                                        : 'bg-white text-gray-700 hover:bg-gray-100'
                                                    }
                        `}
                                            >
                                                {tag}
                                            </button>
                                        ))}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

                    {/* Fixed Footer */}
                    <div className="bg-white border-t px-4 py-4 sticky bottom-0 z-20">
                        {errors.tags && (
                            <p className="text-red-500 text-sm mb-3">{errors.tags}</p>
                        )}

                        <div className="flex justify-between items-center">
                            <button
                                type="button"
                                onClick={prevStep}
                                className="px-6 py-2 bg-gray-100 text-gray-700 hover:bg-gray-200 transition duration-300 "
                            >
                                Back
                            </button>
                            <button
                                type="button"
                                onClick={handleSkip}
                                className="px-6 py-2 bg-white text-blue-500 border border-blue-500 hover:bg-blue-50 transition duration-300 "
                            >
                                Skip for now
                            </button>
                            <button
                                type="submit"
                                className={`
                  px-6 py-2  transition duration-300
                  ${selectedTrainingPreferencesTags.length >= 3
                                        ? 'bg-black text-white hover:bg-gray-800'
                                        : 'bg-gray-300 text-gray-500 cursor-not-allowed'
                                    }
                `}
                            >
                                Next
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default TrainingPreferencesStep; 
