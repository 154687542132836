// trainingPreferences.js

// Simplified tag category definitions for TrainingPreferences
export const TRAINING_TAG_GROUPS = {
    WORKOUT_TYPE: [
        "weight training", "bodyweight", "cardio", "flexibility"
    ],
    GOAL: [
        "muscle building", "weight loss", "strength", "endurance"
    ],
    INTENSITY: [
        "high intensity", "moderate intensity", "gentle"
    ],
    APPROACH: [
        "consistent", "varied", "progressive", "mindful"
    ],
    SCHEDULING: [
        "quick workouts", "flexible timing", "scheduled routine"
    ],
    EQUIPMENT: [
        "gym equipment", "home workouts", "minimal equipment"
    ],
    FOCUS: [
        "joint-friendly", "sport-specific", "recovery", "overall health"
    ]
};

export class TrainingPreferences {
    static tagCategories = TRAINING_TAG_GROUPS;

    static validateTags(tags) {
        const allValidTags = new Set(
            Object.values(this.tagCategories).flat()
        );
        const invalidTags = tags.filter(tag => !allValidTags.has(tag));
        return invalidTags.length === 0;
    }

    static trainingStyleDefinitions = [
        {
            id: "strength-builder",
            title: "Strength Builder",
            requiredTags: ["weight training", "strength", "progressive"],
            optionalTags: ["gym equipment", "high intensity", "consistent"],
            excludedTags: ["gentle", "quick workouts", "flexibility"],
            matchScore: 0.85,
            description: "Focus on building maximum strength through progressive weight training",
            division: "strength-focus"
        },
        {
            id: "muscle-sculptor",
            title: "Muscle Sculptor",
            requiredTags: ["weight training", "muscle building", "progressive"],
            optionalTags: ["gym equipment", "consistent", "high intensity"],
            excludedTags: ["cardio", "gentle", "minimal equipment"],
            matchScore: 0.8,
            description: "Build and shape muscle for improved aesthetics and size",
            division: "muscle-development"
        },
        {
            id: "home-fitness",
            title: "Home Fitness",
            requiredTags: ["home workouts", "minimal equipment", "flexible timing"],
            optionalTags: ["bodyweight", "varied", "moderate intensity"],
            excludedTags: ["gym equipment", "high intensity", "sport-specific"],
            matchScore: 0.75,
            description: "Effective workouts optimized for home with minimal equipment",
            division: "home-based"
        },
        {
            id: "time-saver",
            title: "Time Saver",
            requiredTags: ["quick workouts", "high intensity", "efficient"],
            optionalTags: ["flexible timing", "varied", "minimal equipment"],
            excludedTags: ["scheduled routine", "gentle", "recovery"],
            matchScore: 0.85,
            description: "Maximum results in minimum time with efficient workouts",
            division: "busy-lifestyle"
        },
        {
            id: "cardio-lover",
            title: "Cardio Lover",
            requiredTags: ["cardio", "endurance", "varied"],
            optionalTags: ["weight loss", "overall health", "moderate intensity"],
            excludedTags: ["muscle building", "strength", "gym equipment"],
            matchScore: 0.8,
            description: "Focus on cardiovascular health, endurance and calorie burn",
            division: "cardio-focus"
        },
        {
            id: "athletic-performer",
            title: "Athletic Performer",
            requiredTags: ["sport-specific", "varied", "progressive"],
            optionalTags: ["strength", "endurance", "high intensity"],
            excludedTags: ["gentle", "quick workouts", "minimal equipment"],
            matchScore: 0.85,
            description: "Training optimized for sports performance and athletic ability",
            division: "athletic"
        },
        {
            id: "balanced-approach",
            title: "Balanced Approach",
            requiredTags: ["varied", "overall health", "moderate intensity"],
            optionalTags: ["flexible timing", "consistent", "mindful"],
            excludedTags: ["high intensity", "sport-specific", "muscle building"],
            matchScore: 0.75,
            description: "Well-rounded fitness program balancing different aspects of health",
            division: "general-fitness"
        },
        {
            id: "gentle-wellness",
            title: "Gentle Wellness",
            requiredTags: ["joint-friendly", "gentle", "mindful"],
            optionalTags: ["flexibility", "recovery", "overall health"],
            excludedTags: ["high intensity", "weight training", "strength"],
            matchScore: 0.9,
            description: "Low-impact approach focusing on wellness and mobility",
            division: "wellness"
        },
        {
            id: "recovery-focused",
            title: "Recovery Focused",
            requiredTags: ["recovery", "joint-friendly", "mindful"],
            optionalTags: ["flexibility", "overall health", "gentle"],
            excludedTags: ["high intensity", "strength", "muscle building"],
            matchScore: 0.8,
            description: "Program designed to rebuild fitness after injury or setback",
            division: "rehabilitation"
        }
    ];

    static calculateMatchScore(selectedTags, definition) {
        let score = 0;
        const selectedTagSet = new Set(selectedTags);

        // Base matching score
        score += 0.1;

        // Calculate matches for required tags
        const requiredMatches = definition.requiredTags.filter(tag =>
            selectedTagSet.has(tag)
        ).length;
        score += (requiredMatches / definition.requiredTags.length) * 0.4;

        // Calculate matches for optional tags
        const optionalMatches = definition.optionalTags.filter(tag =>
            selectedTagSet.has(tag)
        ).length;
        score += (optionalMatches / definition.optionalTags.length) * 0.3;

        // Penalize for excluded tags
        const excludedMatches = definition.excludedTags.filter(tag =>
            selectedTagSet.has(tag)
        ).length;
        const penaltyPerExcluded = 0.1;
        score = Math.max(0.01, score - (excludedMatches * penaltyPerExcluded));

        // Add small random factor to break ties
        score += Math.random() * 0.05;

        return score;
    }

    static findBestMatch(selectedTags) {
        if (!selectedTags || selectedTags.length === 0) {
            // Return "Balanced Approach" as default
            const defaultStyle = this.trainingStyleDefinitions.find(
                def => def.id === "balanced-approach"
            ) || this.trainingStyleDefinitions[0];

            return {
                definition: defaultStyle,
                score: 0.1
            };
        }

        let matches = this.trainingStyleDefinitions.map(definition => ({
            definition,
            score: this.calculateMatchScore(selectedTags, definition)
        }));

        // Sort by score in descending order
        matches.sort((a, b) => b.score - a.score);

        return matches[0];
    }
}
