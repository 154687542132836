import React from 'react';
import { motion } from 'framer-motion';

const PrototypeWatermark = () => {
  // Original text array
  const watermarkText = Array(20).fill("PROTOTYPE");
  // Duplicate the array for a seamless scroll
  const repeatedWatermark = [...watermarkText, ...watermarkText];

  return (
    <div className="fixed inset-0 overflow-hidden pointer-events-none z-10">
      {Array(15).fill().map((_, rowIndex) => (
        <motion.div
          key={rowIndex}
          className="absolute whitespace-nowrap"
          style={{
            top: `${rowIndex * 8}vh`,
            left: 0,
            transform: 'rotate(-30deg)',
            transformOrigin: 'center',
          }}
          animate={{ x: ['-50%','0%'] }}
          transition={{
            x: {
              repeat: Infinity,
              duration: 200, // Increased duration for a slower scroll
              ease: "linear",
            },
          }}
        >
          <div className="flex">
            {repeatedWatermark.map((text, index) => (
              <span
                key={index}
                className="mx-8 text-4xl font-bold text-white"
                style={{ opacity: 0.05 }}
              >
                {text}
              </span>
            ))}
          </div>
        </motion.div>
      ))}
    </div>
  );
};

export default PrototypeWatermark;
