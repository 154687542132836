import React, { createContext, useContext, useState, useCallback } from 'react';
import { useAuth } from './AuthContext';
import {
    createPost,
    deletePost,
    fetchPostsByExercise,
    fetchPostsByUser,
    fetchAllPosts
} from '../firebase/Posts';
// Import our new interaction functions
import { addLike, removeLike, hasUserLiked, getLikeCount } from '../firebase/interactions/Likes';
import { 
    addComment as createNewComment, 
    deleteComment as removeExistingComment,
    getComments,
    getCommentCount
} from '../firebase/interactions/Comments';

const PostContext = createContext();

export const usePost = () => {
    const context = useContext(PostContext);
    if (!context) {
        throw new Error('usePost must be used within a PostProvider');
    }
    return context;
};

export const PostProvider = ({ children }) => {
    // Maintain separate post lists for different views
    const [userPosts, setUserPosts] = useState({});
    const [exercisePosts, setExercisePosts] = useState({});
    const [allPosts, setAllPosts] = useState([]);
    const [posts, setPosts] = useState([]); // Current active posts
    const [loading, setLoading] = useState(false);
    const { currentUser } = useAuth();

    // Add a new state to track the current view context
    const [currentView, setCurrentView] = useState('all'); // 'all', 'profile', 'exercise', etc.

    // Load posts for an exercise
    const loadExercisePosts = useCallback((exerciseId) => {
        setLoading(true);
        console.log(`Loading posts for exercise: ${exerciseId}`);
        return fetchPostsByExercise(exerciseId, (postsData) => {
            console.log(`Received ${postsData.length} posts for exercise ${exerciseId}`);
            setExercisePosts(prev => ({...prev, [exerciseId]: postsData}));
            setPosts(postsData); // Set as current active posts
            setLoading(false);
        });
    }, []);

    // Load posts for a user
    const loadUserPosts = useCallback((userId) => {
        setLoading(true);
        setCurrentView('profile');
        console.log(`Loading posts for user: ${userId}`);
        return fetchPostsByUser(userId, (postsData) => {
            console.log(`Received ${postsData.length} posts for user ${userId}`);
            setUserPosts(prev => ({...prev, [userId]: postsData}));
            setPosts(postsData); // Set as current active posts
            setLoading(false);
        });
    }, []);

    // Load all posts
    const loadAllPosts = useCallback(() => {
        setLoading(true);
        console.log('Loading all posts');
        return fetchAllPosts((postsData) => {
            console.log(`Received ${postsData.length} posts for all posts feed`);
            setAllPosts(postsData);
            setPosts(postsData); // Set as current active posts
            setLoading(false);
        });
    }, []);

    // Create new post with media
    const addPost = useCallback(async (postData, mediaFile) => {
        if (!currentUser) {
            throw new Error('Must be logged in to create a post');
        }
        try {
            const postId = await createPost(currentUser.uid, postData, mediaFile);
            return postId;
        } catch (error) {
            console.error("Error in addPost:", error);
            throw error;
        }
    }, [currentUser]);

    // Delete post
    const removePost = useCallback(async (postId) => {
        try {
            await deletePost(postId);
        } catch (error) {
            console.error('Error removing post:', error);
            throw error;
        }
    }, []);

    // Handle like/unlike using our new functions
    const handleLike = useCallback(async (postId) => {
        if (!currentUser) {
            throw new Error('Must be logged in to like posts');
        }
        try {
            // Check if user already liked the post
            const alreadyLiked = await hasUserLiked(postId, currentUser.uid);
            
            if (alreadyLiked) {
                await removeLike(postId, currentUser.uid);
            } else {
                await addLike(postId, currentUser.uid);
            }
            
            // Update the post in the local state with new like count
            const likeCount = await getLikeCount(postId);
            setPosts(prevPosts => 
                prevPosts.map(post => 
                    post.id === postId 
                        ? { 
                            ...post, 
                            likeCount,
                            isLikedByCurrentUser: !alreadyLiked
                          } 
                        : post
                )
            );
            
            return !alreadyLiked; // Return the new like state
        } catch (error) {
            console.error('Error toggling like:', error);
            throw error;
        }
    }, [currentUser]);

    // Check if a post is liked by current user
    const checkPostLiked = useCallback(async (postId) => {
        if (!currentUser) return false;
        try {
            return await hasUserLiked(postId, currentUser.uid);
        } catch (error) {
            console.error('Error checking if post is liked:', error);
            return false;
        }
    }, [currentUser]);

    // Add comment using our new function
    const createComment = useCallback(async (postId, text) => {
        if (!currentUser) {
            throw new Error('Must be logged in to comment');
        }
        try {
            // We need the username for the comment
            const username = currentUser.displayName || 'Anonymous';
            const commentId = await createNewComment(postId, currentUser.uid, username, text);
            
            // Update the post in the local state with new comment count
            const commentCount = await getCommentCount(postId);
            setPosts(prevPosts => 
                prevPosts.map(post => 
                    post.id === postId 
                        ? { ...post, commentCount } 
                        : post
                )
            );
            
            return commentId;
        } catch (error) {
            console.error('Error adding comment:', error);
            throw error;
        }
    }, [currentUser]);

    // Remove comment using our new function
    const removeComment = useCallback(async (postId, commentId) => {
        try {
            await removeExistingComment(postId, commentId);
            
            // Update the post in the local state with new comment count
            const commentCount = await getCommentCount(postId);
            setPosts(prevPosts => 
                prevPosts.map(post => 
                    post.id === postId 
                        ? { ...post, commentCount } 
                        : post
                )
            );
        } catch (error) {
            console.error('Error removing comment:', error);
            throw error;
        }
    }, []);

    // Get comments for a post
    const getPostComments = useCallback(async (postId) => {
        try {
            return await getComments(postId);
        } catch (error) {
            console.error('Error getting comments:', error);
            return [];
        }
    }, []);

    // Add a function to clear the current posts view
    const clearCurrentView = useCallback(() => {
        setPosts([]);
        setCurrentView('none');
    }, []);

    const value = {
        posts, // Current active posts
        userPosts, // All user posts by userId
        exercisePosts, // All exercise posts by exerciseId
        allPosts, // All posts for the feed
        loading,
        loadExercisePosts,
        loadUserPosts,
        loadAllPosts,
        addPost,
        removePost,
        handleLike,
        checkPostLiked,
        createComment,
        removeComment,
        getPostComments,
        isLiked: useCallback(async (postId) => {
            if (!currentUser) return false;
            return await checkPostLiked(postId);
        }, [checkPostLiked, currentUser]),
        currentView,
        clearCurrentView,
    };

    return (
        <PostContext.Provider value={value}>
            {children}
        </PostContext.Provider>
    );
};