import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom'
import { useAuth } from '../../contexts/AuthContext'
import { doCreateUserWithEmailAndPassword } from '../../firebase/Auth'
import { getDatabase, ref, get } from 'firebase/database'; // If using Firebase Realtime Database
import logoImage from '../../assets/logo.png';  // Add this import
import { trackAccountCreation } from '../../analytics/conversations';
import PrototypeWatermark from '../../components/PrototypeWatermark';


const Register = () => {

    const navigate = useNavigate()

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setconfirmPassword] = useState('')
    const [isRegistering, setIsRegistering] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    const { userLoggedIn, currentUser } = useAuth()

    // Check onboarding status once the user is logged in
    useEffect(() => {
        if (userLoggedIn && currentUser) {
            const userId = currentUser.uid;
            checkOnboardingStatus(userId).then((onboardingComplete) => {
                if (onboardingComplete) {
                    navigate('/home');
                } else {
                    navigate('/onboarding');
                }
            });
        }
    }, [userLoggedIn, currentUser, navigate]);

    const onSubmit = async (e) => {
        e.preventDefault();
        if (password !== confirmPassword) {
            setErrorMessage('Passwords do not match');
            return;
        }

        if (!isRegistering) {
            setIsRegistering(true);
            setErrorMessage(''); // Clear any previous error messages
            try {
                const userCredential = await doCreateUserWithEmailAndPassword(email, password);
                
                // Track the account creation
                trackAccountCreation('email', userCredential.user.uid);
            } catch (error) {
                if (error.code === 'auth/email-already-in-use') {
                    setErrorMessage('An account with this email already exists. Please use a different email or try logging in.');
                } else {
                    setErrorMessage('Failed to create an account. Please try again.');
                }
            } finally {
                setIsRegistering(false);
            }
        }
    };

    const checkOnboardingStatus = async (userId) => {
        const db = getDatabase();
        const onboardingRef = ref(db, `users/${userId}/onboardingComplete`);
        const snapshot = await get(onboardingRef);
        return snapshot.exists() ? snapshot.val() : false;
    };

    return (
        <div className="flex items-center justify-center bg-black p-4" style={{ height: '100dvh' }}>
            <PrototypeWatermark />
            
            <div className="w-full max-w-md  p-8 shadow-lg">
                <div className="text-center mb-8">
                    <div className="w-full h-32 mx-auto mb-4">
                        <img
                            src={logoImage}
                            alt="Logo"
                            className="w-full h-full object-contain"
                        />
                    </div>
                </div>
                <main className="w-full h-full flex self-center place-content-center place-items-center">
                    <div className="bg-white w-full text-gray-600 space-y-5 p-6 shadow-xl border">
                        <form onSubmit={onSubmit} className="space-y-4">
                            <div>
                                <label className="text-sm text-gray-600 font-bold">
                                    Email
                                </label>
                                <input
                                    type="email"
                                    autoComplete='email'
                                    required
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    className="w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-black shadow-sm transition duration-300"
                                />
                            </div>

                            <div>
                                <label className="text-sm text-gray-600 font-bold">
                                    Password
                                </label>
                                <input
                                    disabled={isRegistering}
                                    type="password"
                                    autoComplete='new-password'
                                    required
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    className="w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-black shadow-sm transition duration-300"
                                />
                            </div>

                            <div>
                                <label className="text-sm text-gray-600 font-bold">
                                    Confirm Password
                                </label>
                                <input
                                    disabled={isRegistering}
                                    type="password"
                                    autoComplete='off'
                                    required
                                    value={confirmPassword}
                                    onChange={(e) => setconfirmPassword(e.target.value)}
                                    className="w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-black shadow-sm transition duration-300"
                                />
                            </div>

                            {errorMessage && (
                                <p className='text-red-600 font-bold text-sm'>{errorMessage}</p>
                            )}

                            <button
                                type="submit"
                                disabled={isRegistering}
                                className={`w-full px-4 py-2 text-white font-medium bg-black ${isRegistering ? 'bg-gray-300 cursor-not-allowed' : 'bg-black hover:bg-gray-700 hover:shadow-xl transition duration-300'}`}
                            >
                                {isRegistering ? 'Signing Up...' : 'Sign Up'}
                            </button>
                        </form>
                        <div className="text-sm text-center">
                            Already have an account? {' '}
                            <Link to={'/login'} className="text-gray-600 hover:underline font-medium">
                                Continue
                            </Link>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    )
}

export default Register
