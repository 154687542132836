import { firestore } from "./Init";
import {
  doc,
  onSnapshot,
  getDoc,
  updateDoc,
  setDoc,
  deleteDoc,
  collection,
  query,
  where,
  getDocs
} from "firebase/firestore";
import {
  setPublicProfileData,
  syncPublicProfileWithUserDoc,
  deletePublicProfile
} from "./PublicProfile";
import { setPrivateProfile } from "./PrivateProfile";
import {
  isUsernameTaken,
  reserveUsername,
  releaseUsername
} from "./Username";
import { PUBLIC_PROFILE_FIELDS } from "./constants";
import { validateUserData } from "./validation";

// Listen for changes to the user document
export const fetchUserData = (uid, callback) => {
  if (!uid) return null;

  const userRef = doc(firestore, "users", uid);
  return onSnapshot(userRef, (snapshot) => {
    if (!snapshot.exists()) {
      callback(null);
      return;
    }

    const data = snapshot.data();
    // Merge doc ID into the data object
    callback({ ...data, uid: snapshot.id });
  });
};

// Update private user data only (including handling username changes)
export const updateUserData = async (uid, updates) => {
  if (!uid) return;
  const validationErrors = validateUserData(updates);
  if (validationErrors) {
    throw new Error(JSON.stringify(validationErrors));
  }

  let oldUsername = null;
  let newUsername = null;

  try {
    const userRef = doc(firestore, "users", uid);
    const snapshot = await getDoc(userRef);
    const currentData = snapshot.data();

    const mergedData = {
      ...currentData,
      ...updates,
      lastUpdated: new Date().toISOString()
    };

    // Handle username change if applicable
    if (mergedData.username !== currentData?.username) {
      newUsername = mergedData.username;
      oldUsername = currentData?.username;

      // Check if new username is already reserved via the usernames collection
      const usernameDocRef = doc(
        firestore,
        "usernames",
        newUsername.toLowerCase().trim()
      );
      const usernameSnapshot = await getDoc(usernameDocRef);
      const usernameData = usernameSnapshot.data();

      if (!usernameData || usernameData.uid !== uid) {
        const taken = await isUsernameTaken(newUsername);
        if (taken) {
          throw new Error("Username already taken");
        }

        const reserved = await reserveUsername(newUsername, uid);
        if (!reserved) {
          throw new Error("Failed to reserve new username");
        }
      }
    }

    await setDoc(userRef, mergedData, { merge: true });

    // If public fields are updated, sync the public profile
    const hasPublicUpdates = Object.keys(updates).some(
      (key) => PUBLIC_PROFILE_FIELDS[key]
    );
    if (hasPublicUpdates) {
      await syncPublicProfileWithUserDoc(uid);
    }

    if (oldUsername && newUsername) {
      await releaseUsername(oldUsername);
    }
  } catch (error) {
    if (newUsername) {
      await releaseUsername(newUsername);
    }
    console.error("Error updating user data:", error);
    throw error;
  }
};

// Update user privacy settings and update the public profile accordingly
export const updateUserPrivacySettings = async (uid, isPrivate) => {
  if (!uid) return;
  try {
    const userRef = doc(firestore, "users", uid);
    const snapshot = await getDoc(userRef);
    if (!snapshot.exists()) {
      throw new Error("User not found");
    }
    const userData = snapshot.data();

    await updateDoc(userRef, {
      isPrivate,
      lastUpdated: new Date().toISOString()
    });

    await setPublicProfileData(uid, userData, isPrivate);

    // Record privacy change (e.g. in a separate collection)
    const privacyChangeRef = doc(firestore, "privacyChanges", uid);
    await setDoc(privacyChangeRef, {
      timestamp: new Date().toISOString(),
      isPrivate
    });
  } catch (error) {
    console.error("Error updating privacy settings:", error);
    throw error;
  }
};

// Update public user data; after updating the main user doc, update the public profile if needed
export const updatePublicUserData = async (uid, updates) => {
  if (!uid) return;
  try {
    await updateUserData(uid, updates);
    const userRef = doc(firestore, "users", uid);
    const snapshot = await getDoc(userRef);
    const userData = snapshot.data();

    if (!userData.isPrivate) {
      await setPublicProfileData(uid, userData, false);
    }
  } catch (error) {
    console.error("Error updating public user data:", error);
    throw error;
  }
};

// Helper to check if updates contain any public fields
export const containsPublicFields = (updates) => {
  return Object.keys(updates).some((key) => PUBLIC_PROFILE_FIELDS[key]);
};

// Initialize AI assistants for a user if not already set
export const initializeAssistants = async (uid, assistants) => {
  if (!uid) return;
  try {
    const userRef = doc(firestore, "users", uid);
    const snapshot = await getDoc(userRef);
    const data = snapshot.data();

    if (!data?.assistants) {
      await updateDoc(userRef, {
        assistants: assistants
      });
    }
    return data?.assistants || assistants;
  } catch (error) {
    console.error("Error initializing assistants:", error);
    throw error;
  }
};

// Fetch a user by their username using a Firestore query
export const fetchUserByUsername = async (username) => {
  if (!username || typeof username !== "string") {
    return null;
  }
  const sanitizedUsername = username.toLowerCase().trim();
  try {
    const usersRef = collection(firestore, "users");
    const q = query(usersRef, where("username", "==", sanitizedUsername));
    const querySnapshot = await getDocs(q);

    if (querySnapshot.empty) {
      return null;
    }

    const userDoc = querySnapshot.docs[0];
    const userData = userDoc.data();

    return {
      uid: userDoc.id,
      username: userData.username || "",
      photoURL: userData.photoURL || ""
    };
  } catch (error) {
    console.error("Error fetching user by username:", error);
    throw error;
  }
};

// Create the user document in Firestore and initialize profiles
export const createUserDocument = async (user, additionalData = {}) => {
  if (!user?.uid) {
    throw new Error("Invalid user object");
  }

  try {
    const { email } = additionalData;
    const createdAt = new Date();
    
    // Process onboarding data to handle skipped steps
    const processedData = { ...additionalData };
    
    // If fitness goals were skipped, ensure we store this information
    if (additionalData.skippedFitnessGoals) {
      processedData.fitnessGoal = {}; 
      processedData.fitnessGoalTags = [];
    }
    
    // If training preferences were skipped, ensure we store this information
    if (additionalData.skippedTrainingPreferences) {
      processedData.trainingStyle = {};
      processedData.trainingStyleTags = [];
    }
    
    const userRef = doc(firestore, "users", user.uid);
    const userData = {
      email,
      createdAt: createdAt.toISOString(),
      lastSignInTime: createdAt.toISOString(),
      photoURL: user.photoURL || "",
      // Initialize follower and following counts
      followerCount: 0,
      followingCount: 0,
      ...processedData
    };

    await setDoc(userRef, userData, { merge: true });

    try {
      // Create public profile data with available fields
      const publicProfileData = {
        bio: "",
        profileImage: user.photoURL || "",
        // Also initialize counts in the public profile
        followerCount: 0,
        followingCount: 0
      };

      await setPublicProfileData(user.uid, publicProfileData, false);
      await setPrivateProfile(user.uid, { privacy: false, followers: {}, following: {}, chatbotThreads: {} });
    } catch (error) {
      await cleanupFailedUserCreation(user.uid);
      throw new Error(`Profile creation failed: ${error.message}`);
    }

    return userRef;
  } catch (error) {
    console.error("Error in createUserDocument:", error);
    await cleanupFailedUserCreation(user.uid);
    throw error;
  }
};

// Cleanup function to remove the user document and public profile if creation fails
const cleanupFailedUserCreation = async (uid) => {
  if (!uid) return;
  try {
    await Promise.all([
      (async () => {
        try {
          const userRef = doc(firestore, "users", uid);
          await deleteDoc(userRef);
        } catch (e) {
          console.error("Error removing user document:", e);
        }
      })(),
      (async () => {
        try {
          await deletePublicProfile(uid);
        } catch (e) {
          console.error("Error removing public profile:", e);
        }
      })()
    ]);
  } catch (error) {
    console.error("Error during cleanup:", error);
  }
};

// Get the user's onboarding status from the main user document
export const getUserOnboardingStatus = async (uid) => {
  if (!uid) return false;
  try {
    const userRef = doc(firestore, "users", uid);
    const snapshot = await getDoc(userRef);
    return snapshot.exists() ? snapshot.data().onboardingComplete : false;
  } catch (error) {
    console.error("Error getting onboarding status:", error);
    return false;
  }
};

// Update the user's last sign in time
export const updateUserLastSignIn = async (uid) => {
  if (!uid) return;
  try {
    const userRef = doc(firestore, "users", uid);
    await updateDoc(userRef, {
      lastSignInTime: new Date().toISOString()
    });
  } catch (error) {
    console.error("Error updating last sign in:", error);
    throw error;
  }
};

// Set the user's username during onboarding, ensuring uniqueness and reserving it
export const setUserUsername = async (uid, username) => {
  if (!uid || !username) {
    throw new Error("User ID and username are required");
  }

  try {
    const taken = await isUsernameTaken(username);
    if (taken) {
      throw new Error("Username already taken");
    }

    const reserved = await reserveUsername(username, uid);
    if (!reserved) {
      throw new Error("Failed to reserve username");
    }

    const userRef = doc(firestore, "users", uid);
    await updateDoc(userRef, {
      username,
      lastUpdated: new Date().toISOString()
    });

    await setPublicProfileData(uid, { username }, false);

    return true;
  } catch (error) {
    console.error("Error setting username:", error);
    await releaseUsername(username);
    throw error;
  }
};

/**
 * Get user profile data by user ID
 * @param {string} userId - The user ID to fetch
 * @returns {Promise<Object|null>} - User profile data or null if not found
 */
export const getUserProfileById = async (userId) => {
  if (!userId) return null;
  
  try {
    const userProfileRef = doc(firestore, `users/${userId}/publicProfile/info`);
    const docSnap = await getDoc(userProfileRef);
    
    if (docSnap.exists()) {
      return {
        id: userId,
        ...docSnap.data()
      };
    }
    
    // If public profile doesn't exist, try getting basic user data
    const userRef = doc(firestore, `users/${userId}`);
    const userSnap = await getDoc(userRef);
    
    if (userSnap.exists()) {
      return {
        id: userId,
        ...userSnap.data()
      };
    }
    
    return null;
  } catch (error) {
    console.error("Error fetching user profile:", error);
    return null;
  }
};

/**
 * Get multiple user profiles by IDs
 * @param {Array<string>} userIds - Array of user IDs to fetch
 * @returns {Promise<Array<Object>>} - Array of user profile data
 */
export const getUserProfilesByIds = async (userIds) => {
  if (!userIds || userIds.length === 0) return [];
  
  try {
    const profiles = await Promise.all(
      userIds.map(userId => getUserProfileById(userId))
    );
    
    // Filter out null results
    return profiles.filter(profile => profile !== null);
  } catch (error) {
    console.error("Error fetching multiple user profiles:", error);
    return [];
  }
};
