import React from 'react';

/**
 * A reusable fixed button component that appears at the bottom of the screen
 * with proper spacing for mobile and desktop layouts.
 * 
 * @param {Object} props
 * @param {string} props.text - The text to display on the button
 * @param {function} props.onClick - The function to call when the button is clicked
 * @param {boolean} props.disabled - Whether the button should be disabled
 * @param {string} props.className - Additional classes to apply to the button
 * @param {boolean} props.isLoading - Whether to show a loading state
 * @param {string} props.loadingText - Text to display when loading
 */
const FixedBottomButton = ({ 
  text, 
  onClick, 
  disabled = false, 
  className = "", 
  isLoading = false,
  loadingText = "Loading...",
  type = "button"
}) => {
  return (
    <>
      {/* Fixed button container with enhanced shadow */}
      <div className="fixed bottom-0 left-0 right-0 md:left-16 lg:left-64 bg-white p-4 md:pb-4 pb-16 z-10 border-t border-gray-200"
           style={{ boxShadow: '0 -10px 25px rgba(0, 0, 0, 0.2)' }}>
        <button
          type={type}
          onClick={onClick}
          className={`w-full bg-black text-white font-bold py-3 sm:py-4 px-4 sm:px-6 disabled:opacity-50 transition-all duration-200 ${className}`}
          disabled={disabled || isLoading}
        >
          {isLoading ? loadingText : text}
        </button>
      </div>
      
      {/* Spacer to prevent content from being hidden behind fixed button */}
      <div className="h-32 md:h-24"></div>
    </>
  );
};

export default FixedBottomButton; 