// fitnessGoals.js

// Simplified tag group definitions for FitnessGoals
export const FITNESS_TAG_GROUPS = {
    BODY_TYPE: [
        "slim", "muscular", "lean", "bulky"
    ],
    DEFINITION: [
        "toned", "defined", "ripped"
    ],
    FOCUS_AREA: [
        "upper body", "lower body", "core", "full body"
    ],
    SHAPE: [
        "v-shape", "hourglass", "balanced"
    ],
    GOALS: [
        "everyday fit", "photo ready", "competition ready"
    ]
};

export class FitnessGoals {
    static tagGroups = FITNESS_TAG_GROUPS;

    static validateTags(tags) {
        const validTagSet = new Set(
            Object.values(this.tagGroups).flat()
        );
        const invalidTags = tags.filter(tag => !validTagSet.has(tag));
        return invalidTags.length === 0;
    }

    // Simplified fitness goal definitions
    static goalDefinitions = [
        {
            id: "slim-toned",
            title: "Slim & Toned",
            type: "Everyday Fit",
            requiredTags: ["slim", "toned"],
            optionalTags: ["lower body", "core", "hourglass"],
            excludedTags: ["bulky", "competition ready"],
            matchScore: 0.8,
            description: "A lean, toned look with emphasis on definition rather than size",
            division: "general-fitness"
        },
        {
            id: "athletic-build",
            title: "Athletic Build",
            type: "Performance Look",
            requiredTags: ["lean", "defined", "balanced"],
            optionalTags: ["full body", "core", "upper body"],
            excludedTags: ["bulky", "competition ready"],
            matchScore: 0.85,
            description: "Balanced, athletic physique with good definition and functional strength",
            division: "athletic"
        },
        {
            id: "classic-physique",
            title: "Classic Physique",
            type: "Aesthetic Goals",
            requiredTags: ["muscular", "v-shape", "defined"],
            optionalTags: ["upper body", "balanced", "toned"],
            excludedTags: ["slim", "hourglass"],
            matchScore: 0.9,
            description: "Traditional muscular look with emphasis on proportions and definition",
            division: "aesthetic"
        },
        {
            id: "model-fit",
            title: "Model Fit",
            type: "Photo-Ready",
            requiredTags: ["lean", "toned", "photo ready"],
            optionalTags: ["defined", "balanced", "core"],
            excludedTags: ["bulky", "competition ready"],
            matchScore: 0.75,
            description: "Lean and defined look that photographs well",
            division: "aesthetic"
        },
        {
            id: "beach-ready",
            title: "Beach Ready",
            type: "Seasonal Goals",
            requiredTags: ["toned", "defined", "everyday fit"],
            optionalTags: ["core", "upper body", "lower body"],
            excludedTags: ["bulky", "competition ready"],
            matchScore: 0.85,
            description: "Defined, confident look for the beach or pool",
            division: "seasonal"
        },
        {
            id: "competition-physique",
            title: "Competition Physique",
            type: "Competition Goals",
            requiredTags: ["muscular", "ripped", "competition ready"],
            optionalTags: ["v-shape", "balanced", "defined"],
            excludedTags: ["slim", "everyday fit"],
            matchScore: 0.9,
            description: "Competition-level muscular development and definition",
            division: "competition"
        },
        {
            id: "curvy-fit",
            title: "Curvy & Fit",
            type: "Body Shape Goals",
            requiredTags: ["hourglass", "toned", "lower body"],
            optionalTags: ["defined", "balanced", "everyday fit"],
            excludedTags: ["v-shape", "bulky", "ripped"],
            matchScore: 0.85,
            description: "Enhanced curves with good muscle tone and definition",
            division: "shape-focused"
        },
        {
            id: "functional-fitness",
            title: "Functional Fitness",
            type: "Lifestyle Goals",
            requiredTags: ["balanced", "full body", "everyday fit"],
            optionalTags: ["toned", "core", "lean"],
            excludedTags: ["bulky", "competition ready", "photo ready"],
            matchScore: 0.75,
            description: "Balanced approach focused on everyday strength and health",
            division: "functional"
        }
    ];

    static calculateMatchScore(selectedTags, goalDefinition) {
        let score = 0;
        const selectedTagSet = new Set(selectedTags);

        // Base matching score
        score += 0.1;

        // Calculate matches for required tags
        const requiredMatches = goalDefinition.requiredTags.filter(tag =>
            selectedTagSet.has(tag)
        ).length;
        score += (requiredMatches / goalDefinition.requiredTags.length) * 0.4;

        // Calculate matches for optional tags
        const optionalMatches = goalDefinition.optionalTags.filter(tag =>
            selectedTagSet.has(tag)
        ).length;
        score += (optionalMatches / goalDefinition.optionalTags.length) * 0.3;

        // Penalize for excluded tags
        const excludedMatches = goalDefinition.excludedTags.filter(tag =>
            selectedTagSet.has(tag)
        ).length;
        const penaltyPerExcluded = 0.1;
        score = Math.max(0.01, score - (excludedMatches * penaltyPerExcluded));

        // Add small random factor to break ties
        score += Math.random() * 0.05;

        return score;
    }

    static findBestMatch(selectedTags) {
        if (!selectedTags || selectedTags.length === 0) {
            return {
                definition: this.goalDefinitions[0],
                score: 0.1
            };
        }

        let matches = this.goalDefinitions.map(definition => ({
            definition,
            score: this.calculateMatchScore(selectedTags, definition)
        }));

        // Sort by score in descending order
        matches.sort((a, b) => b.score - a.score);

        return matches[0];
    }
}