import { getFunctions, httpsCallable } from "firebase/functions";
import {
  collection,
  addDoc,
  onSnapshot,
  deleteDoc,
  doc
} from "firebase/firestore";
import { firestore } from "./Init";

// Generate workout using AI
export const generateWorkout = async (userId, threadId, fitnessGoal) => {
  if (!userId || !threadId || !fitnessGoal) {
    throw new Error("Missing required parameters for workout generation");
  }
  try {
    const functions = getFunctions();
    const generateWorkoutFunction = httpsCallable(functions, "generateWorkout");

    // Get workout plan from cloud function
    const result = await generateWorkoutFunction({
      threadId,
      goal: fitnessGoal
    });

    // Save workout to Firestore
    const workoutData = {
      ...result.data,
      timestamp: Date.now(),
      userId
    };

    await saveWorkout(userId, workoutData);
    return result.data;
  } catch (error) {
    console.error("Error generating workout:", error);
    throw error;
  }
};

// Save workout to Firestore
export const saveWorkout = async (userId, workoutData) => {
  if (!userId) return;
  try {
    const userWorkoutsCollectionRef = collection(
      firestore,
      "users",
      userId,
      "workouts"
    );
    const newWorkoutDoc = await addDoc(userWorkoutsCollectionRef, {
      ...workoutData,
      timestamp: Date.now()
    });
    return newWorkoutDoc.id;
  } catch (error) {
    console.error("Error saving workout:", error);
    throw error;
  }
};

// Fetch workout history with real‑time updates
export const fetchWorkoutHistory = (userId, callback) => {
  if (!userId) return null;
  const userWorkoutsCollectionRef = collection(
    firestore,
    "users",
    userId,
    "workouts"
  );
  const unsubscribe = onSnapshot(
    userWorkoutsCollectionRef,
    (snapshot) => {
      const workoutsArray = snapshot.docs
        .map((docSnap) => ({
          id: docSnap.id,
          ...docSnap.data()
        }))
        .sort((a, b) => b.timestamp - a.timestamp);
      callback(workoutsArray);
    },
    (error) => {
      console.error("Error fetching workout history:", error);
      callback([]);
    }
  );
  return unsubscribe;
};

// Delete workout from Firestore
export const deleteWorkout = async (userId, workoutId) => {
  if (!userId) return;
  try {
    const workoutDocRef = doc(firestore, "users", userId, "workouts", workoutId);
    await deleteDoc(workoutDocRef);
    console.log(`Workout ${workoutId} deleted successfully`);
  } catch (error) {
    console.error(`Error deleting workout ${workoutId}:`, error);
    throw error;
  }
};
